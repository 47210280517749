import { createWebHistory, createRouter } from "vue-router";

// default routes components load
import Home from "@/views/chatwoot/Home.vue";
import sharedInbox from "@/views/chatwoot/shared-inbox/sharedInbox.vue";
import FeatureChannels from "@/views/chatwoot/channels/channels.vue";
import liveChat from "@/views/chatwoot/liveChat/liveChat.vue";
import mobileApps from "@/views/chatwoot/mobileApps/MobileApps.vue";

import privacyPolicy from "@/views/chatwoot/privacy/PrivacyPolicy.vue";

import chatbots from "@/views/chatwoot/chatbots/chatbots.vue";
import FeaturesIntegrations from "@/views/chatwoot/integrations/integrations.vue";
import security from "@/views/chatwoot/security/security.vue";
import termsOfService from "@/views/chatwoot/termsOfService/termsOfService.vue";
import labels from "@/views/chatwoot/labels/labels.vue";
import Automations from "@/views/chatwoot/Automations/Automations.vue";
import teams from "@/views/chatwoot/teams/teams.vue";
import contactNotes from "@/views/chatwoot/contactNotes/contactNotes.vue";
import privateNotes from "@/views/chatwoot/privateNotes/privateNotes.vue";
import contactSegments from "@/views/chatwoot/contactSegments/contactSegments.vue";
import businessHours from "@/views/chatwoot/businessHours/businessHours.vue";
import liveViewOfReports from "@/views/chatwoot/liveViewOfReports/liveViewOfReports.vue";
import conversationReports from "@/views/chatwoot/conversationReports/conversationReports.vue";
import agentReports from "@/views/chatwoot/agentReports/agentReports.vue";
import labelReports from "@/views/chatwoot/labelReports/labelReports.vue";
import csatReports from "@/views/chatwoot/csatReports/csatReports.vue";
import inboxReports from "@/views/chatwoot/inboxReports/inboxReports.vue";
import teamReports from "@/views/chatwoot/teamReports/teamReports.vue";
import keyboardShortcuts from "@/views/chatwoot/keyboardShortcuts/keyboardShortcuts.vue";
import commandBar from "@/views/chatwoot/commandBar/commandBar.vue";
import bulkActions from "@/views/chatwoot/bulkActions/bulkActions.vue";
import cannedResponses from "@/views/chatwoot/cannedResponses/cannedResponses.vue";
import agentCapacity from "@/views/chatwoot/agentCapacity/agentCapacity.vue";
import whatsappBusiness from "@/views/chatwoot/whatsappBusiness/whatsappBusiness.vue";
import twitterIntegration from "@/views/chatwoot/twitterIntegration/twitterIntegration.vue";
import facebookIntegration from "@/views/chatwoot/facebookIntegration/facebookIntegration.vue";
import telegramIntegration from "@/views/chatwoot/telegramIntegration/telegramIntegration.vue";

import lineIntegration from "@/views/chatwoot/lineIntegration/lineIntegration.vue";

import instagramIntegration from "@/views/chatwoot/instagramIntegration/instagramIntegration.vue";
import smsIntegration from "@/views/chatwoot/smsIntegration/smsIntegration.vue";
import emailIntegration from "@/views/chatwoot/emailIntegration/emailIntegration.vue";
import helpCenter from "@/views/chatwoot/helpCenter/helpCenter.vue";
import blog from "@/views/chatwoot/Blog/blog.vue";
import custmerService from "@/views/chatwoot/Blog/custmerService.vue";
import engineering from "@/views/chatwoot/Blog/engineering.vue";
import moreChatwoot from "@/views/chatwoot/Blog/moreChatwoot.vue";
import openSource from "@/views/chatwoot/Blog/openSource.vue";
import productUpdate from "@/views/chatwoot/Blog/productUpdate.vue";
import wootJournal from "@/views/chatwoot/Blog/wootJournal.vue";
import pricing from "@/views/chatwoot/Blog/pricing.vue";
import Pre_Chat_Form from "@/components/docs/product/feature/Pre_Chat_Form.vue";
import Priority from "@/components/docs/product/feature/Priority.vue";
import Dialogflow from "@/components/docs/product/feature/Integrations/Dialogflow.vue";
import Introduction from "@/components/docs/product/Introduction.vue";
import CreateChatwootAccount from "@/components/docs/product/setupAccount/CreateChatwootAccount.vue";
import ConfigureProfile from "@/components/docs/product/setupAccount/ConfigureProfile.vue";
import ConfigureAccount from "@/components/docs/product/setupAccount/ConfigureAccount.vue";
import AddAgentSetting from "@/components/docs/product/setupAccount/AddAgentSetting.vue";
import AddInboxes from "@/components/docs/product/setupAccount/AddInboxes.vue";
import AddTeams from "@/components/docs/product/setupAccount/AddTeams.vue";
import AddLabels from "@/components/docs/product/setupAccount/AddLabels.vue";
import Integrations from "@/components/docs/product/setupAccount/Integrations.vue";
import Applications from "@/components/docs/product/setupAccount/Applications.vue";
import Channels from "@/components/docs/product/channels/channels.vue";
import WebsiteAddConfiguration from "@/components/docs/product/channels/WebsiteLiveChat/add_configure.vue";
import WebsiteSDKSetup from "@/components/docs/product/channels/WebsiteLiveChat/SDKSetup.vue";
import WebsiteIdentityValidation  from "@/components/docs/product/channels/WebsiteLiveChat/IdentityValidation.vue";
import WebsiteDarkmode  from "@/components/docs/product/channels/WebsiteLiveChat/Darkmode.vue";
import WebsiteConversationContinuity  from "@/components/docs/product/channels/WebsiteLiveChat/ConversationContinuity.vue";
import WebsiteWebflowIntegration from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/WebflowIntegration.vue";
import WebsiteDocusaurus from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/Docusaurus.vue";
import WebsiteGoogleTagManager from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/Google_Tag_Manager.vue";
import WebsiteReactNative from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/ReactNative.vue";
import WebsiteNextJS from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/Next_js.vue";
import WebsiteVueJS from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/Vue_js.vue";
import WebsiteGatsBy from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/gatsby.vue";
import WebsiteWordpress from "@/components/docs/product/channels/WebsiteLiveChat/SetupInstructions/wordpress.vue";
import Facebook from "@/components/docs/product/channels/Facebook.vue";
import Instagram from "@/components/docs/product/channels/Instagram.vue";
import Twitter from "@/components/docs/product/channels/Twitter.vue";
import WhatsAppCloud from "@/components/docs/product/channels/WhatsAppChannel/WhatsAppCloud.vue"; 
import WhatsAppTwilio from "@/components/docs/product/channels/WhatsAppChannel/Twilio.vue";
import WhatsAppDialog from "@/components/docs/product/channels/WhatsAppChannel/dialog.vue";
import SMSTwilio from "@/components/docs/product/channels/SMSchannel/TwilioChannel.vue";
import SMSBandwidth from "@/components/docs/product/channels/SMSchannel/Bandwidth.vue";
import EmailCreateChannel from "@/components/docs/product/channels/Email/CreateChannel.vue";
import EmailConfigureIMAP from "@/components/docs/product/channels/Email/ConfigureIMAP.vue";
import EmailConfigureSMTP from "@/components/docs/product/channels/Email/ConfigureSMTP.vue";
import EmailForwardtoEmail from "@/components/docs/product/channels/Email/ForwardtoEmail.vue";
import GmailEnableimap from "@/components/docs/product/channels/Email/Gmail/Enableimap.vue";
import GmailLessSecureAccess from "@/components/docs/product/channels/Email/Gmail/Lesssecureaccess.vue";
import GmailGenerateAppPassword from "@/components/docs/product/channels/Email/Gmail/Generateapppassword.vue";
import GmailAddForwardEmail from "@/components/docs/product/channels/Email/Gmail/Addforwardemail.vue";
import MsCreatechannel from "@/components/docs/product/channels/Email/Microsoft/MsCreatechannel.vue";
import APICreateChannel from "@/components/docs/product/channels/APIChannel/Createchannel.vue";
import APISendMessages from "@/components/docs/product/channels/APIChannel/Sendmessages.vue";
import APIReceiveMessages from "@/components/docs/product/channels/APIChannel/Receivemessages.vue";
import APIClientApis from "@/components/docs/product/channels/APIChannel/Clientapis.vue";
import ChannelTelegram from "@/components/docs/product/channels/Telegram.vue";
import ChannelLine from "@/components/docs/product/channels/Line.vue";
import ChannelSupportedFeatures from "@/components/docs/product/channels/SupportedFeatures.vue";
import AdvancedAgentBots from "@/components/docs/product/Advanced/Agentbots.vue"; 
import AdvancedInteractiveMessages from "@/components/docs/product/Advanced/Interactivemessages.vue"; 
import AdvancedDashboardApps from "@/components/docs/product/Advanced/Dashboardapps.vue";
import AdvancedWebSocketEvents from "@/components/docs/product/Advanced/Websocketevents.vue";
import AdvancedWebHooks from "@/components/docs/product/Advanced/Webhooks/Webhooks.vue"; 
import AdvancedWebHookEvents from "@/components/docs/product/Advanced/Webhooks/WebhookEvents.vue";
import MobileAppAndroid from "@/components/docs/product/MobileApp/Android.vue";
import MobileAppIos from "@/components/docs/product/MobileApp/Ios.vue";
import OtherEnterprise from "@/components/docs/product/Others/Enterprise.vue";
import OtherFAQ from "@/components/docs/product/Others/Faq.vue";
import Agent from "@/components/docs/product/Features/Reports/Agent.vue";
import Concepts from "@/components/docs/product/Features/Reports/Concepts.vue";
import Csat from "@/components/docs/product/Features/Reports/Csat.vue";
import Inbox from "@/components/docs/product/Features/Reports/Inbox.vue";
import Team from "@/components/docs/product/Features/Reports/Team.vue";
import Label from "@/components/docs/product/Features/Reports/Label.vue";
import Overview from "@/components/docs/product/Features/Reports/Overview.vue";
import Conversion from "@/components/docs/product/Features/Reports/Conversion.vue";
import Featurescanned from "@/components/docs/product/Features/canned.vue";
import FeaturesCustom from "@/components/docs/product/Features/custom.vue";
import Featurescsat from "@/components/docs/product/Features/csat.vue";
import Featurescontacts from "@/components/docs/product/Features/contacts.vue";
import Featuresrouting from "@/components/docs/product/Features/routing.vue";
import Featuresfolder from "@/components/docs/product/Features/folder.vue";
import Featureskeyboard from "@/components/docs/product/Features/keyboard.vue";
import Featuressegment from "@/components/docs/product/Features/segment.vue";
import Featurescommandbar from "@/components/docs/product/Features/commandbar.vue";
import FeaturestemplateVariable from "@/components/docs/product/Features/templateVariable.vue";
import Featuresmultilingual from "@/components/docs/product/Features/multilingual.vue";
import FeaturesConversationworkflow from "@/components/docs/product/Features/Conversationworkflow.vue";
import FeaturesAdvancedconversationfilters from "@/components/docs/product/Features/Advancedconversationfilters.vue";
import FeaturesMacros from "@/components/docs/product/Features/Macros.vue";
import FeaturesAutomations from "@/components/docs/product/Features/Automations.vue";
import Featuresongoing from "@/components/docs/product/Features/Campaigns/ongoing.vue";
import Featurescampaigns from "@/components/docs/product/Features/Campaigns/campaigns.vue";
import FeaturesHelpcenter from "@/components/docs/product/Features/Help-Center/Helpcenter.vue";
import FeaturesConfiguresslforhelpcenter from "@/components/docs/product/Features/Help-Center/Configuresslforhelpcenter.vue";
import dialogflow from "@/components/docs/product/Features/Integrations/dialogflow.vue";  
import dyte from "@/components/docs/product/Features/Integrations/dyte.vue";
import NotFound from '@/components/NotFound.vue';

const routes = [
  // default routes
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/docs/product",
    name: "Introduction",
    component: Introduction,
  },
  {
    path: "/docs/user-guide/setup-your-account/add-agent-settings",
    name: "AddAgentSetting",
    component: AddAgentSetting,
  },
  {
    path: "/docs/user-guide/setup-your-account/add-inboxes",
    name: "AddInboxes",
    component: AddInboxes,
  },
  {
    path: "/docs/user-guide/setup-your-account/AddTeams",
    name: "AddTeams",
    component: AddTeams,
  },
  {
    path: "/docs/user-guide/setup-your-account/AddLabels",
    name: "AddLabels",
    component: AddLabels,
  },
  {
    path: "/docs/user-guide/setup-your-account/Integrations",
    name: "Integrations",
    component: Integrations,
  },
  {
    path: "/docs/user-guide/setup-your-account/Applications",
    name: "Applications",
    component: Applications,
  },
  {
    path: "/docs/product/channels/channels",
    name: "Channels",
    component: Channels,
  },

  {
    path: "/features/shared-inbox",
    name: "sharedInbox",
    component: sharedInbox,
  },
  {
    path: "/features/live-chat",
    name: "liveChat",
    component: liveChat,
  },
  {
    path: "/features/channels",
    name: "channels",
    component: FeatureChannels,
  },
  {
    path: "/features/automations",
    name: "Automations",
    component: Automations,
  },
  {
    path: "/features/chatbots",
    name: "chatbots",
    component: chatbots,
  },
  {
    path: "/mobile-apps",
    name: "mobileApps",
    component: mobileApps,
  },
  // {
  //   path: "/features/integrations",
  //   name: "integrations",
  //   component: FeaturesIntegrations,
  // },

  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: "/security",
    name: "security",
    component: security,
  },
  {
    path: "/terms-of-service",
    name: "termsOfService",
    component: termsOfService,
  },
  {
    path: "/features/labels",
    name: "labels",
    component: labels,
  },
  {
    path: "/features/teams",
    name: "teams",
    component: teams,
  },
  {
    path: "/features/contact-notes",
    name: "contactNotes",
    component: contactNotes,
  },
  {
    path: "/features/private-notes",
    name: "privateNotes",
    component: privateNotes,
  },
  {
    path: "/features/contact-segments",
    name: "contactSegments",
    component: contactSegments,
  },
  {
    path: "/features/business-hours",
    name: "businessHours",
    component: businessHours,
  },
  {
    path: "/features/live-view-of-reports",
    name: "liveViewOfReports",
    component: liveViewOfReports,
  },
  {
    path: "/features/conversation-reports",
    name: "conversationReports",
    component: conversationReports,
  },
  {
    path: "/features/agent-reports",
    name: "agentReports",
    component: agentReports,
  },
  {
    path: "/features/label-reports",
    name: "labelReports",
    component: labelReports,
  },
  {
    path: "/features/csat-reports",
    name: "csatReports",
    component: csatReports,
  },
  {
    path: "/features/inbox-reports",
    name: "inboxReports",
    component: inboxReports,
  },
  {
    path: "/features/team-reports",
    name: "teamReports",
    component: teamReports,
  },
  {
    path: "/features/keyboard-shortcuts",
    name: "keyboardShortcuts",
    component: keyboardShortcuts,
  },
  {
    path: "/features/command-bar",
    name: "commandBar",
    component: commandBar,
  },
  {
    path: "/features/bulk-actions",
    name: "bulkActions",
    component: bulkActions,
  },
  {
    path: "/features/canned-responses",
    name: "cannedResponses",
    component: cannedResponses,
  },
  {
    path: "/features/agent-capacity",
    name: "agentCapacity",
    component: agentCapacity,
  },
  {
    path: "/features/whatsapp-for-business",
    name: "whatsappBusiness",
    component: whatsappBusiness,
  },
  {
    path: "/features/twitter-integration",
    name: "twitterIntegration",
    component: twitterIntegration,
  },
  {
    path: "/features/facebook-integration",
    name: "facebookIntegration",
    component: facebookIntegration,
  },
  {
    path: "/features/telegram-integration",
    name: "telegramIntegration",
    component: telegramIntegration,
  },

  {
    path: "/features/line-integration",
    name: "lineIntegration",
    component: lineIntegration,
  },

  {
    path: "/features/instagram-integration",
    name: "instagramIntegration",
    component: instagramIntegration,
  },
  {
    path: "/features/sms-integration",
    name: "smsIntegration",
    component: smsIntegration,
  },
  {
    path: "/features/email-integration",
    name: "emailIntegration",
    component: emailIntegration,
  },

  {
    path: "/features/email-integration",
    name: "emailIntegration",
    component: emailIntegration,
  },
  {
    path: "/docs/product/Features/Reports/Team",
    name: "Team",
    component: Team,
  },
  {
    path: "/docs/user-guide/features/reports/concepts",
    name: "Concepts",
    component: Concepts,
  },

  {
    path: "/docs/user-guide/features/reports/Csat",
    name: "Csat",
    component: Csat,
  },

  {
    path: "/docs/user-guide/features/reports/label",
    name: "Label",
    component: Label,
  },

  {
    path: "/docs/user-guide/features/reports/overview",
    name: "Overview",
    component: Overview,
  },

  {
    path: "/docs/user-guide/features/reports/inbox",
    name: "Inbox",
    component: Inbox,
  },

  {
    path: "/docs/user-guide/features/reports/agent",
    name: "Agent",
    component: Agent,
  },

  {
    path: "/docs/user-guide/features/reports/conversation",
    name: "Conversion",
    component: Conversion,
  },

  {
    path: "/docs/user-guide/features/reports/team",
    name: "Team",
    component: Team,
  },

  {
    //Features help-cengter Routs//
    path: "/docs/product/features/help-center",
    name: "FeaturesHelpcenter",
    component: FeaturesHelpcenter,
  },
  {
    //Features help-cengter Routs//
    path: "/docs/product/features/help-center/configure-ssl-for-help-center",
    name: "FeaturesConfiguresslforhelpcenter",
    component: FeaturesConfiguresslforhelpcenter,
  },
  {
    path: "/docs/product/features/integrations/dialogflow",
    name: "dialogflow",
    component: dialogflow,
  },

  {
    path: "/docs/product/features/integrations/dyte",
    name: "dyte",
    component: dyte,
  },
  {
    path: "/help-center",
    name: "helpCenter",
    component: helpCenter,
  },
  {
    path: "/blog",
    name: "blog",
    component: blog,
  },
  {
    path: "/custmerService",
    name: "custmerService",
    component: custmerService,
  },
  {
    path: "/engineering",
    name: "engineering",
    component: engineering,
  },
  {
    path: "/moreChatwoot",
    name: "moreChatwoot",
    component: moreChatwoot,
  },
  {
    path: "/openSource",
    name: "openSource",
    component: openSource,
  },
  {
    path: "/productUpdate",
    name: "productUpdate",
    component: productUpdate,
  },
  {
    path: "/wootJournal",
    name: "wootJournal",
    component: wootJournal,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: pricing,
  },
  {
    path: "/docs/product",
    name: "Introduction",
    component: Introduction,
  },
  {
    path: "/docs/user-guide/setup-your-account/create-an-account",
    name: "CreateChatwootAccount",
    component: CreateChatwootAccount,
  },
  {
    path: "/docs/user-guide/setup-your-account/configure-your-profile",
    name: "ConfigureProfile",
    component: ConfigureProfile,
  },
  {
    path: "/docs/user-guide/setup-your-account/configure-account-details",
    name: "ConfigureAccount",
    component: ConfigureAccount,
  },
  {
    path: "/docs/user-guide/setup-your-account/add-inbox-settings",
    name: "AddInboxes",
    component: AddInboxes,
  },
  {
    path: "/docs/user-guide/setup-your-account/add-teams-settings",
    name: "AddTeams",
    component: AddTeams,
  },
  {
    path: "/docs/user-guide/setup-your-account/add-label-settings",
    name: "AddLabels",
    component: AddLabels,
  },
  {
    path: "/docs/user-guide/setup-your-account/integrations",
    name: "Integrations",
    component: Integrations,
  },
  {
    path: "/docs/user-guide/setup-your-account/applications",
    name: "Applications",
    component: Applications,
  },

  {
    path: "/docs/product/channels/channels",
    name: "Channels",
    component: Channels,
  },
  {
    path: "/docs/product/channels/live-chat/create-website-channel",
    name: "WebsiteAddConfiguration",
    component: WebsiteAddConfiguration,
  },
  {
    path: "/docs/product/channels/live-chat/sdk/setup",
    name: "WebsiteSDKSetup",
    component: WebsiteSDKSetup,
  },
  {
    path: "/docs/product/channels/live-chat/sdk/identity-validation",
    name: "WebsiteIdentityValidation",
    component: WebsiteIdentityValidation,
  },
  {
    path: "/docs/product/channels/live-chat/sdk/live-chat-dark-mode",
    name: "WebsiteDarkmode",
    component: WebsiteDarkmode,
  },
  {
    path: "/docs/product/channels/live-chat/conversation-continuity",
    name: "WebsiteConversationContinuity",
    component: WebsiteConversationContinuity,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/webflow-integration",
    name: "WebflowIntegration",
    component: WebsiteWebflowIntegration,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/docusaurus",
    name: "Docusaurus",
    component: WebsiteDocusaurus,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/google-tag-manager",
    name: "WebsiteGoogleTagManager",
    component: WebsiteGoogleTagManager,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/react-native-widget",
    name: "WebsiteReactNative",
    component: WebsiteReactNative,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/nextjs",
    name: "WebsiteNextJS",
    component: WebsiteNextJS,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/vue",
    name: "WebsiteVueJS",
    component: WebsiteVueJS,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/gatsby",
    name: "WebsiteGatsBy",
    component: WebsiteGatsBy,
  },
  {
    path: "/docs/product/channels/live-chat/setup-instruction/wordpress",
    name: "WebsiteWordpress",
    component: WebsiteWordpress,
  },
  {
    path: "/docs/product/channels/facebook",
    name: "Facebook",
    component: Facebook,
  },
  {
    path: "/docs/product/channels/instagram",
    name: "Instagram",
    component: Instagram,
  },
  {
    path: "/docs/product/channels/twitter",
    name: "Twitter",
    component: Twitter,
  },
  {
    path: "/docs/product/channels/whatsapp/whatsapp-cloud",
    name: "WhatsAppCloud",
    component: WhatsAppCloud,
  },
  {
    path: "/docs/product/channels/whatsapp/twilio",
    name: "WhatsAppTwilio",
    component: WhatsAppTwilio,
  },
  {
    path: "/docs/product/channels/whatsapp/360dialog",
    name: "WhatsAppDialog",
    component: WhatsAppDialog,
  },
  {
    path: "/docs/product/channels/sms/twilio",
    name: "SMSTwilio",
    component: SMSTwilio,
  },
  {
    path: "/docs/product/channels/sms/bandwidth",
    name: "SMSBandwidth",
    component: SMSBandwidth,
  },
  {
    path: "/docs/product/channels/email/create-channel",
    name: "EmailCreateChannel",
    component: EmailCreateChannel,
  },
  {
    path: "/docs/product/channels/email/configure-imap",
    name: "EmailConfigureIMAP",
    component: EmailConfigureIMAP,
  },
  {
    path: "/docs/product/channels/email/configure-smtp",
    name: "EmailConfigureSMTP",
    component: EmailConfigureSMTP,
  },

  {
    path: "/docs/product/channels/email/forward-email",
    name: "EmailForwardtoEmail",
    component: EmailForwardtoEmail,
  },
  {
    path: "/docs/product/channels/email/gmail/enable-imap",
    name: "GmailEnableimap",
    component: GmailEnableimap,
  },
  {
    path: "/docs/product/channels/email/gmail/less-secure-access",
    name: "GmailLessSecureAccess",
    component: GmailLessSecureAccess,
  },
  {
    path: "/docs/product/channels/email/gmail/generate-app-password",
    name: "GmailGenerateAppPassword",
    component: GmailGenerateAppPassword,
  },
  {
    path: "/docs/product/channels/email/gmail/add-forward-email",
    name: "GmailAddForwardEmail",
    component: GmailAddForwardEmail,
  },
  // channels/emails/microsoft
  {
    path: "/docs/product/channels/email/microsoft/create-channel",
    name: "MsCreatechannel",
    component: MsCreatechannel,
  },
  // channels/api routes
  {
    path: "/docs/product/channels/api/create-channel",
    name: "APICreateChannel",
    component: APICreateChannel,
  },
  {
    path: "/docs/product/channels/api/send-messages",
    name: "APISendMessages",
    component: APISendMessages,
  },
  {
    path: "/docs/product/channels/api/receive-messages",
    name: "APIReceiveMessages",
    component: APIReceiveMessages,
  },
  {
    path: "/docs/product/channels/api/client-apis",
    name: "APIClientApis",
    component: APIClientApis,
  },
  // channels/telegram
  {
    path: "/docs/product/channels/telegram",
    name: "Telegram",
    component: ChannelTelegram,
  },
  // channels/line
  {
    path: "/docs/product/channels/line",
    name: "ChannelLine",
    component: ChannelLine,
  },
  // channels/supported-feature
  {
    path: "/docs/product/channels/supported-features",
    name: "ChannelSupportedFeatures",
    component: ChannelSupportedFeatures,
  },
  {
    path: "/docs/product/advanced/agent-bots",
    name: "AdvancedAgentBots",
    component: AdvancedAgentBots,
  },
  {
    path: "/docs/product/advanced/interactive-messages",
    name: "AdvancedInteractiveMessages",
    component: AdvancedInteractiveMessages,
  },
  {
    path: "/docs/product/advanced/dashboard-apps",
    name: "AdvancedDashboardApps",
    component: AdvancedDashboardApps,
  },
  {
    path: "/docs/product/advanced/websocket-events",
    name: "AdvancedWebSocketEvents",
    component: AdvancedWebSocketEvents,
  },
  {
    path: "/docs/product/advanced/webhooks",
    name: "AdvancedWebHooks",
    component: AdvancedWebHooks,
  },
  {
    path: "/docs/product/advanced/webhook-events",
    name: "AdvancedWebHookEvents",
    component: AdvancedWebHookEvents,
  },
  // mobile-app routes
  {
    path: "/docs/product/mobile-app/android",
    name: "MobileAppAndroid",
    component: MobileAppAndroid,
  },
  {
    path: "/docs/product/mobile-app/ios",
    name: "MobileAppIos",
    component: MobileAppIos,
  },
  // others routes
  {
    path: "/docs/product/others/enterprise-edition",
    name: "OtherEnterprise",
    component: OtherEnterprise,
  },
  {
    path: "/docs/product/others/faq",
    name: "OtherFAQ",
    component: OtherFAQ,
  },

  // features

  {
    path: "/docs/user-guide/features/canned-responses",
    name: "Featurescanned",
    component: Featurescanned,
  },

  {
    path: "/docs/user-guide/features/custom-attributes",
    name: "FeaturesCustom",
    component: FeaturesCustom,
  },

  {
    path: "/docs/user-guide/features/csat",
    name: "Featurescsat",
    component: Featurescsat,
  },

  {
    path: "/docs/user-guide/features/contacts",
    name: "Featurescontacts",
    component: Featurescontacts,
  },

  {
    path: "/docs/product/features/inbox/routing-conversations-round-robin",
    name: "Featuresrouting",
    component: Featuresrouting,
  },

  {
    path: "/docs/user-guide/features/folders",
    name: "Featuresfolder",
    component: Featuresfolder,
  },

  {
    path: "/docs/user-guide/features/keyboard-shortcuts",
    name: "Featureskeyboardr",
    component: Featureskeyboard,
  },

  {
    path: "/docs/user-guide/features/segments",
    name: "Featuressegment",
    component: Featuressegment,
  },

  {
    path: "/docs/user-guide/features/commandbar",
    name: "Featurescommandbar",
    component: Featurescommandbar,
  },

  {
    path: "/docs/user-guide/features/template-variables",
    name: "FeaturestemplateVariable",
    component: FeaturestemplateVariable,
  },

  {
    path: "/docs/user-guide/features/multilingual-support",
    name: "Featuresmultilingual",
    component: Featuresmultilingual,
  },

  {
    path: "/docs/user-guide/features/conversation-workflow",
    name: "FeaturesConversationworkflow",
    component: FeaturesConversationworkflow,
  },
  {
    path: "/docs/product/features/advanced-conversation-filters",
    name: "FeaturesAdvancedconversationfilters",
    component: FeaturesAdvancedconversationfilters,
  },

  {
    path: "/docs/product/features/macros",
    name: "FeaturesMacros",
    component: FeaturesMacros,
  },

  {
    path: "/docs/user-guide/features/automations",
    name: "FeaturesAutomations",
    component: FeaturesAutomations,
  },

  // faetures campaigns

  {
    path: "/docs/product/Features/Campaigns/ongoing",
    name: "Featuresongoing",
    component: Featuresongoing,
  },

  {
    path: "/docs/product/features/campaigns/one-off",
    name: "Featurescampaigns",
    component: Featurescampaigns,
  },
  {
    path: "/docs/user-guide/features/pre-chat-form",
    name: "Pre_Chat_Form",
    component: Pre_Chat_Form,
  },
  {
    path: "/docs/user-guide/features/priority",
    name: "Priority",
    component: Priority,
  },
  // feature/integrations routes
  {
    path: "/docs/product/integrations/dialogflow",
    name: "Dialogflow",
    component: Dialogflow,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  },
    
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//     if (to.path.startsWith('/docs/')) {
//         next('/404');
//     } else {
//         next();
//     }
// });

export default router;
