<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("home.header.Features") }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.CSAT') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>On this page</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Enable-CSAT-link' }">
                                                        <a @click="scrollTo('#Enable-CSAT')" class="link_wrp">{{ $t('doc.AddandConfigure.InboxSettings.stepEnableCSAT') }}</a></li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#analyze-CSAT-link' }">
                                                        <a @click="scrollTo('#analyze-CSAT')" class="link_wrp">{{ $t('features.CSAT.analyzeCSAT') }}</a> </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_2"
                                                        ::class="{ 'active_menu_item': activeMenuItem === 'menu_item_2-link' }">
                                                        <a href="#features-info">Testing</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">
                            <div class="canned_list_wrp">
                                <h1>{{ $t('doc.product.CSAT') }}</h1>
                                <p>{{ $t('features.CSAT.customerDescription') }}</p>
                                <p>{{ $t('features.CSAT.calculateQuestion') }}</p>
                                <h4>{{ $t('features.CSAT.CSATWebsite') }}</h4>
                                <p class="widget_wrp" style="padding-left:0;padding-bottom:20px;">
                                    <img style="max-width: 395px; width: 100%;"
                                        src="../../../../assets/images/csat-web-widget.webp" class="img-fuild">
                                </p>

                                <h4>{{ $t('features.CSAT.CSATNonWebsite') }}</h4>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/csat-non-web.webp" class="img-fuild">
                                </p>

                                <h3 id="Enable-CSAT">{{ $t('features.CSAT.enableCSAT') }}</h3>
                                <p>{{ $t('features.CSAT.navigateInbox') }}</p>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/enable-disable.webp" class="img-fuild">
                                </p>
                                <h3 id="analyze-CSAT">
                                    {{ $t('features.CSAT.analyzeCSAT') }}
                                </h3>

                                <p>
                                    {{ $t('features.CSAT.canView') }} <a href="/docs/user-guide/features/reports/csat"> {{ $t('doc.product.reports') }}</a> {{ $t('features.CSAT.ratingConversation') }}
                                </p>
                            </div>
                        </div>

                        <div class="update_edit_wrp">
                            <!-- <div class="edit_wrp">
                                <a href="#">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>

                                    <p>
                                        {{ $t('doc.product.EditThisPage') }}
                                    </p>
                                </a>
                            </div> -->

                            <div class="update_wrp">
                                <p>
                                    Last updated on <b>9/15/2022</b> by <b>Aswin Dev P.S</b>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>
                                    <router-link to="/docs/user-guide/features/canned-responses"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('doc.product.CannedResponses') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                    <router-link to="/docs/user-guide/features/contacts">
                                        {{ $t('doc.product.Contacts') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Enable-CSAT-link' }"><a
                                        id="#Enable-CSAT-link" @click="scrollTo('#Enable-CSAT')" class="link_wrp">{{ $t('doc.AddandConfigure.InboxSettings.stepEnableCSAT') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#analyze-CSAT-link' }"><a
                                        id="#analyze-CSAT-link" @click="scrollTo('#analyze-CSAT')" class="link_wrp">{{ $t('features.CSAT.analyzeCSAT') }}</a></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {

        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}

p.widget_wrp {
    padding: 0 36px;
}

p.widget_wrp img {
    width: 100%;
    height: 100%;
}

p.non_web_wrp img {
    width: 100%;
    height: 100%;
}</style>