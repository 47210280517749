<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <a href="#">{{ $t('features.Priority.Priority') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                            <p>{{ $t('doc.product.OnThisPage') }}</p>  
       <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#setting-a-priority-link' }">
                                                        <a @click="scrollTo('#setting-a-priority')">
                                                            {{ $t('features.Priority.Settingapriority') }}
                                                            </a>
                                                    </li>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#faqs-link' }">
                                                        <a
                                                            @click="scrollTo('#faqs')">{{ $t('features.Priority.FAQs') }}
                                                        </a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">
                            <h1>{{ $t('features.Priority.Assigningapriority') }}</h1>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc') }}</p>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc1') }}</p>
                            <ul>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{ $t('features.Priority.Low') }}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{ $t('features.Priority.Medium') }}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{ $t('features.Priority.High') }}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{ $t('features.Priority.Urgent') }}</p>
                                </li>
                            </ul>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc2') }}</p>
                            <h2 style="text-align:left;" id="setting-a-priority">{{ $t('features.Priority.Settingapriority') }}</h2>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc3') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/priority.png">
                            </div>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc4') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/priority-done.png">
                            </div>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc4') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/context-menu-priority.png">
                            </div>
                            <p>{{ $t('features.Priority.AssigningapriorityDesc4') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/cmd-k-priority.png">
                            </div>
                            <div class="green-box">
                                <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16"
                                            viewBox="0 0 12 16">
                                            <path fill-rule="evenodd"
                                                d="M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z">
                                            </path>
                                        </svg></span>{{ $t('features.Priority.TIP') }}</h5>
                                <h4>{{ $t('features.Priority.makeFeature') }}</h4>
                                <p><span>1</span>{{ $t('features.Priority.urgentPriority') }}</p>
                                <p><span>2</span>{{ $t('features.Priority.urgentPriority') }}</p>
                            </div>

                            <h2 style="text-align:left;" class="pt-3" id="faqs">FAQs</h2>
                            <p><b>{{ $t('features.Priority.supportPriorities') }}</b></p>
                            <p>{{ $t('features.Priority.supportPriorities1') }}</p>
                            <p><b>{{ $t('features.Priority.supportPriorities1') }}</b></p>
                            <p>{{ $t('features.Priority.supportPriorities1') }}</p>
                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }}<span class="dark">4/28/2023</span>by <span class="dark"> {{ $t('Channels.line.shivamMishra') }}
                                </span>
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>

                                    <router-link to="/docs/user-guide/features/pre-chat-form" class="introduction-account">
                                        <span>«</span> {{ $t('doc.product.PreChatForm') }}
                                    </router-link>

                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                    <router-link to="/docs/product/features/campaigns/ongoing">
                                        {{ $t('Channels.features.Campaigns.onGoingCampaigns.title') }}<span>»</span>
                                    </router-link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#setting-a-priority-link' }"><a
                                        id="#setting-a-priority-link" @click="scrollTo('#setting-a-priority')">
                                        {{ $t('features.Priority.Settingapriority') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#faqs-link' }"><a
                                        id="#faqs-link" @click="scrollTo('#faqs')">{{ $t('features.Priority.FAQs') }}
                                    </a></li>


                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>
