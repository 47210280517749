<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>Features</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">

                            <p>Campaigns</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('Advance.features.Campaigns.OneOffCampaigns.tittle') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <!-- <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            On this page
                                        </button> -->
                                    </h2>
                                    <!-- <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }">
                                                        <a href="#features-info">Testing</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">

                            <div class="ongoing_wrp">
                                <h1>{{ $t('Advance.features.Campaigns.OneOffCampaigns.tittle') }}</h1>
                                <p>{{ $t('Advance.features.Campaigns.OneOffCampaigns.sms') }}</p>

                                <p><strong>{{ $t('Advance.features.Campaigns.OneOffCampaigns.step1') }}</strong>{{ $t('Advance.features.Campaigns.OneOffCampaigns.clickCampaigns') }}</p>

                                <p class="ongoing_img">
                                    <img src="../../../../../assets/images/one-off.webp" class="img-fuild">
                                </p>


                                <p><strong>{{ $t('Advance.features.Campaigns.OneOffCampaigns.step2') }}</strong>{{ $t('Advance.features.Campaigns.OneOffCampaigns.createCampaign') }}</p>

                                <p class="ongoing_img">
                                    <img src="../../../../../assets/images/add-one-off.webp" class="img-fuild">
                                </p>

                                <p>
                                    {{ $t('Advance.features.Campaigns.OneOffCampaigns.inputCampaign') }}
                                </p>
                            </div>

                            <div class="table-details" style="padding-left:0;">
                                <table class="table table-bordered description">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Advance.features.Campaigns.OneOffCampaigns.input') }}</th>
                                            <th>{{ $t('Advance.features.Campaigns.OneOffCampaigns.description') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.tittleNew') }}</td>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.name') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.message') }}</td>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.messageSent') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.selectInbox') }}</td>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.smsInbox') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.audience') }}</td>
                                            <td>
                                                {{ $t('Advance.features.Campaigns.OneOffCampaigns.labels') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.time') }}</td>
                                            <td>{{ $t('Advance.features.Campaigns.OneOffCampaigns.camapignTime') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <p>The URLs in the campaign supports the wildcard patterns. See this guide to learn more about
                                building a wildcard pattern.</p> -->
                            <!-- <p><strong>Step 3</strong>. Visit the URL specified in the campaign and wait for the time
                                configured in the campaign.</p>
                            <p class="ongoing_img">
                                <img src="../../../../../assets/images/campaign-message2.png" class="img-fuild">
                            </p> -->

                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Advance.features.Campaigns.OneOffCampaigns.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Advance.features.Campaigns.OneOffCampaigns.lastUpdatedOn') }} <span class="dark"> {{ $t('Advance.features.Campaigns.OneOffCampaigns.date') }}</span> {{ $t('Advance.features.Campaigns.OneOffCampaigns.by') }} <span class="dark"> {{ $t('Advance.features.Campaigns.OneOffCampaigns.pranavRaj') }}</span>
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Advance.features.Campaigns.OneOffCampaigns.previous') }}</p>

                                    <router-link to="/docs/product/features/campaigns/ongoing" class="introduction-account">
                                        <span>«</span> {{ $t('Advance.features.Campaigns.OneOffCampaigns.onOff') }}
                                    </router-link>


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Advance.features.Campaigns.OneOffCampaigns.next') }}</p>

                                    <router-link to="/docs/product/features/help-center">
                                        {{ $t('Advance.features.Campaigns.OneOffCampaigns.introduction') }}<span>»</span>
                                    </router-link>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>