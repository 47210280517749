<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="Add-labels-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.labels.AddLabels') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.labels.AddLabels') }}</h1>
                            <p>{{ $t('doc.labels.introductionDetails.heading') }}</p>
                            <p>{{ $t('doc.labels.introductionDetails.firstParagraph') }}</p>
                            <p>{{ $t('doc.labels.introductionDetails.addLabelsInstructions') }}</p>
                        </div>
                        <div class="add-labels">
                            <img src="../../../../assets/images/adding-labels.png" alt="logo29">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.labels.designYourOwnLabel') }}</p>
                            <p>{{ $t('doc.labels.addMeaningfulNames') }}</p>
                        </div>
                        <div class="add-labels">
                            <img src="../../../../assets/images/label-settings-form.png" alt="logo29">
                        </div>
                        <div class="table-details">
                            <table class="table table-bordered description">
                                <thead>
                                <tr>
                                    <th style="white-space: nowrap;">{{ $t('doc.labels.tableDetails.fieldName') }}</th>
                                    <th>{{ $t('doc.labels.tableDetails.value') }}</th>
                                    <th>{{ $t('doc.labels.tableDetails.remarks') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{ $t('doc.labels.tableDetails.labelName') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.labelNameDescription') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.labelNameRemarks') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.labels.tableDetails.description') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.descriptionDescription') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.descriptionRemarks') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.labels.tableDetails.colour') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.colourDescription') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.colourRemarks') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.labels.tableDetails.showLabelSidebar') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.showLabelSidebarDescription') }}</td>
                                    <td>{{ $t('doc.labels.tableDetails.showLabelSidebarRemarks') }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="version-detail" id="i-through-home-screen">
                            <p>{{ $t('doc.labels.versionDetail.createLabel') }}</p>
                        </div>
                        <div class="add-labels">
                            <img src="../../../../assets/images/label-list.png" alt="logo29">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.labels.editUpdateDetail.editDeleteLabel') }}</p>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD" aria-hidden="true">
                                Add SVG code here
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.labels.editPage') }}</a>
                            </div> -->
                            <p>
                                {{ $t('doc.labels.lastUpdatedOn') }} <span class="dark">10/17/2022</span>
                                {{ $t('doc.labels.updatedBy') }} <span class="dark">{{ $t('doc.labels.HrichaShandily') }}</span>
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                <p class="preview">{{ $t('doc.labels.row.previous') }}</p>
                                <router-link to="/docs/user-guide/setup-your-account/add-teams-settings" class="introduction-account">
                                    <span>«</span> {{ $t('doc.labels.routerLinks.addTeamsSettings') }}
                                </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                <p>{{ $t('doc.labels.row.next') }}</p>
                                <router-link to="/docs/user-guide/setup-your-account/integrations">
                                    {{ $t('doc.labels.routerLinks.integrations') }}
                                </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                </div>
        </div>
    </div>
</section></template>
<script>
export default ({})
</script>
<style>.add-labels img {
    width: 100%;
    height: 100%;
}

.add-labels {
    padding: 0 36px;
}</style>