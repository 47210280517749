<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>Features</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('Advance.features.Automation.tittle') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('Advance.features.Automation.onThisPage') }}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-events-link' }">
                                                        <a @click="scrollTo('#automation-events')"
                                                            class="link_wrp">{{ $t('Advance.features.Automation.automationEvents') }}</a>
                                                    </li>

                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-conditions-link' }">
                                                        <a @click="scrollTo('#automation-conditions')"
                                                            class="link_wrp">{{ $t('Advance.features.Automation.automationCondition') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-actions-link' }">
                                                        <a @click="scrollTo('#automation-actions')"
                                                            class="link_wrp"></a>
                                                    </li>{{ $t('Advance.features.Automation.automationAction') }}

                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#creating-automations-link' }">
                                                        <a @click="scrollTo('#creating-automations')"
                                                            class="link_wrp">{{ $t('Advance.features.Automation.createAutomation') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#editing-automations-link' }">
                                                        <a @click="scrollTo('#editing-automations')"
                                                            class="link_wrp">{{ $t('Advance.features.Automation.editAutomation') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#clone-automatio-link' }">
                                                        <a @click="scrollTo('#clone-automation')" class="link_wrp">{{ $t('Advance.features.Automation.cloneAutomation') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#deleting-automation-link' }">
                                                        <a @click="scrollTo('#deleting-automations')"
                                                            class="link_wrp">{{ $t('Advance.features.Automation.deleteAutomation') }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Introduction-details text-align-left">
                                    <h1>{{ $t('Advance.features.Automation.tittle') }}</h1>
                                </div>
                                <div class="channels-info break-word code-info">
                                    <div class="channels-title">
                                        <p>{{ $t('Advance.features.Automation.bccorMessage') }}</p>
                                        <p><img loading="lazy" alt="Automations List"
                                                src="../../../../assets/images/list.webp" width="3358" height="2228"
                                                class="max-img"></p>
                                        <p>{{ $t('Advance.features.Automation.bccorAutomation') }} <strong>{{ $t('Advance.features.Automation.event') }}</strong>{{ $t('Advance.features.Automation.bracket') }}
                                            <strong>{{ $t('Advance.features.Automation.conditions') }}</strong> {{ $t('Advance.features.Automation.and') }} <strong>{{ $t('Advance.features.Automation.actions') }}</strong>{{ $t('Advance.features.Automation.eventTigger') }}
                                        </p>
                                        <p><img loading="lazy" alt="Automations Flow"
                                                src="../../../../assets/images/flow.png" width="1280" height="720"
                                                class="max-img"></p>
                                        <h2 class="anchorarticlenew" id="automation-events">{{ $t('Advance.features.Automation.automationEvents') }}<a class="hash-link" href="#automation-events"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('Advance.features.Automation.automationEventsTigger') }}</p>
                                        <ol class="channels-menu-ol">
                                            <li>{{ $t('Advance.features.Automation.conversationCreated') }}
                                            </li>
                                            <li>{{ $t('Advance.features.Automation.conversationUpdate') }}
                                            </li>
                                            <li>{{ $t('Advance.features.Automation.messageCreared') }}</li>
                                        </ol>
                                        <h2 class="anchorarticlenew" id="automation-conditions">{{ $t('Advance.features.Automation.automationCondition') }}<a class="hash-link" href="#automation-conditions"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('Advance.features.Automation.conditionExecuted') }}</p>
                                        <table class="table custom-table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('Advance.features.Automation.createingconve') }}</th>
                                                    <th>{{ $t('Advance.features.Automation.convupdate') }}</th>
                                                    <th>{{ $t('Advance.features.Automation.messageUpdate') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ $t('Advance.features.Automation.status') }}</td>
                                                    <td>{{ $t('Advance.features.Automation.status') }}</td>
                                                    <td>{{ $t('Advance.features.Automation.messageType') }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('Advance.features.Automation.browserLanguage') }}</td>
                                                    <td>{{ $t('Advance.features.Automation.browserLanguage') }}</td>
                                                    <td>{{ $t('Advance.features.Automation.messageContains') }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('Advance.features.Automation.country') }}</td>
                                                    <td>{{ $t('Advance.features.Automation.country') }}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('Advance.features.Automation.refferLink') }}</td>
                                                    <td>{{ $t('Advance.features.Automation.refferLink') }}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>{{ $t('Advance.features.Automation.ass') }}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>{{ $t('Advance.features.Automation.team') }}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h2 class="anchorarticlenew" id="automation-actions">{{ $t('Advance.features.Automation.automationAction') }}<a class="hash-link" href="#automation-actions"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>
                                            {{ $t('Advance.features.Automation.actionTasks') }}</p>
                                        <p>{{ $t('Advance.features.Automation.bcoorCurrently') }}</p>
                                        <ol class="channels-menu-ol">
                                            <li>{{ $t('Advance.features.Automation.assignConversation') }}</li>
                                            <li>{{ $t('Advance.features.Automation.assignLabel') }}</li>
                                            <li>{{ $t('Advance.features.Automation.sendEmail') }}</li>
                                        </ol>
                                        <h2 class="anchorarticlenew" id="creating-automations">{{ $t('Advance.features.Automation.createAutomation') }}<a class="hash-link" href="#creating-automations"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('Advance.features.Automation.pageNavigate') }}
                                            <code>{{ $t('Advance.features.Automation.tittle') }}</code>{{ $t('Advance.features.Automation.stop') }}
                                        </p>
                                        <p>{{ $t('Advance.features.Automation.click') }} <code>{{ $t('Advance.features.Automation.automationRule') }}</code> {{ $t('Advance.features.Automation.automationCreation') }}</p>
                                        <p><img loading="lazy" alt="Automations Create"
                                                src="../../../../assets/images/create.jpg" width="3114" height="2034"
                                                class="max-img"></p>
                                        <ol class="channels-menu-ol">
                                            <li>{{ $t('Advance.features.Automation.automationName') }}</li>
                                            <li>{{ $t('Advance.features.Automation.addSDescription') }}</li>
                                            <li>{{ $t('Advance.features.Automation.eventSelect') }}</li>
                                            <li>{{ $t('Advance.features.Automation.addConditions') }}</li>
                                            <li>{{ $t('Advance.features.Automation.addActions') }}</li>
                                        </ol>
                                        <p>{{ $t('Advance.features.Automation.multiple') }} <code>{{ $t('Advance.features.Automation.and') }}</code> {{ $t('Advance.features.Automation.or') }} <code>{{ $t('Advance.features.Automation.oR') }}</code>
                                            {{ $t('Advance.features.Automation.operator') }}</p>
                                        <p>{{ $t('Advance.features.Automation.example') }}</p>
                                        <ol class="channels-menu-ol">
                                            <li>{{ $t('Advance.features.Automation.addNameDescription') }}</li>
                                            <li>{{ $t('Advance.features.Automation.selectEventAs') }} <code>{{ $t('Advance.features.Automation.createingconve') }}</code></li>
                                            <li>{{ $t('Advance.features.Automation.twoConditions') }} <code>{{ $t('Advance.features.Automation.and') }}</code> {{ $t('Advance.features.Automation.condition1') }} <code>{{ $t('Advance.features.Automation.status') }}</code> {{ $t('Advance.features.Automation.as') }} <code>{{ $t('Advance.features.Automation.open') }}</code> {{ $t('Advance.features.Automation.and') }}
                                                 <code>{{ $t('Advance.features.Automation.browser') }}</code> {{ $t('Advance.features.Automation.as') }} <code>{{ $t('Advance.features.Automation.fr') }}</code>
                                                 {{ $t('Advance.features.Automation.dropdown') }}</li>
                                            <li>{{ $t('Advance.features.Automation.addAction') }} <code>{{ $t('Advance.features.Automation.assignTeam') }}</code> {{ $t('Advance.features.Automation.selectTeam') }}
                                                <code>{{ $t('Advance.features.Automation.france') }}</code> {{ $t('Advance.features.Automation.fromDropdown') }}
                                            </li>
                                        </ol>
                                        <p><img loading="lazy" alt="Automations Create Example"
                                                src="../../../../assets/images/create-example.jpg" width="3114"
                                                height="2034" class="max-img"></p>
                                        <h2 class="anchorarticlenew" id="editing-automations">{{ $t('Advance.features.Automation.editAutomation') }}<a class="hash-link" href="#editing-automations"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('Advance.features.Automation.editClickAutomation') }} <code>{{ $t('Advance.features.Automation.edit') }}</code> {{ $t('Advance.features.Automation.iconAutomations') }}
                                            <img loading="lazy" alt="Automations edit"
                                                src="../../../../assets/images/edit.gif" width="800" height="280"
                                                class="max-img">
                                        </p>
                                        <h2 class="anchorarticlenew" id="clone-automation">{{ $t('Advance.features.Automation.cloneAutomation') }}<a class="hash-link" href="#clone-automation"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('Advance.features.Automation.exisitng') }}</p>
                                        <h2 class="anchorarticlenew" id="deleting-automations">{{ $t('Advance.features.Automation.deletingAutomations') }}<a class="hash-link" href="#deleting-automations"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('Advance.features.Automation.deleteClick') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('Advance.features.Automation.tittle') }}</h1>

                        </div>

                        <div class="channels-info break-word code-info">
                            <div class="channels-title">
                                <p>{{ $t('Advance.features.Automation.bcoorautomationMessage') }}</p>
                                <p><img loading="lazy" alt="Automations List" src="../../../../assets/images/list.jpg"
                                        width="3358" height="2228" class="max-img"></p>
                                <p>{{ $t('Advance.features.Automation.bcoorThree') }} <strong>{{ $t('Advance.features.Automation.event') }}</strong>{{ $t('Advance.features.Automation.bracket') }}
                                    <strong>{{ $t('Advance.features.Automation.conditions') }}</strong> {{ $t('Advance.features.Automation.and') }} <strong>{{ $t('Advance.features.Automation.actions') }}</strong>{{ $t('Advance.features.Automation.eventTiggeringPoint') }}
                                </p>
                                <p><img loading="lazy" alt="Automations Flow" src="../../../../assets/images/flow.png"
                                        width="1280" height="720" class="max-img"></p>
                                <h2 class="anchorarticlenew" id="automation-eventsnew">{{ $t('Advance.features.Automation.automationEvents') }}<a class="hash-link" href="#automation-events"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.executed') }}</p>
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('Advance.features.Automation.conversationCreated') }}
                                    </li>
                                    <li>{{ $t('Advance.features.Automation.conversationUpdate') }}
                                    </li>
                                    <li>{{ $t('Advance.features.Automation.messageCreared') }}</li>
                                </ol>
                                <h2 class="anchorarticlenew" id="automation-conditionsnew">{{ $t('Advance.features.Automation.automationCondition') }}<a class="hash-link" href="#automation-conditions"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.conditionsCriteria') }}</p>
                                <table class="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Advance.features.Automation.createingconve') }}</th>
                                            <th>{{ $t('Advance.features.Automation.convupdate') }}</th>
                                            <th>{{ $t('Advance.features.Automation.messageUpdate') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('Advance.features.Automation.status') }}</td>
                                            <td>{{ $t('Advance.features.Automation.status') }}</td>
                                            <td>{{ $t('Advance.features.Automation.messageType') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Automation.browserLanguage') }}</td>
                                            <td>{{ $t('Advance.features.Automation.browserLanguage') }}</td>
                                            <td>{{ $t('Advance.features.Automation.messageContains') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Automation.country') }}</td>
                                            <td>{{ $t('Advance.features.Automation.country') }}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Advance.features.Automation.refferLink') }}</td>
                                            <td>{{ $t('Advance.features.Automation.refferLink') }}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{{ $t('Advance.features.Automation.ass') }}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{{ $t('Advance.features.Automation.team') }}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h2 class="anchorarticlenew" id="automation-actionsnew">{{ $t('Advance.features.Automation.automationAction') }}<a class="hash-link" href="#automation-actions"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.actionTasks') }}</p>
                                <p>{{ $t('Advance.features.Automation.bcoorCurrently') }}</p>
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('Advance.features.Automation.assignConversation') }}</li>
                                    <li>{{ $t('Advance.features.Automation.assignLabel') }}</li>
                                    <li>{{ $t('Advance.features.Automation.sendEmail') }}</li>
                                </ol>
                                <h2 class="anchorarticlenew" id="creating-automationsnew">{{ $t('Advance.features.Automation.createAutomation') }}<a class="hash-link" href="#creating-automations"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.pageNavigate') }}
                                    <code>{{ $t('Advance.features.Automation.tittle') }}</code>{{ $t('Advance.features.Automation.stop') }}
                                </p>
                                <p>{{ $t('Advance.features.Automation.click') }} <code>{{ $t('Advance.features.Automation.automationRule') }}</code> {{ $t('Advance.features.Automation.automationCreation') }}</p>
                                <p><img loading="lazy" alt="Automations Create" src="../../../../assets/images/create.jpg"
                                        width="3114" height="2034" class="max-img"></p>
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('Advance.features.Automation.automationName') }}</li>
                                    <li>{{ $t('Advance.features.Automation.addSDescription') }}</li>
                                    <li>{{ $t('Advance.features.Automation.eventSelect') }}</li>
                                    <li>{{ $t('Advance.features.Automation.addConditions') }}</li>
                                    <li>{{ $t('Advance.features.Automation.addActions') }}</li>
                                </ol>
                                <p>{{ $t('Advance.features.Automation.multiple') }} <code>{{ $t('Advance.features.Automation.and') }}</code> {{ $t('Advance.features.Automation.or') }} <code>{{ $t('Advance.features.Automation.oR') }}</code>
                                    {{ $t('Advance.features.Automation.operator') }}</p>
                                <p>{{ $t('Advance.features.Automation.example') }}</p>
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('Advance.features.Automation.addNameDescription') }}</li>
                                    <li>{{ $t('Advance.features.Automation.selectEventAs') }} <code>{{ $t('Advance.features.Automation.createingconve') }}</code></li>
                                    <li>{{ $t('Advance.features.Automation.twoConditions') }} <code>{{ $t('Advance.features.Automation.and') }}</code> {{ $t('Advance.features.Automation.condition1') }} <code>{{ $t('Advance.features.Automation.status') }}</code> {{ $t('Advance.features.Automation.as') }} <code>{{ $t('Advance.features.Automation.open') }}</code> {{ $t('Advance.features.Automation.and') }}
                                            <code>{{ $t('Advance.features.Automation.browser') }}</code> {{ $t('Advance.features.Automation.as') }} <code>{{ $t('Advance.features.Automation.fr') }}</code>
                                            {{ $t('Advance.features.Automation.dropdown') }}</li>
                                    <li>{{ $t('Advance.features.Automation.addAction') }} <code>{{ $t('Advance.features.Automation.assignTeam') }}</code> {{ $t('Advance.features.Automation.selectTeam') }}
                                        <code>{{ $t('Advance.features.Automation.france') }}</code> {{ $t('Advance.features.Automation.fromDropdown') }}
                                    </li>
                                </ol>
                                <p><img loading="lazy" alt="Automations Create Example"
                                        src="../../../../assets/images/create-example.jpg" width="3114" height="2034"
                                        class="max-img"></p>
                                <h2 class="anchorarticlenew" id="editing-automationsnew">{{ $t('Advance.features.Automation.editAutomation') }}<a class="hash-link" href="#editing-automations"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.editClickAutomation') }} <code>{{ $t('Advance.features.Automation.edit') }}</code> {{ $t('Advance.features.Automation.iconAutomations') }}
                                    <img loading="lazy" alt="Automations edit" src="../../../../assets/images/edit.gif"
                                        width="800" height="280" class="max-img">
                                </p>
                                <h2 class="anchorarticlenew" id="clone-automationnew">{{ $t('Advance.features.Automation.cloneAutomation') }}<a class="hash-link" href="#clone-automation"
                                    title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.exisitng') }}</p>
                                <h2 class="anchorarticlenew" id="deleting-automationsnew">{{ $t('Advance.features.Automation.deletingAutomations') }}<a class="hash-link" href="#deleting-automations"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('Advance.features.Automation.deleteClick') }}</p>
                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Advance.features.Automation.editPage') }}</a>
                            </div> -->
                            <p>{{  $t("doc.inboxes.last_updated_on") }} <span class="dark">{{ $t('Advance.features.Automation.date') }}</span> {{ $t('Advance.features.Automation.by') }} <span class="dark"> {{ $t('Advance.features.Automation.pranavRaj') }}</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Advance.features.Automation.previous') }}</p>
                                    <router-link to="/docs/product/features/advanced-conversation-filters"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('Advance.features.Automation.introduction') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Advance.features.Automation.next') }}</p>
                                    <router-link to="/docs/product/features/macros">
                                        {{ $t('Advance.features.Automation.onOff') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-eventsnew-link' }">
                                    <a id="#automation-eventsnew-link" @click="scrollTo('#automation-eventsnew')" class="link_wrp">Automation
                                        Events</a>
                                </li>
                                <!-- <li :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-events-link' }">
                                    <a id="#automation-events-link" @click="scrollTo('#automation-events')" class="link_wrp"></a>
                                </li> -->
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-conditionsnew-link' }">
                                    <a id="#automation-conditionsnew-link" @click="scrollTo('#automation-conditionsnew')" class="link_wrp">Automation Condition</a>
                                </li>
                                <!-- <li :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-conditionsnew-link' }">
                                    <a id="#automation-conditionsnew-link" @click="scrollTo('#automation-conditionsnew')" class="link_wrp">Automation Condition</a>
                                </li> -->

                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#automation-actionsnew-link' }">
                                    <a id="#automation-actionsnew-link" @click="scrollTo('#automation-actionsnew')" class="link_wrp">Automation Actions</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#creating-automationsnew-link' }">
                                    <a id="#creating-automationsnew-link" @click="scrollTo('#creating-automationsnew')" class="link_wrp">Creating Automations</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#editing-automationsnew-link' }">
                                    <a id="#editing-automationsnew-link" @click="scrollTo('#editing-automationsnew')">Editing Automations</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#clone-automationnew-link' }">
                                    <a id="#clone-automationnew-link" @click="scrollTo('#clone-automationnew')" class="link_wrp">Clone Automation</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#deleting-automationsnew-link' }">
                                    <a id="#deleting-automationsnew-link" @click="scrollTo('#deleting-automationsnew')" class="link_wrp">Deleting Automations</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'Automations',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
