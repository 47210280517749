<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="addinboxes-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.inboxes.AddInboxes') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                    aria-expanded="true" aria-controls="collapseOne">
                                    <p>{{ $t('doc.inboxes.onThisPage') }}</p>
                                    <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="tableOfContents-list">
                                    <ul id="side_section_info">
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#i-through-welcome-link' }">
                                        <a @click="scrollTo('#i-through-welcome')">{{ $t('doc.inboxes.iThroughWelcomeScreen') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#i-through-setting-screen-link' }">
                                        <a @click="scrollTo('#i-through-setting-screen')">{{ $t('doc.inboxes.iThroughSettingScreen') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#i-through-home-screen-link' }">
                                        <a @click="scrollTo('#i-through-home-screen')">{{ $t('doc.inboxes.iThroughHomeScreen') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Start-adding-inboxes-link' }">
                                        <a @click="scrollTo('#Start-adding-inboxes')">{{ $t('doc.inboxes.startAddingInboxes') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#next-step-index-link' }">
                                        <a @click="scrollTo('#next-step-index')">{{ $t('doc.inboxes.nextSteps') }}</a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.inboxes.addInboxes') }}</h1>
                            <p>{{ $t('doc.inboxes.inboxConnects') }}</p>
                            <p>{{ $t('doc.inboxes.unlimitedInboxes') }}</p>
                            <p>{{ $t('doc.inboxes.addInboxOptions') }}</p>
                        </div>
                        <div class="version-detail" id="i-through-welcome">
                            <h4>{{ $t('doc.inboxes.iThroughWelcomeScreen') }}</h4>
                            <p>{{ $t('doc.inboxes.onboardingScreenOption') }}</p>
                            <p>{{ $t('doc.inboxes.clickHereToCreateInbox') }}</p>
                        </div>
                        <div class="craete-inbox">
                            <img src="../../../../assets/images/click-create-inbox.webp" alt="logo22">
                        </div>
                        <div class="version-detail" id="i-through-setting-screen">
                            <h4>{{ $t('doc.inboxes.way2Title') }}</h4>
                            <p>{{ $t('doc.inboxes.way2Description') }}</p>
                        </div>
                        <div class="craete-inbox">
                            <img src="../../../../assets/images/add-inbox-through-settings.webp" alt="logo22">
                        </div>
                        <div class="version-detail" id="i-through-home-screen">
                            <h4>{{ $t('doc.inboxes.way3Title') }}</h4>
                            <p>{{ $t('doc.inboxes.way3Description') }}</p>
                        </div>
                        <div class="craete-inbox">
                            <img src="../../../../assets/images/add-inbox-42.png" alt="logo22">
                        </div>
                        <div class="version-detail" id="Start-adding-inboxes">
                            <h4>{{ $t('doc.inboxes.start_adding') }}</h4>
                            <p>{{ $t('doc.inboxes.click_button') }}</p>
                            <p>{{ $t('doc.inboxes.select_channels') }}</p>
                        </div>
                        <div class="craete-inbox">
                            <img src="../../../../assets/images/inboxes-list-4c1071.png" alt="logo22">
                        </div>
                        <p class="guide-host-two">
                            {{ $t('doc.inboxes.guide_host') }}
                            <a href="#">{{ $t('doc.inboxes.setting_up_widget') }}</a>
                        </p>
                        <div class="version-detail" id="next-step-index">
                            <h4>{{ $t('doc.inboxes.next_steps') }}</h4>
                            <p>{{ $t('doc.inboxes.find_detailed_steps') }}</p>
                        </div>
                        <div class="channels-title">
                            <ul class="channels-menu">
                                <li><a href="/docs/product/channels/live-chat/create-website-channel">{{ $t('doc.inboxes.website_channel') }}</a></li>
                                <li><a href="/docs/product/channels/facebook">{{ $t('doc.inboxes.facebook_messenger_channel') }}</a></li>
                                <li><a href="/docs/product/channels/twitter">{{ $t('doc.inboxes.twitter_channel') }}</a></li>
                                <li><a href="/docs/product/channels/whatsapp/whatsapp-cloud">{{ $t('doc.inboxes.whatsappChannel') }}</a></li>
                                <li><a href="/docs/product/channels/sms/twilio">{{ $t('doc.inboxes.smsChannel') }}</a></li>
                                <li><a href="/docs/product/channels/email/create-channel">{{ $t('doc.inboxes.emailChannel') }}</a></li>
                                <!-- <li><a href="">{{ $t('doc.inboxes.connectChannel') }}</a></li> -->
                                <li><a href="/docs/product/channels/telegram">{{ $t('doc.inboxes.telegramChannel') }}</a></li>
                                <li><a href="/docs/product/channels/line">{{ $t('doc.inboxes.lineChannel') }}</a></li>


                                <!-- Add other channels here -->
                            </ul>
                        </div>
                        <div class="edit-update-detail">
                        <!-- <div class="edit-pen">
                            <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD" aria-hidden="true">
                            Add SVG code here
                            </svg>
                            <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.inboxes.edit_page') }}</a>
                        </div> -->
                        <p>
                            {{ $t('doc.inboxes.last_updated_on') }} <span class="dark">10/17/2022</span>
                            {{ $t('doc.inboxes.updated_by') }} <span class="dark">Hricha Shandily</span>
                        </p>
                    </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>

                                    <router-link to="/docs/user-guide/setup-your-account/add-agent-settings" class="introduction-account">
                                        <span>«</span> {{ $t('doc.inboxes.addAgents') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>

                                    <router-link to="/docs/user-guide/setup-your-account/add-teams-settings">
                                        {{ $t('doc.inboxes.AddTeams') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#i-through-welcome-link' }">
                                    <a @click="scrollTo('#i-through-welcome')" id="#i-through-welcome-link">I. Through your Welcome/onboarding screen</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#i-through-setting-screen-link' }">
                                    <a @click="scrollTo('#i-through-setting-screen')" id="#i-through-setting-screen-link">II. Through your Settings screen</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#i-through-home-screen-link' }">
                                    <a @click="scrollTo('#i-through-home-screen')" id="#i-through-home-screen-link">III. Through your home screen</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Start-adding-inboxes-link' }">
                                    <a @click="scrollTo('#Start-adding-inboxes')" id="#Start-adding-inboxes-link">Start adding inboxes</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#next-step-index-link' }">
                                    <a @click="scrollTo('#next-step-index')" id="#next-step-index-link">Next steps</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: 'AddInboxes',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>
<style>
/* .click-inbox {
    background-color: #f6f7f8;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 2px;
    font-size: 12px;
} */

.craete-inbox img {
    width: 100%;
    height: 100%;
}

.craete-inbox {
    padding: 0 36px;
    margin-bottom: 20px;
}

p.guide-host-two {
    text-align: left;
    line-height: 28px;
    color: #3c4858;
    padding: 0 36px;
    margin-bottom: 15px;
    font-size: 18px;
}

p.guide-host-two a {
    text-decoration: none;
    color: #1f93ff;
    font-weight: 400;
}
</style>