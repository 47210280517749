<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                             <p>{{ $t('doc.menu.Channels') }}</p>
                                 <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                                <a href="#">{{ $t('Advance.Channels.line.title') }}</a>
                        </div>
                        <!-- <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            On this page
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_1"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                                            href="#channels-info">Channels</a></li>
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }"><a
                                                            href="#features-info">Features</a></li>
                                                    <li id="menu_item_3"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_3' }"><a
                                                            href="#integration-info">Integration</a></li>
                                                    <li id="menu_item_4"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_4' }"><a
                                                            href="#advanced-info">Advanced</a></li>
                                                    <li id="menu_item_5"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_5' }"><a
                                                            href="#mobile-app-info">Mobile app</a></li>
                                                    <li id="menu_item_6"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_6' }"><a
                                                            href="#other-info">Others</a></li>
                                                    <li id="menu_item_7"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_7' }"><a
                                                            href="#compatibility-info">Compatibility and Requirements</a>
                                                    </li>
                                                    <li id="menu_item_8"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_8' }"><a
                                                            href="#next-steps">Next Steps</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> -->

                        <div class="Introduction-details">
                            <h1>{{ $t('Channels.line.channelCreate') }}</h1>
                            <p><b>{{ $t('Channels.line.step1') }}</b> {{ $t('doc.twitter.step1Content') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/Line-one-img.png">
                              </div>
                              <p><b>{{ $t('Channels.line.step2') }}</b> {{ $t('Channels.line.clickIcon') }}</p>
                              <div class="whatssimg">
                                <img src="../../../../assets/images/line-two.png">
                                </div>
                                <p><b>{{ $t('Channels.line.step3') }}</b> {{ $t('Channels.line.goTo') }} <a href="#"> {{ $t('Channels.line.developerConsole') }}</a> {{ $t('Channels.line.lineAccount') }}</p>
                                <p><b>{{ $t('Channels.line.step4') }}</b> {{ $t('Channels.line.createDeveloperConsole') }} </p>
                              <div class="whatssimg">
                                <img src="../../../../assets/images/line-three.png">
                                </div>
                                
                                <p><b>{{ $t('Channels.line.step5') }}</b>{{ $t('Channels.line.messagingApi') }} </p>
                                <div class="whatssimg">
                                    <img src="../../../../assets/images/line_four.png">
                                    </div>
                                

                                
                                <p><b>{{ $t('Channels.line.step6') }}</b> {{ $t('Channels.line.fieldsDeveloperConsole') }}</p>
                                    <ol>
                                        <li style="list-style: auto;">{{ $t('Channels.line.channelName') }}</li>
                                        <li style="list-style: auto;">{{ $t('Channels.line.channelId') }}</li>
                                        <li style="list-style: auto;">{{ $t('Channels.line.channelSecret') }}</li>
                                        <li style="list-style: auto;">{{ $t('Channels.line.channelToken') }}</li>
                                    </ol>
                                <p>{{ $t('Channels.line.channelId') }}</p>  
                                <div class="whatssimg">
                                    <img src="../../../../assets/images/line_five.png">
                                    </div>
                                    <p>{{ $t('Channels.line.channelSecret') }}</p>
                                    <div class="whatssimg">
                                        <img src="../../../../assets/images/line_six.png">
                                    </div>
                                    <p>{{ $t('Channels.line.channelToken') }}</p>
                                    <div class="whatssimg">
                                        <img src="../../../../assets/images/line_seven.png">
                                    </div>
                                    <p>{{ $t('Channels.line.channelClick') }}</p>
                                    <div class="whatssimg">
                                        <img src="../../../../assets/images/line-eight.png">
                                    </div>

                                    <p><b>{{ $t('Channels.line.step8') }}</b> {{ $t('Channels.line.addAgents') }}</p>

                                    <!-- <ol>
                                        <li style="list-style: auto;">{{ $t('Channels.line.verifyBccor') }}</li>
                                        <li style="list-style: auto;">{{ $t('Channels.line.useWebhook') }}</li>
                                    </ol> -->
                                    <div class="whatssimg">
                                        <img src="../../../../assets/images/line_nine.png">
                                    </div>
                                    <p><b>{{ $t('Channels.line.step9') }}</b> {{ $t('Channels.line.successfullyCreated') }}</p>
                                    <div class="whatssimg">
                                        <img src="../../../../assets/images/line_ten.png">
                                    </div>
                                    <p><b>{{ $t('Channels.line.step10') }}</b> {{ $t('Channels.line.messagingConsole') }}</p>
                                     <ol>
                                        <li style="list-style: auto;">{{ $t('Channels.line.verifyBccor') }}</li>
                                        <li style="list-style: auto;">{{ $t('Channels.line.useWebhook') }}</li>
                                    </ol>
                                    <div class="whatssimg">
                                        <img src="../../../../assets/images/line-elevenpng.png">
                                    </div>
                                    <p><b>{{ $t('Channels.line.step11') }}</b> {{ $t('Channels.line.messagingConsole') }}</p>
                                    <p><b>{{ $t('Channels.line.step12') }}</b> {{ $t('Channels.line.inboxMessage') }}</p>
                                </div>
                        
                          <div class="edit-update-detail">
                              <!-- <div class="edit-pen">
                                  <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                      aria-hidden="true">
                                      <g>
                                          <path
                                              d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                          </path>
                                      </g>
                                  </svg>
                                  <a href="#">{{ $t('Channels.line.editPage') }}</a>
                              </div> -->
                              <p>{{ $t('Channels.line.lastUpdatedOn') }} <span class="dark"> {{ $t('Channels.line.date') }} </span> {{ $t('Channels.line.by') }} <span class="dark"> {{ $t('Channels.line.shivamMishra') }} </span></p>
                          </div>
                
                
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Channels.line.previous') }}</p>
                                
                                    <router-link to="/docs/product/channels/telegram" class="introduction-account">
                                        <span>«</span> {{ $t('Channels.line.telegram') }}
                                    </router-link>
                            </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Channels.line.next') }}</p>
                                    <router-link to="/docs/product/channels/supported-features">
                                        {{ $t('Channels.line.supportedFeatures') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">
                 
            </div>
        </div>
    </div>
</section></template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');

            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('href'));

                if (target?.offsetTop <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>


6500