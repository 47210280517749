<template>
  <Header @language-selected="handleLanguageSelected"/>
  <div class="mobile-apps">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5 leftcolumn">
          <img
            src="https://d33wubrfki0l68.cloudfront.net/0d4fbe8734b9b2ee810d27dbdc4fffe45465f829/a5cdd/features/mobileapps/phone-hero.png"
            class="h-[300px] sm:h-[400px] md:h-[500px] w-auto"
            alt="Phone"
          />
        </div>
        <div class="col-md-7 rightcolumn">
          <h1>{{ $t('mobile-apps.mobileDesc') }}</h1>
          <p>
            {{ $t('mobile-apps.mobile_desc') }}
          </p>
          <div class="d-flex appstore">
            <!-- <a
              href="https://apps.apple.com/app/id1495796682"
              rel="noopener noreferrer nofollow"
              target="_blank"
              ><img
                src="https://d33wubrfki0l68.cloudfront.net/814d5384ef54c6996e1ebb76c103acd448eaf018/270d3/features/mobileapps/app-store.png"
                alt="{AppStore}"
                class="h-10 w-auto" /></a
            ><a
              href="https://play.google.com/store/apps/details?id=com.chatwoot.app&amp;hl=en"
              rel="noopener noreferrer nofollow"
              target="_blank"
              ><img
                src="https://d33wubrfki0l68.cloudfront.net/fb32fa6315ef7b4721fce6f662fdf10f62a3a2a5/150d3/features/mobileapps/playstore.png"
                alt="{PlayStore}"
                class="h-10 w-auto ml-2"
            /></a> -->
            <img @click="downloadFile('ios')" 
                src="https://d33wubrfki0l68.cloudfront.net/814d5384ef54c6996e1ebb76c103acd448eaf018/270d3/features/mobileapps/app-store.png"
                alt="{AppStore}"
                class="h-10 w-auto app-download-link" />
            <img @click="downloadFile('android')"
                src="https://d33wubrfki0l68.cloudfront.net/fb32fa6315ef7b4721fce6f662fdf10f62a3a2a5/150d3/features/mobileapps/playstore.png"
                alt="{PlayStore}"
                class="h-10 w-auto ml-2 app-download-link"
            />
            <!-- <p class="m-0 pt-1 download-apk">{{ $t("home.header.downloadApk") }}</p>] -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer :selectLanguage="selectLanguage"/>
</template>
<script>
import Footer from "@/views/chatwoot/Footer.vue";
import Header from "@/views/chatwoot/Header.vue";

export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      selectLanguage: 'en',
    };
  },
  methods: {
    handleLanguageSelected (language) {
      this.selectLanguage = language;
    },
    downloadFile (type) {
      if(type == 'android'){
          let url = "https://download.bcoor.com/download/Androidapk/Bcoor%E5%85%8D%E8%B4%B9%E5%9C%A8%E7%BA%BF%E5%AE%A2%E6%9C%8D%E7%B3%BB%E7%BB%9F.apk"
          const a = document.createElement('a')
          a.href = url
          a.download = url.split('/').pop()
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
      }else if(type == 'ios'){
          window.open("https://apps.apple.com/app/bcoor在线客服/id6499088891")
      }
    },
  },
  created() {
    const localStorageItem = localStorage.getItem('language');

    if (localStorageItem) {

      this.selectLanguage = localStorageItem;
    }
  }
};
</script>

<style scoped>
.download-apk {
  font-size: 14px !important;
  text-decoration: none !important;
}

.app-download-link {
    cursor: pointer;
}

.app-download-link:hover {
    transform: scale(1.04);
    transition: all .2s;
}
</style>
