<template>
  <Header @language-selected="handleLanguageSelected"/>
  <section class="termsService">
    <div class="container">
      <div class="terms-heading">
        <h2>{{ $t("footer.footerProduct.legal.items.textTermsofService") }}</h2>
      </div>
      <!-- <div class="terms-sec-heading">
        <h4>
          <a href="#"
            ><svg
              aria-hidden="true"
              focusable="false"
              height="16"
              version="1.1"
              viewBox="0 0 16 16"
              width="16"
            >
              <path
                fillrule="evenodd"
                d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              ></path></svg></a
          >
          {{ $t("terms-of-service.FOSS") }}
        </h4>
        <p>
          {{ $t("terms-of-service.FOSSlicense") }}
          <a href="#"> {{ $t("terms-of-service.github_repo") }}</a> .
          {{ $t("terms-of-service.FOSS_MIT_license") }}
        </p>
      </div> -->
      <div class="terms-sec-heading">
        <h4>
          <a href="#"
            ><svg
              aria-hidden="true"
              focusable="false"
              height="16"
              version="1.1"
              viewBox="0 0 16 16"
              width="16"
            >
              <path
                fillrule="evenodd"
                d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              ></path></svg></a
          >{{ $t("terms-of-service.bcoor-license-term") }}
        </h4>
        <p>{{ $t("terms-of-service.bcoor-license-term-text") }} <a class="mit-link" target="_blank" href="https://www.chatwoot.com/docs/self-hosted/faq#how-can-i-customise-the-chatwoot-branding-">MIT</a> {{ $t("terms-of-service.license") }}</p>

        <h4>
          <a href="#"
            ><svg
              aria-hidden="true"
              focusable="false"
              height="16"
              version="1.1"
              viewBox="0 0 16 16"
              width="16"
            >
              <path
                fillrule="evenodd"
                d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              ></path></svg></a
          >{{ $t("terms-of-service.subscriptions_terms") }}
        </h4>
        <p>{{ $t("terms-of-service.self_hosted_plan") }}</p>
        <p>{{ $t("terms-of-service.signing_desc") }}</p>
        <h5>{{ $t("terms-of-service.license_support") }}</h5>
        <p>{{ $t("terms-of-service.license_support_1") }}</p>
        <p>{{ $t("terms-of-service.license_support_2") }}</p>
        <p>{{ $t("terms-of-service.license_support_3") }}</p>
        <h5>{{ $t("terms-of-service.restrictions_responsibility") }}</h5>
        <p>{{ $t("terms-of-service.restrictions_responsibility_1") }}</p>
        <p>{{ $t("terms-of-service.restrictions_responsibility_2") }}</p>
        <p>{{ $t("terms-of-service.restrictions_responsibility_3") }}</p>
        <h5>{{ $t("terms-of-service.confidentiality") }}</h5>
        <p>{{ $t("terms-of-service.confidentiality_desc") }}</p>
        <p>{{ $t("terms-of-service.confidentiality_desc_1") }}</p>
        <p>{{ $t("terms-of-service.confidentiality_desc_2") }}</p>
        <p>{{ $t("terms-of-service.confidentiality_desc_3") }}</p>
        <h5>{{ $t("terms-of-service.intellectual_property") }}</h5>
        <p>{{ $t("terms-of-service.intellectual_property_1") }}</p>
        <p>{{ $t("terms-of-service.intellectual_property_2") }}</p>
        <p>{{ $t("terms-of-service.intellectual_property_3") }}</p>
        <p>{{ $t("terms-of-service.intellectual_property_4") }}</p>
        <h5>{{ $t("terms-of-service.payment_fees") }}</h5>
        <p>
          {{ $t("terms-of-service.payment_fees_1")
          }}<a href="#"> {{ $t("terms-of-service.support_email_before") }}@{{ $t("terms-of-service.support_email_after") }}</a> .
        </p>
        <p>{{ $t("terms-of-service.payment_Desc") }}</p>
        <p>{{ $t("terms-of-service.payment_Desc_1") }}</p>
        <p>{{ $t("terms-of-service.payment_Desc_2") }}</p>
        <h5>{{ $t("terms-of-service.termination") }}</h5>
        <p>{{ $t("terms-of-service.termination_1") }}</p>
        <p>{{ $t("terms-of-service.termination_2") }}</p>
        <p>{{ $t("terms-of-service.termination_3") }}</p>
        <p>{{ $t("terms-of-service.termination_4") }}</p>
        <h5>{{ $t("terms-of-service.warranty") }}</h5>
        <p>{{ $t("terms-of-service.warranty_1") }}</p>
        <h5>{{ $t("terms-of-service.warranty_disclaimer") }}</h5>
        <p>{{ $t("terms-of-service.warranty_disclaimer_1") }}</p>
        <h5>{{ $t("terms-of-service.limitation_of_liability") }}</h5>
        <p>{{ $t("terms-of-service.limitation_of_liability_1") }}</p>
        <h5>{{ $t("terms-of-service.government_matters") }}</h5>
        <p>{{ $t("terms-of-service.government_matters_1") }}</p>
        <h5>{{ $t("terms-of-service.miscellaneous") }}</h5>
        <p>{{ $t("terms-of-service.miscellaneous_1") }}</p>
        <h5>{{ $t("terms-of-service.data_privacy") }}</h5>
        <p>{{ $t("terms-of-service.data_privacy_1") }}</p>
        <h4>
          <a href="#"
            ><svg
              aria-hidden="true"
              focusable="false"
              height="16"
              version="1.1"
              viewBox="0 0 16 16"
              width="16"
            >
              <path
                fillrule="evenodd"
                d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              ></path></svg></a
          >{{ $t("terms-of-service.professional_terms") }}
        </h4>
        <p>{{ $t("terms-of-service.professional_terms_1") }}</p>
        <h5>{{ $t("terms-of-service.services") }}</h5>
        <p>{{ $t("terms-of-service.services_1") }}</p>
        <p>{{ $t("terms-of-service.services_2") }}</p>
        <p>{{ $t("terms-of-service.services_3") }}</p>
        <h5>{{ $t("terms-of-service.payment_taxes") }}</h5>
        <p>{{ $t("terms-of-service.payment_taxes_1") }}</p>
        <p>{{ $t("terms-of-service.payment_taxes_2") }}</p>
        <h5>{{ $t("terms-of-service.confidentiality") }}</h5>
        <p>{{ $t("terms-of-service.confidentiality_desc") }}</p>
        <p>{{ $t("terms-of-service.confidentiality_desc_1") }}</p>
        <p>{{ $t("terms-of-service.confidentiality_desc_2") }}</p>
        <p>{{ $t("terms-of-service.confidentiality_desc_3") }}</p>
        <h5>{{ $t("terms-of-service.termination") }}</h5>
        <p>{{ $t("terms-of-service.service_agreement") }}</p>
        <p>{{ $t("terms-of-service.service_agreement_1") }}</p>
        <p>{{ $t("terms-of-service.service_agreement_2") }}</p>
        <p>{{ $t("terms-of-service.service_agreement_3") }}</p>
        <h5>{{ $t("terms-of-service.warranty5") }}</h5>
        <p>{{ $t("terms-of-service.warranty_desc") }}</p>
        <h5>{{ $t("terms-of-service.warrant_disclaimer") }}</h5>
        <p>{{ $t("terms-of-service.warrant_disclaimer_1") }}</p>
        <h5>7. {{ $t("terms-of-service.limitation_of_liability") }}</h5>
        <p>{{ $t("terms-of-service.limitation_of_liability_1") }}</p>
        <h5>8. {{ $t("terms-of-service.miscellaneous") }}</h5>
        <p>{{ $t("terms-of-service.miscellaneous_1") }}</p>
        <h5>9. {{ $t("terms-of-service.data_privacy") }}</h5>
        <p>{{ $t("terms-of-service.data_privacy_1") }}</p>
        <h4>
          <a href="#"
            ><svg
              aria-hidden="true"
              focusable="false"
              height="16"
              version="1.1"
              viewBox="0 0 16 16"
              width="16"
            >
              <path
                fillrule="evenodd"
                d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              ></path></svg></a
          >{{ $t("terms-of-service.GDPR_Agreement") }}
        </h4>
        <p>
          {{ $t("terms-of-service.data_processing")
          }}<a href="#">{{ $t("terms-of-service.gdpr_email_before") }}@{{ $t("terms-of-service.gdpr_email_after") }}</a
          > {{ $t("terms-of-service.for_more_detail") }}
        </p>
      </div>
    </div>
  </section>
  <Footer :selectLanguage="selectLanguage"/>
</template>
<script>
import Footer from "@/views/chatwoot/Footer.vue";
import Header from "@/views/chatwoot/Header.vue";

export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      selectLanguage: 'en',
    };
  },
  methods: {
    handleLanguageSelected (language) {
      this.selectLanguage = language;
    },
  },
  created() {
    const localStorageItem = localStorage.getItem('language');

    if (localStorageItem) {

      this.selectLanguage = localStorageItem;
    }
  }
};
</script>

<style scoped >
.mit-link {
  color: black;
}

.mit-link:hover {
  color: blue;
}
</style>
