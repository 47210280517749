<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('home.header.Features') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.Contacts') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>On this page</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Outbound-link' }">
                                                        <a @click="scrollTo('#Outbound')" class="link_wrp">{{
                                                            $t('features.contacts.outboundConversation') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Contacts-Notes-link' }">
                                                        <a @click="scrollTo('#Contacts-Notes')" class="link_wrp">{{
                                                            $t('features.contacts.contactNotes') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Import-Contacts-link' }">
                                                        <a @click="scrollTo('#Import-Contacts')" class="link_wrp">{{
                                                            $t('features.contacts.importContacts') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Search-Contacts-link' }">
                                                        <a @click="scrollTo('#Search-Contacts')" class="link_wrp">{{
                                                            $t('features.contacts.searchcontacts') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Filtering-Contacts-link' }">
                                                        <a @click="scrollTo('#Filtering-Contacts')" class="link_wrp">{{
                                                            $t('features.contacts.filteringContacts') }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="Introduction-details">
                            <div class="canned_list_wrp">
                                <h1>{{ $t('doc.product.Contacts') }}</h1>
                                <p>{{ $t('features.contacts.contactDescription') }}</p>
                                <p>{{ $t('features.contacts.clickContactHomeScreen') }}</p>

                                <p class="widget_wrp">
                                    <img src="../../../../assets/images/homecontacts.webp" class="img-fuild">
                                </p>
                                <p>{{ $t('features.contacts.alreadyAddedContact') }}</p>

                                <p class="widget_wrp">
                                    <img src="../../../../assets/images/emptycontacts.webp" class="img-fuild">
                                </p>

                                <p>{{ $t('features.contacts.stepsForAddedcontact') }}</p>

                                <p class="widget_wrp">
                                    <img src="../../../../assets/images/createnewcontact.webp" class="img-fuild">
                                </p>

                                <p>{{ $t('features.contacts.secondWay') }}
                                    <a href="/docs/user-guide/setup-your-account/add-inbox-settings#pre-chat-form">
                                        {{ $t('features.contacts.preChatForms') }}
                                    </a>.
                                </p>

                                <p>{{ $t('features.contacts.detailConfiguration') }}
                                    <a href="/docs/user-guide/features/pre-chat-form">{{
                                        $t('doc.conversationContinuity.guide') }}
                                    </a>
                                </p>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/prechatform.webp" class="img-fuild">
                                </p>

                                <p>{{ $t('features.contacts.customerEmilId') }}</p>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/howconversationhappens.webp" class="img-fuild">
                                </p>

                                <p>{{ $t('features.contacts.listofcontact') }}</p>

                                <h3 id="Outbound">
                                    {{ $t('features.contacts.outboundConversation') }}
                                </h3>

                                <p>{{ $t('features.contacts.sendOutboundConversation') }}</p>

                                <div class="contact-list">
                                    <ul>
                                        <li>{{ $t('features.contacts.HMACContact') }}</li>
                                        <li>
                                            {{ $t('doc.product.Email') }}
                                        </li>
                                        <li>
                                            {{ $t('headersubmenu.Features.headerSMS') }}
                                        </li>
                                        <li>
                                            {{ $t('headersubmenu.Features.headerWhatsApp') }}
                                        </li>
                                    </ul>
                                </div>

                                <div class="outbond_wrp">
                                    <h4>
                                        {{ $t('features.contacts.outboundconversation') }}
                                    </h4>
                                    <ol>
                                        <li>{{ $t('features.contacts.clickOutboundConversation') }}</li>
                                        <li>{{ $t('features.contacts.conversationInbox') }}</li>
                                        <li>{{ $t('features.contacts.fillMessageContact') }}</li>
                                        <li>{{ $t('features.contacts.clickSend') }}</li>
                                    </ol>
                                </div>

                                <p class="non_web_wrp conectone" style="width: 100%;">
                                    <img src="../../../../assets/images/onecontact/_1_4x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp conecttwo" style="width: 100%;">
                                    <img src="../../../../assets/images/onecontact/_1_3x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp conectthree" style="width: 100%;">
                                    <img src="../../../../assets/images/onecontact/_1_2x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp conectonefour" style="width: 100%;">
                                    <img src="../../../../assets/images/onecontact/_1_1x.webp" class="img-fuild">
                                </p>



                                <p><strong>{{ $t('doc.SDKSetup.sdkSettings.noteLabel') }}</strong>
                                    {{ $t('features.contacts.agentCreateoutBoundConversation') }}
                                </p>

                                <h3 id="Contacts-Notes">
                                    {{ $t('features.contacts.contactNotes') }}
                                </h3>

                                <p>{{ $t('features.contacts.dedicatdPageNote') }}</p>

                                <div class="outbond_wrp">
                                    <h4>{{ $t('features.contacts.addNoteContact') }}</h4>
                                    <ol>
                                        <li>{{ $t('features.contacts.contactItemFrom') }}</li>
                                        <li>{{ $t('features.contacts.selectContactList') }}</li>
                                        <li>{{ $t('features.contacts.yourNotes') }}</li>
                                        <li>{{ $t('features.contacts.addButton') }}
                                            <code>{{ $t('features.contacts.command') }}</code>.
                                        </li>
                                    </ol>
                                </div>

                                <!-- <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/contact-add.gif" class="img-fuild">
                                </p> -->
                                   <p class="non_web_wrp conitmgone" style="width: 100%;">
                                        <img src="../../../../assets/images/contactimgfive/_15_4x.webp"   class="img-fuild">
                                    </p>
                                       <p class="non_web_wrp conitmgtwo" style="width: 100%;">
                                            <img src="../../../../assets/images/contactimgfive/_15_3x.webp"   class="img-fuild">
                                        </p>
                                           <p class="non_web_wrp conitmgthree" style="width: 100%;">
                                            <img src="../../../../assets/images/contactimgfive/_15_2x.webp"   class="img-fuild">
                                        </p>
                                           <p class="non_web_wrp conitmgfour" style="width: 100%;">
                                            <img src="../../../../assets/images/contactimgfive/_15_1x.webp"   class="img-fuild">
                                        </p>

                                <p>{{ $t('features.contacts.highlightData') }}</p>

                                <h4>{{ $t('features.contacts.deleteContactNote') }}</h4>

                                <p>{{ $t('features.contacts.simpleDeleteIcon') }}</p>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/contact-note.png" class="img-fuild">
                                </p>

                                <h3 id="Import-Contacts">
                                    {{ $t('features.contacts.importContacts') }}
                                </h3>

                                <p>{{ $t('features.contacts.CSVfile') }}</p>

                                <div class="outbond_wrp">
                                    <ol>
                                        <li>{{ $t('features.contacts.navigatePageSideBar') }}</li>
                                        <li>{{ $t('features.contacts.importButton') }}</li>
                                        <li>{{ $t('features.contacts.selectCSVFile') }}
                                            <a href="https://staging.chatwoot.com/downloads/import-contacts-sample.csv"
                                                target="_blank" rel="noopener noreferrer">{{
                                                    $t('features.contacts.CSVfile1') }}</a>
                                            {{ $t('features.contacts.forReference') }}
                                        </li>
                                        <li>{{ $t('features.contacts.importButtonUploadCSV') }}</li>
                                    </ol>
                                </div>

                                <p>{{ $t('features.contacts.quickdemo') }}</p>

                                <!-- <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/contacts-import.gif" class="img-fuild">
                                </p> -->
                                <p class="non_web_wrp conimgone" style="width: 100%;">
                                    <img src="../../../../assets/images/contactimgthree/_3_4x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp conimgtwo" style="width: 100%;">
                                    <img src="../../../../assets/images/contactimgthree/_3_3x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp conimgthree" style="width: 100%;">
                                    <img src="../../../../assets/images/contactimgthree/_3_2x.webp" class="img-fuild">
                                </p>
                                   <p class="non_web_wrp conimgfour" style="width: 100%;">
                                            <img src="../../../../assets/images/contactimgthree/_3_1x.webp" class="img-fuild">
                                        </p>

                                <h3 id="Search-Contacts">
                                    {{ $t('features.contacts.searchcontacts') }}
                                </h3>

                                <p>{{ $t('features.contacts.contactByEntering') }}</p>

                                <p>{{ $t('features.contacts.currentlyFilterContact') }}</p>

                                <!-- <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/search-contacts.gif" class="img-fuild">
                                </p> -->
                                <p class="non_web_wrp contectimgone" style="width: 100%;">
                                    <img src="../../../../assets/images/contacttwo/_2_4x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp contectimgtwo" style="width: 100%;">
                                    <img src="../../../../assets/images/contacttwo/_2_3x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp contectimgthree" style="width: 100%;">
                                    <img src="../../../../assets/images/contacttwo/_2_2x.webp" class="img-fuild">
                                </p>
                                <p class="non_web_wrp contectimgfour" style="width: 100%;">
                                    <img src="../../../../assets/images/contacttwo/_2_1x.webp" class="img-fuild">
                                </p>





                                <h3 id="Filtering-Contacts">
                                    {{ $t('features.contacts.filteringContacts') }}
                                </h3>

                                <p>{{ $t('features.contacts.advancedFilterContact') }}</p>

                                <p>{{ $t('features.contacts.offersContact') }}</p>

                                <div class="outbond_wrp">
                                    <ol>
                                        <li>{{ $t('features.contacts.contactEmail') }}</li>
                                        <li>{{ $t('features.contacts.phoneNumber') }}</li>
                                        <li>{{ $t('features.contacts.contactIdentifier') }}</li>
                                        <li>{{ $t('features.contacts.Country') }}</li>
                                        <li>{{ $t('features.contacts.City') }}</li>
                                    </ol>
                                </div>

                                <p>{{ $t('features.contacts.filterContact') }}</p>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/contact-filters.webp" class="img-fuild">
                                </p>

                                <u class="example_wrp">
                                    {{ $t('doc.SDKSetup.toggleWidgetBubbleVisibility.example') }}
                                </u>
                                <p>{{ $t('features.contacts.allContactFromUSA') }}</p>

                                <div class="outbond_wrp">
                                    <ol>
                                        <li>{{ $t('features.contacts.filterTypeName') }}</li>
                                        <li>{{ $t('features.contacts.addFilter') }}</li>
                                        <li>{{ $t('features.contacts.countryFilter') }}</li>
                                        <li>{{ $t('features.contacts.submitcontact') }}</li>
                                    </ol>
                                </div>

                                <p class="non_web_wrp" style="width: 100%;">
                                    <img src="../../../../assets/images/contact-filters-list.png" class="img-fuild">
                                </p>

                                <p>{{ $t('features.contacts.queriesFilter') }}</p>

                                <p>{{ $t('features.contacts.clearFilter') }}</p>
                            </div>
                        </div>

                        <div class="update_edit_wrp">
                            <!-- <div class="edit_wrp">
                                <a href="#">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>

                                    <p>
                                        {{ $t('doc.product.EditThisPage') }}
                                    </p>
                                </a>
                            </div> -->

                            <div class="update_wrp">
                                <p>
                                    {{ $t('doc.product.LastUpdated') }} <b>{{ $t('features.contacts.date') }}</b> {{
                                        $t('doc.product.By') }} <b>{{ $t('features.canned-responses.author') }}</b>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">Previous</p>
                                    <router-link to="/docs/user-guide/features/csat" class="introduction-account">
                                        <span>«</span> {{ $t('doc.product.CSAT') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                    <router-link to="/docs/product/features/inbox/routing-conversations-round-robin">
                                        {{ $t('features.contacts.routing') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Outbound-link' }"><a
                                        id="#Outbound-link" @click="scrollTo('#Outbound')" class="link_wrp">{{
                                            $t('features.contacts.outboundConversation') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Contacts-Notes-link' }"><a
                                        id="#Contacts-Notes-link" @click="scrollTo('#Contacts-Notes')" class="link_wrp">{{
                                            $t('features.contacts.contactNotes') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Import-Contacts-link' }"><a
                                        id="#Import-Contacts-link" @click="scrollTo('#Import-Contacts')" class="link_wrp">{{
                                            $t('features.contacts.importContacts') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Search-Contacts-link' }"><a
                                        id="#Search-Contacts-link" @click="scrollTo('#Search-Contacts')" class="link_wrp">{{
                                            $t('features.contacts.searchcontacts') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Filtering-Contacts-link' }"><a
                                        id="#Filtering-Contacts-link" @click="scrollTo('#Filtering-Contacts')"
                                        class="link_wrp">{{ $t('features.contacts.filteringContacts') }}</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}

p.widget_wrp img {
    width: 100%;
    height: 100%;
}

.contact-list ul li {
    text-align: left;
    list-style: disc;
    color: var(--side-bar-font-color);
}

.outbond_wrp ol li {
    text-align: left;
    list-style: auto;
    color: var(--side-bar-font-color);
    margin: 10px 0;
    font-weight: 500;
}

u.example_wrp {
    text-align: left;
    display: flex;
    margin: 14px 0;
    color: var(--side-bar-font-color);
}</style>