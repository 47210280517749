<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{$t('doc.product.Channels')}}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{$t('wassuptwilo.WLC')}}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{$t('twiloChannel.twilio')}}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                            <p>{{$t('doc.product.OnThisPage')}}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#channels-Twilio-link' }">
                                                        <a @click="scrollTo('#channels-Twilio')">{{$t('twiloChannel.congifChannel')}}</a></li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Additional-info-link' }">
                                                        <a @click="scrollTo('#Additional-info')">{{$t('wassuptwilo.additionIS')}}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">
                            <h1>{{$t('wassuptwilo.setUpTwilo')}}</h1>
                            <p><b>{{$t('twiloChannel.step1')}}</b>  {{$t('twiloChannel.setupPath')}}</p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/adding-inbox-in-chatwootAA.webp">
                            </div>
                            <p><b>{{$t('twiloChannel.step2')}}</b> {{$t('wassuptwilo.clickWassup')}} </p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/select_whatsapp.png">
                            </div>
                            <p><b>{{$t('twiloChannel.step3')}}</b> {{$t('wassuptwilo.fillInDetails')}} </p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/twilio_add_detail.webp">
                            </div>

                            <div class="table-details tabel-twillo">
                                <table class="table table-bordered widthfull">
                                    <thead>
                                        <tr>
                                            <th>{{$t('twiloChannel.input')}}</th>
                                            <th>{{$t('twiloChannel.DESC')}}</th>
                                            <th>{{$t('twiloChannel.WhereTF')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{$t('wassuptwilo.input1')}}</td>
                                            <td>{{$t('twiloChannel.description')}}</td>
                                            <td>{{$t('twiloChannel.whereCanIFindIt1')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('twiloChannel.inputPN')}}</td>
                                            <td>{{$t('wassuptwilo.descPN')}}.</td>
                                            <td>{{$t('twiloChannel.whereCanIFindItPN')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('twiloChannel.inputSID')}}</td>
                                            <td>{{$t('twiloChannel.descriptionSID')}}</td>
                                            <td>{{$t('twiloChannel.whereCanIFindItSID')}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('twiloChannel.inputAT')}}</td>
                                            <td>{{$t('twiloChannel.descriptionAT')}}</td>
                                            <td> {{$t('twiloChannel.whereCanIFindItAT')}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                                <p class="text-left">{{$t('twiloChannel.click')}}<span class="click-inbox">{{$t('twiloChannel.createChannel')}}</span>{{$t('twiloChannel.afterFI')}}</p>
                            </div>
                            <p><b>{{$t('twiloChannel.step4')}}</b> {{$t('twiloChannel.addAgentToInbox')}}</p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/add-agents-to-whatsapp-twilio.webp">
                            </div>
                            <p>{{$t('twiloChannel.HoorayMSG')}}</p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/whatsapp-inbox-is-ready.webp">
                            </div>
                            <p>{{$t('wassuptwilo.toconectA')}} <b>{{$t('wassuptwilo.whatsApp')}}</b> {{$t('wassuptwilo.congidWChannel')}}</p>
                            <ul>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('wassuptwilo.loginbToConsole')}}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('wassuptwilo.GT')}}<span class="click-inbox">{{$t('wassuptwilo.clickPath')}}</span></p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('wassuptwilo.descPNC')}}
                                    </p>
                                </li>
                            </ul>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/twilio-setup.webp">
                            </div>
                            <ul>
                                <li>
                                    <p>{{$t('wassuptwilo.provide')}}<span class="click-inbox">{{$t('wassuptwilo.callbackUrl')}}</span>{{$t('wassuptwilo.asTVF')}} <span class="click-inbox">WHEN A MESSAGE COMES IN</span> input. </p>
                                </li>
                            </ul>
                        </div>

                        <div class="Introduction-details" id="channels-Twilio">
                            <h1>{{$t('twiloChannel.congifChannel')}}</h1>
                            <p>{{$t('wassuptwilo.customeFlow')}}</p>
                            <p>{{$t('wassuptwilo.foloStepsW')}}</p>
                            <p>{{$t('wassuptwilo.firstStep')}}</p>
                            <p>{{$t('wassuptwilo.stepFroAdd')}} <span class="click-inbox">{{$t('wassuptwilo.MHRW')}} </span> {{$t('wassuptwilo.showBelow')}} </p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/twilio-studio-setup.webp">
                            </div>
                            <p><b>{{$t('wassuptwilo.REQMethod')}} </b> {{$t('wassuptwilo.post')}} </p>
                            <p><b>{{$t('wassuptwilo.requestURL')}} </b> <a href="#"> {{$t('wassuptwilo.callbackUrl')}}</a> </p>
                            <p><b>{{$t('wassuptwilo.content_type')}}</b> {{$t('wassuptwilo.jsontype')}}</p>
                            <!-- <p><b>REQUEST BODY</b><span class="click-inbox">{{trigger.message | to_json }}
                              </span>  ( make changes as required based on your flow variables). </p> -->
                            <p class="http-request">{{$t('wassuptwilo.reqDesc')}}</p>
                            <p class="mt-2">{{$t('wassuptwilo.usrRes')}}</p>
                        </div>

                        <div class="Introduction-details" id="Additional-info">
                            <h1>{{$t('wassuptwilo.additionSetting')}}</h1>
                            <p>{{$t('wassuptwilo.ADSDesc')}}</p>
                            <p>{{$t('wassuptwilo.ADSdesc1')}}</p>
                        </div>



                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{$t('doc.inboxes.edit_page')}}</a>
                            </div> -->
                            <p>{{$t('doc.inboxes.last_updated_on')}} <span class="dark"> {{$t('wassuptwilo.date')}} </span> {{$t('doc.inboxes.updated_by')}} <span class="dark"> {{$t('wassuptwilo.name')}}</span></p>
                        </div>



                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{$t('concepts.previousPage')}}</p>

                                    <router-link to="/docs/product/channels/whatsapp/whatsapp-cloud"
                                        class="introduction-account">
                                        <span>«</span> {{$t('wassuptwilo.whatsAppCloud')}}
                                    </router-link>

                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{$t('concepts.nextPage')}}</p>

                                    <router-link to="/docs/product/channels/whatsapp/360dialog">
                                        {{$t('twiloChannel.360Dialog')}}  <span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#channels-Twilio-link' }"><a
                                        id="#channels-Twilio-link" @click="scrollTo('#channels-Twilio')">{{$t('twiloChannel.congifChannel')}}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Additional-info-link' }"><a
                                        id="#Additional-info-link" @click="scrollTo('#Additional-info')">{{$t('wassuptwilo.additionIS')}}</a></li>

                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>