<template>
    <Header @language-selected="handleLanguageSelected"/>
        <section class="labels-page">
          <div class="container">
            <div class="integrations-heading teams-heading">
            <h2>{{ $t('featuresteams.agent_team') }}</h2>
              <p>{{ $t('featuresteams.team_create_desc') }}</p>
              <div class="start-button">
                <a href="https://app.bcoor.com">{{
                        $t("automation.freeTrial")}}</a>
              </div>
          </div>
          <div class="labels-page-details">
            <p>{{ $t('featuresteams.team_create_desc1') }}</p>
          </div>
          <div class="labels-page-contant">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="labels-page-img">
                  <img src="../../../assets/images/teams/create-team.png">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="labels-page-img-details">
                    <h4>{{ $t('featuresteams.setup_your_team_ac') }}</h4>
                    <p>{{ $t('featuresteams.setup_team_conversation') }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="labels-page-contant">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12 orderr-2">
                <div class="labels-page-img-details">
                    <h4>{{ $t('featuresteams.assign_coversation') }}</h4>
                    <p>{{ $t('featuresteams.automation_data') }}</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12 orderr-1">
                <div class="labels-page-img">
                  <img src="../../../assets/images/teams/auto-assign-teams.png">
                </div>
              </div>
            </div>
            </div>

          <div class="labels-page-contant">
                <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="labels-page-img">
                  <img src="../../../assets/images/teams/team-overview.png">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="labels-page-img-details">
                  <h4>{{ $t('featuresteams.team_analytics') }}</h4>
                  <p>{{ $t('featuresteams.team_desc') }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="conversations d-none">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="conversations-heading">
                    <h3>{{ $t('featuresteams.start_team_ac') }}</h3>
                    <p>{{ $t('labels.access_organized1') }}</p>
                    <div class="signup-button">
                      <a href="https://app.bcoor.com">{{ $t('emailIntegration.sign_up_free') }}</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="conversations-img">
                    <img src="../../../assets/images/automations/dashboard-small.webp">
                  </div>
                </div>
              </div>
          </div>
          </div>

        </section>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>