<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Others') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.EnterpriseEdition') }}</a>
                        </div>  
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                          {{ $t('Enterprise.onThisPage') }}
                                        </button>
                                    </h2>
                                    <!-- <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }">
                                                        <a href="#features-info">Testing</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">

                            <div class="edition_wrp">
                                <h1>{{ $t('Enterprise.enterpriseEdition') }}</h1>
                                <p>{{ $t('Enterprise.startingFrom') }}<a
                                        href="https://www.chatwoot.com/blog/2-0-release-ee-edition-et-al/"
                                        target="_blank" rel="noopener noreferrer">{{ $t('Enterprise.version') }} <span class="click-inbox body-tag"><code>2.0</code></span> </a>,
                                  {{ $t('Enterprise.shipsTwoVersion') }}<span class="click-inbox body-tag"><code>MIT</code></span>
                                  {{ $t('Enterprise.licensedEnterprise') }} <a
                                        href="https://github.com/chatwoot/chatwoot/blob/develop/LICENSE"
                                        target="_blank" rel="noopener noreferrer">{{ $t('Enterprise.proprietaryLicense') }}</a>. {{ $t('Enterprise.bothSoftwareVersion') }} <a href="https://github.com/chatwoot/chatwoot"
                                        target="_blank" rel="noopener noreferrer">{{ $t('Enterprise.githubRepository') }}</a>. </p>


                                <p>{{ $t('Enterprise.intro1') }}</p>
                            </div>

                            <div class="edition_wrp">
                                <h2>{{ $t('Enterprise.differencesBetweenCommunity') }}</h2>
                                <p>{{ $t('Enterprise.intro2') }}</p>

                                <p>{{ $t('Enterprise.intro3') }}<code>/{{ $t('Enterprise.enterprise') }}</code> {{ $t('Enterprise.folderContents') }}</p>
                            </div>

                            <div class="edition_wrp">
                                <h2>{{ $t('Enterprise.choosingTheRightEdition') }}</h2>
                                <blockquote style="height:auto;">
                                        <p style="padding-bottom:0;">{{ $t('Enterprise.intro4') }}</p>
                                </blockquote>
                                
                            </div>

                            <div class="ios_options">
                                <h3>{{ $t('Enterprise.enterpriseEdition') }}</h3>
                                <ul>
                                    <li>
                                      {{ $t('Enterprise.enterpriseAdvantage1') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.enterpriseAdvantage2') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.enterpriseAdvantage3') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.enterpriseAdvantage4') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.enterpriseAdvantage5') }}
                                    </li>
                                </ul>
                            </div>

                            <div class="ios_options">
                                <h3>{{ $t('Enterprise.communityEdition') }}</h3>
                                <ul>
                                    <li>
                                      {{ $t('Enterprise.communityAdvantage1') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.communityAdvantage2') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.communityAdvantage3') }}
                                    </li>
                                    <li>
                                      {{ $t('Enterprise.communityAdvantage4') }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                       
                       <div class="edit-update-detail">
                                <!-- <div class="edit-pen">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                        aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>
                                    <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('Mobile_App.edit_this_page') }}</a>
                                </div> -->
                                <p>{{ $t('Mobile_App.last_update') }} <span class="dark">5/03/2023 </span>{{ $t('Mobile_App.by') }} <span class="dark">Douglas Lara</span>
                                </p>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p class="preview">{{ $t('Mobile_App.previous') }}</p>

                                        <router-link to="/docs/product/mobile-app/android" class="introduction-account">
                                            <span>«</span> {{ $t('Mobile_App.ios') }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p>{{ $t('Mobile_App.next') }}</p>

                                        <router-link to="/docs/product/others/enterprise-edition">
                                          {{ $t('doc.product.FAQ') }}<span>»</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                    </div>

                </div>
                <div>
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li><a href="#">{{ $t('Enterprise.differencesBetweenCommunity') }}</a></li>
                                <li><a href="#">{{ $t('Enterprise.choosingTheRightEdition') }}</a>
                                    <ul>
                                        <li><a href="#">{{ $t('Enterprise.enterpriseEdition') }}</a>
                                        </li>
                                        <li><a href="#">{{ $t('Enterprise.communityEdition') }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
