<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("doc.product.Features") }}</p>
              <img
                src="../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <a href="#">{{ $t("headersubmenu.Features.headercommandBar") }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.product.OnThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img src="/img/up_arrow_side.7f2fb856.svg" alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#opening-the-command-bar-link',
                            }"
                          >
                            <a
                              @click="scrollTo('#opening-the-command-bar')"
                              class="link_wrp"
                            >
                              {{ $t("features.commandBar.openingCommandBar") }}
                            </a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#fast-navigation-link',
                            }"
                          >
                            <a @click="scrollTo('#fast-navigation')" class="link_wrp">
                              {{ $t("features.commandBar.fastNavigation") }}
                            </a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Conversation-Actions-link',
                            }"
                          >
                            <a
                              @click="scrollTo('#Conversation-Actions')"
                              class="link_wrp"
                            >
                              {{ $t("features.commandBar.conversationActions") }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details">
              <div class="canned_list_wrp">
                <h1>{{ $t("headersubmenu.Features.headercommandBar") }}</h1>
                <p>
                  {{ $t("features.commandBar.commandBarDesc") }}
                  <code>{{ $t("features.commandBar.Icondata") }}</code
                  >{{ $t("features.commandBar.macOS")
                  }}<code>{{ $t("features.commandBar.ctrlK") }}</code>
                  {{ $t("features.commandBar.commandBarDesc2") }}
                </p>

                <p class="widget_wrp">
                  <img src="../../../../assets/images/commandbar.png" class="img-fuild" />
                </p>
              </div>

              <div class="canned_list_wrp" id="opening-the-command-bar">
                <h1>{{ $t("features.commandBar.openingCommandBar") }}</h1>

                <p>{{ $t("features.commandBar.commandBarDesc3") }}</p>

                <ul class="system_command">
                  <li>
                    {{ $t("features.commandBar.windowLinux") }}
                    <code>{{ $t("features.commandBar.ctrlK") }}</code>
                  </li>

                  <li>
                    {{ $t("features.commandBar.ctrlK") }}
                    <code>{{ $t("features.commandBar.cmd") }} </code>
                  </li>
                </ul>
              </div>

              <div class="canned_list_wrp" id="fast-navigation">
                <h3>{{ $t("features.commandBar.fastNavigation") }}</h3>
                <p>{{ $t("features.commandBar.fastNavigationDesc1") }}</p>

                <p class="widget_wrp">
                  <img src="../../../../assets/images/24.gif" class="img-fuild" />
                </p>

                <div class="command_wrp mt-3">
                  <h4>
                    <u>
                      {{ $t("features.commandBar.quickNavigations") }}
                    </u>
                  </h4>
                  <ul class="navigation_list">
                    <li>
                      {{ $t("features.commandBar.dashboardHome") }}
                    </li>
                    <li>
                      {{ $t("doc.product.Contacts") }}
                    </li>
                    <li>
                      {{ $t("doc.product.Reports") }}
                      <ul class="">
                        <li>
                          {{ $t("features.commandBar.agentReport") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.labelReport") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.inboxReport") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.teamReport") }}
                        </li>
                      </ul>
                    </li>
                    <li>
                      {{ $t("features.commandBar.Settings") }}
                      <ul>
                        <li>
                          {{ $t("features.commandBar.agentSetting") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.teamSetting") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.labelSetting") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.cannedResponseSetting") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.applicationSetting") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.accountSetting") }}
                        </li>
                        <li>
                          {{ $t("features.commandBar.profileSetting") }}
                        </li>
                      </ul>
                    </li>

                    <li>
                      {{ $t("doc.configProfile.notificationsSettings") }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="canned_list_wrp" id="Conversation-Actions">
                <h3>{{ $t("commandBar.conversation_action") }}</h3>
                <p>
                  {{ $t("features.commandBar.commandBarIs") }}
                  <strong>{{ $t("features.commandBar.aware") }}</strong>
                  {{ $t("features.commandBar.commandBarIsDesc") }}
                </p>

                <p>
                  {{ $t("features.commandBar.commandBarIsDesc1") }}
                </p>

                <p class="widget_wrp">
                  <img src="../../../../assets/images/24.gif" class="img-fuild" />
                </p>

                <div class="commangd_list_menu">
                  <h4>
                    <u>
                      {{ $t("features.commandBar.commandAvailable") }}
                    </u>
                  </h4>
                  <ul class="navigation_list">
                    <li>
                      {{ $t("features.commandBar.resolveReopenConversation") }}
                    </li>
                    <li>
                      {{ $t("features.commandBar.muteUnmuteconversation") }}
                    </li>
                    <li>
                      {{ $t("features.commandBar.sendEmailTranscript") }}
                    </li>
                    <li>
                      {{ $t("features.commandBar.assignConversation") }}
                    </li>

                    <li>
                      {{ $t("features.commandBar.addLabelConversation") }}
                    </li>

                    <li>
                      {{ $t("features.commandBar.snoozeConversation") }}
                    </li>
                  </ul>
                </div>

                <div class="commangd_list_menu">
                  <h4>
                    <u>
                      {{ $t("features.commandBar.commandAvailable") }}
                    </u>
                  </h4>
                  <ol class="navigation_list">
                    <li>
                      {{ $t("features.commandBar.openCommandBar") }}
                    </li>
                    <li>
                      {{ $t("features.commandBar.searchAssignAgnet") }}
                    </li>
                    <li>
                      {{ $t("features.commandBar.selectWantAgent") }}
                    </li>
                  </ol>
                </div>

                <p class="widget_wrp">
                  <img
                    src="../../../../assets/images/assign-agent.gif"
                    class="img-fuild"
                  />
                </p>

                <p>
                  {{ $t("features.commandBar.similarlyAssignTeam") }}
                </p>
              </div>
            </div>

            <div class="update_edit_wrp">
              <!-- <div class="edit_wrp">
                                <a href="#">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>

                                    <p>
                                        {{ $t('doc.product.EditThisPage') }}
                                    </p>
                                </a>
                            </div> -->

              <div class="update_wrp">
                <p>
                  {{ $t("doc.product.LastUpdated") }} <b>12/2/2021</b> by
                  <b>Fayaz Ahmed</b>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account create-chat-previous">
                  <p>{{ $t("Mobile_App.previous") }}</p>
                  <a href="/docs/user-guide/features/segments"
                    ><span>«</span>{{ $t("doc.product.Segments") }}</a
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("doc.product.Next") }}</p>
                  <a href="/docs/user-guide/features/template-variables"
                    >{{ $t("doc.product.TemplateVariables") }}<span>»</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <ul id="side_section_info">
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#opening-the-command-bar-link',
                  }"
                >
                  <a
                    id="#opening-the-command-bar-link"
                    @click="scrollTo('#opening-the-command-bar')"
                    class="link_wrp"
                  >
                    {{ $t("features.commandBar.openingCommandBar") }}
                  </a>
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#fast-navigation-link',
                  }"
                >
                  <a
                    id="#fast-navigation-link"
                    @click="scrollTo('#fast-navigation')"
                    class="link_wrp"
                  >
                    {{ $t("features.commandBar.fastNavigation") }}
                  </a>
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#Conversation-Actions-link',
                  }"
                >
                  <a
                    id="#Conversation-Actions-link"
                    @click="scrollTo('#Conversation-Actions')"
                    class="link_wrp"
                  >
                    {{ $t("features.commandBar.conversationActions") }}</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
  name: "ios",
  data() {
    return {
      isToggle: false,
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  components: {
    Header,
    Footer,
    SideBar,
    // MainContent
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
