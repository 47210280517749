<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Reports') }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#"> {{ $t("CSAT.CSAT") }}</a>
                        </div>
                        <!-- <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne"> On this page </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_1" class="active_menu_item"><a
                                                            href="#channels-info">Channels</a></li>
                                                    <li id="menu_item_2" class=""><a
                                                            href="#features-info">Features</a></li>
                                                    <li id="menu_item_3" class=""><a
                                                            href="#integration-info">Integration</a></li>
                                                    <li id="menu_item_4" class=""><a
                                                            href="#advanced-info">Advanced</a></li>
                                                    <li id="menu_item_5" class=""><a href="#mobile-app-info">Mobile
                                                            app</a></li>
                                                    <li id="menu_item_6" class=""><a href="#other-info">Others</a>
                                                    </li>
                                                    <li id="menu_item_7" class=""><a
                                                            href="#compatibility-info">Compatibility and
                                                            Requirements</a></li>
                                                    <li id="menu_item_8" class=""><a href="#next-steps">Next
                                                            Steps</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->


                        <div class="Introduction-details text-align-left">
                            <h1> {{ $t("CSAT.CSAT") }}</h1>
                            <p> {{ $t("CSAT.CSATDesc") }}
                            </p>
                            <p>{{ $t("CSAT.CSATDesc1") }}</p>

                        </div>

                        <div class="channels-info code-info">
                            <div class="channels-title">

                                <div class="center"><img 
                                        src="../../../../../assets/images/csat_feedback.png"
                                            alt="CSAT Report Screen" class="max-img"></div>
                                <p>{{ $t("CSAT.CSATDesc2") }}</p>
                                <p>{{ $t("CSAT.CSATDesc3") }}</p>
                                <div class="center"><img 
                                        src="../../../../../assets/images/csat2.png"
                                        alt="CSAT Report Screen" class="max-img"></div>
                                <p>{{ $t("CSAT.CSATDesc4") }}</p>
                                <div class="center"><img 
                                        src="../../../../../assets/images/csat_agent_filter.png"
                                            alt="CSAT Report Screen" class="max-img"></div>

                            </div>
                        </div>
                         <div class="edit-update-detail">
                                <!-- <div class="edit-pen">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                        aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>
                                    <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">Edit this page</a>
                                </div> -->
                                <p> {{ $t("doc.inboxes.last_updated_on") }} <span class="dark">9/15/2022</span>{{ $t("doc.inboxes.updated_by") }} <span class="dark"> Aswin Dev
                                        P.S</span>
                                </p>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p class="preview">{{ $t("Mobile_App.previous") }}</p>


                                        <router-link to="/docs/user-guide/features/reports/overview"
                                            class="introduction-account">
                                            <span>«</span> {{ $t("CSAT.conversation") }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p>{{ $t("Mobile_App.next") }}</p>

                                          <router-link to="/docs/user-guide/features/reports/agent">
                                            {{ $t("agent.Agent") }}<span>»</span>
                                                        </router-link>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list"> -->
                            <!-- <ul id="side_section_info">
                                <li id="menu_item_1" class="active_menu_item">
                                    <a href="#conversations">{{ $t("CSAT.Conversations") }}</a>
                                </li>
                                <li><a href="#incoming-messages">{{ $t("CSAT.IncomingMessages") }}</a></li>
                                <li><a href="#outgoing-messages">{{ $t("CSAT.OutgoingMessages") }}</a></li>
                                <li><a href="#first-response-time">{{ $t("CSAT.FirstResponse") }}</a></li>
                                <li><a href="#resolution-time">{{ $t("CSAT.ResolutionTime") }}</a></li>
                                <li><a href="#resolution-count">{{ $t("CSAT.ResolutionCount") }}</a></li>
                                <li><a href="#download-agent-reports">{{ $t("CSAT.DownloadagentReports") }}</a>
                                </li>
                                <li><a href="#business-hours">{{ $t("CSAT.BusinessHours") }}</a></li>
                                <li><a href="#trend">{{ $t("CSAT.Trend") }}</a></li>
                            </ul> -->
                        <!-- </div>
                    </section>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'csat',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
