<template>
    <div class="hello">
        This is footer page
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>
