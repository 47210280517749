<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.menu.Channels') }}</p>
                             <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('Channels.Telegram.tittle') }}</a>
                        </div>
                        <!-- <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                           <p>On this page</p>  
       <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_1"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                                            href="#channels-info">Channels</a></li>
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }"><a
                                                            href="#features-info">Features</a></li>
                                                    <li id="menu_item_3"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_3' }"><a
                                                            href="#integration-info">Integration</a></li>
                                                    <li id="menu_item_4"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_4' }"><a
                                                            href="#advanced-info">Advanced</a></li>
                                                    <li id="menu_item_5"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_5' }"><a
                                                            href="#mobile-app-info">Mobile app</a></li>
                                                    <li id="menu_item_6"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_6' }"><a
                                                            href="#other-info">Others</a></li>
                                                    <li id="menu_item_7"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_7' }"><a
                                                            href="#compatibility-info">Compatibility and Requirements</a>
                                                    </li>
                                                    <li id="menu_item_8"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_8' }"><a
                                                            href="#next-steps">Next Steps</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> -->

                        <div class="Introduction-details">
                            <h1>{{ $t('Channels.Telegram.telegramChannel') }}</h1>
                            <p><b>{{ $t('Channels.Telegram.step1') }}</b> {{ $t('Channels.Telegram.settingClick') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/dashboard.png">
                            </div>
                            <p><b>{{ $t('Channels.Telegram.step2') }} </b> {{ $t('Channels.Telegram.selectInboxes') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/inbox_settings.webp">
                            </div>
                            <p><b>{{ $t('Channels.Telegram.step3') }} </b> {{ $t('Channels.Telegram.telegramIcon') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/list_of_channels.webp">
                            </div>

                            <p><b>{{ $t('Channels.Telegram.step4') }} </b> {{ $t('Channels.Telegram.createTelegramBot') }}<a href="#"> {{ $t('Channels.Telegram.botFather') }}</a></p>

                            <p><b>{{ $t('Channels.Telegram.step5') }} </b> {{ $t('Channels.Telegram.apiToken') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/create_telegram_channel.webp">
                            </div>
                            <p><b>{{ $t('Channels.Telegram.step6') }} </b> {{ $t('Channels.Telegram.addAgents') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/create_telegram_channel.webp">
                            </div>
                            <p><b>{{ $t('Channels.Telegram.step7') }} </b>{{ $t('Channels.Telegram.telegramInbox') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/finish_inbox.webp">
                            </div>
                            <p><b>{{ $t('Channels.Telegram.step8') }} </b>{{ $t('Channels.Telegram.inboxSetting') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/telegram_inbox.webp">
                            </div>
                            <p><b>{{ $t('Channels.Telegram.step9') }} </b>{{ $t('Channels.Telegram.messageTelegramBot') }}</p>
                            <p><b>{{ $t('Channels.Telegram.step10') }} </b>{{ $t('Channels.Telegram.bccorTelegramInbox') }}</p>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/telegram_messag.png">
                            </div>
                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Channels.Telegram.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Channels.ApiChannel.recieveMessage.lastUpdatedOn') }} <span class="dark"> {{ $t('Channels.Telegram.date') }} </span> {{ $t('Channels.Telegram.by') }}  <span class="dark"> {{ $t('Channels.Telegram.shivamMishra') }} </span></p>
                        </div>



                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Channels.Telegram.previous') }}</p>
                                    <router-link to="/docs/product/channels/api/client-apis" class="introduction-account">
                                        <span>«</span> {{ $t('Channels.Telegram.clientApi') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Channels.Telegram.next') }}</p>
                                    <router-link to="/docs/product/channels/line">
                                        {{ $t('Channels.Telegram.line') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');

            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('href'));

                if (target?.offsetTop <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>