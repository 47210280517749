<template>
    <Header @language-selected="handleLanguageSelected"/>
      <div class="blog-page">
        <div class="bg-white">
            <div class="bg-gradient-to-b from-white to-blue-50">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="blog-title-box">
                                <h1>{{ $t('blog.blogWoot') }}</h1>
                                <p>
                                    {{ $t('wootjournal.wootjournalBuilding') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-wrap d-flex">
                        <div class="left-blog">
                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogCategories') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/blog.html" class="">{{ $t('blog.blogbcoor') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/product-update.html" class="">{{ $t('blog.blogupdates') }}</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/custmer_service.html"
                                            class="">{{ $t('blog.blogCustomer') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right-blog">
                            <div class="right-blog-list">
                                <div class="right-blog-list-inner">
                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2023/05/copywriting-guide.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('wootjournal.wootjournalPrinciples') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalfor') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first"> {{ $t('doc.createChatWoodAccount.hrichaShandily') }}</p>
                                                        <p class="blog-user-second"> {{ $t('wootjournal.wootjournalfor') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2023/03/chatwoot-is-soc2-type2-compliant--1-.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('wootjournal.wootjournalbcoor') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalwidely') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blogmar') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/12/undraw_Community_re_cyrm-1.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('wootjournal.wootjournalwidely') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('wootjournal.wootjournalcommunity') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                                        <p class="blog-user-second">  {{ $t('wootjournal.wootjournaldec') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/long_way_to_go.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('wootjournal.wootjournalwoot') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalabout') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                                        <p class="blog-user-second">{{ $t('wootjournal.wootjournaldec30') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/confused-about-pricing.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('wootjournal.wootjournaldec30') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('wootjournal.wootjournalpricing') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                                        <p class="blog-user-second">{{ $t('wootjournal.wootjournalsep12') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/one-year-since-open-sourcing-chatwoot.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('wootjournal.wootjournalone') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('wootjournal.wootjournallooking') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                                        <p class="blog-user-second"> {{ $t('wootjournal.wootjournalaug15') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/its-a-bot-story.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('wootjournal.wootjournalit') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalbots') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                                        <p class="blog-user-second">{{ $t('wootjournal.wootjournalapr') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/new-decade-2020.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('wootjournal.wootjournalapr') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalWelcoming') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                                        <p class="blog-user-second"> {{ $t('wootjournal.wootjournalfeb') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/red-pill-blue-pill.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('wootjournal.wootjournalRed') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalThoughts') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                                        <p class="blog-user-second">{{ $t('wootjournal.wootjournaldec15') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/camping.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                      {{ $t('wootjournal.wootjournalthe') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('wootjournal.wootjournalstory') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                                        <p class="blog-user-second">{{ $t('wootjournal.wootjournalnov') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>