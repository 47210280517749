/<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Channels') }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('doc.instagram.title') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.instagram.OnThisPage') }} </p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#setting-up-instagram-link' }">
                                                        <a @click="scrollTo('#setting-up-instagram')">{{ $t('doc.instagram.settingUp') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#connecting-instagram-to-chatwoot-link' }">
                                                        <a @click="scrollTo('#connecting-instagram-to-chatwoot')">{{ $t('doc.instagram.connectingToChatwoot') }}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('doc.instagram.setupTitle') }}</h1>
                            <p>{{ $t('doc.instagram.selfHostedDescription') }}&nbsp;
                                <a href="#">{{ $t('doc.instagram.guide') }}</a>.
                            </p>
                            <p>{{ $t('doc.instagram.cloudVersionDescription') }}</p>


                        </div>

                        <div class="channels-info">
                            <div class="channels-title">

                                <h2 class="anchorarticlenew" id="setting-up-instagram">{{ $t('doc.instagram.setupTitle1') }}<a class="hash-link" href="#setting-up-instagram"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p><strong>{{ $t('doc.instagram.Step1') }}</strong> {{ $t('doc.instagram.step1Content') }} <a href="#" target="_blank" rel="noopener noreferrer">{{ $t('doc.instagram.step1Content2') }}</a> to
                                    {{ $t('doc.instagram.step1Content3') }}</p>
                                <p><strong>{{ $t('doc.instagram.step2') }}</strong> {{ $t('doc.instagram.step2Content') }}
                                </p>
                                <ol>
                                    <li style="list-style: auto;">{{ $t('doc.instagram.GoTo') }}&nbsp;<a href="#" target="_blank"
                                            rel="noopener noreferrer">{{ $t('doc.instagram.step2List') }}</a>
                                    </li>
                                    <li style="list-style: auto;">{{ $t('doc.instagram.step2List') }}</li>
                                    <li style="list-style: auto;">{{ $t('doc.instagram.step2List2') }}</li>
                                    <li style="list-style: auto;">{{ $t('doc.instagram.step2List3') }}
                                        <blockquote style="height: auto;">
                                            <p><img alt="connect_fb_to_ig"
                                                    src="../../../../assets/images/connect_fb_to_ig.png" class="max-img">
                                            </p>
                                        </blockquote>
                                    </li>
                                </ol>
                                <p><strong>{{ $t('doc.instagram.switchBack') }}</strong></p>
                                <h2 class="anchor anchorWithStickyNavbar_mojV" id="connecting-instagram-to-chatwoot">
                                    {{ $t('doc.instagram.connectingTitle') }}<a class="hash-link"
                                        href="#connecting-instagram-to-chatwoot"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p><strong>{{ $t('doc.instagram.connectingSteps1') }}</strong>. {{ $t('doc.instagram.connectingSteps1Content') }}</p>
                                <p><img loading="lazy" alt="ig_create" src="../../../../assets/images/add-inbox.webp"
                                        class="max-img"></p>
                                <p><strong>{{ $t('doc.instagram.connectingSteps2') }}</strong>. {{ $t('doc.instagram.connectingSteps2Content') }}</p>
                                <p><img loading="lazy" alt="list_of_channels"
                                        src="../../../../assets/images/list_of_channels_new.webp" class="max-img"></p>
                                <p><strong>{{ $t('doc.instagram.connectingSteps3') }}</strong>. {{ $t('doc.instagram.connectingSteps3Content') }}</p>
                                <p><img loading="lazy" alt="login_to_fb"
                                        src="../../../../assets/images/fb-signin-in-chatwoot-insta.png" class="max-img">
                                </p>
                                <p><strong>{{ $t('doc.instagram.connectingSteps4') }}</strong>. {{ $t('doc.instagram.connectingSteps4Content') }}</p>
                                <p><img loading="lazy" alt="link_account"
                                        src="../../../../assets/images/authenticate-chatwoot.webp" class="max-img">
                                </p>
                                <p><img loading="lazy" alt="list_of_pages"
                                        src="../../../../assets/images/chatwoot-permissions.webp" class="max-img">
                                </p>
                                <p><strong>{{ $t('doc.instagram.connectingSteps5') }}</strong>. {{ $t('doc.instagram.connectingSteps5Content') }}</p>
                                <p><img loading="lazy" alt="select_agents"
                                        src="../../../../assets/images/add-agents-to-fb.webp" class="max-img"></p>
                                <p>{{ $t('doc.instagram.successMessage') }}</p>

                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.instagram.editThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.instagram.lastUpdatedOn') }} <span class="dark">10/20/2022 </span>{{ $t('doc.instagram.LastUpdated') }} <span class="dark">{{ $t('doc.instagram.HrichaShandily') }}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.instagram.navigation.previous') }}</p>
                                    <router-link to="/docs/product/channels/facebook" class="introduction-account">
                                        <span>«</span> {{ $t('doc.instagram.navigation.facebook') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.instagram.navigation.next') }}</p>
                                    <router-link to="/docs/product/channels/twitter">
                                        {{ $t('doc.instagram.navigation.twitter') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#setting-up-instagram-link' }">
                                    <a id="#setting-up-instagram-link" @click="scrollTo('#setting-up-instagram')">{{ $t('doc.instagram.settingUp') }}</a>
                                </li>
                                <li
                                    :class="{ 'active_menu_item': rightPanelMenuItem === '#connecting-instagram-to-chatwoot-link' }">
                                    <a id="#connecting-instagram-to-chatwoot-link" @click="scrollTo('#connecting-instagram-to-chatwoot')">{{ $t('doc.instagram.connectingToChatwoot') }}</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'instagram',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
