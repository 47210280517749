<template>
    <section class="docmain-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="docmain-container">
              <div class="getting-details">
                <p>{{ $t("home.header.Features") }}</p>
                <img
                  src="../../../../assets/images/up-arrow.svg"
                  alt="logo"
                  class="right-arrow"
                />
                <a href="#">{{ $t("doc.product.CustomAttributes") }}</a>
              </div>
              <div class="according-menu">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <p>On this page</p>
                        <span class="down_up_icon"
                          ><img src="/img/up_arrow_side.7f2fb856.svg" alt=""
                        /></span>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="tableOfContents-list">
                          <ul id="side_section_info">
                            <li
                              :class="{
                                active_menu_item:
                                  rightPanelMenuItem === '#custom-attribute-link',
                              }"
                            >
                              <a @click="scrollTo('#custom-attribute')" class="link_wrp">{{
                                $t("features.custom.custom_attribute")
                              }}</a>
                            </li>
                            <li
                              :class="{
                                active_menu_item:
                                  rightPanelMenuItem === '#Conversation-custom-link',
                              }"
                            >
                              <a
                                @click="scrollTo('#Conversation-custom')"
                                class="link_wrp"
                                >{{ $t("features.custom.conversationCustomAttribute") }}</a
                              >
                            </li>
                            <li
                              :class="{
                                active_menu_item:
                                  rightPanelMenuItem === '#c-attributes-link',
                              }"
                            >
                              <a @click="scrollTo('#c-attributes')" class="link_wrp">{{
                                $t("features.custom.contactCustomAttribute")
                              }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="Introduction-details">
                <div class="canned_list_wrp">
                  <h1>{{ $t("features.custom.contactCustomAttribute") }}</h1>
                  <p>{{ $t("features.custom.supportDataDesc") }}</p>
                  <div class="custome_list_wrp">
                    <ul>
                      <li>
                        {{ $t("features.custom.subscriptionplan") }}
                      </li>
                      <li>
                        {{ $t("features.custom.subscribeddate") }}
                      </li>
                      <li>
                        {{ $t("features.custom.signupdate") }}
                      </li>
                      <li>
                        {{ $t("features.custom.mostordereditem") }}
                      </li>
                      <li>
                        {{ $t("features.custom.orderedproductlink") }}
                      </li>
                      <li>
                        {{ $t("features.custom.lasttransactiondate") }}
                      </li>
                    </ul>
                  </div>
                  <div class="version-detail difference">
                    <p class="pb-0">{{ $t("features.custom.difference") }}</p>
                  </div>
  
                  <div class="custom_paragraph">
                    <p>{{ $t("features.custom.informationOrder") }}</p>
  
                    <h2 id="custom-attribute">
                      {{ $t("features.custom.custom_attribute") }}
                    </h2>
                    <p>{{ $t("features.custom.customAttributeClick") }}</p>
                  </div>
  
                  <div class="custome_img_wrp">
                    <p class="gif_wrp">
                      <img
                        src="../../../../assets/images/add-action.png"
                        class="img-fuild"
                      />
                    </p>
  
                    <p>
                      {{ $t("doc.teams.clickAddAgents") }}
                      <strong>{{ $t("features.custom.addCustomAttribute") }}</strong>
                      {{ $t("features.custom.buttonAvailable") }}
                    </p>
  
                    <p class="gif_wrp">
                      <img src="../../../../assets/images/add.png" class="img-fuild" />
                    </p>
                    <p>{{ $t("features.custom.input_required") }}</p>
                  </div>
                </div>
  
                <div class="table-details" style="padding-left: 0">
                  <table class="table table-bordered description">
                    <thead>
                      <tr>
                        <th>{{ $t("features.custom.input") }}</th>
                        <th>{{ $t("features.custom.description") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ $t("features.custom.applies_to") }}</td>
                        <td>{{ $t("features.custom.attribute_type") }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t("features.custom.display_name") }}</td>
                        <td>{{ $t("features.custom.label_rendering") }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t("features.custom.description") }}</td>
                        <td>{{ $t("features.custom.attribute_description") }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t("features.custom.type") }}</td>
                        <td>{{ $t("features.custom.data_type") }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t("features.custom.key") }}</td>
                        <td>{{ $t("features.custom.unique_identifier") }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <blockquote style="height: auto">
                  <p style="padding-bottom: 0">
                    {{ $t("features.custom.attribute_exists") }}
                  </p>
                </blockquote>
  
                <div class="canned_list_wrp">
                  <p>
                    {{ $t("features.canned-responses.Enterdetails")
                    }}<strong>{{ $t("features.custom.create") }}</strong>
                    {{ $t("features.canned-responses.request_btn") }}
                  </p>
                  <h3 id="Conversation-custom">
                    {{ $t("features.custom.conversationCustomAttribute") }}
                  </h3>
  
                  <div class="first_box_wrp">
                    <h4>{{ $t("features.custom.attributeViaSDK") }}</h4>
                    <p>
                      {{ $t("features.custom.eachConversation") }}
                      <code>
                        {{
                          $t(
                            "doc.SDKSetup.customAttributes.setConversationCustomAttributes"
                          )
                        }}
                      </code>
                      {{ $t("features.custom.methodDelete") }}
                      <code>
                        {{
                          $t(
                            "doc.SDKSetup.customAttributes.deleteConversationCustomAttribute"
                          )
                        }}
                      </code>
                      {{ $t("features.custom.methodDeleteDescription") }}
                      <a
                        href="/docs/product/channels/live-chat/sdk/setup/#for-a-conversation"
                        >here</a
                      >.
                    </p>
                  </div>
  
                  <div class="first_box_wrp">
                    <h4>{{ $t("features.custom.setAttributeViaConnect") }}</h4>
                    <p>{{ $t("features.custom.creatingConversation") }}</p>
                  </div>
  
                  <p class="custom_wrp">
                    <img src="../../../../assets/images/add-conv.png" class="img-fuild" />
                  </p>
                  <p>{{ $t("features.custom.OpenDropdownSelector") }}</p>
  
                  <p class="custom_wrp">
                    <img
                      src="../../../../assets/images/select-attribute.png"
                      class="img-fuild"
                    />
                  </p>
                  <p>{{ $t("features.custom.attributeScessFully") }}</p>
  
                  <p class="custom_wrp">
                    <img
                      src="../../../../assets/images/focus-attribute.png"
                      class="img-fuild"
                    />
                  </p>
  
                  <p>{{ $t("features.custom.actionDeleteEdit") }}</p>
  
                  <p class="custom_wrp">
                    <img
                      src="../../../../assets/images/attribute-actions.png"
                      class="img-fuild"
                    />
                  </p>
                </div>
  
                <div class="canned_list_wrp" id="c-attributes">
                  <h3>{{ $t("features.custom.contactCustomAttribute") }}</h3>
                  <p>{{ $t("features.custom.settingCustomAttribute") }}</p>
                  <div class="first_box_wrp">
                    <h4>{{ $t("features.custom.attributeViaSDK") }}</h4>
                    <p>
                      {{ $t("features.custom.setcontactCustomAttribute") }}
                      <code>{{
                        $t("doc.SDKSetup.tableOfContents.setCustomAttributes")
                      }}</code>
                      {{ $t("features.custom.methodFollow") }}
                    </p>
  
                    <div class="coding_box">
                      <div class="code_info">
                        <pre>
                              <code class="code_wrp">
                                  <span>
                                      {{ $t('doc.SDKSetup.windowObject') }}
                                      <span class="custome_info_wrp">{{ $t('features.custom.setCustomAttributes') }}</span><span class="dot_wrp">({</span>{{ $t('features.custom.keyValue') }}<span class="dot_wrp">,</span>
                                  </span>
                                  <p>{{ $t('features.custom.identifierData') }}</p>
                                  <span class="dot_wrp">});</span>
                              </code>
                          </pre>
                      </div>
  
                      <div class="example_wrp">
                        <span>{{ $t("features.custom.ex") }}</span>
                      </div>
                      <div class="code_info">
                        <pre>
                              <code class="code_wrp">
                                  <span>
                                      {{ $t('doc.SDKSetup.windowObject') }}
                                      <span class="custome_info_wrp">{{ $t('features.custom.setCustomAttributes') }}</span><span class="dot_wrp">({</span>{{ $t('features.custom.keyValue') }}<span class="dot_wrp">,</span>
                                  </span>
                                  <span class="dot_wrp">});</span>
                              </code>
                          </pre>
                      </div>
  
                      <div class="example_wrp">
                        <span>{{ $t("features.custom.contactSidePaneel") }}</span>
                        <span>
                          <p>
                            {{ $t("doc.SDKSetup.customAttributes.deleteAttribute") }}
                            <code>{{
                              $t("doc.SDKSetup.customAttributes.deleteCustomAttribute")
                            }}</code>
                            {{ $t("doc.SDKSetup.customAttributes.follows") }}
                          </p>
                        </span>
                      </div>
  
                      <div class="code_info">
                        <pre>
                          <code class="code_wrp">
                              <span>
                                  window<span class="dot_wrp">.</span>$bcoor<span class="dot_wrp">.</span><span class="custome_info_wrp">{{ $t('doc.SDKSetup.customAttributes.deleteAttribute') }}</span><span class="dot_wrp">(</span><span class="premium_wrp">"attribute-key"</span><span class="dot_wrp">);</span>
                              </span>
                          </code>
                      </pre>
                      </div>
  
                      <div class="example_wrp">
                        <span> {{ $t("features.custom.ex") }} </span>
                      </div>
  
                      <div class="code_info">
                        <pre>
                          <code class="code_wrp">
                              <p>{{ $t('doc.SDKSetup.customAttributes.viewInfo') }}</p>
                              <p>{{ $t('doc.SDKSetup.customAttributes.deleteAttribute') }} <span class="click-inbox">{{ $t('doc.SDKSetup.customAttributes.deleteCustomAttribute') }}</span>{{ $t('doc.SDKSetup.customAttributes.follows') }}</p>
                          </code>
                      </pre>
                      </div>
                    </div>
  
                    <blockquote style="height: auto">
                      <p style="padding-bottom: 0">
                        {{ $t("features.custom.versionRendered") }}
                      </p>
                    </blockquote>
                    <h4>{{ $t("features.custom.setAttributeViaConnect") }}</h4>
  
                    <p>{{ $t("features.custom.creatingAttribute") }}</p>
  
                    <p class="custom_wrp">
                      <img
                        src="../../../../assets/images/add-contact-attribute.png"
                        class="img-fuild"
                      />
                    </p>
  
                    <p>{{ $t("features.custom.OpenDropdownSelector") }}</p>
  
                    <p class="custom_wrp">
                      <img
                        src="../../../../assets/images/select-contact-attribute.png"
                        class="img-fuild"
                      />
                    </p>
  
                    <p>{{ $t("features.custom.creatButton") }}</p>
  
                    <p class="custom_wrp">
                      <img
                        src="../../../../assets/images/focus-contact-attribute.png"
                        class="img-fuild"
                      />
                    </p>
  
                    <p>{{ $t("features.custom.actionDeleteEdit") }}</p>
  
                    <p class="custom_wrp">
                      <img
                        src="../../../../assets/images/contact-attribute-actions.png"
                        class="img-fuild"
                      />
                    </p>
                  </div>
                </div>
              </div>
  
              <div class="update_edit_wrp">
                <!-- <div class="edit_wrp">
                  <a href="#">
                    <svg
                      fill="currentColor"
                      height="20"
                      width="20"
                      viewBox="0 0 40 40"
                      class="iconEdit_dcUD"
                      aria-hidden="true"
                    >
                      <g>
                        <path
                          d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z"
                        ></path>
                      </g>
                    </svg>
  
                    <p>{{ $t("doc.product.EditThisPage") }}</p>
                  </a>
                </div> -->
  
                <div class="update_wrp">
                  <p>
                    {{ $t("doc.product.LastUpdated") }}
                    <span class="dark">{{ $t("doc.product.Date") }} </span>
                    {{ $t("doc.product.By") }}
                    <span class="dark"> {{ $t("doc.product.Author") }}</span>
                  </p>
                </div>
              </div>
  
              <div class="row">
                <div class="col-lg-6">
                  <div class="create-chat-woot-account">
                    <p class="preview">{{ $t("doc.SDKSetup.navigation.previous") }}</p>
                    <router-link
                      to="/docs/user-guide/features/canned-responses"
                      class="introduction-account"
                    >
                      <span>«</span>
                      {{ $t("headersubmenu.Features.headerCannedResponses") }}
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="create-chat-woot-account">
                    <p>{{ $t("doc.product.Next") }}</p>
                    <router-link to="/docs/user-guide/features/csat">
                      {{ $t("doc.product.CSAT") }} <span>»</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <section class="tableOfContents">
              <div class="tableOfContents-list">
                <ul id="side_section_info">
                  <li
                    :class="{
                      active_menu_item: rightPanelMenuItem === '#custom-attribute-link',
                    }"
                  >
                    <a
                      id="#custom-attribute-link"
                      @click="scrollTo('#custom-attribute')"
                      class="link_wrp"
                      >{{ $t("features.custom.custom_attribute") }}</a
                    >
                  </li>
                  <li
                    :class="{
                      active_menu_item: rightPanelMenuItem === '#Conversation-custom-link',
                    }"
                  >
                    <a
                      id="#Conversation-custom-link"
                      @click="scrollTo('#Conversation-custom')"
                      class="link_wrp"
                      >{{ $t("features.custom.conversationCustomAttribute") }}</a
                    >
                  </li>
                  <li
                    :class="{
                      active_menu_item: rightPanelMenuItem === '#c-attributes-link',
                    }"
                  >
                    <a
                      id="#c-attributes-link"
                      @click="scrollTo('#c-attributes')"
                      class="link_wrp"
                      >{{ $t("features.custom.contactCustomAttribute") }}</a
                    >
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import SideBar from "@/components/SideBar.vue";
  // import MainContent from "@/components/MainContent.vue";
  
  export default {
    name: "ios",
    data() {
      return {
        isToggle: false,
        activeMenuItem: null,
        rightPanelMenuItem: null,
      };
    },
    components: {
      Header,
      Footer,
      SideBar,
      // MainContent
    },
    mounted() {
      window.addEventListener("scroll", this.setActiveMenuItem);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.setActiveMenuItem);
    },
    methods: {
      scrollTo(selector) {
        const element = document.querySelector(selector);
        if (element) {
          const offset = 106;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
          this.rightPanelMenuItem = `${selector}-link`;
        }
      },
      setActiveMenuItem() {
        const scrollPosition = window.scrollY;
        const menuItems = document.querySelectorAll("#side_section_info li");
        this.activeMenuItem = null;
        for (let i = menuItems.length - 1; i >= 0; i--) {
          const menuItem = menuItems[i];
          // console.log(menuItem)
          const target = document.querySelector(
            menuItem
              .querySelector("a")
              .getAttribute("id")
              ?.replace(/-link(?=[^-]*$)/, "")
          );
          if (target?.offsetTop - 200 <= scrollPosition) {
            let tempEle;
            if (menuItem) {
              tempEle = menuItem?.getAttribute("id");
              let targetID = target?.getAttribute("id");
              this.rightPanelMenuItem = `#${targetID}-link`;
              // menuItem?.classList.add("active_menu_item");
            }
            this.activeMenuItem = tempEle;
            return;
          }
        }
      },
      handleToggle() {
        this.isToggle = !this.isToggle;
      },
    },
  };
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
  }
  
  .custome_list_wrp ul li {
    list-style-type: disc;
    text-align: left;
    /* padding: 20px 0; */
    color: var(--main-containt-font-color);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    font-weight: 200;
  }
  
  .difference {
    border-left: 2px solid gray;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  
  .update_edit_wrp {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
  
  .edit_wrp a {
    display: flex;
    text-decoration: none;
    gap: 7px;
  }
  </style>
  