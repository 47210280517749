<template>
    <section class="channels-menu-detail">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="Conversation-Continuity-through-Email-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.channels') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.liveChat') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.conversationContinuity.conversationContinuityTitle') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.conversationContinuity.onThisPage') }} </p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="tableOfContents-list">
                                        <ul id="side_section_info">
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#obtaining-email' }">
                                            <a @click="scrollTo('#obtaining-email')">{{ $t('doc.conversationContinuity.obtainingEmail.title') }}</a>
                                            <ul>
                                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#via-Chatwoot-SDK' }">
                                                <a @click="scrollTo('#via-Chatwoot-SDK')">{{ $t('doc.conversationContinuity.obtainingEmail.viaSdk') }}</a>
                                                </li>
                                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#via-PreChat-Form' }">
                                                <a @click="scrollTo('#via-PreChat-Form')">{{ $t('doc.conversationContinuity.obtainingEmail.viaPreChatForm') }}</a>
                                                </li>
                                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#via-email' }">
                                                <a @click="scrollTo('#via-email')">{{ $t('doc.conversationContinuity.obtainingEmail.viaEmailPrompt') }}</a>
                                                </li>
                                            </ul>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Conversation-Continuity' }">
                                            <a @click="scrollTo('#Conversation-Continuity')">{{ $t('doc.conversationContinuity.conversationContinuityTitle') }}</a>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.conversationContinuity.introduction.title') }}</h1>
                            <p>{{ $t('doc.conversationContinuity.introduction.description') }}</p>
                        </div>
                        <div class="channels-title">
                            <ul class="next-details">
                                <li><a href="#">{{ $t('doc.conversationContinuity.channelsTitle.listItems1') }}</a></li>
                                <li><a href="#">{{ $t('doc.conversationContinuity.channelsTitle.listItems2') }}</a></li>
                            </ul>
                        </div>
                        <div class="cloud-version" id="obtaining-email">
                            <h4>{{ $t('doc.conversationContinuity.obtainingEmail.title') }}</h4>
                            <p>{{ $t('doc.conversationContinuity.obtainingEmail.description') }}</p>
                        </div>
                        <div class="version-detail" id="via-Chatwoot-SDK">
                            <h4>{{ $t('doc.conversationContinuity.obtainingEmail.viaSdk') }}</h4>
                            <p>{{ $t('doc.conversationContinuity.obtainingEmail.customerEmail') }} <span
                                    class="click-inbox">{{ $t('doc.conversationContinuity.obtainingEmail.setUser') }}</span>{{ $t('doc.conversationContinuity.obtainingEmail.method') }} <a href="/docs/product/channels/live-chat/sdk/setup">{{ $t('doc.conversationContinuity.obtainingEmail.sdk') }}</a></p>
                        </div>
                        <div class="version-detail" id="via-PreChat-Form">
                            <h4>{{ $t('doc.conversationContinuity.obtainingEmail.viaPreChatForm') }}</h4>
                            <p>{{ $t('doc.conversationContinuity.obtainingEmail.viaPreChatFormContent') }}
                            </p>
                        </div>
                        <div class="chatwoot-email-collect">
                            <img src="../../../../../assets/images/16.png" alt="logo45">
                        </div>
                        <div class="version-detail" id="Use-browser">
                            <p>{{ $t('doc.conversationContinuity.obtainingEmail.useBrowser') }} <a href="/docs/user-guide/features/pre-chat-form">{{ $t('doc.conversationContinuity.obtainingEmail.guide') }}</a></p>
                        </div>
                        <div class="version-detail" id="via-email">
                            <h4>{{ $t('doc.conversationContinuity.obtainingEmail.viaEmailPrompt') }}</h4>
                            <p>{{ $t('doc.conversationContinuity.obtainingEmail.viaEmailPromptContent') }}</p>
                        </div>
                        <div class="chatwoot-email-collect">
                            <img src="../../../../../assets/images/chatwoot-email-collect-two.png" alt="logo45">
                        </div>
                        <div class="channels-title" id="Conversation-Continuity">
                            <h2 class="mt-5">{{ $t('doc.conversationContinuity.title') }}</h2>
                            <p>
                            <p class="italic">{{ $t('doc.conversationContinuity.note') }}</p>{{ $t('doc.conversationContinuity.enableGuide') }} <a href="#">{{ $t('doc.conversationContinuity.guide') }}</a> </p>
                            <p>{{ $t('doc.conversationContinuity.customerEmailUpdate') }}</p>                            <ul class="next-details">
                                <li><a href="#">{{ $t('doc.conversationContinuity.customerReceives') }}</a></li>
                                <li><a href="#">{{ $t('doc.conversationContinuity.agentReceives') }}</a></li>
                            </ul>
                        </div>
                        <div class="chatwoot-email-collect">
                            <img src="../../../../../assets/images/16.png" alt="logo45">
                        </div>
                        <div class="version-detail" id="Use-browser">
                            <p>{{ $t('doc.conversationContinuity.emailIcon') }}</p>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">Edit this page</a>
                            </div> -->
                            <p>{{ $t('doc.conversationContinuity.lastUpdatedOn') }} <span class="dark">5/29/2023 </span>{{ $t('doc.conversationContinuity.updatedBy') }} <span class="dark"> {{ $t('doc.conversationContinuity.author') }}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.conversationContinuity.navigation.previous') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/sdk/live-chat-dark-mode"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('doc.conversationContinuity.navigation.darkMode') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.conversationContinuity.navigation.next') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/setup-instruction/webflow-integration">
                                        {{ $t('doc.conversationContinuity.navigation.webflowIntegration') }} <span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#obtaining-email-link' }">
                                    <a :id="'#obtaining-email-link'" @click="scrollTo('#obtaining-email')">{{ $t('doc.IdentityValidation.generatingHMAC.title') }}</a>
                                </li>
                                <ul>
                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#via-Chatwoot-SDK-link' }">
                                        <a :id="'#via-Chatwoot-SDK-link'" @click="scrollTo('#via-Chatwoot-SDK')">{{ $t('doc.conversationContinuity.tableOfContents.obtainingEmail.viaChatwootSDK') }}</a>
                                    </li>
                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#via-PreChat-Form-link' }">
                                        <a :id="'#via-PreChat-Form-link'" @click="scrollTo('#via-PreChat-Form')">{{ $t('doc.conversationContinuity.tableOfContents.obtainingEmail.viaPreChatForm') }}</a>
                                    </li>
                                        
                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#via-email-link' }">
                                        <a :id="'#via-email-link'" @click="scrollTo('#via-email')">{{ $t('doc.conversationContinuity.tableOfContents.obtainingEmail.viaEmailPrompt') }}</a>
                                    </li>
                                </ul>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Conversation-Continuity-link' }">
                                    <a :id="'#Conversation-Continuity-link'" @click="scrollTo('#Conversation-Continuity')">{{ $t('doc.conversationContinuity.tableOfContents.conversationContinuity.title') }}</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default ({
    name: 'AddConfigure',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
        //  this.scrollToTopcustom = this.scrollToTopcustom.bind(this);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {

        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
})
</script>
<style>
.chatwoot-email-collect {
    padding: 0 36px;
}

.chatwoot-email-collect img {
    width: 100%;
    height: 100%;
}</style>