
<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="configure-page-detail">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.configProfile.configureProfile') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            <p>On this page</p>
                                            <span class="down_up_icon"><img
                                                    src="../../../../assets/images/up_arrow_side.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="app">
                                        <div id="collapseOne" class="accordion-collapse collapse"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#update-info-link' }">
                                                        <a @click="scrollTo('#update-info')">{{ $t('doc.configProfile.updateProfile') }}</a>
                                                    </li>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#personal-message-link' }">
                                                        <a @click="scrollTo('#personal-message')">{{ $t('doc.configProfile.setPersonalMessage') }}</a>
                                                    </li>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Change-password-link' }">
                                                        <a @click="scrollTo('#Change-password')">{{ $t('doc.configProfile.changePassword') }}</a>
                                                    </li>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Notifications-settings-link' }">
                                                        <a @click="scrollTo('#Notifications-settings')">{{ $t('doc.configProfile.notificationsSettings') }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.configProfile.configureProfile') }}</h1>
                            <p class="personal-profile-detail">{{ $t('doc.configProfile.personalProfileDetail') }}</p>
                            <p class="password-detail">{{ $t('doc.configProfile.changePasswordDetail') }}</p>
                            <p class="password-detail">{{ $t('doc.configProfile.findProfileSettings') }}</p>
                        </div>
                        <div class="configure-profile-img">
                            <img src="../../../../assets/images/kebab-menu-f77d5f7fc4c0cffc2b999c2c1435db48.png" alt="logo22">
                        </div>
                        <div class="cloud-version" id="update-info">
                            <h4>{{ $t('doc.configProfile.updateProfileHeading') }}</h4>
                            <p>{{ $t('doc.configProfile.updateProfileDescription') }}</p>
                        </div>
                        <div class="upload-profile-img">
                            <img src="../../../../assets/images/update-your-profile-img.png" alt="logo28">
                        </div>
                        <p class="img-detils">{{ $t('doc.configProfile.fieldsRequired') }}</p>


                    <div class="table-details">
                        <table class="table table-bordered description">
                            <thead>
                                <tr>
                                <th style="white-space: nowrap;">{{ $t('doc.configProfile.fieldName') }}</th>
                                <th style="white-space: nowrap;">{{ $t('doc.configProfile.description') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>{{ $t('doc.configProfile.profileImage') }}</td>
                                <td>{{ $t('doc.configProfile.profileImageDescription') }}</td>
                                </tr>
                                <tr>
                                <td>{{ $t('doc.configProfile.fullName') }}</td>
                                <td>{{ $t('doc.configProfile.fullNameDescription') }}</td>
                                </tr>
                                <tr>
                                <td>{{ $t('doc.configProfile.displayName') }}</td>
                                <td>{{ $t('doc.configProfile.displayNameDescription') }}</td>
                                </tr>
                                <tr>
                                <td>{{ $t('doc.configProfile.emailAddress') }}</td>
                                <td>{{ $t('doc.configProfile.emailAddressDescription') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="update-profile-check">
                        <p>{{ $t('doc.configProfile.updateProfileClick') }} "{{ $t('doc.configProfile.profileSuccessfully')}}"</p>
                        <p>{{ $t('doc.configProfile.updateProfileSuccessMessage') }}</p>
                    </div>
                    <div class="cloud-version" id="personal-message">
                        <h4>{{ $t('doc.configProfile.personalMessageHeading') }}</h4>
                        <p>{{ $t('doc.configProfile.personalMessageDescription') }}</p>
                    </div>
                    <div class="cloud-version" id="Change-password">
                        <h4>{{ $t('doc.configProfile.changePasswordHeading') }}</h4>
                        <P>{{ $t('doc.configProfile.changePasswordDescription') }}</P>
                        <p>{{ $t('doc.configProfile.passwordRequirements') }}</p>
                    </div>
                    <div class="password-img">
                        <img src="../../../../assets/images/update-your-password-.png" alt="logo55">
                    </div>
                    <div class="cloud-version" id="Notifications-settings">
                        <h4>{{ $t('doc.configProfile.notificationsSettingsHeading') }}</h4>
                        <p>{{ $t('doc.configProfile.notificationsSettingsDescription') }}</p>
                    </div>
                    <div class="edit-update-detail">
                            <!-- <div class="edit-pen"><svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }} <span class="dark">10/17/2022 </span>{{ $t('doc.product.By') }} <span class="dark"> {{ $t('doc.createChatWoodAccount.hrichaShandily') }}
                                </span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>
                                    <router-link to="/docs/product" class="introduction-account"><span>«</span>{{ $t('doc.product.Introduction') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                    <router-link to="/docs/user-guide/setup-your-account/configure-account-details">
                                        {{ $t('doc.configProfile.configureGeneralAccountDetails') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#update-info-link' }">
                                <a @click="scrollTo('#update-info')" :id="$t('doc.configProfile.updateProfileLinkId')">
                                    {{ $t('doc.configProfile.updateProfile') }}
                                </a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#personal-message-link' }">
                                <a @click="scrollTo('#personal-message')" :id="$t('doc.configProfile.setPersonalMessageLinkId')">
                                    {{ $t('doc.configProfile.setPersonalMessage') }}
                                </a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Change-password-link' }">
                                <a @click="scrollTo('#Change-password')" :id="$t('doc.configProfile.changePasswordLinkId')">
                                    {{ $t('doc.configProfile.changePassword') }}
                                </a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Notifications-settings-link' }">
                                <a @click="scrollTo('#Notifications-settings')" :id="$t('doc.configProfile.notificationsSettingsLinkId')">
                                    {{ $t('doc.configProfile.notificationsSettings') }}
                                </a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

    export default {
        name: 'ConfigureProfile',
        data() {
            return {
                activeMenuItem: null,
                rightPanelMenuItem: null
            };
        },
        mounted() {
            window.addEventListener('scroll', this.setActiveMenuItem);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.setActiveMenuItem);
        },
        methods: {
            scrollTo(selector) {
                console.log("==>", selector);
                const element = document.querySelector(selector);
                if (element) {
                    const offset = 106;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = element.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                    this.rightPanelMenuItem = `${selector}-link`

                    console.log("==>", this.rightPanelMenuItem);
                }
            },
            setActiveMenuItem() {
                const scrollPosition = window.scrollY;
                const menuItems = document.querySelectorAll('#side_section_info li');
                this.activeMenuItem = null
                for (let i = menuItems.length - 1; i >= 0; i--) {
                    const menuItem = menuItems[i];
                    // console.log(menuItem)
                    const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                    if ((target?.offsetTop - 200) <= scrollPosition) {
                        let tempEle
                        if (menuItem) {
                            tempEle = menuItem?.getAttribute('id')
                            let targetID = target?.getAttribute('id')
                            this.rightPanelMenuItem = `#${targetID}-link`
                            // menuItem?.classList.add("active_menu_item");
                        }
                        this.activeMenuItem = tempEle
                        return;
                    }
                }
            },
            isActiveSection(index) {
                return index === this.activeSectionIndex;
            }
        },
    }
</script>
<style>
    .personal-profile-detail {
        text-align: left;
        padding: 20px 0;
        margin: 0;
        color: var(--main-containt-font-color);
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
    }

    p.password-detail {
        text-align: left;
        padding: 8px 0;
        margin: 0;
        color: var(--main-containt-font-color);
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
    }

    .configure-profile-img {
        width: 100%;
        max-width: 546px;
        padding: 15px 0;
        text-align: center;
        margin: 0 36px;
    }

    .configure-profile-img img {
        width: 100%;
        height: 100%;
    }

    .configure-page-detail {
        padding: 16px 0;
    }

    .upload-profile-img {
        margin: 0 auto;
        padding: 0 36px;
        margin-bottom: 15px;
    }

    .upload-profile-img img {
        width: 100%;
        height: 100%;
    }

    .update-profile-check p {
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        color: var(--main-containt-font-color);
        font-weight: 500;
    }

    .update-profile-check {
        padding: 0 36px;
    }

    .password-img {
        padding: 0 36px;
    }

    .password-img img {
        width: 100%;
        height: 100%;
    }
</style>