<template>

    <Header @language-selected="handleLanguageSelected"/>
<!-- shared-inbox -->
 <section class="shared-inbox">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12 orderr-2">
                <div class="sharedinbox-heading">
                    <span class="Omnichannel">{{ $t('headersubmenu.Features.headereOmnichannel') }}</span>
                    <h1 class="channel-heading">{{ $t('channels.channels_desc') }}</h1>
                    <p>{{ $t('channels.conversationchannel') }}</p>
                    <a href="https://app.bcoor.com/app/auth/signup">{{ $t('home.header.Createafreeaccount') }}</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 orderr-1">
                <div class="sharedinbox-img">
                    <img src="../../../assets/images/channels/multiple-conversations.png">
                </div>
            </div>
        </div>
        
    </div>
 </section>
<!-- Connect with your customer -->
<section class="con-customer channels-ref">
    <div class="container">
        <div class="con-customer-heading">
            <h2>{{ $t('channels.channels_desc1') }}</h2>
            <img src="../../../assets/images/channels/supported-channels.png">
        </div>
    </div>
</section>
<!-- inbox -->
<section class="inbox">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12 order-2">
                <div class="index-details">
                    <h2>{{ $t('channels.twitterLink') }}</h2>
                    <p class="dms">{{ $t('channels.ConnectYourAC') }}</p>
                    <ul class="index-details-list">
                        <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                            <p>{{ $t('channels.receiveReply') }}</p></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                                <p>{{ $t('channels.agentsProviding') }}</p></li>
                                <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                                    <p>{{ $t('channels.Rlabel') }}</p></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 order-1">
                <div class="index-img">
                    <img src="../../../assets/images/channels/tweets.png">
                </div>
            </div>
        </div>
    </div> 
</section>
<!-- Private-sec -->
<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/channels/fb.png">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('channels.mange_fb') }}</h2>
                    <p>{{ $t('channels.FacebookMessemger') }}</p>
                </div>
                <ul class="index-details-list">
                    <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                        <p>{{ $t('channels.receiveReply') }}</p></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                            <p>{{ $t('channels.agentsProviding') }}</p></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                                <p>{{ $t('channels.Rlabel') }}</p></li>
                </ul>
            </div>
         
        </div>
    </div> 
</section>
<!-- Canned Responses -->
<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">

            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('channels.BusinessAC') }}</h2>
                    <p>{{ $t('channels.engagingCustomer') }}</p>
                    <ul class="index-details-list">
                        <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                            <p>{{ $t('channels.receivemessages') }}</p></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                                <p>{{ $t('channels.CRRM') }}</p></li>
                                <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                                    <p>{{ $t('channels.contentsupport') }}</p></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/channels/whatsapp.png">
                </div>
            </div>
           
         
        </div>
    </div> 
</section>

<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/channels/api-channel.png">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('channels.rich_mesg') }}</h2>
                    <p>{{ $t('channels.supportexperiences') }}</p>
                    <ul class="index-details-list">
                        <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                            <p>{{ $t('channels.bcoorApi') }}</p></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon text-woot h-8 w-8" style="" width="1em" height="1em" viewBox="0 0 256 256" data-v-ca945699=""><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96Z" opacity=".2"></path><path d="M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0ZM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Z"></path></g></svg>
                                <p>{{ $t('channels.webhookevents') }}</p></li>     
                    </ul>
                </div>
            </div>
        </div>
    </div> 
</section>
 <Footer :selectLanguage="selectLanguage"/>

</template>
<script>
import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header
  },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
}
</script>
