<template>
    <Header @language-selected="handleLanguageSelected"/>
    <div class="blog-page">
        <div class="bg-white">
            <div class="bg-gradient-to-b from-white to-blue-50">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="blog-title-box">
                                <h1>{{ $t('productupdate.productupdatebcorblog') }}</h1>
                                <p>
                                    {{ $t('productupdate.productupdateread') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-wrap d-flex">
                        <div class="left-blog">
                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogCategories') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/blog.html" class="">{{ $t('blog.blogs')
                                        }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/product-update.html" class="">{{ $t('blog.blogupdates')
                                        }}</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/custmer_service.html" class="">{{
                                            $t('blog.blogCustomer') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering')
                                        }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource')
                                        }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor')
                                        }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right-blog">
                            <div class="right-blog-list">
                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateconversation') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateversion') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatemay') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatelog') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatenew') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateapr') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateHeatmap') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateThe') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatemar20') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateGoogle') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdaterelease') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatefeb') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatefeb') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateintegration') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateJan') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateUnattended') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatebcoor') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatebcoor') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatemacros') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateproductivity') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogHricha') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatebcoor') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatereplies') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdaterfocuses') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateroct') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateCreate') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateRead') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatesep') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateContext') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateimproved') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateaug') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateCloud') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateCloud') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatejul') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev2') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateyour') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateJun') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev5') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatepre') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author')
                                            }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatemay16') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatemay16') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatebfor') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author')
                                            }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdate22') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev23') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatehello') }}

                                            {{ $t('productupdate.productupdatefollowing') }}

                                            {{ $t('productupdate.productupdatefix') }}
                                            {{ $t('productupdate.productupdatefor') }}
                                            {{ $t('productupdate.productupdatebeing') }}
                                            {{ $t('productupdate.productupdatescroll') }}
                                            {{ $t('productupdate.productupdatesentry') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdateMar') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev230') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatehello') }}

                                            {{ $t('productupdate.productupdatewith') }}

                                            {{ $t('productupdate.productupdateTimezone') }}
                                            {{ $t('productupdate.productupdateability') }}
                                            {{ $t('productupdate.productupdaterecord') }}
                                            {{ $t('productupdate.productupdateSupport') }}
                                            {{ $t('productupdate.productupdateSupport') }}
                                            {{ $t('productupdate.productupdatebulk') }}
                                            {{ $t('productupdate.productupdateCSAT') }}
                                            {{ $t('productupdate.productupdateslots') }}
                                            {{ $t('productupdate.productupdatemms') }}
                                            {{ $t('productupdate.productupdateab') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatmar15') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdatev22') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatelearn') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author')}}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatefeb21') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatee') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateAnnouncing') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatedec21') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatedev1') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatedeis') }}

                                            {{ $t('productupdate.productupdatesecurity') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatenov23') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatenov23') }}
                                        </b>
                                        <p>
                                           {{ $t('productupdate.prpductupdatehey') }}

                                            {{ $t('productupdate.propductupdateversion') }}

                                            {{ $t('productupdate.propductupdateQuickly') }}
                                            {{ $t('productupdate.propductupdateContact') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.propductupdatenov') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.propductupdatenov1') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.propductupdatenov1') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.propductupdatenov1') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.propductupdatev120') }}
                                        </b>
                                        <p>
                                              {{ $t('productupdate.propductupdatev120') }}
                                           {{ $t('productupdate.propductupdatenewimages/files') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.propductupdateSep') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.propductupdateSep') }} 
                                        </b>
                                        <p>
                                           {{ $t('productupdate.propductupdateselfhosted') }} 
                                           

                                            {{ $t('productupdate.propductupdate') }} 
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.propductupdate') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.propductupdatemoblie') }}
                                        </b>
                                        <p>
                                           {{ $t('productupdate.propductupdatereleased') }}

                                            {{ $t('productupdate.propductupdatereleased') }}
                                            {{ $t('productupdate.propductupdateUpgrade') }}
                                            {{ $t('productupdate.propductupdateoperating') }}
                                            {{ $t('productupdate.propductupdateimprove') }}
                                            {{ $t('productupdate.propductupdateimprove') }}
                                            {{ $t('productupdate.propductupdate5s') }}

                                            {{ $t('productupdate.propductupdateHighlights') }}
                                            {{ $t('productupdate.propductupdateUpgraded') }}
                                            {{ $t('productupdate.propductupdateAdded') }}
                                            {{ $t('productupdate."propductupdateposthog"') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/Screenshot-2021-08-15-at-2.39.18-PM.png"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first"> {{ $t('productupdate.propductupdateMuhsin') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.propductupdateaug21') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.propductupdatev118') }}
                                        </b>
                                        <p>
                                           {{ $t('productupdate.propductupdatesatisfaction') }}

                                            {{ $t('productupdate.propductupdateconversation') }}

                                             {{ $t('productupdate.propductupdateceavailable') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.propductupdatejul17') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.propductupdatev1') }}
                                        </b>
                                        <p>
                                             {{ $t('productupdate.propductupdatefollowing') }}

                                            {{ $t('productupdate.propductupdateChatbots') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.propductupdatejun17') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.propductupdatev1162') }}
                                        </b>
                                        <p>
                                           {{ $t('productupdate.propductupdateAddresses') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateMay') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdatev1') }} 
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatewe') }} 

                                            {{ $t('productupdate.productupdateCampaigns') }} 

                                            {{ $t('productupdate.productupdateCampaigns') }} 

                                            {{ $t('productupdate.productupdatecurrent') }} 
                                                                                    </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatemay17') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdatev115') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateincludes') }}

                                            {{ $t('productupdate.productupdatefix') }}

                                            {{ $t('productupdate.productupdatev115') }}
                                            {{ $t('productupdate.productupdatebug') }}
                                            {{ $t('productupdate.productupdateAPI') }}
                                            {{ $t('productupdate.productupdateerror') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdateapr20') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateaprv1') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatewith') }}

                                            {{ $t('productupdate.productupdateAbility') }}
                                            {{ $t('productupdate.productupdateOthei') }}
                                            {{ $t('productupdate.productupdateAdded') }}
                                            {{ $t('productupdate.productupdateemail') }}
                                            {{ $t('productupdate.productupdateto') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateapr20') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev1142') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateFixes') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatemar23') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdateBugfix') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateSquishing') }}

                                            {{ $t('productupdate.productupdatefixed') }}
                                            {{ $t('productupdate.productupdatefixedHide') }}
                                            {{ $t('productupdate.productupdatefilters') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatemar21') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev114') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatehave') }}

                                            {{ $t('productupdate.productupdateWith') }}
                                            {{ $t('productupdate.productupdatewebsite') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatewebsite') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdatev113') }} 
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatev113') }} 

                                            {{ $t('productupdate.productupdateUse') }} 

                                            {{ $t('productupdate.productupdateForm') }} 

                                            {{ $t('productupdate.productupdateOthei') }}
                                            {{ $t('productupdate.productupdateNotification') }}
                                            {{ $t('productupdate.productupdateAddedapi') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatefeb17') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdateHotfix') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateHotfix') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatejan') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev112') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatewas') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatejan') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev1120') }}
                                        </b>
                                        <p>
                                           {{ $t('productupdate.productupdateupdates') }}

                                            {{ $t('productupdate.productupdateRich') }}

                                            {{ $t('productupdate.productupdatePreview') }}
                                            {{ $t('productupdate.productupdateMessage') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatejan20') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                          {{ $t('productupdate.productupdatev1111') }}  
                                        </b>
                                        <p>
                                             {{ $t('productupdate.productupdatehotfix') }}  

                                               {{ $t('productupdate.productupdatethat') }}    

                                              {{ $t('productupdate.productupdateCause') }}    
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productupdatedec18') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productupdatev1110') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdatereleased') }}

                                            {{ $t('productupdate.productupdatedetect') }}
                                            {{ $t('productupdate.productupdateupdated') }}

                                            {{ $t('productupdate.productupdateupdated') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdatedec18') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdatev1100') }} 
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productupdateexcited') }} 

                                            {{ $t('productupdate.productupdateexcited') }} 

                                            {{ $t('productupdate.productupdateGlobal') }} 

                                            {{ $t('productupdate.productupdateothers') }} 
                                            {{ $t('productupdate.productupdateLookup') }} 
                                            {{ $t('productupdate.productupdateBusiness hours APIs') }} 
                                            {{ $t('productupdate.productupdateBusinessResolve') }} 
                                            {{ $t('productupdate.productupdateAgents') }} 
                                            {{ $t('productupdate.productupdateActivity') }} 
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productupdateNov192020') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productupdatev190') }}
                                        </b>
                                        <p>
                                           {{ $t('productupdate.productupdatev190') }}

                                             {{ $t('productupdate.productChat') }}

                                            {{ $t('productupdate.productfew') }}
                                            {{ $t('productupdate.producthours') }}
                                            {{ $t('productupdate.productinday') }}

                                            {{ $t('productupdate.productreply') }}

                                            {{ $t('productupdate.productAbility') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productAbilityOct') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productAbilityv180') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productImprove') }}
                                           {{ $t('productupdate.productdetach') }}
                                           {{ $t('productupdate.productEnable') }}
                                           {{ $t('productupdate.productconfiguration') }}
                                           {{ $t('productupdate.productfornew') }}
                                           {{ $t('productupdate.productCapture') }}
                                           {{ $t('productupdate.productemail') }}
                                           {{ $t('productupdate.producteMade') }}
                                             {{ $t('productupdate.producteNumerou') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productesep22') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productev172') }}
                                        </b>
                                        <p>
                                             {{ $t('productupdate.productFix') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productaug23') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productv171') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productversion') }}

                                            {{ $t('productupdate.productedit') }}

                                            
                                            {{ $t('productupdate.producteset') }}
                                            {{ $t('productupdate.productelauncher') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productaug22') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productv170') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productwe') }}

                                            {{ $t('productupdate.productChannel') }}
                                            {{ $t('productupdate.productbubble') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productaug11') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productv160') }}
                                        </b>
                                        <p>
                                             {{ $t('productupdate.productwehave') }}
                                             {{ $t('productupdate.productConversation') }}
                                             {{ $t('productupdate.productIntegration') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productjul') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                           {{ $t('productupdate.productjul08') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productbug') }}

                                            {{ $t('productupdate.productthisrelease') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productjun10') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productjun10') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productRecent') }}
                                            {{ $t('productupdate.productFixthe') }}
                                            {{ $t('productupdate.productundefined') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productjun') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productv150') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productnew') }}

                                            {{ $t('productupdate.productjs') }}
                                            {{ $t('productupdate.productcontacts') }}
                                            
                                            {{ $t('productupdate.productspam') }}

                                            {{ $t('productupdate.productChrome') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productChrome') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productv142') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productHotfix') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second">{{ $t('productupdate.productmay30') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                          {{ $t('productupdate.productv141') }}
                                        </b>
                                        <p>
                                             {{ $t('productupdate.productwhich') }}
                                             {{ $t('productupdate.productimmediately') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productmay11') }} </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="conversation_version">
                                    <div class="conversation_heading">
                                        <b>
                                            {{ $t('productupdate.productv140') }}
                                        </b>
                                        <p>
                                            {{ $t('productupdate.productnews!') }}

                                            {{ $t('productupdate.productTwilio') }}

                                            
                                            {{ $t('productupdate.productTwilio') }}

                                            {{ $t('productupdate.productbrowser') }}
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center blog-user">
                                        <div class="blog-user-image">
                                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                                                alt="Hricha Shandily" />
                                        </div>
                                        <div class="blog-user-text">
                                            <p class="blog-user-first">{{ $t('doc.setupInstruction.googleTagManager.author') }}</p>
                                            <p class="blog-user-second"> {{ $t('productupdate.productmay06') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
import Footer from "@/views/chatwoot/Footer.vue";
import Header from "@/views/chatwoot/Header.vue";

export default {
    name: "sharedInbox",
    components: {
        Footer,
        Header
    },
  data () {
    return {
      selectLanguage: 'en',
    };
  },
  methods: {
    handleLanguageSelected (language) {
      this.selectLanguage = language;
    },
  },
  created() {
    const localStorageItem = localStorage.getItem('language');

    if (localStorageItem) {

      this.selectLanguage = localStorageItem;
    }
  }
}
</script>