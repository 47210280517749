<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("doc.product.Features") }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.Folders') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.product.OnThisPage') }}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">

                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#save-folder-link' }">
                                                        <a @click="scrollTo('#save-folder')" class="link_wrp">{{ $t('features.folder.saveNewFolder') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Select-folder-link' }">
                                                        <a @click="scrollTo('#Select-folder')" class="link_wrp">{{ $t('features.folder.selectSavedFolder') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#Delete-folder-link' }">
                                                        <a @click="scrollTo('#Delete-folder')" class="link_wrp">{{ $t('features.folder.deleteSavedFolder') }}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="Introduction-details">
                            <div class="canned_list_wrp">
                                <h1>{{ $t('doc.product.Folders') }}</h1>
                                <p>{{ $t('features.folder.folderDesc') }}</p>
                                <p>{{ $t('features.folder.folderDesc1') }}</p>

                                <p class="widget_wrp1" style="padding: 0;">
                                    <img src="../../../../assets/images/filters-modal.webp" class="img-fuild">
                                </p>
                                <p style="padding-top:15px;">{{ $t('features.folder.folderDesc2') }}</p>
                                <p class="widget_wrp2" style="padding: 0;">
                                    <img src="../../../../assets/images/apply-filter.webp" class="img-fuild">
                                </p>
                                <h3 id="save-folder">
                                    {{ $t('features.folder.saveNewFolder') }}
                                </h3>
                                <p>{{ $t('features.folder.saveNewFolderDesc1') }}</p>

                                <p class="widget_wrp3" style="padding: 0;">
                                    <img src="../../../../assets/images/open-save.webp" class="img-fuild">
                                </p>

                                <p style="padding-top: 15px;"> {{ $t('features.folder.saveNewFolderDesc2') }}
                                <b>{{ $t('features.folder.saveNewFolderDesc3') }}</b>
                                {{ $t('features.folder.saveNewFolderDesc4') }}
                                </p>

                                <p class="widget_wrp4" style="padding: 0;">
                                    <img src="../../../../assets/images/saved-folder.webp" class="img-fuild">
                                </p>
                                <div class="select_wrp">
                                    <h3 id="Select-folder">
                                        {{ $t('features.folder.selectSavedFolder') }}
                                    </h3>
                                    <p>{{ $t('features.folder.selectSavedFolderDesc1') }}
                                    <b>{{ $t('features.folder.displayed') }}</b> {{ $t('features.folder.selectSavedFolderDesc2') }} <b>{{ $t('doc.product.Folders') }},</b> 
                                    {{ $t('features.folder.selectSavedFolderDesc3') }}
                                    </p>

                                    <p class="widget_wrp" style="padding: 0;">
                                        <img src="../../../../assets/images/saved-folder-list.webp" class="img-fuild">
                                    </p>
                                </div>

                                <div class="delete_wrp" id="Delete-folder">
                                    <h3 style="margin-bottom: 0;">
                                        {{ $t('features.folder.deleteSavedFolder') }}
                                    </h3>

                                    <p style="padding-top: 15px;">
                                        {{ $t('features.folder.ifYouWanttouse') }} <strong>{{ $t('features.folder.delete') }}</strong> {{ $t('features.folder.deleteClickFolder') }} <strong>{{ $t('features.folder.binIcon') }}</strong> 
                                        {{ $t('features.folder.binIcon1') }}
                                    </p>

                                    <p class="widget_wrp" style="padding: 0;">
                                        <img src="../../../../assets/images/open-delete-folder.webp" class="img-fuild">
                                    </p>
                                </div>

                                <div class="confirm_deletion">
                                    <p style="padding-top: 15px;">
                                        {{ $t('features.folder.modalShow') }}
                                    <strong>{{ $t('features.folder.ConfirmDeletion') }}</strong>
                                    {{ $t('features.folder.click') }}
                                         <strong> {{ $t('features.folder.keepIt') }} </strong>, {{ $t('features.folder.folderView') }}<strong>{{ $t('features.canned-responses.yes_delete') }}</strong>, {{ $t('features.canned-responses.continue_deleteion') }}
                                    </p>

                                    <p class="widget_wrp" style="padding: 0;">
                                        <img src="../../../../assets/images/delete-folder.webp" class="img-fuild">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="update_edit_wrp">
                        <!-- <div class="edit_wrp">
                            <a href="#">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>

                                <p>
                                    {{ $t('doc.product.EditThisPage') }}
                                </p>
                            </a>
                        </div> -->

                        <div class="update_wrp">
                            <p>
                                {{ $t('doc.product.LastUpdated') }} <b>{{ $t('features.folder.date') }}</b> by 
                                <b> {{ $t('features.folder.author') }}</b>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="create-chat-woot-account">
                                <p class="preview">{{ $t('Mobile_App.previous') }}</p>
                                <router-link to="/docs/product/features/inbox/routing-conversations-round-robin"
                                    class="introduction-account">
                                    <span>«</span> {{ $t('features.contacts.routing') }}
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="create-chat-woot-account">
                                <p>{{ $t('doc.product.Next') }}</p>
                                <router-link to="/docs/user-guide/features/keyboard-shortcuts"> {{ $t('headersubmenu.Features.headerKeyboardShortcuts') }}<span>»</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <section class="tableOfContents">
                    <div class="tableOfContents-list">
                        <ul id="side_section_info">
                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#save-folder-link' }"><a
                                    id="#save-folder-link" @click="scrollTo('#save-folder')" class="link_wrp">{{ $t('features.folder.saveNewFolder') }}</a></li>
                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Select-folder-link' }"><a
                                    id="#Select-folder-link" @click="scrollTo('#Select-folder')" class="link_wrp">{{ $t('features.folder.selectSavedFolder') }}</a></li>
                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Delete-folder-link' }"><a
                                    id="#Delete-folder-link" @click="scrollTo('#Delete-folder')" class="link_wrp">{{ $t('features.folder.deleteSavedFolder') }}</a></li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {

        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>