<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                           <p>{{ $t('doc.menu.Channels') }}</p>
                                     <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                                    <a href="#">{{ $t('Channels.Supportedfeatures.title') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                             <p>{{ $t('Channels.Supportedfeatures.onThisPage') }}</p>  
       <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                   <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Supported-message-type-link' }"><a @click="scrollTo('#Supported-message-type')"
                                          >{{ $t('Channels.Supportedfeatures.supportMessage') }}</a></li>
                                                      <li  :class="{ 'active_menu_item': rightPanelMenuItem === '#Maximum-message-link' }"><a @click="scrollTo('#Maximum-message')"
                                           >{{ $t('Channels.Supportedfeatures.maximumMessage') }}</a></li>
                                                     <li   :class="{ 'active_menu_item': rightPanelMenuItem === '#Outgoing-message-link' }"><a @click="scrollTo('#Outgoing-message')"
                                            >{{ $t('Channels.Supportedfeatures.outgoingMessage') }}</a></li>
                                                  <li  :class="{ 'active_menu_item': rightPanelMenuItem === '#Available-features-link' }"><a @click="scrollTo('#Available-features')"
                                            >{{ $t('Channels.Supportedfeatures.availableFeatures') }}</a></li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">
                            <h1 style="padding-left:0px;">{{ $t('Channels.Supportedfeatures.supportedFeatures') }}</h1>
                            <h2 style="padding-left:0px;" class="table-heading" id="Supported-message-type">{{ $t('Channels.Supportedfeatures.supportMessage') }}</h2>

                            <div style="padding-left:0px;" class="table-details">
                                <table class="table table-bordered widthfull">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Channels.Supportedfeatures.channel') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.incomingMessage') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.activityMessage') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.temaplateMessage') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.website') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.facebook') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.twitter') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.tweets') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.sms') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.whatsapp') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h2 style="padding-left:0px;" class="table-heading" id="Maximum-message">{{ $t('Channels.Supportedfeatures.maximumMessage') }}
                            </h2>
                            <div style="padding-left:0px;" class="table-details">
                                <table class="table table-bordered widthfull">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Channels.Supportedfeatures.channel') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.maximum') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.website') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.rupee') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.facebook') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.640') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.twitter') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.rupee') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.tweets') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.280') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.sms') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.160') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.whatsapp') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.rupee') }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h2 style="padding-left:0px;" id="Outgoing-message" class="table-heading">{{ $t('Channels.Supportedfeatures.outgoingMessage') }}</h2>
                            <div style="padding-left:0px;" class="table-details">
                                <table class="table table-bordered widthfull">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Channels.Supportedfeatures.channel') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.restriction') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.website') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.noRestriction') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.facebook') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.promotionalMessage') }}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.twitter') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.noRestriction') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.tweets') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.noRestriction') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.sms') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.noRestriction') }}</td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.whatsapp') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.whatsappMessage') }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h2 style="padding-left:0px;" id="Available-features" class="table-heading">{{ $t('Channels.Supportedfeatures.availableFeatures') }}</h2>
                            <div style="padding-left:0px;" class="table-details">
                                <table class="table table-bordered widthfull">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Channels.Supportedfeatures.channel') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.channelGreeting') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.attachments') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.agentAuto') }}</th>
                                            <th>{{ $t('Channels.Supportedfeatures.slack') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.website') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.facebook') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.twitter') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.tweets') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.sms') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('Channels.Supportedfeatures.whatsapp') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.yes') }}</td>
                                            <td>{{ $t('Channels.Supportedfeatures.no') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Channels.Supportedfeatures.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Channels.Supportedfeatures.lastUpdatedOn') }} <span class="dark"> {{ $t('Channels.Supportedfeatures.date') }} </span> {{ $t('Channels.Supportedfeatures.by') }} <span class="dark"> {{ $t('Channels.Supportedfeatures.pranavRaj') }}
                                    </span></p>
                        </div>



                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Channels.Supportedfeatures.previous') }}</p>


                                    <router-link to="/docs/product/channels/line" class="introduction-account">
                                        <span>«</span> {{ $t('Channels.Supportedfeatures.line') }}
                                    </router-link>


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Channels.Supportedfeatures.next') }}</p>

                                    <router-link to="/docs/user-guide/features/canned-responses">
                                        {{ $t('Channels.Supportedfeatures.cannedResponses') }} <span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Supported-message-type-link' }"><a id="#Supported-message-type-link" @click="scrollTo('#Supported-message-type')"
                                      >{{ $t('Channels.Supportedfeatures.supportMessage') }}</a></li>
                                <li  :class="{ 'active_menu_item': rightPanelMenuItem === '#Maximum-message-link' }"><a id="#Maximum-message-link" @click="scrollTo('#Maximum-message')"
                                       >{{ $t('Channels.Supportedfeatures.maximumMessage') }}</a></li>
                                <li   :class="{ 'active_menu_item': rightPanelMenuItem === '#Outgoing-message-link' }"><a id="#Outgoing-message-link" @click="scrollTo('#Outgoing-message')"
                                        >{{ $t('Channels.Supportedfeatures.outgoingMessage') }}</a></li>
                                <li  :class="{ 'active_menu_item': rightPanelMenuItem === '#Available-features-link' }"><a id="#Available-features-link" @click="scrollTo('#Available-features')"
                                        >{{ $t('Channels.Supportedfeatures.availableFeatures') }}</a></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>


6500