<template>
  <div class="sidebar" :class="{ 'show-sidebar': isToggle }">
    <div class="navbar-sidebar__brand">
      <div class="navbar-sidebar-logo">
        <a href="/"
          ><img
            src="../assets/images/bcoor_logo.svg"
            alt="logo1"
            class="logo"
            width="200"
            height="100"
        /></a>
        <a href="/"
          ><img
            src="../assets/images/darkmood-bcoor.png"
            alt="logo2"
            class="logo-white"
            width="200"
            height="100"
        /></a>
        <div class="moon-effiect" @click="theameModeHandler"></div>
      </div>
      <div class="sidebar__close" @click="toggleResponsiveHeader(false)">
        <button
          class="clean-btn navbar-sidebar__close"
          @click="handleToggle"
          type="button"
        >
          <svg viewBox="0 0 15 15" width="21" height="21" class="close-button">
            <g stroke="var(--ifm-color-emphasis-600)" stroke-width="1.2">
              <path d="M.75.75l13.5 13.5M14.25.75L.75 14.25"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>

    <!-- responsiveness hide div -->
    <div v-if="!isNone">
      <div class="side-menu-back-button">
        <button
          class="side-active-nav-show"
          @click="toggleResponsiveHeader(true)"
          type="button"
        >
          ← Back to main menu
        </button>
      </div>
      <div class="menu__list-item-collapsible">
        <div class="start-introduction">
          <p>{{ $t("home.sidebarMenu.gettingStarted") }}</p>
          <router-link @click="handleToggle" to="/docs/product">{{
            $t("home.sidebarMenu.introduction")
          }}</router-link>
          <ul class="menu">
            <li :class="{ active: parentMenu === 'setup-your-account' }">
              <a
                @click="toggleParentClass('setup-your-account')"
                class="toggle-list step-list"
                :class="parentMenu == 'setup-your-account' ? 'activeSubmenu' : ''"
                href="javascript:void(0);"
                >{{ $t("home.sidebarMenu.setupYourAccount") }}
                <img src="../assets/images/up-arrow.svg" alt="logo" class="right-arrow" />
              </a>
              <ul class="drop-donw-menu">
                <li>
                  <router-link
                    @click="handleToggle(), activeSelectedMenu()"
                    to="/docs/user-guide/setup-your-account/create-an-account"
                    >{{ $t("home.sidebarMenu.createYourBccorAccount") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/configure-your-profile"
                    >{{ $t("home.sidebarMenu.configuareYourProfile") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/configure-account-details"
                  >
                    {{ $t("home.sidebarMenu.configuareYourGeneralAccountDetail") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/add-agent-settings"
                  >
                    {{ $t("home.sidebarMenu.addAgents") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/add-inbox-settings"
                  >
                    {{ $t("home.sidebarMenu.addInboxes") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/add-teams-settings"
                  >
                    {{ $t("home.sidebarMenu.addTeams") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/add-label-settings"
                  >
                    {{ $t("home.sidebarMenu.addLabels") }}
                  </router-link>
                </li>

                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/integrations"
                  >
                    {{ $t("home.sidebarMenu.integrations") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/setup-your-account/applications"
                  >
                    {{ $t("home.sidebarMenu.applications") }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="start-introduction">
          <ul class="menu">
            <li :class="{ active: parentMenu === 'channels' }">
              <a
                @click="toggleParentClass('channels')"
                class="toggle-list grey"
                href="javascript:void(0);"
                :class="{ activeMenu: channelsRoutes.includes(routePath) }"
                >{{ $t("home.sidebarMenu.CHANNELS") }}
                <img src="../assets/images/up-arrow.svg" alt="logo" class="right-arrow" />
              </a>
              <ul class="drop-donw-menu">
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('live-chat')"
                        class="toggle-list"
                        href="javascript:void(0);"
                        :class="{ activeMenu: subMenu === 'live-chat' }"
                        >{{ $t("home.sidebarMenu.headerChat") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'live-chat' ? 'right-on' : 'right-off'"
                      /></a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'live-chat' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/live-chat/create-website-channel"
                          >
                            {{ $t("home.sidebarMenu.addAndConfiguare") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/live-chat/sdk/setup"
                          >
                            {{ $t("home.sidebarMenu.sdkSetup") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/live-chat/sdk/identity-validation"
                          >
                            {{ $t("home.sidebarMenu.identityValidation") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/live-chat/sdk/live-chat-dark-mode"
                          >
                            {{ $t("home.sidebarMenu.darkMode") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/live-chat/conversation-continuity"
                          >
                            {{ $t("home.sidebarMenu.conversationContinuity") }}
                          </router-link>
                        </li>
                        <li>
                          <ul class="menu">
                            <li>
                              <a
                                @click="toggleChildClass('setup-instruction')"
                                class="toggle-list"
                                :class="{ activeMenu: childMenu === 'setup-instruction' }"
                                href="javascript:void(0);"
                                >{{ $t("home.sidebarMenu.SetupInstructions") }}
                                <img
                                  src="../assets/images/up-arrow.svg"
                                  alt="logo"
                                  :class="
                                    subMenu == 'setup-instruction'
                                      ? 'right-on'
                                      : 'right-off'
                                  "
                                />
                              </a>
                              <ul
                                class="drop-donw-menu"
                                :class="
                                  childMenu == 'setup-instruction'
                                    ? 'isActiveClass'
                                    : 'isNotActiveClass'
                                "
                              >
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/webflow-integration"
                                  >
                                    {{ $t("home.sidebarMenu.WebflowIntegration") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/docusaurus"
                                  >
                                    {{ $t("home.sidebarMenu.Docusaurus") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/google-tag-manager"
                                  >
                                    {{ $t("home.sidebarMenu.GoogleTagManager") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/react-native-widget"
                                  >
                                    {{ $t("home.sidebarMenu.ReactNative") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/nextjs"
                                  >
                                    {{ $t("home.sidebarMenu.Nextjs") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/vue"
                                  >
                                    {{ $t("home.sidebarMenu.Vuejs") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/live-chat/setup-instruction/gatsby"
                                  >
                                    {{ $t("home.sidebarMenu.Gatsby") }}
                                  </router-link>
                                </li>
                                <!-- <li>
                                                                    <router-link @click="handleToggle"
                                                                        to="/docs/product/channels/live-chat/setup-instruction/wordpress">
                                                                        WordPress
                                                                    </router-link>
                                                                </li> -->
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link
                    @click="handleToggle()"
                    to="/docs/product/channels/facebook"
                    :class="{ activeMenu: routePath === 'menu-7' }"
                  >
                    {{ $t("home.sidebarMenu.Facebook") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/channels/instagram"
                  >
                    {{ $t("home.sidebarMenu.Instagram") }}
                  </router-link>
                </li>
                <li>
                  <router-link @click="handleToggle" to="/docs/product/channels/twitter">
                    {{ $t("home.sidebarMenu.Twitter") }}
                  </router-link>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('whatsapp')"
                        class="toggle-list"
                        :class="{ activeMenu: subMenu === 'whatsapp' }"
                        href="javascript:void(0);"
                        >{{ $t("home.sidebarMenu.whatsAppChannel") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'whatsapp' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'whatsapp' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/whatsapp/whatsapp-cloud"
                          >
                            {{ $t("home.sidebarMenu.whatsAppCloud") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/whatsapp/twilio"
                          >
                            {{ $t("home.sidebarMenu.twilio") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/whatsapp/360dialog"
                          >
                            {{ $t("home.sidebarMenu.360Dialog") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('sms')"
                        class="toggle-list"
                        :class="{ activeMenu: subMenu === 'sms' }"
                        href="javascript:void(0);"
                        >{{ $t("home.sidebarMenu.smsChannel") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'sms' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="subMenu == 'sms' ? 'isActiveClass' : 'isNotActiveClass'"
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/sms/twilio"
                          >
                            {{ $t("home.sidebarMenu.twilio") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/sms/bandwidth"
                          >
                            {{ $t("home.sidebarMenu.bandwidth") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('email')"
                        class="toggle-list"
                        :class="subMenu == 'email' ? 'activeSubmenu' : ''"
                        href="javascript:void(0);"
                        >Email
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'email' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="subMenu == 'email' ? 'isActiveClass' : 'isNotActiveClass'"
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/email/create-channel"
                          >
                            {{ $t("home.sidebarMenu.EmailViaCreateChannel") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/email/configure-imap"
                          >
                            {{ $t("home.sidebarMenu.EmailViaConfigureIMAP") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/email/configure-smtp"
                          >
                            {{ $t("home.sidebarMenu.EmailVia") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/channels/email/forward-email"
                          >
                            {{ $t("home.sidebarMenu.EmailViaForwardEmail") }}
                          </router-link>
                        </li>
                        <li>
                          <ul class="menu">
                            <li>
                              <a
                                @click="toggleChildClass('gmail')"
                                class="toggle-list"
                                href="javascript:void(0);"
                                :class="childMenu == 'gmail' ? 'activeChildmenu' : ''"
                                >{{ $t("home.sidebarMenu.Gmail") }}
                                <img
                                  src="../assets/images/up-arrow.svg"
                                  alt="logo"
                                  class="right-arrow"
                              /></a>
                              <ul
                                class="drop-donw-menu"
                                :class="
                                  childMenu == 'gmail'
                                    ? 'isActiveClass'
                                    : 'isNotActiveClass'
                                "
                              >
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/email/gmail/enable-imap"
                                  >
                                    {{ $t("home.sidebarMenu.tittle") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/email/gmail/less-secure-access"
                                  >
                                    {{ $t("home.sidebarMenu.secureAccess") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/email/gmail/generate-app-password"
                                  >
                                    {{ $t("home.sidebarMenu.appPassword") }}
                                  </router-link>
                                </li>
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/email/gmail/add-forward-email"
                                  >
                                    {{ $t("home.sidebarMenu.forwarding") }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <ul class="menu">
                            <li>
                              <a
                                @click="toggleChildClass('microsoft')"
                                class="toggle-list"
                                href="javascript:void(0);"
                                :class="childMenu == 'microsoft' ? 'activeChildmenu' : ''"
                                >{{ $t("home.sidebarMenu.MicrosoftEdge") }}
                                <img
                                  src="../assets/images/up-arrow.svg"
                                  alt="logo"
                                  class="right-arrow"
                              /></a>
                              <ul
                                class="drop-donw-menu"
                                :class="
                                  childMenu == 'microsoft'
                                    ? 'isActiveClass'
                                    : 'isNotActiveClass'
                                "
                              >
                                <li>
                                  <router-link
                                    @click="handleToggle"
                                    to="/docs/product/channels/email/microsoft/create-channel"
                                  >
                                    {{ $t("home.sidebarMenu.EmailViaCreateChannel") }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link @click="handleToggle" to="/docs/product/channels/telegram">
                    {{ $t("home.sidebarMenu.Telegram") }}
                  </router-link>
                </li>
                <li>
                  <router-link @click="handleToggle" to="/docs/product/channels/line">
                    {{ $t("home.sidebarMenu.Line") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/channels/supported-features"
                  >
                    {{ $t("home.sidebarMenu.SupportedFeatures") }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="start-introduction">
          <ul class="menu">
            <li :class="{ active: parentMenu === 'features' }">
              <a
                @click="toggleParentClass('features')"
                class="toggle-list grey"
                href="javascript:void(0);"
              >
                {{ $t("home.sidebarMenu.Features") }}
                <img src="../assets/images/up-arrow.svg" alt="logo" class="right-arrow" />
              </a>
              <ul class="drop-donw-menu">
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/canned-responses"
                  >
                    {{ $t("home.sidebarMenu.headerCannedResponses") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/custom-attributes"
                  >
                    {{ $t("home.sidebarMenu.CustomAttributes") }}
                  </router-link>
                </li>
                <li>
                  <router-link @click="handleToggle" to="/docs/user-guide/features/csat">
                    {{ $t("home.sidebarMenu.CSAT") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/contacts"
                  >
                    {{ $t("home.sidebarMenu.Contacts") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/features/inbox/routing-conversations-round-robin"
                  >
                    {{ $t("home.sidebarMenu.ConversationRouting") }}
                  </router-link>
                </li>

                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/folders"
                  >
                    {{ $t("home.sidebarMenu.Folders") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/keyboard-shortcuts"
                  >
                    {{ $t("home.sidebarMenu.KeyboardShortcuts") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/segments"
                  >
                    {{ $t("home.sidebarMenu.Segments") }}
                  </router-link>
                </li>
                <router-link
                  @click="handleToggle"
                  to="/docs/user-guide/features/commandbar"
                >
                  {{ $t("home.sidebarMenu.Commandbar") }}s
                </router-link>

                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/template-variables"
                  >
                    {{ $t("home.sidebarMenu.TemplateVariables") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/multilingual-support"
                  >
                    {{ $t("home.sidebarMenu.MultilingualSupport") }}
                  </router-link>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('reports')"
                        class="toggle-list"
                        :class="subMenu == 'reports' ? 'activeSubmenu' : ''"
                        href="javascript:void(0);"
                      >
                        {{ $t("home.sidebarMenu.Reports") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'reports' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'reports' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/concepts"
                          >
                            {{ $t("home.sidebarMenu.mainconcepts") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/overview"
                          >
                            {{ $t("home.sidebarMenu.overview") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/conversation"
                          >
                            {{ $t("home.sidebarMenu.conversation") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/csat"
                          >
                            {{ $t("home.sidebarMenu.CSAT") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/agent"
                          >
                            {{ $t("home.sidebarMenu.Agent") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/label"
                          >
                            {{ $t("home.sidebarMenu.Telegram") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/inbox"
                          >
                            {{ $t("home.sidebarMenu.inbox") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/user-guide/features/reports/team"
                          >
                            {{ $t("home.sidebarMenu.Team") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/conversation-workflow"
                  >
                    {{ $t("home.sidebarMenu.conversationWorkflow") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/features/advanced-conversation-filters"
                  >
                    {{ $t("home.sidebarMenu.introductionAd") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/automations"
                  >
                    {{ $t("home.sidebarMenu.headerAutomations") }}
                  </router-link>
                </li>
                <li>
                  <router-link @click="handleToggle" to="/docs/product/features/macros">
                    {{ $t("home.sidebarMenu.macros") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/pre-chat-form"
                  >
                    {{ $t("home.sidebarMenu.PreChatForm") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/user-guide/features/priority"
                  >
                    {{ $t("home.sidebarMenu.ConversationPriority") }}
                  </router-link>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('campaigns')"
                        class="toggle-list"
                        href="javascript:void(0);"
                        :class="subMenu == 'campaigns' ? 'activeSubmenu' : ''"
                      >
                        {{ $t("home.sidebarMenu.Campaigns") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'campaigns' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'campaigns' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/campaigns/ongoing"
                          >
                            {{ $t("home.sidebarMenu.PreviousOngoing") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/campaigns/one-off"
                          >
                            {{ $t("home.sidebarMenu.tittleOff") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('help-center')"
                        class="toggle-list"
                        :class="subMenu == 'help-center' ? 'activeSubmenu' : ''"
                        href="javascript:void(0);"
                      >
                        {{ $t("home.sidebarMenu.HelpCenter") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'help-center' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'help-center' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/help-center"
                          >
                            {{ $t("home.sidebarMenu.introduction") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/help-center/configure-ssl-for-help-center"
                          >
                            {{ $t("home.sidebarMenu.Configure") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('integrations')"
                        class="toggle-list"
                        :class="subMenu == 'integrations' ? 'activeSubmenu' : ''"
                        href="javascript:void(0);"
                      >
                        {{ $t("home.sidebarMenu.introduction") }}
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'integrations' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'integrations' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/integrations/dialogflow"
                          >
                            {{ $t("home.sidebarMenu.DialogFlow") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/integrations/dyte"
                          >
                            {{ $t("home.sidebarMenu.Dyte") }}
                          </router-link>
                        </li>
                        <!-- <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/integrations/google-translate"
                          >
                            {{ $t("home.sidebarMenu.GoogleTranslate") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/features/integrations/slack"
                          >
                            {{ $t("home.sidebarMenu.Slack") }}
                          </router-link>
                        </li> -->
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="start-introduction">
          <ul class="menu">
            <li :class="{ active: parentMenu === 'advanced' }">
              <a
                @click="toggleParentClass('advanced')"
                class="toggle-list grey"
                href="javascript:void(0);"
                >{{ $t("home.sidebarMenu.Advanced") }}
                <img src="../assets/images/up-arrow.svg" alt="logo" class="right-arrow" />
              </a>
              <ul class="drop-donw-menu">
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/advanced/agent-bots"
                  >
                    {{ $t("home.sidebarMenu.addAgents") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/advanced/interactive-messages"
                  >
                    {{ $t("home.sidebarMenu.InteractiveMessages") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/advanced/dashboard-apps"
                  >
                    {{ $t("home.sidebarMenu.dashboardApps") }}
                  </router-link>
                </li>
                <li>
                  <ul class="menu">
                    <li>
                      <a
                        @click="toggleSubClass('webhooks')"
                        class="toggle-list"
                        :class="subMenu == 'webhooks' ? 'activeSubmenu' : ''"
                        href="javascript:void(0);"
                      >
                        Webhooks
                        <img
                          src="../assets/images/up-arrow.svg"
                          alt="logo"
                          :class="subMenu == 'webhooks' ? 'right-on' : 'right-off'"
                        />
                      </a>
                      <ul
                        class="drop-donw-menu"
                        :class="
                          subMenu == 'webhooks' ? 'isActiveClass' : 'isNotActiveClass'
                        "
                      >
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/advanced/webhooks"
                          >
                            {{ $t("home.sidebarMenu.configureWebhooks") }}
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            @click="handleToggle"
                            to="/docs/product/advanced/webhook-events"
                          >
                            {{ $t("home.sidebarMenu.SupportedWebhookEventsInBccor") }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/advanced/websocket-events"
                  >
                    {{ $t("home.sidebarMenu.WebsocketEvents") }}
                  </router-link>
                </li>
                <!-- <li>
                  <router-link @click="handleToggle" to="/docs/product/advanced/cookies">
                    {{ $t("home.sidebarMenu.Cookies") }}
                  </router-link>
                </li> -->
              </ul>
            </li>
          </ul>
        </div>
        <div class="start-introduction">
          <ul class="menu">
            <li :class="{ active: parentMenu === 'mobile-app' }">
              <a
                @click="toggleParentClass('mobile-app')"
                class="toggle-list grey"
                href="javascript:void(0);"
                >{{ $t("home.sidebarMenu.MobileApp") }}
                <img src="../assets/images/up-arrow.svg" alt="logo" class="right-arrow"
              /></a>
              <ul class="drop-donw-menu">
                <li>
                  <router-link
                    @click="handleToggle"
                    to="/docs/product/mobile-app/android"
                  >
                    {{ $t("home.sidebarMenu.Android") }}
                  </router-link>
                </li>
                <li>
                  <router-link @click="handleToggle" to="/docs/product/mobile-app/ios">
                    {{ $t("home.sidebarMenu.iOS") }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- responsiveness hide div -->
    <div class="back-to-menu" v-if="isNone">
      <div class="start-introduction">
        <router-link @click="handleToggle" to="/docs/product">{{
          $t("home.sidebarMenu.UserGuide")
        }}</router-link>
      </div>
    </div>
  </div>
  <div class="sidebar-overlay"></div>
</template>
<style scoped>
.isActiveClass {
  display: block !important;
}

.isNotActiveClass {
  display: none !important;
}

.right-off {
  transform: rotate(90deg);
  transition: 0.4s;
  /* filter: invert(1); */
}

.right-on {
  transform: rotate(180deg);
  transition: 0.4s;
}
</style>
<script>
export default {
  name: "SideBar",
  props: {
    isToggle: {
      type: Boolean,
      require: true,
    },
    handleToggle: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      closeSidebar: true,
      menuName: null,
      parentMenu: null,
      subMenu: false,
      childMenu: false,
      isNone: false,
      channelsRoutes: [
        "channels/facebook",
        "channels/instagram",
        "channels/twitter",
        "whatsapp/360dialog",
        "whatsapp",
        "whatsapp/whatsapp-cloud",
        "whatsapp/twilio",
        "live-chat/create-website-channel",
        "sdk/setup",
        "sdk/identity-validation",
        "sdk/live-chat-dark-mode",
        "live-chat/conversation-continuity",
        "live-chat/webflow-integration",
        "integrations/docusaurus",
        "integrations/google-tag-manager",
        "integrations/react-native-widget",
        "integrations/nextjs",
        "integrations/vue",
        "integrations/gatsby",
        "integrations/wordpress",
      ],
      liveChatChannels: [
        "live-chat",
        "live-chat/create-website-channel",
        "sdk/setup",
        "sdk/identity-validation",
        "sdk/live-chat-dark-mode",
        "live-chat/conversation-continuity",
        "live-chat/webflow-integration",
        "integrations/docusaurus",
        "integrations/google-tag-manager",
        "integrations/react-native-widget",
        "integrations/nextjs",
        "integrations/vue",
        "integrations/gatsby",
        "integrations/wordpress",
        "setup-instruction",
        "whatsapp",
        "sms",
      ],
    };
  },
  computed: {
    routePath() {
      const path = this.$route.path;
      const parts = path.split("/");
      const tt = path.split("/");
      this.subMenu = parts[4];
      this.parentMenu = parts[3];
      this.childMenu = parts[5];
      return parts;
    },
  },

  mounted() {},

  methods: {
    activeSelectedMenu() {
      console.log("route name ->", this.$router.currentRoute.value.path);
    },
    toggleParentClass(value) {
      if (this.parentMenu === value) {
        this.parentMenu = null;
      } else {
        this.parentMenu = value;
        this.subMenu = null;
        this.childMenu = null;
      }
    },
    toggleSubClass(value) {
      console.log(value);
      if (this.subMenu === value) {
        this.subMenu = null;
      } else {
        this.subMenu = value;
        this.childMenu = null;
      }
    },
    toggleChildClass(value) {
      if (this.childMenu === value) {
        this.childMenu = null;
      } else {
        this.childMenu = value;
      }
      console.log("subMenu : ", this.subMenu);
      console.log("childMenu : ", this.childMenu);
    },
    toggleResponsiveHeader(value) {
      this.isNone = value;
    },
  },
};
</script>
