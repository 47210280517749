<template>
  <Header @language-selected="handleLanguageSelected" />
  <section class="labels-page">
    <div class="container">
      <div class="integrations-heading privateNotes-heading">
        <h2>{{ $t('privateNotes.workTogether') }}</h2>
        <p>
          {{ $t('privateNotes.customer_queries') }}
        </p>
        <div class="start-button">
          <a href="https://app.bcoor.com"
            >{{ $t("automation.freeTrial") }}</a
          >
        </div>
      </div>
      <div class="labels-page-details">
        <p>
          {{ $t('privateNotes.one_person_data') }}
        </p>
        <p class="mt-2">
          {{ $t('privateNotes.black_friday')}}
        </p>
        <p class="mt-2">
          {{ $t('privateNotes.customer_screen_shot') }}
        </p>
      </div>
      <div class="labels-page-contant">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="labels-page-img">
              <img src="../../../assets/images/privateNotes/switch-to-private-note.png" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="labels-page-img-details">
              <h4>{{ $t('privateNotes.switch_customer') }}</h4>
              <p>
                {{ $t('privateNotes.middle_conversation') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="labels-page-contant">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-12 orderr-2">
            <div class="labels-page-img-details">
              <h4>{{ $t('privateNotes.mention_teammates') }}</h4>
              <p>
                {{ $t('privateNotes.press_teammates') }}
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 orderr-1">
            <div class="labels-page-img">
              <img src="../../../assets/images/privateNotes/private-note-mentions.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="labels-page-contant">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="labels-page-img">
              <img src="../../../assets/images/privateNotes/format-private-note.png" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="labels-page-img-details">
              <h4>{{ $t('privateNotes.attachment_emoji') }}</h4>
              <p>
                {{ $t('privateNotes.limited_ability') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="conversations d-none">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="conversations-heading">
              <h3>{{ $t('privateNotes.collaborateData') }}</h3>
              <p>
                {{ $t('automation.conversations') }}
              </p>
              <div class="signup-button">
                <a href="https://app.bcoor.com"
                  >Sign up for free</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="conversations-img">
              <img src="../../../assets/images/automations/dashboard-small.webp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer :selectLanguage="selectLanguage" />
</template>

<script>
import Footer from "@/views/chatwoot/Footer.vue";
import Header from "@/views/chatwoot/Header.vue";

export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      selectLanguage: 'en',
    };
  },
  methods: {
    handleLanguageSelected (language) {
      this.selectLanguage = language;
    },
  },
  created() {
    const localStorageItem = localStorage.getItem('language');

    if (localStorageItem) {

      this.selectLanguage = localStorageItem;
    }
  }
};
</script>
