<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("doc.menu.Channels") }}</p>
              <img
                src="../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <p>{{ $t("home.header.Gmail") }}</p>
              <img
                src="../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <a href="#">{{ $t("Advance.Channels.Email.CreateChannel.tittle") }}</a>
            </div>

            <div class="email-channel-page">
              <div class="channel-details">
                <h1>{{ $t("Advance.Channels.Email.CreateChannel.setup") }}</h1>
              </div>

              <section class="channel-steps-wrp">
                <div class="channel-steps">
                  <p>
                    <b>{{ $t("Advance.Channels.Email.CreateChannel.step1") }}</b>
                    {{ $t("Advance.Channels.Email.CreateChannel.openBccor") }}
                  </p>
                  <div class="channel-image-box">
                    <img
                      class="img-fluid"
                      src="../../../../../assets/images/adding-inbox-in-one.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="channel-steps">
                  <p>
                    <b>{{ $t("Advance.Channels.Email.CreateChannel.step2") }}</b>
                    {{ $t("Advance.Channels.Email.CreateChannel.emailClick") }}
                  </p>
                  <div class="channel-image-box">
                    <img
                      class="img-fluid"
                      src="../../../../../assets/images/list_of_channels-8-three.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="channel-steps">
                  <p>
                    <b>{{ $t("Advance.Channels.Email.CreateChannel.step3") }}</b>
                    {{ $t("Advance.Channels.Email.CreateChannel.channelName") }}
                  </p>
                  <div class="channel-image-box">
                    <img
                      class="img-fluid"
                      src="../../../../../assets/images/14.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="channel-steps">
                  <p>
                    <b>{{ $t("Advance.Channels.Email.CreateChannel.step4") }}</b
                    >{{ $t("Advance.Channels.Email.CreateChannel.addAgents") }}
                  </p>
                  <div class="channel-image-box">
                    <img
                      class="img-fluid"
                      src="../../../../../assets/images/add-agents-to-email-inbox-channel.png"
                      alt=""
                    />
                  </div>
                  {{ $t("Advance.Channels.Email.CreateChannel.successFully") }}
                </div>
                <div class="channel-steps">
                  <p>
                    <b>{{ $t("Advance.Channels.Email.CreateChannel.step5") }}</b
                    >{{ $t("Advance.Channels.Email.CreateChannel.emailSettings") }}
                  </p>
                  <div class="channel-image-box">
                    <img
                      class="img-fluid"
                      src="../../../../../assets/images/13.png"
                      alt=""
                    />
                  </div>
                </div>
              </section>

              <section id="next-steps" class="create-channels-emial">
                <div class="channels-title" style="padding-left: 0px">
                  <p>{{ $t("Advance.Channels.Email.CreateChannel.inboxConfigure") }}</p>
                  <ol class="next-details">
                    <li>
                      <a href="#"
                        >{{ $t("Advance.Channels.Email.CreateChannel.forward") }}
                      </a>
                    </li>
                    <li>
                      <a href="#"
                        >{{ $t("Advance.Channels.Email.CreateChannel.configure") }}
                      </a>
                    </li>
                  </ol>
                </div>
              </section>
            </div>

            <div class="edit-update-detail">
              <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Advance.Channels.Email.CreateChannel.editPage') }}</a>
                            </div> -->
              <p>
                {{ $t("Advance.Channels.Email.CreateChannel.lastUpdatedOn") }}
                <span class="dark">
                  {{ $t("Advance.Channels.Email.CreateChannel.date") }}
                </span>
                {{ $t("Advance.Channels.Email.CreateChannel.by") }}
                <span class="dark">
                  {{ $t("Advance.Channels.Email.CreateChannel.hrichaShandily") }}</span
                >
              </p>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p class="preview">
                    {{ $t("Advance.Channels.Email.CreateChannel.previous") }}
                  </p>

                  <router-link
                    to="/docs/product/channels/sms/bandwidth"
                    class="introduction-account"
                  >
                    <span>{{ $t("Advance.Channels.Email.CreateChannel.side") }}</span>
                    {{ $t("Advance.Channels.Email.CreateChannel.bandwidth") }}
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("Advance.Channels.Email.CreateChannel.next") }}</p>

                  <router-link to="/docs/product/channels/email/configure-imap">
                    {{ $t("Advance.Channels.Email.CreateChannel.configure") }}
                    <span>{{ $t("Advance.Channels.Email.CreateChannel.arrow") }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4"></div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
  name: "Introduction",
  data() {
    return {
      isToggle: false,
    };
  },
  components: {
    Header,
    Footer,
    SideBar,
    MainContent,
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
