<template>
  <Header @language-selected="handleLanguageSelected"/>
        <section class="labels-page">
          <div class="container">
                <div class="integrations-heading privateNotes-heading">
                    <h2>{{ $t('helpCenter.welcomeMessage') }}</h2>
                    <p>{{ $t('helpCenter.helpText') }}</p>
                </div>
                <div class="browser-box">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                          <a :href="selectLanguage === 'en' ? 'https://docs-en.bcoor.com/' : 'https://docs-cn.bcoor.com'" class="browser-hosted">
                            <div class="browser-box-details">
                              <div class="gradient-bg"></div>
                              <div class="browser-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon w-6 h-6" style="" width="1em" height="1em" viewBox="0 0 24 24" data-v-ca945699=""><path fill="currentColor" d="M6 2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m0 14v4h12v-4H6Z"></path></svg>
                              </div>
                              <h5>{{ $t('helpCenter.productDocTitle') }}</h5>
                              <p>{{ $t('helpCenter.productDocDescription') }}</p>
                            </div>
                          </a>
                        </div>
                        
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <a href="https://t.me/bcoorsupport" class="browser-hosted">
                                <div class="browser-box-details">
                                    <div class="gradient-bg"></div>
                                    <div class="browser-svg">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon w-6 h-6" style="" width="1em" height="1em" viewBox="0 0 24 24" data-v-ca945699=""><path fill="currentColor" d="m15.07 11.25l-.9.92C13.45 12.89 13 13.5 13 15h-2v-.5c0-1.11.45-2.11 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41a2 2 0 0 0-2-2a2 2 0 0 0-2 2H8a4 4 0 0 1 4-4a4 4 0 0 1 4 4a3.2 3.2 0 0 1-.93 2.25M13 19h-2v-2h2M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10c0-5.53-4.5-10-10-10Z"></path></svg>
                                    </div>
                                    <h5>{{ $t('helpCenter.askQuestionTitle') }}</h5>
                                    <p>{{ $t('helpCenter.askQuestionDescription') }}</p>
                                </div>
                            </a>
                            
                        </div>
                    </div>
                </div>
                <div class="faq-details">
                    <div class="faq-heading">

                    </div>
                </div>
            </div>
        </section>
  <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');
            
            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
        }
    </script>
