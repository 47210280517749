<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("doc.product.Features") }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.Segments') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>On this page</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#save-a-segment-link' }">
                                                        <a @click="scrollTo('#save-a-segment')" class="link_wrp">{{
                                                            $t('features.Segment.saveSegment') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#select-a-saved-link' }">
                                                        <a @click="scrollTo('#select-a-saved')" class="link_wrp">{{
                                                            $t('features.Segment.selectSavedSegment') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#delete-a-saved-segment-link' }">
                                                        <a @click="scrollTo('#delete-a-saved-segment')" class="link_wrp">{{
                                                            $t('features.Segment.deleteSegment') }}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">

                            <div class="canned_list_wrp">
                                <h1>{{ $t('doc.product.Segments') }}</h1>
                                <p>
                                    {{ $t('features.Segment.segmentDesc1') }}
                                </p>

                                <p class="widget_wrp">
                                    <img src="../../../../assets/images/apply-filter.webp" class="img-fuild">
                                </p>


                                <div class="canned_list_wrp">
                                    <h1>{{ $t('doc.product.Segments') }}</h1>
                                    <p>
                                        {{ $t('features.Segment.segmentDesc1') }}
                                    </p>

                                    <p>
                                        {{ $t('features.Segment.segmentDesc2') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/apply-filter-moda.webp" class="img-fuild">
                                    </p>

                                    <p>
                                        {{ $t('features.Segment.segmentDesc3') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/apply-filter-moda.webp" class="img-fuild">
                                    </p>
                                </div>

                                <div class="canned_list_wrp" id="save-a-segment">
                                    <h3>{{ $t('features.Segment.saveSegment') }}</h3>
                                    <p>
                                        {{ $t('features.Segment.saveSegmentDesc1') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/apply-filter-moda.webp" class="img-fuild">
                                    </p>

                                    <p style="padding-top: 15px;"> {{ $t('features.folder.saveNewFolderDesc2') }}
                                        <b>{{ $t('features.folder.saveNewFolderDesc3') }}</b>
                                        {{ $t('features.folder.saveNewFolderDesc4') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/save-button.webp" class="img-fuild">
                                    </p>
                                </div>

                                <div class="canned_list_wrp" id="select-a-saved">
                                    <h3>{{ $t('features.Segment.selectSavedSegment') }}</h3>
                                    <p>
                                        {{ $t('features.Segment.segmentName') }}
                                        <strong>{{ $t('features.folder.displayed') }}</strong>
                                        {{ $t('features.Segment.secondarySidebar') }}
                                        <strong>{{ $t('doc.product.Segments') }}</strong>
                                        {{ $t('features.Segment.fetchContactSaved') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/segments-list.webp" class="img-fuild">
                                    </p>
                                </div>

                                <div class="canned_list_wrp" id="delete-a-saved-segment">
                                    <h3>{{ $t('features.Segment.deleteSegment') }}</h3>
                                    <p>
                                        If you want to <strong>{{ $t('features.folder.delete') }}</strong> {{
                                            $t('features.Segment.deleteSegment1') }} <strong>{{
        $t('features.Segment.deleteFilter') }}</strong> {{
        $t('features.Segment.segmentDesc2') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/delete-modal.webp" class="img-fuild">
                                    </p>

                                    <p>
                                        {{ $t('features.folder.modalShow') }} <strong>{{
                                            $t('features.folder.ConfirmDeletion') }}</strong> {{ $t('features.folder.click')
    }}
                                        <strong>{{ $t('features.folder.keepIt') }}</strong>,
                                        {{ $t('features.Segment.segmentDelete') }}
                                        <strong>{{ $t('features.canned-responses.yes_delete') }}</strong>,
                                        {{ $t('features.Segment.deletingSegement') }}
                                    </p>

                                    <p class="widget_wrp">
                                        <img src="../../../../assets/images/delete-button.webp" class="img-fuild">
                                    </p>
                                </div>

                                <div class="update_edit_wrp">
                                    <!-- <div class="edit_wrp">
                                        <a href="#">
                                            <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                                class="iconEdit_dcUD" aria-hidden="true">
                                                <g>
                                                    <path
                                                        d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                                    </path>
                                                </g>
                                            </svg>

                                            <p>
                                                {{ $t('doc.product.EditThisPage') }}
                                            </p>
                                        </a>
                                    </div> -->

                                    <div class="update_wrp">
                                        <p>
                                            {{ $t('doc.product.LastUpdated') }} <b>{{ $t('features.folder.date') }}</b> by
                                            <b> {{ $t('features.folder.author') }}</b>
                                        </p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account create-chat-previous">
                                            <p>{{ $t('Mobile_App.previous') }}</p>
                                            <a href="/docs/user-guide/features/keyboard-shortcuts"><span>«</span>{{
                                                $t('headersubmenu.Features.headerKeyboardShortcuts') }}</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p>{{ $t('doc.product.Next') }}</p>
                                            <a href="/docs/user-guide/features/commandbar">{{
                                                $t('headersubmenu.Features.headercommandBar') }}<span>»</span></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#save-a-segment-link' }"><a
                                        id="#save-a-segment-link" @click="scrollTo('#save-a-segment')" class="link_wrp">{{
                                            $t('features.Segment.saveSegment') }}</a></li>

                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#select-a-saved-link' }"><a
                                        id="#select-a-saved-link" @click="scrollTo('#select-a-saved')" class="link_wrp">{{
                                            $t('features.Segment.selectSavedSegment') }}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#delete-a-saved-segment-link' }">
                                    <a id="#delete-a-saved-segment-link" @click="scrollTo('#delete-a-saved-segment')"
                                        class="link_wrp">{{ $t('features.Segment.deleteSegment') }}</a></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {

        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>