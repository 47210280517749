<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("doc.product.Features") }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.TemplateVariables') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                           <p>{{ $t('doc.product.OnThisPage') }}</p>
                                                <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                        alt=""></span>

                                        </button>
                                    </h2>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">

                            <div class="canned_list_wrp">
                                <h1>{{ $t('doc.product.TemplateVariables') }}</h1>
                                <p>
                                    {{ $t('features.template-variables.templatevariableDesc') }}
                                </p>

                                <p>{{ $t('features.template-variables.templatevariableDesc1') }}</p>

                                <p class="widget_wrp"  style="padding-left:0;">
                                    <img src="../../../../assets/images/variable-list.png" class="img-fuild">
                                </p>

                                <p class="send_msg_wrp">
                                    {{ $t('features.template-variables.sendMessage') }}<code>{{ $t('features.template-variables.mayIhelp') }}</code>,
                                    {{ $t('features.template-variables.bcoorContactName') }}
                                    <code>{{ $t('features.template-variables.howMayIhelp') }}</code>{{ $t('features.template-variables.personalized') }}
                                </p>

                                <blockquote style="height: auto;">
                                    <p style="padding-bottom: 0;">{{ $t('features.template-variables.supportCanned') }}</p>
                                </blockquote>

                                <p class="widget_wrp"  style="padding-left:0;">
                                    <img src="../../../../assets/images/variable-demo.gif" class="img-fuild">
                                </p>

                                <p class="use-variable">
                                    {{ $t('features.template-variables.supportVariable') }}
                                </p>

                                <p>{{ $t('features.template-variables.currentTemplate') }}</p>
                            </div>

                            <div class="table-details" style="padding-left: 0;">
                                <table class="table table-bordered description">
                                    <thead>
                                        <tr>
                                            <th>Variable</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>conversation.id</td>
                                            <td>Numeric version of the conversation id</td>
                                        </tr>
                                        <tr>
                                            <td>contact.id</td>
                                            <td>Numeric version of the contact id</td>
                                        </tr>
                                        <tr>
                                            <td>contact.name</td>
                                            <td>Contact full name</td>
                                        </tr>
                                        <tr>
                                            <td>contact.first_name</td>
                                            <td>Contact first name</td>
                                        </tr>
                                        <tr>
                                            <td>contact.last_name</td>
                                            <td>Contact last name</td>
                                        </tr>
                                        <tr>
                                            <td>contact.phone_number</td>
                                            <td>Contact phone number</td>
                                        </tr>
                                        <tr>
                                            <td>agent.name</td>
                                            <td>Agent full name</td>
                                        </tr>
                                        <tr>
                                            <td>agent.first_name</td>
                                            <td>Agent first name</td>
                                        </tr>
                                        <tr>
                                            <td>agent.last_name</td>
                                            <td>Agent last name</td>
                                        </tr>
                                        <tr>
                                            <td>agent.phone_number</td>
                                            <td>Agent phone number</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="canned_menulist">

                                <p><strong>{{ $t('features.template-variables.variableDoesnt') }}</strong></p>

                                <p class="undefine-variable">{{ $t('features.template-variables.undefinedVariable') }}</p>

                                <p class="widget_wrp" style="padding-left:0;">
                                    <img src="../../../../assets/images/undefined-variables.png" class="img-fuild">
                                </p>

                                <p><strong>{{ $t('features.template-variables.fallbackText') }}</strong></p>

                                <p>{{ $t('features.template-variables.singleQuotes') }}</p>
                                <span class="click-inbox">{{ $t('features.template-variables.contactFirstname') }}</span>

                            </div>

                            <div class="update_edit_wrp">
                                <!-- <div class="edit_wrp">
                                    <a href="#">
                                        <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                            class="iconEdit_dcUD" aria-hidden="true">
                                            <g>
                                                <path
                                                    d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                                </path>
                                            </g>
                                        </svg>

                                        <p>
                                            {{ $t('doc.product.EditThisPage') }}
                                        </p>
                                    </a>
                                </div> -->

                                <div class="update_wrp">
                                    <p>
                                        {{ $t('doc.product.LastUpdated') }}<b>12/2/2021</b> by <b>Fayaz Ahmed</b>
                                    </p>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account create-chat-previous">
                                        <p>{{ $t('Mobile_App.previous') }}</p>
                                        <a href="/docs/user-guide/features/segments"><span>«</span>{{ $t('doc.product.Segments') }}</a>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p>{{ $t('doc.product.Next') }}</p>
                                        <a href="/docs/user-guide/features/multilingual-support">{{ $t('doc.product.MultilingualSupport') }}<span>»</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <!-- <section class="tableOfContents">
                            <div class="tableOfContents-list">
                                <ul id="side_section_info">
                                    <li><a href="#" class="link_wrp">Opening the command bar</a></li>
                                    <li><a href="#" class="link_wrp">Fast Navigation</a></li>
                                    <li><a href="#" class="link_wrp">Conversation Actions</a></li>
                                </ul>
                            </div>
                        </section> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>