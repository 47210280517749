<template>
    <section class="chatwoot-account-deatils">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="create-woot-account-details">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.createChatWoodAccount.CreateYourBcoorAccount') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.product.OnThisPage') }}</p>
                                            <span class="down_up_icon"><img
                                                    src="../../../../assets/images/up_arrow_side.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        class="{ 'active_menu_item': rightPanelMenuItem === '#cloud-info' }">
                                                        <a @click="scrollTo('#cloud-info')">{{ $t('doc.createChatWoodAccount.CloudVersion') }}</a>
                                                    </li>
                                                    <li class="{ 'active_menu_item': rightPanelMenuItem === '#next-info' }">
                                                        <a @click="scrollTo('#next-info')">{{ $t('doc.menu.NextSteps') }}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.createChatWoodAccount.CreateYourBcoorAccount') }}</h1>
                            <p>{{ $t('doc.createChatWoodAccount.Guide') }}</p>
                        </div>
                        <!-- <div class="version-detail" id="version-info">
                            <h4>I am using the self-hosted version</h4>
                            <p>If you are using a self-hosted bcoor installation, you may directly open the
                                URL&nbsp;<span class="click-inbox">{your_installation_url}/app/auth/signup.</span></p>
                            <p class="guide-host">P.S. We have a wholly different <a href="#">guide</a>for self-hosted
                                bcoor.</p>
                        </div> -->
                        <div class="cloud-version" id="cloud-info">
                            <h4>{{ $t('doc.createChatWoodAccount.CloudVersion') }}</h4>
                            <p><span class="step">{{ $t('doc.createChatWoodAccount.Step1') }}</span> If you want to use bcoor cloud, you may open <a
                                    href="http://bcoor.com/">bcoor.com </a> {{ $t('doc.createChatWoodAccount.CreateaFreeAccount') }} </p>
                        </div>
                        <div class="delightful-customer-img">
                            <img src="../../../../assets/images/defaut-custome-img.webp" alt="logo12">
                        </div>
                        <p class="step-number"><span class="step">{{ $t('doc.createChatWoodAccount.Step2') }}</span>{{ $t('doc.createChatWoodAccount.SignUpMessage') }}</p>
                        <div class="delightful-customer-img">
                            <img src="../../../../assets/images/account-registration-form.webp" alt="logo12">
                        </div>
                        <p class="img-detils">The fields required to signup are as shown below. Fill up the fields.</p>

                        <div class="table-details">
                            <table class="table table-bordered description">
                                <thead>
                                    <tr>
                                        <th>{{ $t('doc.createChatWoodAccount.FieldName') }}</th>
                                        <th>{{ $t('doc.createChatWoodAccount.Description') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ $t('doc.createChatWoodAccount.fieldNameFullName') }}</td>
                                        <td>{{ $t('doc.createChatWoodAccount.FullNameDescription') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.createChatWoodAccount.fieldNameWorkEmail') }}</td>
                                        <td>{{ $t('doc.createChatWoodAccount.WorkEmailDescription') }} <a
                                                href="mailto:john.hopkins@companyname.com">{{ $t('doc.createChatWoodAccount.WorkEmailDescriptionEg') }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.createChatWoodAccount.fieldNameAccountName') }}</td>
                                        <td>{{ $t('doc.createChatWoodAccount.AccountNameDescription') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.createChatWoodAccount.fieldNamePassword') }}</td>
                                        <td>{{ $t('doc.createChatWoodAccount.PasswordDescription') }}<span
                                                class="click-inbox">
                                                <code>(!@#$%^&amp;*()_+-=[]{}\|'"/\^.,</code>&lt;&gt;:;?~)`</span>
                                            <p></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>c</td>
                                        <td>{{ $t('doc.createChatWoodAccount.ConfirmPasswordDescription') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="step-number"><span class="step">{{ $t('doc.createChatWoodAccount.Step3') }}</span>{{ $t('doc.createChatWoodAccount.content') }} <span class="step">{{ $t('doc.createChatWoodAccount.ConfirmationInstruction') }}</span>{{ $t('doc.createChatWoodAccount.InstructionBcoor') }}</p>
                        <div class="email-template-img">
                            <img src="../../../../assets/images/email-template.png" alt="logo21">
                        </div>
                        <p class="step-number"><span class="step"> {{ $t('step4') }}</span> {{ $t('doc.createChatWoodAccount.clickOnThe') }} 
                            <span class="step"> {{ $t('doc.createChatWoodAccount.confirmMyAccountButton') }}</span></p>
                        <div class="email-template-img">
                            <img src="../../../../assets/images/Chatwoot-welcome-screen.webp" alt="logo21">
                        </div>
                        <p class="verfiled-info">{{ $t('doc.createChatWoodAccount.accountVerificationMessage') }}</p>
                        <div class="channels-title" id="next-info">
                            <h2>{{ $t('doc.product.NextSteps') }}</h2>
                            <p>{{ $t('doc.createChatWoodAccount.followSteps') }}</p>
                            <ul class="next-configuer-menu">
                                <li><a href="/docs/user-guide/setup-your-account/configure-your-profile">{{ $t('doc.createChatWoodAccount.configureProfile') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.setProfileDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/configure-account-details">{{ $t('doc.createChatWoodAccount.configureAccountDetails') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.setupAccountDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/add-agent-settings">{{ $t('doc.createChatWoodAccount.addAgents') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.addAgentsDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/add-inbox-settings">{{ $t('doc.createChatWoodAccount.addInboxes') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.addInboxesDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/configure-account-details">{{ $t('doc.createChatWoodAccount.configureChatWidget') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.configureChatWidgetDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/add-teams-settings">{{ $t('doc.createChatWoodAccount.addTeams') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.addTeamsDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/add-label-settings">{{ $t('doc.createChatWoodAccount.addLabels') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.addLabelsDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/features/canned-responses">{{ $t('doc.createChatWoodAccount.addCannedResponses') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.addCannedResponsesDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/setup-your-account/integrations">{{ $t('doc.createChatWoodAccount.integrations') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.integrationsDetails') }}</p>
                                </li>
                                <li><a href="/docs/user-guide/features/canned-responses">{{ $t('doc.createChatWoodAccount.applications') }}</a>
                                    <p class="setup-detail">{{ $t('doc.createChatWoodAccount.applicationsDetails') }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }} <span class="dark">10/17/2022 </span>{{ $t('doc.product.By') }} <span class="dark"> {{ $t('doc.createChatWoodAccount.hrichaShandily') }}
                                </span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>

                                    <router-link to="/docs/product" class="introduction-account"><span>«</span>{{ $t('doc.product.Introduction') }}

                                    </router-link>

                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>

                                    <router-link to="/docs/user-guide/setup-your-account/configure-your-profile">
                                        {{ $t('doc.createChatWoodAccount.configureProfile') }}<span>»</span>
                                    </router-link>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#cloud-info-link' }">
                                    <a id="#cloud-info-link" @click="scrollTo('#cloud-info')">{{ $t('doc.createChatWoodAccount.CloudVersion') }}</a></li>
                                    
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#next-info-link' }">
                                    <a id="#next-info-link" @click="scrollTo('#next-info')">{{ $t('doc.product.NextSteps') }}</a></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'CreateChatwootAccount',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>
<style>
/* .version-detail {
    padding: 0px 36px;
} */

.version-detail h4 {
    text-align: left;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-containt-font-color);
    font-weight: 600;
    margin-bottom: 20px;
}

.version-detail p {
    text-align: left;
    color: var(--main-containt-font-color);
    font-size: 18px;
    line-height: 28px;
}

p.guide-host {
    text-align: left;
    color: var(--main-containt-font-color);
    font-size: 18px;
    line-height: 28px;
}

p.guide-host a {
    text-decoration: none;
    margin: 0 4px;
    font-size: 18px;
    color: #1f93ff;
}

.cloud-version {
    padding: 0 36px;
}

.cloud-version h4 {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    color: var(--main-containt-font-color);
    line-height: 34px;
    margin: 20px 0;
}

.cloud-version p {
    text-align: left;
    color: var(--main-containt-font-color);
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
}

.step {
    font-weight: 700;
}

.delightful-customer-img img {
    width: 100%;
    height: 100%;
}

.delightful-customer-img {
    /* max-width: 834px;
    width: 100%; */
    margin: 0 36px;
}

.step-number {
    text-align: left;
    color: var(--main-containt-font-color);
    font-size: 18px;
    line-height: 28px;
    margin: 30px 0;
    padding: 0 36px;
}

.img-detils {
    text-align: left;
    color: var(--main-containt-font-color);
    font-size: 18px;
    line-height: 28px;
    margin: 0px 36px;
    padding-bottom: 15px;
    /* padding: 0 36px; */
}

table.table.table-bordered.description {
    max-width: 830px;
    width: 100%;
}

.description a {
    text-decoration: none;
    font-style: oblique;
}

.email-template-img img {
    width: 100%;
    height: 100%;
}

.email-template-img {
    margin: 0 36px;
}

.verfiled-info {
    text-align: left;
    color: var(--main-containt-font-color);
    font-size: 18px;
    line-height: 28px;
    margin: 20px 36px 30px 36px;
}

ul.next-configuer-menu li a {
    font-size: 18px;
    text-decoration: none;
    line-height: 29px;
    color: #1f93ff;
}

ul.next-configuer-menu li {

    list-style-type: disc;
    color: var(--responsive-bg-color);  
}

p.setup-detail {
    display: contents;
    font-size: 18px;
    line-height: 28px;
    color: #3C4E6E;
}

ul.next-configuer-menu {
    margin: 0;
    padding: 0;
    margin: 0 20px;
}

.cloud-version p a {
    text-decoration: none;
    color: #1f93ff;
}</style>    