<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("home.header.Gmail") }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('Advance.Channels.Email.Gmail.SecureAccess.tittle') }}</a>
                        </div>
                        
                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.lessAccess') }}</h1>
                        </div>
                        <div class="channels-info break-word code-info">
                            <div class="channels-title channels-anchor">

                                <blockquote class="blockquote_custom">
                                    <p><strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.note') }}</strong> {{ $t('Advance.Channels.Email.Gmail.SecureAccess.2step') }}</p>
                                </blockquote>
                                <p><strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.step1') }}</strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.goTo') }} <a href="#" target="_blank"
                                        rel="noopener noreferrer">{{ $t('Advance.Channels.Email.Gmail.SecureAccess.google') }}</a></p>
                                <p><img alt="gmail_account_security"
                                        src="../../../../../../assets/images/gmail_account_security.png" class="max-img">
                                </p>
                                <p><strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.step2') }}</strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.clickTurn') }}</p>
                                <p><img alt="less_secure_access"
                                        src="../../../../../../assets/images/less_secure_access.png" class="max-img"></p>
                                <p><strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.step3') }}</strong>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.toggleAllow') }}</p>
                                <p><img alt="enable_access" src="../../../../../../assets/images/enable_access.png"
                                        class="max-img"></p>

                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Advance.Channels.Email.Gmail.SecureAccess.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.lastUpdatedOn') }} <span class="dark">{{ $t('Advance.Channels.Email.Gmail.SecureAccess.date') }} </span> {{ $t('Advance.Channels.Email.Gmail.SecureAccess.by') }} <span class="dark"> {{ $t('Advance.Channels.Email.Gmail.SecureAccess.hrichaShandily') }}
                                </span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Advance.Channels.Email.Gmail.SecureAccess.previous') }}</p>

                                    <router-link to="/docs/product/channels/email/gmail/enable-imap"
                                        class="introduction-account">
                                        <span>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.side') }}</span> {{ $t('Advance.Channels.Email.Gmail.SecureAccess.imap') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.next') }}</p>

                                    <!-- <router-link to="/docs/product/channels/email/configure-imap">
                                        Configure IMAP
                                    </router-link> -->
                                    <router-link to="/docs/product/channels/email/gmail/generate-app-password">
                                        {{ $t('Advance.Channels.Email.Gmail.SecureAccess.appPassword') }} <span>{{ $t('Advance.Channels.Email.Gmail.SecureAccess.arrow') }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'enable-imap',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
