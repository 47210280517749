<template>
  <Header @language-selected="handleLanguageSelected"/>
  <div class="mobile-apps private">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="private-policy">
            <div class="Policy">
              <h2>{{ $t('footer.footerProduct.legal.items.textPrivacyPolicy') }}</h2>
            </div>
            <div class="introduction">
              <div class="info">
                <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                  <path fillRule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                  </path>
                </svg>
                <h6>{{ $t('doc.product.Introduction') }}</h6>
              </div>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicyapplies') }}
              </p>
            </div>
            <div class="introduction">
              <div class="info">
                <div>
                  <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                    <path fillRule="evenodd"
                      d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                    </path>
                  </svg>
                </div> 
                <h6>{{ $t('privacypolicy.privacypolicyData') }}</h6>
              </div>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicyOversight') }}{{ "@" }}{{ $t('pricing.pricingbcoorcom') }}
              </p>
            </div>
            <div class="introduction">
              <div class="info collectsone">
                <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                  <path fillRule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                  </path>
                </svg>
                <h6>{{ $t('privacypolicy.privacypolicybcoor') }}</h6>
              </div>
              <h5 class="web_visit">{{ $t('privacypolicy.privacypolicyWebsite') }}</h5>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicymost') }}
              </p>
              <p class="policy_detail">{{ $t('privacypolicy.privacypolicylike') }}</p>
              <p class="policy_detail">{{ $t('privacypolicy.privacypolicycollects') }}</p>
              <h5 class="web_visit">{{ $t('privacypolicy.privacypolicyfrom') }}</h5>
              <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicydisable') }}
              </p>
              <h5 class="web_visit">{{ $t('privacypolicy.privacypolicyPersonally') }}</h5>
              <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicyviews') }}
              </p>
              <p class="policy_detail"> {{ $t('privacypolicy.privacypolicyreport') }}</p>
              <p class="policy_detail">{{ $t('privacypolicy.privacypolicyalways') }}</p>

            </div>
            <div class="introduction">
              <div class="info">
                <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                  <path fillRule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                  </path>
                </svg>
                <h6>{{ $t('privacypolicy.privacypolicyDoes') }}</h6>
              </div>
              <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicyAPI') }}
              </p>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicyOther') }}
              </p>
            </div>
            <div class="introduction">
              <div class="info collectsone">
                <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                  <path fillRule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                  </path>
                </svg>
                <h6> {{ $t('privacypolicy.privacypolicyBasis') }}</h6>
              </div>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicycontract') }}
              </p>
              <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicybest') }}
              </p>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicycertain') }}
              </p>
            </div>
            <div class="introduction">
              <div class="info collectsone">
                <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                  <path fillRule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                  </path>
                </svg>
                <h6>{{ $t('privacypolicy.privacypolicyProtects') }}</h6>
              </div>
              <h5 class="web_visit">{{ $t('privacypolicy.privacypolicySharing') }}</h5>
              <p class="policy_detail">
              {{ $t('privacypolicy.privacypolicyonly') }}
              </p>
              <p class="policy_detail">
                 {{ $t('privacypolicy.privacypolicynote') }}
              </p>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicycontractors') }}
              </p>

              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicyaccess') }}
              </p>

              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicysole') }}{{ "@" }}{{ $t('pricing.pricingbcoorcom') }}
              </p>
              <h5 class="web_visit"> {{ $t('privacypolicy.privacypolicyTransfer') }}</h5>
              <p class="policy_detail">
              {{ $t('privacypolicy.privacypolicyhosted') }}
              </p>
              <h5 class="web_visit"> {{ $t('privacypolicy.privacypolicy') }}</h5>
              <p class="policy_detail">
              {{ $t('privacypolicy.privacypolicyregistered') }}
              </p>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicyrequest') }}
              </p>
            </div>
            <div class="introduction">
              <div class="info collectsone">
                <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                  <path fillRule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                  </path>
                </svg>
                <h6> {{ $t('privacypolicy.privacypolicyCookies') }}</h6>
              </div>
              <h5 class="web_visit">{{ $t('privacypolicy.privacypolicyCookie') }}</h5>
              <p class="policy_detail">
                {{ $t('privacypolicy.privacypolicystring') }}
              </p>
              <h5 class="web_visit"> {{ $t('privacypolicy.privacypolicyTracking') }}</h5>
              <p class="policy_detail">
                 {{ $t('privacypolicy.privacypolicythird') }}
            </p>
            <h5 class="web_visit">  {{ $t('privacypolicy.privacypolicyDo') }}</h5>
            <p class="policy_detail">
              {{ $t('privacypolicy.privacypolicypreference') }}
            </p>
          </div>
          <div class="introduction">
            <div class="info">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                <path fillRule="evenodd"
                  d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                </path>
              </svg>
              <h6>  {{ $t('privacypolicy.privacypolicyGlobal') }}</h6>
            </div>
            <p class="policy_detail">
              {{ $t('privacypolicy.privacypolicyregard') }}
            </p>
            <ul class="method_info">
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyclear') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyhave') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyrecourse') }} </p>
              </li>

            </ul>
            <p class="policy_detail union">{{ $t('privacypolicy.privacypolicylocated') }}</p>
            <ul class="method_info">
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRight') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRightone') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRighttwo') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRightthree') }}</p>
              </li>
            </ul>
            <p class="policy_detail union">{{ $t('privacypolicy.privacypolicyRightfour') }}</p>
            <ul class="method_info">
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRightfive') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRightsix') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRightobject') }}
                </p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRightseven') }}</p>
              </li>
              <li>
                <p class="data_method">{{ $t('privacypolicy.privacypolicyRighterasure') }}</p>
              </li>
            </ul>
            <p class="policy_detail union">{{ $t('privacypolicy.privacypolicyRightexercise') }}</p>
          </div>
          <div class="introduction">
            <div class="info">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                <path fillRule="evenodd"
                  d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                </path>
              </svg>
              <h6>{{ $t('privacypolicy.privacypolicyDeletion') }}</h6>
            </div>
            <p class="policy_detail">
             {{ $t('privacypolicy.privacypolicyor') }}
            </p>
            <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicyretain') }}
            </p>
            <p class="policy_detail">
              {{ $t('privacypolicy.privacypolicythat') }}
            </p>
          </div>
          <div class="introduction">
            <div class="info">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                <path fillRule="evenodd"
                  d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                </path>
              </svg>
              <h6> {{ $t('privacypolicy.privacypolicyContacting') }}</h6>
            </div>
            <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicyconcerns') }}{{ "@" }}{{ $t('pricing.pricingbcoorcom') }} {{ $t('privacypolicy.recommendEmailing') }}
            </p>
          </div>
          <div class="introduction">
            <div class="info">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
                <path fillRule="evenodd"
                  d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
                </path>
              </svg>
              <h6>{{ $t('privacypolicy.privacypolicyChanges') }}</h6>
            </div>
            <p class="policy_detail">
             {{ $t('privacypolicy.privacypolicyprovide') }}
            </p>
            <p class="policy_detail">
                 {{ $t('privacypolicy.privacypolicylast') }}
            </p>
            <!-- <p class="policy_detail">
               {{ $t('privacypolicy.privacypolicycredit') }}  <a href="#" class="posthog">  {{ $t('privacypolicy.privacypolicyPosthog') }} </a>&<a href="#" class="posthog"> {{ $t('privacypolicy.privacypolicyGitlab') }} </a> {{ $t('privacypolicy.privacypolicyinitial') }}
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Footer :selectLanguage="selectLanguage"/></template>
<script>
import Footer from "@/views/chatwoot/Footer.vue";
import Header from "@/views/chatwoot/Header.vue";

export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      selectLanguage: 'en',
    };
  },
  methods: {
    handleLanguageSelected (language) {
      this.selectLanguage = language;
    },
  },
  created() {
    const localStorageItem = localStorage.getItem('language');

    if (localStorageItem) {

      this.selectLanguage = localStorageItem;
    }
  }
};
</script>
