<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("doc.product.Channels") }}</p>
              <img
                src="../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <p>{{ $t("doc.whatsAppCloud.websiteLiveChat") }}</p>
              <img
                src="../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <a href="#">{{ $t("doc.whatsAppCloud.whatsAppCloud") }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.whatsAppCloud.OnThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img src="/img/up_arrow_side.7f2fb856.svg" alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Prerequisites-link',
                            }"
                          >
                            <a @click="scrollTo('#Prerequisites')">{{
                              $t("doc.whatsAppCloud.Prerequisites")
                            }}</a>
                            <ul>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#features-info-link',
                                }"
                              >
                                <a @click="scrollTo('#features-info')">{{
                                  $t("doc.whatsAppCloud.features-info")
                                }}</a>
                              </li>

                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#compatibility-info-link',
                                }"
                              >
                                <a @click="scrollTo('#compatibility-info')"
                                  >{{ $t("doc.whatsAppCloud.compatibility-info") }}
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem ===
                                '#Setup-WhatsApp-Inbox-in-Chatwoot-link',
                            }"
                          >
                            <a @click="scrollTo('#Setup-WhatsApp-Inbox-in-Chatwoot')">{{
                              $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoot")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Enable-the-webhook-link',
                            }"
                          >
                            <a @click="scrollTo('#Enable-the-webhook')">{{
                              $t("doc.whatsAppCloud.Enable-the-webhook")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item: rightPanelMenuItem === '#FAQ-link',
                            }"
                          >
                            <a @click="scrollTo('#FAQ')"
                              >{{ $t("doc.whatsAppCloud.FAQ") }}
                            </a>
                            <ul>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#configure-Facebook-link',
                                }"
                              >
                                <a @click="scrollTo('#configure-Facebook')">{{
                                  $t("doc.whatsAppCloud.configure-Facebook")
                                }}</a>
                              </li>
                            </ul>
                          </li>

                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Additional-Inbox-settings-link',
                            }"
                          >
                            <a @click="scrollTo('#Additional-Inbox-settings')">{{
                              $t("doc.whatsAppCloud.Additional-Inbox-settings")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Supported-media-types-link',
                            }"
                          >
                            <a @click="scrollTo('#Supported-media-types')">{{
                              $t("doc.whatsAppCloud.Supported-media-types")
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Introduction-details">
              <h1>{{ $t("doc.whatsAppCloud.Introduction-details.title") }}</h1>
              <p>{{ $t("doc.whatsAppCloud.Introduction-details.content") }}</p>
            </div>
            <div class="Introduction-details" id="Prerequisites">
              <h1>{{ $t("doc.whatsAppCloud.Prerequisites") }}</h1>
            </div>

            <section id="features-info">
              <div class="channels-info">
                <div class="version-detail">
                  <h4>{{ $t("doc.whatsAppCloud.features-info") }}</h4>
                  <p>
                    {{ $t("doc.whatsAppCloud.features-info-content1")
                    }}<span class="click-inbox">{{
                      $t("doc.whatsAppCloud.features-info-content2")
                    }}</span
                    >{{ $t("doc.whatsAppCloud.features-info-content3") }}
                    <a href="#">{{ $t("doc.whatsAppCloud.features-info-content4") }}</a
                    >{{ $t("doc.whatsAppCloud.features-info-content5")
                    }}<span class="click-inbox">{{
                      $t("doc.whatsAppCloud.features-info-content6")
                    }}</span>
                    {{ $t("doc.whatsAppCloud.features-info-content7") }}
                    {{ $t("doc.whatsAppCloud.features-info-content8") }}
                  </p>
                  <p>
                    {{ $t("doc.whatsAppCloud.features-info-content9") }}
                    <span class="click-inbox"
                      >{{ $t("doc.whatsAppCloud.features-info-content10") }} </span
                    >{{ $t("doc.whatsAppCloud.features-info-content11") }}
                    <span class="click-inbox"
                      >{{ $t("doc.whatsAppCloud.features-info-content12") }}
                    </span>
                    {{ $t("doc.whatsAppCloud.features-info-content13")
                    }}<span class="click-inbox">{{
                      $t("doc.whatsAppCloud.features-info-content14")
                    }}</span>
                    {{ $t("doc.whatsAppCloud.features-info-content15") }}
                  </p>
                </div>
                <div class="whatssimg">
                  <img src="../../../../../assets/images/whatsapp.png" />
                </div>
              </div>
            </section>
            <section id="compatibility-info">
              <div class="version-detail">
                <h4>{{ $t("doc.whatsAppCloud.compatibility-info") }}</h4>
              </div>
              <div class="version-detail">
                <p>
                  <a href="#">{{
                    $t("doc.whatsAppCloud.compatibility-infos.content1")
                  }}</a>
                  {{ $t("doc.whatsAppCloud.compatibility-infos.content2") }}
                </p>
                <p>{{ $t("doc.whatsAppCloud.compatibility-infos.content3") }}</p>
                <p>
                  <span class="click-inbox">{{
                    $t("doc.whatsAppCloud.compatibility-infos.content4")
                  }}</span>
                  {{ $t("doc.whatsAppCloud.compatibility-infos.content5") }}
                  <span class="click-inbox">{{
                    $t("doc.whatsAppCloud.compatibility-infos.content6")
                  }}</span
                  >{{ $t("doc.whatsAppCloud.compatibility-infos.content7") }}
                </p>
                <p>
                  <b>{{ $t("doc.whatsAppCloud.compatibility-infos.step1") }}</b
                  >{{ $t("doc.whatsAppCloud.compatibility-infos.step1Content") }}
                </p>
                <p>
                  <b>{{ $t("doc.whatsAppCloud.compatibility-infos.step2") }}</b
                  >{{ $t("doc.whatsAppCloud.compatibility-infos.step2Content") }}
                </p>
                <div class="whatssimg bussiness">
                  <img src="../../../../../assets/images/meta-business-settings.png" />
                </div>
                <p>
                  <b>{{ $t("doc.whatsAppCloud.compatibility-infos.step3") }}</b
                  >{{ $t("doc.whatsAppCloud.compatibility-infos.step3Content") }}
                </p>
                <div class="whatssimg bussiness">
                  <img src="../../../../../assets/images/meta.png" />
                </div>
                <p>
                  {{ $t("doc.whatsAppCloud.compatibility-infos.referVideo") }}
                  <a href="#">{{
                    $t("doc.whatsAppCloud.compatibility-infos.referVideo2")
                  }}</a>
                  {{ $t("doc.whatsAppCloud.compatibility-infos.referVideo3") }}
                </p>
              </div>
              <div class="version-detail" id="Setup-WhatsApp-Inbox-in-Chatwoot">
                <p>
                  <b>{{
                    $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoots.step1")
                  }}</b>
                  {{
                    $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoots.step1Content")
                  }}
                </p>
              </div>

              <div class="whatssimg">
                <img src="../../../../../assets/images/adding-inbox-in-chatwoot.png" />
              </div>
              <div class="version-detail">
                <p>
                  <b>{{
                    $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoots.step2")
                  }}</b>
                  {{
                    $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoots.step2Content")
                  }}
                </p>
              </div>
              <div class="whatssimg">
                <img src="../../../../../assets/images/21.png" />
              </div>
              <div class="version-detail">
                <p>
                  <b>{{
                    $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoots.step3")
                  }}</b>
                  {{
                    $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoots.step3Content")
                  }}
                </p>
              </div>
              <div class="whatssimg">
                <img src="../../../../../assets/images/22.png" />
              </div>
            </section>

            <div class="table-details padding-leftone">
              <h4>{{ $t("doc.whatsAppCloud.table-details.title") }}</h4>
              <table class="table table-bordered widthfull">
                <thead>
                  <tr>
                    <th>{{ $t("doc.whatsAppCloud.table-details.thead.values") }}</th>
                    <th>{{ $t("doc.whatsAppCloud.table-details.thead.description") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t("doc.whatsAppCloud.table-details.tbody.name") }}</td>
                    <td>
                      {{ $t("doc.whatsAppCloud.table-details.tbody.nameDescription") }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.whatsAppCloud.table-details.tbody.phoneNumber") }}</td>
                    <td>
                      {{
                        $t("doc.whatsAppCloud.table-details.tbody.phoneNumberDescription")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("doc.whatsAppCloud.table-details.tbody.phoneNumberId") }}
                    </td>
                    <td>
                      {{
                        $t(
                          "doc.whatsAppCloud.table-details.tbody.phoneNumberIdDescription"
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("doc.whatsAppCloud.table-details.tbody.businessAccountId") }}
                    </td>
                    <td>
                      {{
                        $t(
                          "doc.whatsAppCloud.table-details.tbody.businessAccountIdDescription"
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.whatsAppCloud.table-details.tbody.apiKey") }}</td>
                    <td>
                      {{ $t("doc.whatsAppCloud.table-details.tbody.apiKeyDescription") }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("doc.whatsAppCloud.table-details.tbody.webhookVerifyToken") }}
                    </td>
                    <td>
                      {{
                        $t(
                          "doc.whatsAppCloud.table-details.tbody.webhookVerifyTokenDescription"
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-left">
                {{ $t("doc.whatsAppCloud.table-details.tbody.webhookCallbackURL") }}
              </p>
            </div>

            <section id="Enable-the-webhook">
              <div class="version-detail bussiness">
                <h4>{{ $t("doc.whatsAppCloud.Enable-the-webhook") }}</h4>
              </div>
              <div class="version-detail">
                <p>{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content1") }}</p>
                <p>
                  {{ $t("doc.whatsAppCloud.Enable-the-webhooks.content2")
                  }}<span class="click-inbox"
                    >{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content3") }}
                  </span>
                  {{ $t("doc.whatsAppCloud.Enable-the-webhooks.content4") }}
                  <span class="click-inbox"
                    >{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content5") }} </span
                  >{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content6") }}
                  <span class="click-inbox"
                    >{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content7") }}
                  </span>
                </p>
                <p>
                  {{ $t("doc.whatsAppCloud.Enable-the-webhooks.content8") }}
                  <b>{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content9") }}</b>
                  {{ $t("doc.whatsAppCloud.Enable-the-webhooks.content10") }}
                </p>
                <p>{{ $t("doc.whatsAppCloud.Enable-the-webhooks.content11") }}</p>
                <div class="whatssimg" style="padding-left: 0">
                  <img src="../../../../../assets/images/enabling-webhook.png" />
                </div>
              </div>
            </section>

            <section id="FAQ">
              <div class="version-detail">
                <h4>{{ $t("doc.whatsAppCloud.FAQ") }}</h4>
              </div>
              <div class="version-detail" id="configure-Facebook">
                <h4>{{ $t("doc.whatsAppCloud.FAQs.configure-Facebook.title") }}</h4>
              </div>
              <div class="version-detail">
                <p>{{ $t("doc.whatsAppCloud.FAQs.configure-Facebook.content") }}</p>
              </div>
            </section>
            <section id="compatibility-info">
              <div class="version-detail" id="Additional-Inbox-settings">
                <h4>{{ $t("doc.whatsAppCloud.Additional-Inbox-settings") }}</h4>
              </div>
              <div class="version-detail">
                <p>{{ $t("doc.whatsAppCloud.Additional-Inbox-settingss.content1") }}</p>
                <p>
                  {{ $t("doc.whatsAppCloud.Additional-Inbox-settingss.content2") }}
                  <a href="#">{{
                    $t("doc.whatsAppCloud.Additional-Inbox-settingss.content3")
                  }}</a>
                  {{ $t("doc.whatsAppCloud.Additional-Inbox-settingss.content4") }}
                </p>
              </div>
            </section>

            <section id="Supported-media-types">
              <div class="version-detail">
                <h4>{{ $t("doc.whatsAppCloud.Supported-media-types") }}</h4>
              </div>
              <div class="version-detail">
                <p>{{ $t("doc.whatsAppCloud.Additional-Inbox-settingss.content1") }}</p>
                <p>
                  {{ $t("doc.whatsAppCloud.Additional-Inbox-settingss.content2") }}
                  <a href="#">{{
                    $t("doc.whatsAppCloud.Additional-Inbox-settingss.content3")
                  }}</a>
                  {{ $t("doc.whatsAppCloud.Additional-Inbox-settingss.content4") }}
                </p>
              </div>

              <div class="table-details padding-leftone">
                <table class="table table-bordered widthfull">
                  <thead>
                    <tr>
                      <th>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.thead.supportedExtensions"
                          )
                        }}
                      </th>
                      <th>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.thead.endUser"
                          )
                        }}
                      </th>
                      <th>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.thead.agentDashboard"
                          )
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                          $t("doc.whatsAppCloud.Supported-media-typess.table.tbody.audio")
                        }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/aac.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/aac.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/aac.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/mp4.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/mp4.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/mp4.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/mpeg.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/mpeg.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/mpeg.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/amr.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/amr.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/amr.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/ogg.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/ogg.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.audio/ogg.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.document"
                          )
                        }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.text/plain.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.text/plain.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.text/plain.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/pdf.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/pdf.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/pdf.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/msword.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/msword.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/msword.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/ms-excel.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/ms-excel.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/ms-excel.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/wordprocessingml/document.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/wordprocessingml/document.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/wordprocessingml/document.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/presentationml/presentation.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/presentationml/presentation.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/presentationml/presentation.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/spreadsheetml/sheet.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/spreadsheetml/sheet.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.application/vnd/openxmlformats-officedocument/spreadsheetml/sheet.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{
                          $t("doc.whatsAppCloud.Supported-media-typess.table.tbody.image")
                        }}</b>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/jpeg.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/jpeg.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/jpeg.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/png.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/png.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/png.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>{{
                          $t("doc.whatsAppCloud.Supported-media-typess.table.tbody.video")
                        }}</b>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.video/mp4.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.video/mp4.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.video/mp4.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.video/3gp.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.video/3gp.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.video/3gp.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.sticker"
                          )
                        }}</b>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/webp.title"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/webp.endUser"
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $t(
                            "doc.whatsAppCloud.Supported-media-typess.table.tbody.image/webp.agentDashboard"
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <div class="sec-footer">
              <div class="doc-page-footer">
                <p>
                  {{ $t("doc.whatsAppCloud.footer.helpText") }}
                  <a href="#"> {{ $t("doc.whatsAppCloud.footer.githubDiscussions") }}</a>
                  {{ $t("doc.whatsAppCloud.footer.or") }}
                  <a href="#">{{ $t("doc.whatsAppCloud.footer.discordChannel") }}</a
                  >.
                </p>
              </div>

              <div class="edit-update-detail">
                <!-- <div class="edit-pen">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>
                                    <a href="#">{{ $t('doc.whatsAppCloud.footer.editPage') }}</a>
                                </div> -->
                <p>
                  {{ $t("doc.whatsAppCloud.footer.lastUpdatedOn") }}
                  <span class="dark">4/28/2023 </span>
                  {{ $t("doc.whatsAppCloud.footer.by") }}
                  <span class="dark">{{ $t("doc.whatsAppCloud.footer.author") }}</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p class="preview">{{ $t("doc.whatsAppCloud.navigation.previous") }}</p>
                  <router-link
                    to="/docs/product/channels/twitter"
                    class="introduction-account"
                  >
                    <span>«</span> {{ $t("doc.whatsAppCloud.navigation.twitter") }}Twitter
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("doc.whatsAppCloud.navigation.next") }}</p>
                  <router-link to="/docs/product/channels/whatsapp/twilio">
                    {{ $t("doc.whatsAppCloud.navigation.twilio") }} <span>»</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4">
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <ul id="side_section_info">
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#Prerequisites-link',
                  }"
                >
                  <a id="#Prerequisites-link" @click="scrollTo('#Prerequisites')"
                    >Prerequisites</a
                  >
                  <ul>
                    <li
                      :class="{
                        active_menu_item: rightPanelMenuItem === '#features-info-link',
                      }"
                    >
                      <a id="#features-info-link" @click="scrollTo('#features-info')">{{
                        $t("doc.whatsAppCloud.features-info")
                      }}</a>
                    </li>

                    <li
                      :class="{
                        active_menu_item:
                          rightPanelMenuItem === '#compatibility-info-link',
                      }"
                    >
                      <a
                        id="#compatibility-info-link"
                        @click="scrollTo('#compatibility-info')"
                        >{{ $t("doc.whatsAppCloud.compatibility-info") }}
                      </a>
                    </li>
                  </ul>
                </li>

                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#Setup-WhatsApp-Inbox-in-Chatwoot-link',
                  }"
                >
                  <a
                    id="#Setup-WhatsApp-Inbox-in-Chatwoot-link"
                    @click="scrollTo('#Setup-WhatsApp-Inbox-in-Chatwoot')"
                    >{{ $t("doc.whatsAppCloud.Setup-WhatsApp-Inbox-in-Chatwoot") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#Enable-the-webhook-link',
                  }"
                >
                  <a
                    id="#Enable-the-webhook-link"
                    @click="scrollTo('#Enable-the-webhook')"
                    >{{ $t("doc.whatsAppCloud.Enable-the-webhook") }}</a
                  >
                </li>
                <li :class="{ active_menu_item: rightPanelMenuItem === '#FAQ-link' }">
                  <a id="#FAQ-link" @click="scrollTo('#FAQ')">FAQ </a>
                  <ul>
                    <li
                      :class="{
                        active_menu_item:
                          rightPanelMenuItem === '#configure-Facebook-link',
                      }"
                    >
                      <a
                        id="#configure-Facebook-link"
                        @click="scrollTo('#configure-Facebook')"
                        >{{ $t("doc.whatsAppCloud.configure-Facebook") }}</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#Additional-Inbox-settings-link',
                  }"
                >
                  <a
                    id="#Additional-Inbox-settings-link"
                    @click="scrollTo('#Additional-Inbox-settings')"
                    >{{ $t("doc.whatsAppCloud.Additional-Inbox-settings") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#Supported-media-types-link',
                  }"
                >
                  <a
                    id="#Supported-media-types-link"
                    @click="scrollTo('#Supported-media-types')"
                    >{{ $t("doc.whatsAppCloud.Supported-media-types") }}</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainContent",
  data() {
    return {
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    isActiveSection(index) {
      return index === this.activeSectionIndex;
    },
  },
};
</script>

6500
