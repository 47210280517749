<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Channels') }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('doc.twitter.title') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.setupInstruction.onThisPage') }} </p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#additional-inbox-settings-link' }">
                                                        <a @click="scrollTo('#additional-inbox-settings')">{{ $t('doc.twitter.additionalInboxSettings') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#settings-tab-link' }">
                                                        <a @click="scrollTo('#settings-tab')">{{ $t('doc.twitter.settingsTab') }}</a></li>

                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#collaborators-tab-link' }">
                                                        <a @click="scrollTo('#collaborators-tab')">{{ $t('doc.twitter.collaboratorsTab') }}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#business-hours-tab-link' }">
                                                        <a @click="scrollTo('#business-hours-tab')">{{ $t('doc.twitter.businessHoursTab') }}</a></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('doc.twitter.setupTitle') }}</h1>
                            <p>{{ $t('doc.twitter.selfHostedNote') }}&nbsp;<a href="#">{{ $t('doc.twitter.guide') }}</a>.
                            </p>
                            <p>{{ $t('doc.twitter.cloudVersionNote') }}</p>
                        </div>

                        <div class="channels-info code-info">
                            <div class="channels-title">


                                <p><strong>{{ $t('doc.twitter.step1') }}</strong>{{ $t('doc.twitter.step1Content') }}</p>
                                <p><img alt="inbox_create" src="../../../../assets/images/add-inbox-twitter.webp"
                                        class="max-img"></p>
                                <p><strong>{{ $t('doc.twitter.step2') }}</strong>{{ $t('doc.twitter.step2Content') }}</p>
                                <p><img alt="list_of_channels" src="../../../../assets/images/list_of_channels-twitter.webp"
                                        class="max-img">
                                </p>
                                <p><strong>{{ $t('doc.twitter.step3') }}</strong>{{ $t('doc.twitter.step3Content') }}</p>
                                <p><img alt="sign_in_with_twitter"
                                        src="../../../../assets/images/twitter-signin-in-chatwoot.webp" class="max-img"></p>
                                <p><strong>{{ $t('doc.twitter.step4') }}</strong>{{ $t('doc.twitter.step4Content') }}</p>
                                <p><img alt="authorize_twitter"
                                        src="../../../../assets/images/auth-chatwoot-for-twitter.webp" class="max-img">
                                </p>
                                <p><strong>{{ $t('doc.twitter.step5') }}</strong>{{ $t('doc.twitter.step5Content') }}</p>
                                <p><img alt="add_agents" src="../../../../assets/images/add-agents-to-twitter.webp"
                                        class="max-img"></p>
                                <p>{{ $t('doc.twitter.successMessage') }}</p>
                                <p><img alt="finish_inbox" src="../../../../assets/images/twitter-inbox-is-ready.webp"
                                        class="max-img"></p>
                                <h2 class="anchorarticlenew" id="additional-inbox-settings">
                                    {{ $t('doc.twitter.additionalSettingsTitle') }}<a class="hash-link" href="#additional-inbox-settings"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('doc.twitter.intro') }}</p>
                                <p><img alt="twitter settings in chatwoot"
                                        src="../../../../assets/images/twitter-settings-page.webp" class="max-img"></p>
                                <p>{{ $t('doc.twitter.basicChanges') }} </p>
                                <h3 class="anchor anchorWithStickyNavbar_mojV" id="settings-tab">{{ $t('doc.twitter.settingsTab') }}<a
                                        class="hash-link" href="#settings-tab"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p><strong>{{ $t('doc.twitter.enableGreeting') }}</strong>&nbsp;{{ $t('doc.twitter.enableGreeting2') }}</p>
                                <p><strong>{{ $t('doc.twitter.enableCSAT') }}</strong>{{ $t('doc.twitter.enableCSAT') }}</p>
                                <h3 class="anchor anchorWithStickyNavbar_mojV" id="collaborators-tab">
                                    {{ $t('doc.twitter.title') }}<a class="hash-link" href="#collaborators-tab"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('doc.twitter.clickOn') }}&nbsp;<code>{{ $t('doc.twitter.collaboratorsTab') }}</code>&nbsp;{{ $t('doc.twitter.CollaboratorsTabs.addDeleteAgents') }}</p>
                                <p>{{ $t('doc.twitter.CollaboratorsTabs.enableAutomaticAssignment') }}</p>
                                <p><img alt="twitter team settings in chatwoot"
                                        src="../../../../assets/images/collaborators-setting-twitter.webp" class="max-img">
                                </p>
                                <p>{{ $t('doc.twitter.clickOn') }}&nbsp;<code>{{ $t('doc.twitter.update') }}</code>&nbsp;{{ $t('doc.twitter.saveChanges') }}</p>
                                <h3 class="anchorarticlenew" id="business-hours-tab">{{ $t('doc.twitter.businessHoursTab') }}<a class="hash-link" href="#business-hours-tab"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('doc.twitter.collaboratorsNote') }}&nbsp;<code>{{ $t('doc.twitter.businessHoursNote2') }}</code>&nbsp;{{ $t('doc.twitter.businessHoursNote3') }}
                                </p>
                                <p><img alt="twitter business hours settings in chatwoot"
                                        src="../../../../assets/images/business-hrs-twitter.webp    " class="max-img"></p>
                                <p>{{ $t('doc.twitter.businessHoursNote4') }}</p>
                                <p>{{ $t('doc.twitter.businessHoursNote5') }}</p>
                                <p>{{ $t('doc.twitter.businessHoursNote6') }}</p>
                                <p><img alt="twitter business hours advanced settings in chatwoot"
                                        src="../../../../assets/images/business-hrs-adv-twitter.png" class="max-img">
                                </p>
                                <p>{{ $t('doc.twitter.CollaboratorsTabs.clickOnThe') }}&nbsp;{{ $t('doc.twitter.CollaboratorsTabs.clickOnThe') }}&nbsp;{{ $t('doc.twitter.button') }}</p>


                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.product.EditThisPage') }} </a>
                            </div> -->
                            <p>{{ $t('doc.twitter.lastUpdatedOn') }} <span class="dark">  {{ $t('doc.twitter.date') }} </span>{{ $t('doc.twitter.LastUpdated') }} <span class="dark">{{ $t('doc.twitter.HrichaShandily') }}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.twitter.navigation.previous') }} </p>
                                    <router-link to="/docs/product/channels/instagram" class="introduction-account">
                                        <span>«</span> {{ $t('doc.twitter.navigation.instagram') }}
                                    </router-link>

                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.twitter.navigation.next') }}</p>
                                    <router-link to="/docs/product/channels/whatsapp/whatsapp-cloud">
                                        {{ $t('doc.twitter.navigation.whatsAppCloud') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#additional-inbox-settings-link' }">
                                    <a id="#additional-inbox-settings-link" @click="scrollTo('#additional-inbox-settings')">{{ $t('doc.twitter.additionalInboxSettings') }}</a>
                                </li>
                                    <ul>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#settings-tab-link' }"><a
                                                id="#settings-tab-link" @click="scrollTo('#settings-tab')">{{ $t('doc.twitter.settingsTab') }}</a></li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#collaborators-tab-link' }"><a
                                                id="#collaborators-tab-link" @click="scrollTo('#collaborators-tab')">{{ $t('doc.twitter.collaboratorsTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#business-hours-tab-link' }"><a
                                                id="#business-hours-tab-link" @click="scrollTo('#business-hours-tab')">{{ $t('doc.twitter.businessHoursTab') }}</a></li>
                                    </ul>

                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'twitter',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {

        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
