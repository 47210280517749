<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Others') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.product.FAQ') }}</a>
                        </div>

                        <div class="Introduction-details">
                            <div class="faq_wrp pt-0">
                                <h1>{{ $t('FAQ.frequently_question') }}</h1>
                                <p>{{ $t('FAQ.guide_collect_data') }}</p>
                                <h5>{{ $t('FAQ.add_agent_data') }}</h5>
                                <p>{{ $t('FAQ.confirm_data') }}</p>
                                <h5>{{ $t('FAQ.agent_data') }}</h5>
                                <p>{{ $t('FAQ.nickname_data') }}</p>
                            </div>
                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.applications.editPage') }}</a>
                            </div> -->
                            <p> {{ $t('doc.applications.editPage') }} <span class="dark">{{ $t('FAQ.date') }} </span> {{ $t('doc.product.By') }} <span class="dark">{{ $t('doc.product.Author') }}</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.SDKSetup.navigation.previous') }}</p>
                                    <router-link to="/docs/product/mobile-app/ios">
                                 <span>«</span>{{ $t('doc.product.iOS') }}
                                    </router-link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>