<template>
  <section class="channels-menu-detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8">
          <div class="WebflowIntegration-page">
            <div class="getting-details">
              <p>{{ $t("doc.channels") }}</p>
              <img
                src="../../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <p>{{ $t("doc.liveChat") }}</p>
              <img
                src="../../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <p>{{ $t("doc.setupInstruction.setupInstructionsTitle") }}</p>
              <img
                src="../../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <a href="#">{{
                $t("doc.setupInstruction.webflowIntegration.webflowIntegrationTitle")
              }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.setupInstruction.onThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img
                          src="../../../../../../assets/images/up_arrow_side.svg"
                          alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#create-a-website-inbox-link',
                            }"
                          >
                            <a @click="scrollTo('#create-a-website-inbox')">{{
                              $t("doc.setupInstruction.webflowIntegration.step1")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#install-the-generated-link',
                            }"
                          >
                            <a @click="scrollTo('#install-the-generated')">{{
                              $t("doc.setupInstruction.webflowIntegration.step2.title")
                            }}</a>
                            <ul>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#Go-to-website-link',
                                }"
                              >
                                <a @click="scrollTo('#Go-to-website')">{{
                                  $t(
                                    "doc.setupInstruction.webflowIntegration.step2.substeps.goToWebsiteSettings"
                                  )
                                }}</a>
                              </li>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#paste-the-flow-link',
                                }"
                              >
                                <a @click="scrollTo('#paste-the-flow')">{{
                                  $t(
                                    "doc.setupInstruction.webflowIntegration.step2.substeps.pasteTheScript"
                                  )
                                }}</a>
                              </li>
                            </ul>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#publish-the-changes-link',
                            }"
                          >
                            <a @click="scrollTo('#publish-the-changes')">{{
                              $t("doc.setupInstruction.webflowIntegration.step3")
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details">
              <h1>
                {{ $t("doc.setupInstruction.webflowIntegration.introduction.title") }}
              </h1>
              <p>
                {{
                  $t("doc.setupInstruction.webflowIntegration.introduction.description")
                }}
              </p>
            </div>
            <div class="version-detail webflow" id="create-a-website-inbox">
              <h4>{{ $t("doc.setupInstruction.webflowIntegration.step1") }}</h4>
              <p>
                {{ $t("doc.setupInstruction.webflowIntegration.referTo") }}
                <a href="#"
                  >{{ $t("doc.setupInstruction.webflowIntegration.websiteChannel") }} </a
                >{{ $t("doc.setupInstruction.webflowIntegration.document") }}
              </p>
            </div>
            <div class="version-detail webflow" id="install-the-generated">
              <h4>{{ $t("doc.setupInstruction.webflowIntegration.step2.title") }}</h4>
            </div>
            <div class="version-detail" id="Go-to-website">
              <h4>
                {{
                  $t(
                    "doc.setupInstruction.webflowIntegration.step2.substeps.goToWebsiteSettings"
                  )
                }}
              </h4>
              <p>
                {{
                  $t(
                    "doc.setupInstruction.webflowIntegration.step2.substeps.selectSetting"
                  )
                }}
              </p>
            </div>
            <div class="Go-to-website-settings-img">
              <img
                src="../../../../../../assets/images/select-website.webp"
                alt="logo21"
              />
            </div>
            <div class="version-detail" id="paste-the-flow">
              <h4>
                {{
                  $t(
                    "doc.setupInstruction.webflowIntegration.step2.substeps.pasteTheScript"
                  )
                }}
              </h4>
              <p>
                {{ $t("doc.setupInstruction.webflowIntegration.goTo") }}
                <span class="step">{{
                  $t("doc.setupInstruction.webflowIntegration.customCode")
                }}</span>
                ->
                <span class="step">{{
                  $t("doc.setupInstruction.webflowIntegration.footerCode")
                }}</span>
              </p>
              <p>{{ $t("doc.setupInstruction.webflowIntegration.codeSection") }}</p>
            </div>
            <div class="Go-to-website-settings-img">
              <img
                src="../../../../../../assets/images/custom-html-css.jpg"
                alt="logo21"
              />
            </div>
            <div class="version-detail webflow" id="publish-the-changes">
              <h4>{{ $t("doc.setupInstruction.webflowIntegration.step3") }}</h4>
              <p>{{ $t("doc.setupInstruction.webflowIntegration.step3") }}</p>
            </div>
            <div class="Go-to-website-settings-img">
              <img
                src="../../../../../../assets/images/publish-the-website.png   "
                alt="logo21"
              />
            </div>
            <div class="update-profile-check">
              <p>
                <span class="step">{{
                  $t("doc.setupInstruction.webflowIntegration.voila")
                }}</span
                >{{ $t("doc.setupInstruction.webflowIntegration.successMessage") }}
              </p>
            </div>
            <div class="Go-to-website-settings-img">
              <img src="../../../../../../assets/images/20.jpg " alt="logo21" />
            </div>
            <div class="edit-update-detail">
              <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.setupInstruction.webflowIntegration.EditThisPage') }}</a>
                            </div> -->
              <p>
                {{ $t("doc.setupInstruction.webflowIntegration.lastUpdatedOn") }}
                <span class="dark">10/17/2022 </span
                >{{ $t("doc.setupInstruction.webflowIntegration.updatedBy") }}
                <span class="dark">
                  {{ $t("doc.setupInstruction.webflowIntegration.HrichaShandily") }}</span
                >
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p class="preview">
                    {{
                      $t("doc.setupInstruction.webflowIntegration.navigation.previous")
                    }}
                  </p>
                  <router-link
                    to="/docs/product/channels/live-chat/conversation-continuity"
                    class="introduction-account"
                  >
                    <span>«</span>
                    {{
                      $t(
                        "doc.setupInstruction.webflowIntegration.navigation.conversationContinuity"
                      )
                    }}
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>
                    {{ $t("doc.setupInstruction.webflowIntegration.navigation.next") }}
                  </p>
                  <router-link
                    to="/docs/product/channels/live-chat/setup-instruction/docusaurus"
                  >
                    {{
                      $t("doc.setupInstruction.webflowIntegration.navigation.docusaurus")
                    }}<span>»</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4">
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <ul id="side_section_info">
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#create-a-website-inbox-link',
                  }"
                >
                  <a
                    id="#create-a-website-inbox-link"
                    @click="scrollTo('#create-a-website-inbox')"
                    >{{ $t("doc.setupInstruction.webflowIntegration.step1") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#install-the-generated-link',
                  }"
                >
                  <a
                    :id="'#install-the-generated-link'"
                    @click="scrollTo('#install-the-generated')"
                    >{{ $t("doc.setupInstruction.webflowIntegration.step2.title") }}</a
                  >
                </li>
                <ul>
                  <li
                    :class="{
                      active_menu_item: rightPanelMenuItem === '#Go-to-website-link',
                    }"
                  >
                    <a id="#Go-to-website-link" @click="scrollTo('#Go-to-website')">{{
                      $t(
                        "doc.setupInstruction.webflowIntegration.step2.substeps.goToWebsiteSettings"
                      )
                    }}</a>
                  </li>
                  <li
                    :class="{
                      active_menu_item: rightPanelMenuItem === '#paste-the-flow-link',
                    }"
                  >
                    <a id="#paste-the-flow-link" @click="scrollTo('#paste-the-flow')">{{
                      $t(
                        "doc.setupInstruction.webflowIntegration.step2.substeps.pasteTheScript"
                      )
                    }}</a>
                  </li>
                </ul>

                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#publish-the-changes-link',
                  }"
                >
                  <a
                    id="#publish-the-changes-link"
                    @click="scrollTo('#publish-the-changes')"
                    >{{ $t("doc.setupInstruction.webflowIntegration.step3") }}</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "AddConfigure",
  data() {
    return {
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
    //  this.scrollToTopcustom = this.scrollToTopcustom.bind(this);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    isActiveSection(index) {
      return index === this.activeSectionIndex;
    },
  },
};
</script>
<style>
.version-detail.webflow h4 {
  font-size: 32px;
  line-height: 42px;
}

.Go-to-website-settings-img {
  padding: 0 36px;
  margin-bottom: 60px;
  max-width: 720px;
  width: 100%;
}

.Go-to-website-settings-img img {
  width: 100%;
  height: 100%;
}
</style>
