<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{$t('concepts.reports')}}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#"> {{$t('conversation.conv')}}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{$t('doc.product.OnThisPage')}}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#conversation-link' }">
                                                        <a @click="scrollTo('#conversation')"
                                                            class="link_wrp"> {{$t('conversation.conv')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#incoming-messages-link' }">
                                                        <a @click="scrollTo('#incoming-messages')" class="link_wrp"> {{$t('conversation.incomeMessage')}}</a>
                                                    </li>

                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#outgoing-messages-link' }">
                                                        <a @click="scrollTo('#outgoing-messages')" class="link_wrp">{{$t('conversation.outgnMessages')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#first-response-time-link' }">
                                                        <a @click="scrollTo('#first-response-time')" class="link_wrp">{{$t('conversation.FRT')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#resolution-time-link' }">
                                                        <a @click="scrollTo('#resolution-time')" class="link_wrp">{{$t('conversation.resoTime')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#resolution-time-link' }">
                                                        <a @click="scrollTo('#resolution-time')" class="link_wrp">{{$t('conversation.resoCount')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#resolution-time-link' }">
                                                        <a @click="scrollTo('#download-agent-reports')"
                                                            class="link_wrp">{{$t('conversation.dwnldAgentReport')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#resolution-time-link' }">
                                                        <a @click="scrollTo('#business-hourss')" class="link_wrp">{{$t('conversation.businessHours')}}</a>
                                                    </li>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#trend-link' }">
                                                        <a @click="scrollTo('#trends')" class="link_wrp">{{$t('conversation.trend')}}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left" id="conversation">
                            <h1>{{$t('conversation.conv')}}</h1>
                            <p>{{$t('conversation.convoPara')}}
                            </p>

                        </div>

                        <div class="channels-info code-info">
                            <div class="channels-title">


                                <div><img loading="lazy" src="../../../../../assets/images/reports1.png"
                                        alt="Chatwoot Home Screen" class="max-img"></div>
                                <p>{{$t('conversation.windowPara')}}</p>
                                <div><img loading="lazy" src="../../../../../assets/images/reports2.png"
                                        alt="Chatwoot Report Home Screen" class="max-img"></div>
                                <p>{{$t('conversation.reportgeneration')}}</p>
                                <div><img loading="lazy" src="../../../../../assets/images/reports3.png"
                                        alt="Report Duration selection drop down" class="max-img">
                                </div>
                                <p>{{$t('conversation.reportGrp')}}</p>
                                <table class="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>{{$t('conversation.dateRange')}}</th>
                                            <th>{{$t('conversation.grpby')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{$t('conversation.lastSevenDays')}}</td>
                                            <td>{{$t('conversation.day')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('conversation.last30Days')}}</td>
                                            <td>{{$t('conversation.dayweek')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('conversation.last3months')}}</td>
                                            <td>{{$t('conversation.DWM')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('conversation.last6Months')}}</td>
                                            <td>{{$t('conversation.DWM')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('conversation.lastYear')}}</td>
                                            <td>{{$t('conversation.DWM')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('conversation.customeDataRange')}}</td>
                                            <td>{{$t('conversation.DMWY')}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div><img loading="lazy" src="../../../../../assets/images/report_group_by_filter.png"
                                        width="800" alt="Report Duration selection drop down" class="max-img">
                                </div>
                                <h3 class="anchorarticlenew" id="conversations">
                                    {{$t('conversation.conv')}}<a class="hash-link" href="#conversations"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.convoParagraph')}}
                                </p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports4.png"
                                        width="800" alt="Conversations Report Screen" class="max-img"></div>
                                <h3 class="anchorarticlenew" id="incoming-messages">{{$t('conversation.incomeMessage')}}<a class="hash-link" href="#incoming-messages"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.incomingMessagePara')}}</p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports5.png"
                                        width="800" alt="Incoming Messages Report Screen" class="max-img">
                                </div>
                                <h3 class="anchorarticlenew" id="outgoing-messages">{{$t('conversation.outgnMessages')}}<a class="hash-link" href="#outgoing-messages"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.outgoingmsgpara')}}</p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports6.png"
                                        width="800" alt="Outgoing Messages Report Screen" class="max-img">
                                </div>
                                <h3 class="anchorarticlenew" id="first-response-time">{{$t('conversation.FRT')}}<a class="hash-link" href="#first-response-time"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.FRTPara')}}</p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports7.png"
                                        width="800" alt="First Response Time Report Screen" class="max-img">
                                </div>
                                <h3 class="anchorarticlenew" id="resolution-time">{{$t('conversation.resoTime')}}<a class="hash-link" href="#resolution-time"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.resoTimepara')}}
                                </p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports8.png"
                                        width="800" alt="Resolution Time Report Screen" class="max-img"></div>
                                <h3 class="anchorarticlenew" id="resolution-count">{{$t('conversation.resoCount')}}<a class="hash-link" href="#resolution-count"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.resoCountpara')}}</p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports9.png"
                                        width="800" alt="Resolved Count Report Screen" class="max-img"></div>
                                <h3 class="anchorarticlenew" id="download-agent-reports">
                                    {{$t('conversation.dwnldAgentReport')}}<a class="hash-link" href="#download-agent-reports"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.agentReportPara')}}</p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports10.png"
                                        width="800" alt="Download Report Button " class="max-img"></div>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/reports11.png"
                                        width="800" alt="Downloaded Report in CSV File format " class="max-img">
                                </div>
                                <h3 class="anchorarticlenew" id="business-hours">{{$t('conversation.businessHours')}}<a class="hash-link" href="#business-hours"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.BHpara')}}</p>
                                <p>{{$t('conversation.inboxPara')}}
                                </p>
                                <div align="center"><img loading="lazy"
                                        src="../../../../../assets/images/business_hours_configuration.png" width="800"
                                        alt="Downloaded Report in CSV File format " class="max-img">
                                </div>
                                <p>{{$t('conversation.togglrpara')}}</p>
                                <div align="center"><img loading="lazy"
                                        src="../../../../../assets/images/toggle_business_hours.gif" width="800"
                                        alt="Downloaded Report in CSV File format " class="max-img">
                                </div>
                                <p>{{$t('conversation.businesshrequiredfor')}} <a
                                        href="/docs/user-guide/features/reports/conversation"
                                        target="_blank" rel="noopener noreferrer">{{$t('conversation.conv')}}</a>, <a
                                        href="/docs/user-guide/features/reports/agent" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.agent')}}</a>, <a
                                        href="/docs/user-guide/features/reports/inbox" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.inbox')}}</a>, <a
                                        href="/docs/user-guide/features/reports/label" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.label')}}</a> {{$t('conversation.and')}} <a
                                        href="/docs/user-guide/features/reports/team" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.team')}}</a> {{$t('concepts.reports')}}.</p>
                                <h3 class="anchorarticlenew" id="trend">{{$t('conversation.trend')}}<a class="hash-link" href="#trend"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('conversation.trendPara1')}}</p>
                                <p>{{$t('conversation.trentPara2')}}</p>
                                <p><code>{{$t('conversation.eqn')}}</code></p>
                                <div align="center"><img loading="lazy" src="../../../../../assets/images/metric_trend.gif"
                                        width="800" alt="Downloaded Report in CSV File format " class="max-img">
                                </div>
                                <p style="margin-top:15px;">{{$t('conversation.trentsupport')}} <a
                                        href="/docs/user-guide/features/reports/conversation"
                                        target="_blank" rel="noopener noreferrer">{{$t('conversation.conv')}}</a>, <a
                                        href="/docs/user-guide/features/reports/agent" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.agent')}}</a>, <a
                                        href="/docs/user-guide/features/reports/inbox" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.inbox')}}</a>, <a
                                        href="/docs/user-guide/features/reports/label" target="_blank"
                                        rel="noopener noreferrer">{{$t('conversation.label')}}</a> {{$t('conversation.and')}} <a
                                        href="/docs/user-guide/features/reports/team" target="_blank"
                                        rel="noopener noreferrer"> {{$t('conversation.team')}}</a> {{$t('concepts.reports')}}.</p>


                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{$t('doc.inboxes.edit_page')}}</a>
                            </div> -->
                            <p>{{$t('doc.inboxes.last_updated_on')}} <span class="dark">{{$t('concepts.date')}}</span>{{$t('doc.inboxes.updated_by')}} <span class="dark"> {{$t('concepts.updatedByname')}}</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{$t('concepts.previousPage')}}</p>


                                    <router-link to="/docs/user-guide/features/reports/overview"
                                        class="introduction-account">
                                        <span>«</span> {{$t('concepts.overview')}}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{$t('concepts.nextPage')}}</p>

                                    <router-link to="/docs/user-guide/features/reports/csat">
                                        {{$t('conversation.CSAT')}}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#conversation-link' }">
                                    <a id="#conversation-link" @click="scrollTo('#conversation')" class="link_wrp"> {{$t('overview.conversations')}}</a>
                                </li>

                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#incoming-messages-link' }">
                                    <a id="#incoming-messages-link" @click="scrollTo('#incoming-messages')" class="link_wrp"> {{$t('conversation.incomeMessage')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#outgoing-messages-link' }">
                                    <a id="#outgoing-messages-link" @click="scrollTo('#outgoing-messages')" class="link_wrp">{{$t('conversation.outgnMessages')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#first-response-time-link' }">
                                    <a id="#first-response-time-link" @click="scrollTo('#first-response-time')" class="link_wrp">{{$t('conversation.FRT')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#resolution-time-link' }">
                                    <a id="#resolution-time-link" @click="scrollTo('#resolution-time')" class="link_wrp">{{$t('conversation.resoTime')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#resolution-count-link' }">
                                    <a id="#resolution-count-link" @click="scrollTo('#resolution-count')" class="link_wrp">{{$t('conversation.resoCount')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#download-agent-reports-link' }">
                                    <a id="#download-agent-reports-link" @click="scrollTo('#download-agent-reports')" class="link_wrp">{{$t('conversation.dwnldAgentReport')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#business-hours-link' }">
                                    <a id="#business-hours-link" @click="scrollTo('#business-hours')" class="link_wrp">{{$t('conversation.businessHours')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#trend-link' }">
                                    <a id="#trend-link" @click="scrollTo('#trend')" class="link_wrp">{{$t('conversation.trend')}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'conversion',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
