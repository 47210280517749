<template>

    <Header @language-selected="handleLanguageSelected"/>
<!-- shared-inbox -->
<section class="shared-inbox">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12 orderr-2">
                <div class="sharedinbox-heading">
                    <h1>{{ $t('sharedInbox.shared_inbox') }} <span> {{ $t('sharedInbox.team') }} </span> </h1>
                    <p>{{ $t('sharedInbox.shared_desc1') }}</p>
                    <a href="https://app.bcoor.com/app/auth/signup" >{{ $t('home.header.Createafreeaccount') }}</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 orderr-1">
                <div class="sharedinbox-img">
                    <img src="../../../assets/images/sharedInbox/one-inbox-for-all.png">
                </div>
            </div>
        </div>
        
    </div>
</section>
<!-- inbox -->
<section class="inbox">
    <div class="container">
        <div class="row aline-center max-w-7xl mx-auto pt-16 pb-12 px-4 py-5 sm:px-6 lg:px-8 xl:px-0">
            <div class="col-lg-6 col-md-6 col-12 ">
                <div class="index-details">
                    <h2>{{ $t('sharedInbox.shared_inbox') }} {{ $t('sharedInbox.team') }}</h2>
                    <p>{{ $t('sharedInbox.shared_desc1') }}</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 ">
                <div class="index-img">
                    <img src="../../../assets/images/sharedInbox/multiple-inboxes.png">
                </div>
            </div>
        </div>
    </div> 
</section>
<!-- Private-sec -->
<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/sharedInbox/collaboration.png">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details private-detail">
                    <h2>{{ $t('headersubmenu.Features.headerprivatenotes') }}  </h2>
                    <p>{{ $t('sharedInbox.shared_desc') }} {{ "@" }}{{ $t('sharedInbox.mentions') }}</p>
                </div>
            </div>
         
        </div>
    </div> 
</section>
<!-- Canned Responses -->
<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">

            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('headersubmenu.Features.headerCannedResponses') }}</h2>
                    <p>{{ $t('sharedInbox.shared_desc2') }}</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/sharedInbox/canned-responses.png">
                </div>
            </div>
           
         
        </div>
    </div> 
</section>
 <Footer :selectLanguage="selectLanguage"/>

</template>
<script>
import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header
  },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
}
</script>

    

    