<template>
    <section class="channels-menu-detail">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="Integrate-Chatwoot-with-Vue.js-pages">
                        <div class="getting-details">
                            <p>{{ $t('doc.channels') }}</p>
                                <img src="../../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.liveChat') }}</p>
                            <img src="../../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.setupInstruction.setupInstructionsTitle') }}</p>
                            <img src="../../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.setupInstruction.WordPress.wordPress') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.setupInstruction.onThisPage') }}</p>
                                            <span class="down_up_icon"><img
                                                    src="../../../../../../assets/images/up_arrow_side.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <div class="tableOfContents-list">
                                            <ul id="side_section_info">
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#download-the-plugin-link' }">
                                                <a @click="scrollTo('#download-the-plugin')">
                                                {{ $t('doc.setupInstruction.WordPress.step1Title') }}
                                                </a>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#upload-the-plugin-link' }">
                                                <a @click="scrollTo('#upload-the-plugin')">
                                                {{ $t('doc.setupInstruction.WordPress.step2Title') }}
                                                </a>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#configure-plugin-link' }">
                                                <a @click="scrollTo('#configure-plugin')">
                                                {{ $t('doc.setupInstruction.WordPress.step3Title') }}
                                                </a>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Verify-that-the-plugin-link' }">
                                                <a @click="scrollTo('#Verify-that-the-plugin')">
                                                {{ $t('doc.setupInstruction.WordPress.step4Title') }}
                                                </a>
                                            </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.setupInstruction.WordPress.introductionTitle') }}</h1>
                            <p><a href="https://wordpress.com/" target="_blank" rel="noopener noreferrer">{{ $t('doc.setupInstruction.WordPress.wordPress') }}</a> {{ $t('doc.setupInstruction.WordPress.introductionText') }}</p>
                            <p>
                                {{ $t('doc.setupInstruction.WordPress.introductionText1') }}
                            </p>
                        </div>

                        <div class="version-detail webflow" id="download-the-plugin">
                            <h4>{{ $t('doc.setupInstruction.WordPress.step1Title') }}</h4>
                            <p>
                                {{ $t('doc.setupInstruction.WordPress.step1Text') }} <a
                                    href="#" target="_blank"
                                    rel="noopener noreferrer">{{ $t('doc.setupInstruction.WordPress.gitHub') }}</a> {{ $t('doc.setupInstruction.WordPress.step1Text') }}
                            </p>

                            <div class="finsh-img">
                                <img src="../../../../../../assets/images/wordPress-plugin.png" alt="logo21">
                            </div>
                        </div>

                        <div class="version-detail webflow" id="upload-the-plugin">
                            <h4>{{ $t('doc.setupInstruction.WordPress.step2Title') }}</h4>
                            <p>{{ $t('doc.setupInstruction.WordPress.step2Text') }}</p>

                            <div class="finsh-img">
                                <img src="../../../../../../assets/images/add-plugin.png" alt="logo21">
                            </div>

                            <p>
                                {{ $t('doc.setupInstruction.WordPress.step2content') }}
                            </p>

                            <div class="finsh-img">
                                <img src="../../../../../../assets/images/wordpress-chatwoot.png" alt="logo21">
                            </div>

                            <p>
                                {{ $t('doc.setupInstruction.WordPress.step2content2') }}                            </p>

                            <div class="finsh-img">
                                <img src="../../../../../../assets/images/activate-the-plugin.png" alt="logo21">
                            </div>

                            <p>
                                {{ $t('doc.setupInstruction.WordPress.step2content3') }}                            </p>

                            <div class="finsh-img">
                                <img src="../../../../../../assets/images/chatwoot-plugin.webp" alt="logo21">
                            </div>
                        </div>
                        <div class="version-detail webflow" id="configure-plugin">
                            <h4>{{ $t('doc.setupInstruction.WordPress.step2content3') }}</h4>
                            <p>{{ $t('doc.setupInstruction.WordPress.step2content3') }}</p>

                            <div class="setting_wrp">
                                <img src="../../../../../../assets/images/chatwoot-settings.webp" alt="logo21">
                            </div>

                            <p>{{ $t('doc.setupInstruction.WordPress.step4Text1') }}</p>

                            <p>
                                {{ $t('doc.setupInstruction.WordPress.step4Text2') }}&nbsp;<a
                                    href="#"
                                    target="_blank" rel="noopener noreferrer">{{ $t('doc.setupInstruction.WordPress.guide') }}</a> {{ $t('doc.setupInstruction.WordPress.websiteChannel') }}
                            </p>

                            <div class="setting_wrp">
                                <img src="../../../../../../assets/images/chatwoot-wordpress.webp" alt="logo21">
                            </div>

                            <p>{{ $t('doc.setupInstruction.WordPress.availableWebsite') }}</p>
                            <div class="table-details">
                                <table class="table table-bordered description">
                                    <thead>
                                        <tr>
                                            <th>Option</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[0].option') }}</td>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[0].description') }} <img
                                                    src="../../../../../../assets/images/website-inbox-table.webp" alt="logo"
                                                    class="website-inbox"></td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[1].option') }}</td>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[1].description') }} <a
                                                    href="/docs/product/channels/live-chat/sdk/setup#widget-designs">
                                                    {{ $t('doc.setupInstruction.WordPress.step3Table[1].description1') }}</a></td>

                                        </tr>
                                        <tr>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[2].option') }}</td>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[2].description') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[3].option') }}</td>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[3].description') }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[4].option') }}</td>
                                            <td>{{ $t('doc.setupInstruction.WordPress.step3Table[4].description') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="version-detail webflow" id="Verify-that-the-plugin">
                                <h4>{{ $t('doc.setupInstruction.WordPress.step4Title') }}</h4>
                                <p>{{ $t('doc.setupInstruction.WordPress.step4Text') }}</p>
                            </div>
                            <div class="chatwoot_widget">
                                <img src="../../../../../../assets/images/chatwoot-widget-on-a-wordpress-website.png"
                                    alt="logo21">
                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">Edit this page</a>
                            </div> -->
                            <p>{{ $t('doc.setupInstruction.WordPress.lastUpdated') }} <span class="dark">10/17/2022</span>{{ $t('doc.setupInstruction.WordPress.updatedBy') }} <span class="dark">{{ $t('doc.setupInstruction.WordPress.author') }}</span>
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.setupInstruction.WordPress.navigation.previous') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/setup-instruction/gatsby"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('doc.setupInstruction.WordPress.navigation.gatsby') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.setupInstruction.WordPress.navigation.next') }}</p>
                                    <router-link to="/docs/product/channels/facebook">
                                        {{ $t('doc.setupInstruction.WordPress.navigation.facebook') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">
                <section class="tableOfContents">
                <div class="tableOfContents-list">
                    <ul id="side_section_info">
                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#download-the-plugin-link' }">
                        <a id="#download-the-plugin-link" @click="scrollTo('#download-the-plugin')">
                        {{ $t('doc.setupInstruction.WordPress.tableOfContents.step1') }}
                        </a>
                    </li>
                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#upload-the-plugin-link' }">
                        <a id="#upload-the-plugin-link" @click="scrollTo('#upload-the-plugin')">
                        {{ $t('doc.setupInstruction.WordPress.tableOfContents.step2') }}
                        </a>
                    </li>
                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#configure-plugin-link' }">
                        <a id="#configure-plugin-link" @click="scrollTo('#configure-plugin')">
                        {{ $t('doc.setupInstruction.WordPress.tableOfContents.step3') }}
                        </a>
                    </li>
                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Verify-that-the-plugin-link' }">
                        <a id="#Verify-that-the-plugin-link" @click="scrollTo('#Verify-that-the-plugin')">
                        {{ $t('doc.setupInstruction.WordPress.tableOfContents.step4') }}
                        </a>
                    </li>
                    </ul>
                </div>
                </section>
            </div>
            </div>

        </div>
    </section>
</template>
<script>
export default ({
    name: 'AddConfigure',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
})
</script>
<style>
.finsh-img img {
    width: 100%;
    height: 100%;
}

.finsh-img {
    padding: 0 36px;
    margin-bottom: 30px;
}

.website-inbox {
    width: 100%;
    height: 100%;
    max-width: 396px;
}

.chatwoot_widget img {
    width: 100%;
    height: 100%;
}

.chatwoot_widget {
    padding: 0 36px;
}</style>