<template>
    <Header @language-selected="handleLanguageSelected"/>
       <div class="blog-page">
        <div class="bg-white">
            <div class="bg-gradient-to-b from-white to-blue-50">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="blog-title-box">
                                <h1>{{ $t('blog.blogwithbcoor') }}</h1>
                                <p>
                                   {{ $t('moreChatwoot.moreChatwootLearn') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-wrap d-flex">
                        <div class="left-blog">
                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogCategories') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/blog.html" class="">{{ $t('blog.blogs') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/product-update.html" class="">{{ $t('blog.blogupdates') }}</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/custmer_service.html"
                                            class="">Customer service</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right-blog">
                            <div class="right-blog-list">
                                <div class="right-blog-list-inner">
                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2023/06/AI-and-Chatwoot.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('blog.blogLeveraging') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('moreChatwoot.moreChatwootIntroducing') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.IdentityValidation.HrichaShandily') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blogJun') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2023/06/dashboard-apps.webp" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('blog.bloghow') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('moreChatwoot.moreChatwootDashboard') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogPranav') }}</p>
                                                        <p class="blog-user-second"> {{ $t('moreChatwoot.moreChatwootjun02') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/08/Dashboard-apps.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('moreChatwoot.moreChatwootApps') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('moreChatwoot.moreChatwoottried') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('moreChatwoot.moreChatwootaug') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/05/pre-chat-form-and-automations.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                      {{ $t('moreChatwoot.moreChatwootaug') }}  
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('moreChatwoot.moreChatwootaug') }}   
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                                        <p class="blog-user-second"> {{ $t('moreChatwoot.moreChatwootmay') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>