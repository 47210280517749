<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.menu.Channels') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t("home.header.Gmail") }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('Advance.Channels.Email.ConfigureSMTP.tittle') }}</a>
                        </div>

                        
                        <div class="email-channel-page">
                            <div class="channel-details">
                                <h1>{{ $t('Advance.Channels.Email.ConfigureSMTP.configureSmtp') }}</h1>
                                <p> {{ $t('Advance.Channels.Email.ConfigureSMTP.gmail') }} <a href="#">{{ $t('Advance.Channels.Email.ConfigureSMTP.gmailInbox') }}</a>
                                </p>
                            </div>

                            <section class="channel-steps-wrp">
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step1') }}</b>{{ $t('Advance.Channels.Email.ConfigureSMTP.settings') }}</p>
                                    <div class="channel-image-box">
                                        <img class="img-fluid"
                                            src="../../../../../assets/images/inbox_settings-smtp.webp"
                                        alt="">
                                    </div>
                                </div>
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step2') }}</b> {{ $t('Advance.Channels.Email.ConfigureSMTP.configuration') }}</p>

                                    <div class="channel-image-box">
                                        <img class="img-fluid"
                                            src="../../../../../assets/images/configuration-smtp-two.webp"
                                            alt="">
                                    </div>
                                </div>
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step3') }}</b> {{ $t('Advance.Channels.Email.ConfigureSMTP.inbox') }}</p>
                                    <div class="channel-image-box">
                                        <img class="img-fluid"
                                            src="../../../../../assets/images/enable_smtp-step-3.webp"
                                            alt="">
                                    </div>
                                </div>
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step4') }}</b>{{ $t('Advance.Channels.Email.ConfigureSMTP.fields') }}</p>
                                    <div class="channel-steps-details">
                                        <ol>
                                            <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.address') }}</li>
                                            <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.port') }}</li>
                                            <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.emailNew') }}</li>
                                            <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.password') }}

                                                <ul>
                                                    <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.2step') }} <a href="#">{{ $t('Advance.Channels.Email.ConfigureSMTP.appPassword') }}</a></li>
                                                    <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.gmailAccounts') }} <a href="#">{{ $t('Advance.Channels.Email.ConfigureSMTP.lessSecure') }}</a>
                                                    </li>
                                                </ul>

                                            </li>
                                            <li>{{ $t('Advance.Channels.Email.ConfigureSMTP.domain') }}</li>
                                        </ol>
                                    </div>
                                    <div class="channel-image-box">
                                        <img class="img-fluid"
                                            src="../../../../../assets/images/smtp_details-step-4.webp"
                                            alt="">
                                    </div>
                                </div>
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step5') }}</b> {{ $t('Advance.Channels.Email.ConfigureSMTP.existingEmail') }}</p>
                                </div>
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step6') }}</b>{{ $t('Advance.Channels.Email.ConfigureSMTP.send') }}</p>
                                    <div class="channel-image-box">
                                        <img class="img-fluid"
                                            src="../../../../../assets/images/smtp_reply_email-step-6.webp"
                                            alt="">
                                    </div>
                                </div>
                                <div class="channel-steps">

                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step6') }}</b> {{ $t('Advance.Channels.Email.ConfigureSMTP.mail') }}</p>

                                    <div class="channel-image-box">
                                        <img class="img-fluid"
                                            src="../../../../../assets/images/email_delivery_status-step-7.webp"
                                            alt="">
                                    </div>
                                </div>
                                <div class="channel-steps">
                                    <p><b>{{ $t('Advance.Channels.Email.ConfigureSMTP.step7') }}</b>{{ $t('Advance.Channels.Email.ConfigureSMTP.delivered') }}</p>
                                </div>
                            </section>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Advance.Channels.Email.ConfigureSMTP.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Advance.Channels.Email.ConfigureSMTP.lastUpdatedOn') }} <span class="dark">{{ $t('Advance.Channels.Email.ConfigureSMTP.date') }} </span> {{ $t('Advance.Channels.Email.ConfigureSMTP.by') }} <span class="dark"> {{ $t('Advance.Channels.Email.ConfigureSMTP.shivam') }}</span></p>
                        </div>

                       <div class="row">
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p class="preview">{{ $t('Advance.Channels.Email.ConfigureSMTP.previous') }}</p>
                                       
                                                  <router-link to="/docs/product/channels/email/configure-imap" class="introduction-account">
                                                   <span>{{ $t('Advance.Channels.Email.ConfigureSMTP.side') }}</span>  {{ $t('Advance.Channels.Email.ConfigureSMTP.imap') }}
                                                    </router-link>       
                                                
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p>{{ $t('Advance.Channels.Email.ConfigureSMTP.next') }}</p>
                                                                             
                                                         <router-link to="/docs/product/channels/email/forward-email">
                                                        {{ $t('Advance.Channels.Email.ConfigureSMTP.forwardEmail') }}<span>{{ $t('Advance.Channels.Email.ConfigureSMTP.arrow') }}»</span>
                                                    </router-link>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                 
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'Introduction',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
