<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("doc.product.Features") }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a href="#">{{ $t('features.conversation-workflow.advancedConversation') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne"> 
                                           <p>{{ $t('doc.product.OnThisPage') }}</p>  
       <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        class="{ 'active_menu_item': rightPanelMenuItem === '#filter-example' }">
                                                        <a @click="scrollTo('#filter-example')" class="link_wrp">{{ $t('features.advancedConversationFilters.filterExample') }}</a>
                                                    </li>
                                                    <li class="{ 'active_menu_item': rightPanelMenuItem === '#api' }">
                                                        <a @click="scrollTo('#api')" class="link_wrp">{{ $t('features.advancedConversationFilters.API') }}</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="Introduction-details text-align-left">
                                    <h1>{{ $t('features.advancedConversationFilters.advancedConversation') }}</h1>
                                    <p>{{ $t('features.advancedConversationFilters.advancedConversation') }}</p>
                                    <p>{{ $t('features.advancedConversationFilters.filteringConversation') }}</p>
                                </div>

                                <div class="channels-info break-word">
                                    <div class="channels-title">
                                        <ol class="channels-menu-ol">
                                            <li>{{ $t('features.conversation-workflow.ConversationStatuses') }}</li>
                                            <li>{{ $t('features.conversation-workflow.AgentAssigned') }}</li>
                                            <li>{{ $t('features.conversation-workflow.Inbox') }}</li>
                                            <li>{{ $t('headersubmenu.Features.headerTeams') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.ConversationIdentifier') }}</li>
                                            <li>{{ $t('headersubmenu.Features.headerLabels') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.Campaigns') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.BrowserLanguage') }}</li>
                                            <li>{{ $t('features.contacts.Country') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.ReferrerLinks') }}</li>
                                        </ol>
                                        <blockquote style="height:auto;">
                                            <p style="padding-bottom:0;">{{ $t('features.advancedConversationFilters.oldStatus') }}</p>
                                        </blockquote>
                                        <p>
                                            {{ $t('features.advancedConversationFilters.conversationFilter') }}
                                        </p>
                                        <p><img loading="lazy" alt="conversation-filters-button"
                                                src="../../../../assets/images/filters-button.webp" width="3358"
                                                height="2228" class="max-img"></p>
                                        <p></p>
                                        <p><img loading="lazy" alt="conversation-filters-modal"
                                                src="../../../../assets/images/filters-modal.webp" width="3358" height="2228"
                                                class="max-img"></p>
                                        <h3 class="anchorarticlenew" id="filter-example">{{ $t('features.advancedConversationFilters.filterExample') }}<a class="hash-link" href="#filter-example"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                        <p>{{ $t('features.advancedConversationFilters.conversationOrder') }}</p>
                                        <p><img loading="lazy" alt="conversation-filters-example"
                                                src="../../../../assets/images/filters-applied-example.webp" width="3358"
                                                height="2228" class="max-img"></p>
                                        <ol class="channels-menu-ol">
                                            <li>{{ $t('features.advancedConversationFilters.resolvedStatus') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.conversationOrder') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.conversationOrder') }}</li>
                                        </ol>
                                        <p>{{ $t('features.advancedConversationFilters.conversationOrder') }}
                                        </p>
                                        <p>
                                            {{ $t('features.advancedConversationFilters.clearFilterButton') }}
                                            <img loading="lazy" alt="conversation-filters-clear"
                                                src="../../../../assets/images/filters-clear.png" width="1634" height="760"
                                                class="max-img">
                                        </p>
                                        <h3 class="anchorarticlenew" id="api">API<a class="hash-link" href="#api"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                        <p>{{ $t('features.advancedConversationFilters.bcoorProvides') }}<a href="#" target="_blank" rel="noopener noreferrer">docs</a> here</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('features.conversation-workflow.advancedConversation') }}</h1>
                            <p>{{ $t('features.advancedConversationFilters.conversationsBcoor') }}</p>
                            <p>{{ $t('features.advancedConversationFilters.filteringConversation') }}</p>
                        </div>

                        <div class="channels-info break-word">
                            <div class="channels-title">
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('features.conversation-workflow.ConversationStatuses') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.AgentAssigned') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.Inbox') }}</li>
                                            <li>{{ $t('headersubmenu.Features.headerTeams') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.ConversationIdentifier') }}</li>
                                            <li>{{ $t('headersubmenu.Features.headerLabels') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.Campaigns') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.BrowserLanguage') }}</li>
                                            <li>{{ $t('features.contacts.Country') }}</li>
                                            <li>{{ $t('features.advancedConversationFilters.ReferrerLinks') }}</li>
                                </ol>
                                <blockquote style="height: auto;">
                                    <p style="padding-bottom:0;">{{ $t('features.advancedConversationFilters.oldStatus') }}</p>
                                </blockquote>
                                <p>{{ $t('features.advancedConversationFilters.filterExample') }}
                                </p>
                                <p><img loading="lazy" alt="conversation-filters-button"
                                        src="../../../../assets/images/filters-button.png" width="3358" height="2228"
                                        class="max-img"></p>
                                <p>{{ $t('features.advancedConversationFilters.filterExample') }}</p>
                                <p><img loading="lazy" alt="conversation-filters-modal"
                                        src="../../../../assets/images/filters-modal.png" width="3358" height="2228"
                                        class="max-img"></p>
                                <h3 class="anchorarticlenew" id="filter-examplenew">{{ $t('features.advancedConversationFilters.filterExample') }}<a class="hash-link" href="#filter-example"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('features.advancedConversationFilters.bcoorProvides') }}</p>
                                <p><img loading="lazy" alt="conversation-filters-example"
                                        src="../../../../assets/images/filters-applied-example.png" width="3358"
                                        height="2228" class="max-img"></p>
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('features.advancedConversationFilters.bcoorProvides') }}</li>
                                    <li>{{ $t('features.advancedConversationFilters.bcoorProvides') }}</li>
                                    <li>{{ $t('features.advancedConversationFilters.bcoorProvides') }}</li>
                                </ol>
                                <p>
                                    {{ $t('features.advancedConversationFilters.filtercombing') }}
                                </p>
                                <p>{{ $t('features.contacts.clearFilter') }}
                                    <img loading="lazy" alt="conversation-filters-clear"
                                        src="../../../../assets/images/filters-clear.png" width="1634" height="760"
                                        class="max-img">
                                </p>
                                <h3 class="anchorarticlenew" id="apinew">API<a class="hash-link" href="#api"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('features.contacts.clearFilter') }} <a href="#" target="_blank" rel="noopener noreferrer">docs</a> here</p>


                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }} <span class="dark">11/24/2021</span>by <span class="dark"> Fayaz Ahmed</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>
                                    <router-link to="/docs/user-guide/features/conversation-workflow" class="introduction-account">
                                        <span>«</span> {{ $t('features.conversation-workflow.conversationWorkflow') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>Next</p>


                                    <router-link to="/docs/user-guide/features/automations">
                                        {{ $t('headersubmenu.Features.headerAutomations') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#filter-examplenew-link' }">
                                    <a id="#filter-examplenew-link" @click="scrollTo('#filter-examplenew')" class="link_wrp">{{ $t('features.advancedConversationFilters.filterExample') }}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#apinew-link' }">
                                    <a id="#apinew-link" @click="scrollTo('#apinew')" class="link_wrp">{{ $t('features.advancedConversationFilters.API') }}</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'dashboard-apps',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
.active_menu_item a {
    color: #1f93ff !important;
}
</style>
