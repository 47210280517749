<template>
    <Header @language-selected="handleLanguageSelected"/>
        <section class="integrations security">
            <div class="container">
                <div class="integrations-heading">
                    <h2>{{ $t('security.security') }}</h2>
                    <p>{{ $t('security.securitydesc') }}</p>
                    <!-- <div class="responsible">
                        <p>{{ $t('security.securityReport') }}<a href="#">{{ $t('security.securityResponsible') }}</a>{{ $t('security.page') }}</p>
                    </div> -->
                </div>
                <div class="secure-design-box">
                    <div class="secure-design-details">
                        <img src="../../../assets/images/security/soc-blue.webp">
                        <h3>{{ $t('footer.footerProduct.secureDesignMain') }}</h3>
                        <p>{{ $t('security.compliant') }}</p>
                    </div>
                    <div class="secure-details">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="DataSecurity">
                                    <div class="DataSecurity-img">
                                        <div class="DataSecurity-icon">
                                            <svg width="16" height="16" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 10c4.418 0 8-1.79 8-4s-3.582-4-8-4-8 1.79-8 4 3.582 4 8 4Zm6.328.17A7.61 7.61 0 0 0 20 9.053V18c0 2.21-3.582 4-8 4s-8-1.79-8-4V9.053a7.61 7.61 0 0 0 1.672 1.117C7.37 11.018 9.608 11.5 12 11.5c2.392 0 4.63-.482 6.328-1.33Z" fill="#ffffff"></path></svg>
                                        </div>
                                        <h4>{{ $t('security.data_security') }}</h4>
                                    </div>
                                    <div class="DataSecurity-details">
                                        <p>{{ $t('security.security_encrypt') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="DataSecurity">
                                    <div class="DataSecurity-img">
                                        <div class="DataSecurity-icon">
                                            <svg width="16" height="16" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m18.492 2.33 3.179 3.179a2.25 2.25 0 0 1 0 3.182l-2.423 2.422A2.501 2.501 0 0 1 21 13.5v5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13A2.5 2.5 0 0 1 5.5 3h5c1.121 0 2.07.737 2.387 1.754L15.31 2.33a2.25 2.25 0 0 1 3.182 0ZM11 13H5v5.5a.5.5 0 0 0 .5.5H11v-6Zm7.5 0H13v6h5.5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5Zm-4.06-2.001L13 9.559v1.44h1.44Zm-3.94-6h-5a.5.5 0 0 0-.5.5V11h6V5.5a.5.5 0 0 0-.5-.5Z" fill="#ffffff"></path></svg>
                                        </div>
                                        <h4>{{ $t('security.application_security') }}</h4>
                                    </div>
                                    <div class="DataSecurity-details">
                                        <p>{{ $t('security.application_open_source') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="DataSecurity">
                                    <div class="DataSecurity-img">
                                        <div class="DataSecurity-icon">
                                            <svg width="16" height="16" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H9Zm-.5 4.75A.75.75 0 0 1 9.25 6h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0 11a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0-3a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Z" fill="#ffffff"></path></svg>
                                        </div>
                                        <h4>{{ $t('security.infrastructure_security') }}</h4>
                                    </div>
                                    <div class="DataSecurity-details">
                                        <p>{{ $t('security.web_services_desc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <Footer :selectLanguage="selectLanguage"/>
</template>
<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>