<template>
    <Header @language-selected="handleLanguageSelected"/>
    <div class="container">
        <div class="row live_chat_section">
            <div class="col-lg-6 col-12">
                <div class="live_chat_wrp">
                    <div class="open_source_text">
                        <span>
                            {{ $t("feature-live-chat.open_source_live_chat") }}
                        </span>
                    </div>
                    <div class="chat_for_business">
                        <h3>
                            {{ $t("feature-live-chat.simple_live_chat") }}
                        </h3>
                        <p>
                            {{ $t("feature-live-chat.software_for") }}
                            <span> {{ $t("feature-live-chat.businesses") }} </span>
                        </p>
                    </div>
                    <div class="chat_description">
                        <p>
                            {{ $t("feature-live-chat.imporve_exprience") }}
                        </p>
                    </div>

                    <div class="btn_wrp">
                        <button @click="handleRedirect">
                            {{ $t("feature-live-chat.Request_demo") }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-12">
                <div class="chat_card">
                    <img src="../../../assets/images/live-chat/live-chat-software.png" />
                </div>
            </div>
        </div>
    </div>

    <div class="brand">
        <div class="container">
            <div class="row brand_section">
                <div class="col-lg-6 col-12 chat_wrp">
                    <div class="chat_card_wrp">
                        <img src="../../../assets/images/live-chat/message-view.png" />
                    </div>
                </div>

                <div class="col-lg-6 col-12 chat_wrp">
                    <div class="brand_wrp">
                        <h2>
                            {{ $t("feature-live-chat.chat_fits_brand") }}
                        </h2>
                        <p>
                            {{ $t("feature-live-chat.widgets_desc") }}
                        </p>

                        <div class="live_chat">
                            <div class="first_data_wrp">
                                <span class=""><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                        class="text-woot h-6 w-6" data-v-04994775="" width="1em" height="1em"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z">
                                        </path>
                                    </svg>
                                </span>
                                <p>{{ $t("feature-live-chat.support_lang") }}</p>
                            </div>
                            <div class="first_data_wrp">
                                <span class=""><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                        class="text-woot h-6 w-6" data-v-04994775="" width="1em" height="1em"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z">
                                        </path>
                                    </svg>
                                </span>
                                <p>{{ $t("feature-live-chat.conversationemail") }}</p>
                            </div>
                            <div class="first_data_wrp">
                                <span class=""><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                        class="text-woot h-6 w-6" data-v-04994775="" width="1em" height="1em"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z">
                                        </path>
                                    </svg>
                                </span>
                                <p>{{ $t("feature-live-chat.attachments") }}</p>
                            </div>
                            <div class="first_data_wrp">
                                <span class=""><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                        class="text-woot h-6 w-6" data-v-04994775="" width="1em" height="1em"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z">
                                        </path>
                                    </svg>
                                </span>
                                <p>{{ $t("feature-live-chat.Customize_widget") }}</p>
                            </div>
                            <div class="first_data_wrp">
                                <span class=""><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                        class="text-woot h-6 w-6" data-v-04994775="" width="1em" height="1em"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z">
                                        </path>
                                    </svg>
                                </span>
                                <p>{{ $t("feature-live-chat.indicators_experience") }}</p>
                            </div>
                            <div class="first_data_wrp">
                                <span class=""><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                        class="text-woot h-6 w-6" data-v-04994775="" width="1em" height="1em"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z">
                                        </path>
                                    </svg>
                                </span>
                                <p>{{ $t("feature-live-chat.distraction_messaging") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="brand_wrp-p">
        <div class="container">
            <div class="row brand_section_wrp">
                <div class="col-12">
                    <div class="manage_account_wrp">
                        <div class="open_source_text">
                            <span>
                                {{ $t("feature-live-chat.MultiINBOXES") }}
                            </span>
                        </div>
                        <div class="manage_account_text">
                            <h2>
                                {{ $t("feature-live-chat.manage_account") }}
                            </h2>
                            <p>
                                {{ $t("feature-live-chat.manage_desc_access") }}
                            </p>
                        </div>
                        <div class="comment_image">
                            <img src="../../../assets/images/live-chat/multiple-inbox.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer :selectLanguage="selectLanguage" />
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
        name: "sharedInbox",
        components: {
            Footer,
            Header,
        },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
        handleRedirect() {
            window.location.href = 'https://app.bcoor.com'
        }
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
    };
</script>