<template>
    <Header @language-selected="handleLanguageSelected"/>
    <section class="labels-page">
        <div class="container">
            <div class="integrations-heading">
                <h2>{{ $t('conversationReports.title') }}</h2>
                <p>{{ $t('conversationReports.important_metrics') }}</p>
                <div class="start-button">
                    <a href="https://app.bcoor.com">{{ $t("automation.freeTrial") }}</a>
                </div>
            </div>
            <div class="labels-page-details">
                <p>{{ $t('conversationReports.attending_conversation') }}</p>
                <p class="mt-2">{{ $t('conversationReports.bcoor_member') }}</p>
            </div>
            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/conversationReports/conversations-reports-in-chatwoot.png">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('conversationReports.easy-to-understand') }}</h4>
                            <p>{{ $t('conversationReports.constantlyCalculates') }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12 orderr-2">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('conversationReports.filterToSee') }}</h4>
                            <p>{{ $t('conversationReports.custom_date') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 orderr-1">
                        <div class="labels-page-img">
                            <img
                                src="../../../assets/images/conversationReports/conversation-reports-filters-chatwoot.png">
                        </div>
                    </div>
                </div>
            </div>

            <div class="conversations d-none">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="conversations-heading">
                            <h3>{{ $t('conversationReports.start_tracking') }}</h3>
                            <p>{{ $t('automation.conversations') }}</p>
                            <div class="signup-button">
                                <a href="https://app.bcoor.com">{{ $t('emailIntegration.sign_up_free') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="conversations-img">
                            <img src="../../../assets/images/automations/dashboard-small.webp">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
        name: "sharedInbox",
        components: {
            Footer,
            Header
        },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
    }
</script>