<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="configure-account-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.generalAccountDetails.configureGeneralAccountDetails') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.generalAccountDetails.configureGeneralAccountDetails') }}</h1>
                            <p>{{ $t('doc.generalAccountDetails.updateOrganizationInfo') }}</p>
                            <p>{{ $t('doc.generalAccountDetails.updateAccountDetails') }}</p>
                        </div>
                        <div class="account-setting-img">
                            <img src="../../../../assets/images/account-settings-screen-.png" alt="">
                        </div>
                        <div class="table-details">
                            <table class="table table-bordered description">
                                <thead>
                                <tr>
                                    <th>{{ $t('doc.generalAccountDetails.fieldName') }}</th>
                                    <th>{{ $t('doc.generalAccountDetails.description') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{ $t('doc.generalAccountDetails.accountName') }}</td>
                                    <td>{{ $t('doc.generalAccountDetails.accountNameDescription') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.generalAccountDetails.siteLanguage') }}</td>
                                    <td>{{ $t('doc.generalAccountDetails.siteLanguageDescription') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.generalAccountDetails.autoResolveDays') }}</td>
                                    <td>{{ $t('doc.generalAccountDetails.autoResolveDaysDescription') }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.generalAccountDetails.clickUpdateSettings') }}</p>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen"><svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }} <span class="dark">10/17/2022 </span>{{ $t('doc.product.By') }} <span class="dark"> {{ $t('doc.createChatWoodAccount.hrichaShandily') }}
                                </span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>
                                    <router-link to="/docs/user-guide/setup-your-account/configure-your-profile"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('doc.generalAccountDetails.configureGeneralAccountDetails') }}
                                    </router-link>


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                     <router-link to="/docs/user-guide/setup-your-account/add-agent-settings">
                                        {{ $t('doc.generalAccountDetails.addAgents') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default ({})
</script>
<style>
.account-setting-img img {
    width: 100%;
    height: 100%;
}

.account-setting-img {
    padding: 0 36px;
    margin-bottom: 25px;
}

/* .configure-account-page {
    padding: 16px 36px;
} */


/* media-query */
</style>