<template>
    <section class="channels-menu-detail">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="Add-and-configure-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.channels') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.liveChat') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.AddandConfigure.AddandConfigure') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne"> 
                                            <p>{{ $t('doc.AddandConfigure.OnThisPage') }}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                        
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <!-- <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Additional-inbox-settings-link' }"><a
                                                    @click="scrollTo('#Additional-inbox-settings')">{{ $t('doc.AddandConfigure.AdditionandConfigure') }}</a> -->
                                                        
                                                        <ul>
                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Setting-tab-link' }"><a
                                                            @click="scrollTo('#Setting-tab')">{{ $t('doc.AddandConfigure.SettingsTab') }}</a>
                                                            </li>
                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Collaborator-tab-link' }"><a
                                                            @click="scrollTo('#Collaborator-tab')">{{ $t('doc.AddandConfigure.CollaboratorsTab') }}</a>
                                                            </li>
                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#bussiness-hours-tab-link' }"><a
                                                            @click="scrollTo('#bussiness-hours-tab')">{{ $t('doc.AddandConfigure.BusinessHoursTab') }}</a></li>
                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#pre-chat-form-tab-link' }"><a
                                                            @click="scrollTo('#pre-chat-form-tab')">
                                                            {{ $t('doc.AddandConfigure.PreChatFormTab') }}</a></li>

                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Configuration-tab-link' }">
                                                                <a @click="scrollTo('#Configuration-tab')">{{ $t('doc.AddandConfigure.ConfigurationTab') }}</a>
                                                            </li>

                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#widget-builder-tab-link' }">
                                                                <a @click="scrollTo('#widget-builder-tab')">{{ $t('doc.AddandConfigure.WidgetBuilderTab') }}</a>
                                                            </li>
                                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#next-step-find-link' }">
                                                                <a @click="scrollTo('#next-step-find')">{{ $t('doc.AddandConfigure.NextSteps') }}</a>
                                                            </li>
                                                        </ul>
                                                    <!-- </li> -->

                              
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.AddandConfigure.title') }}</h1>
                            <p><span class="step">{{ $t('doc.AddandConfigure.step1') }}</span> {{ $t('doc.AddandConfigure.step1Info') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/add-inbox-channel.webp" alt="logo45">
                        </div>

                        <div class="update-profile-check">
                            <p><span class="step">{{ $t('doc.AddandConfigure.step2') }}</span>{{ $t('doc.AddandConfigure.step2Info') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/list_of_channels.png" alt="logo45">
                        </div>
                        <div class="update-profile-check">
                            <p><span class="step">{{ $t('doc.AddandConfigure.step3') }}</span>{{ $t('doc.AddandConfigure.step3Info') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/add-website-inbox-details.png" alt="logo45">
                        </div>
                        <div class="table-details">
                            <table class="table table-bordered description">
                                <thead>
                                    <tr>
                                        <th>{{ $t('doc.AddandConfigure.TableDetails.fieldName') }}</th>
                                        <th>{{ $t('doc.AddandConfigure.TableDetails.value') }}</th>
                                        <th>{{ $t('doc.AddandConfigure.TableDetails.remarks') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.websiteName') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.websiteNameDesc') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.websiteNameExample') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.websiteDomain') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.websiteDomainDesc') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.websiteDomainExample') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.widgetColor') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.widgetColorDesc') }}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.welcomeHeading') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.welcomeHeadingDesc') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.welcomeHeadingExample') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.welcomeTagline') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.welcomeTaglineDesc') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.welcomeTaglineExample') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.enableChannelGreeting') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.enableChannelGreetingDesc') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.enableChannelGreetingExample') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.channelGreetingMessage') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.channelGreetingMessageDesc') }}</td>
                                        <td>{{ $t('doc.AddandConfigure.TableDetails.channelGreetingMessageExample') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.AddandConfigure.step4Details') }}</p>
                        </div>
                        <div class="update-profile-check">
                            <p><span class="step">{{ $t('doc.AddandConfigure.step4') }}</span> {{ $t('doc.AddandConfigure.step4Details') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/add-agents-to-website-inbox-shane-watson.png"
                                alt="logo45">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.AddandConfigure.agentsInfo') }}</p>
                            <p>{{ $t('doc.AddandConfigure.agentsInfo2') }}</p>
                        </div>
                        <div class="update-profile-check">
                            <p><span class="step">{{ $t('doc.AddandConfigure.step5') }}</span>{{ $t('doc.AddandConfigure.step5Details') }}</p>
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.AddandConfigure.copySnippet') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/inbox-is-ready.webp" alt="logo45">
                        </div>
                        <div class="version-detail" id="i-through-home-screen">
                            <p>{{ $t('doc.AddandConfigure.moreSettings') }}</p>
                            <p>{{ $t('doc.AddandConfigure.moreSettings2') }}</p>
                        </div>
                        <div class="update-profile-check">
                            <p><span class="step">{{ $t('doc.AddandConfigure.step6') }}</span> {{ $t('doc.AddandConfigure.step6Details') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/inbox_settings.png" alt="logo45">
                        </div>
                        <div class="Inbox-settings" id="Additional-inbox-settings">
                            <h2>{{ $t('doc.AddandConfigure.InboxSettings.additionalSettings') }}</h2>
                            <p>{{ $t('doc.AddandConfigure.InboxSettings.settingsTab') }}</p>
                        </div>
                        <div class="update-profile-check" id="Setting-tab">
                            <h4>{{ $t('doc.AddandConfigure.InboxSettings.settingsTab') }}</h4>
                            <div class="add-channels-img">
                                <img src="../../../../../assets/images/basic-inbox-settings-paperlayer.png" alt="logo45">
                            </div>
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.AddandConfigure.InboxSettings.scrollMoreSettings') }}</p>
                            <div class="add-channels-img">
                                <img src="../../../../../assets/images/more-settings.png" alt="logo45">
                            </div>
                        </div>

                        <div class="version-detail" id="i-through-home-screen">
                            <p>{{ $t('doc.AddandConfigure.InboxSettings.coupleExample') }}</p>
                            <p><span class="step">{{ $t('doc.AddandConfigure.InboxSettings.stepCollectBox') }}</span> {{ $t('doc.AddandConfigure.InboxSettings.exampleEnableEmailCollectBox') }}</p>
                            <p><span class="step">{{ $t('doc.AddandConfigure.InboxSettings.stepEnableCSAT') }}</span> {{ $t('doc.AddandConfigure.InboxSettings.exampleEnableCSAT') }}</p>
                            <p>{{ $t('doc.AddandConfigure.InboxSettings.ClickOnThe') }} <span class="click-inbox">{{ $t('doc.AddandConfigure.InboxSettings.update') }}</span>{{ $t('doc.AddandConfigure.InboxSettings.updateButton') }}</p>
                        </div>
                        <div class="version-detail" id="Collaborator-tab">
                            <h4>{{ $t('doc.AddandConfigure.CollaboratorsTabs.title') }}</h4>
                            <p>{{ $t('doc.AddandConfigure.CollaboratorsTabs.Clickonthe') }} <span class="click-inbox">{{ $t('doc.AddandConfigure.CollaboratorsTabs.collaborators') }}</span>{{ $t('doc.AddandConfigure.CollaboratorsTabs.tabs') }}</p>
                            <p>{{ $t('doc.AddandConfigure.CollaboratorsTabs.addDeleteAgents') }}</p>
                            <p>{{ $t('doc.AddandConfigure.CollaboratorsTabs.enableAutomaticAssignment') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/collaborators-.png" alt="logo45">
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.AddandConfigure.clickOn') }} <span class="click-inbox">{{ $t('doc.AddandConfigure.update') }}</span>{{ $t('doc.AddandConfigure.CollaboratorsTabs.enableAutomaticAssignment') }}</p>
                        </div>
                        <div class="version-detail" id="bussiness-hours-tab">
                            <h4>{{ $t('doc.AddandConfigure.BusinessHoursTabs.title') }}</h4>
                            <p>{{ $t('doc.AddandConfigure.BusinessHoursTabs.Clickonthe') }}<span class="click-inbox">{{ $t('doc.AddandConfigure.BusinessHoursTabs.businessHours') }}</span> {{ $t('doc.AddandConfigure.BusinessHoursTabs.teamMessage') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/business-hours-setting.png" alt="logo45">
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.AddandConfigure.BusinessHoursTabs.clickOnTheCheckBox') }}<span class="click-inbox">{{ $t('doc.AddandConfigure.BusinessHoursTabs.enableBusinessAvailability') }}</span>{{ $t('doc.AddandConfigure.BusinessHoursTabs.definedbusinessHours') }}</p>
                            <p>{{ $t('doc.AddandConfigure.BusinessHoursTabs.messageBusinessHours') }}</p>
                            <p>{{ $t('doc.AddandConfigure.BusinessHoursTabs.availableBusinessHours') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/business-hours-advanced-setting-back.png" alt="logo45">
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.AddandConfigure.clickOn') }} <span class="click-inbox">{{ $t('doc.AddandConfigure.BusinessHoursTabs.businessHoursSetting') }}</span>{{ $t('doc.AddandConfigure.BusinessHoursTabs.button') }}</p>
                        </div>
                        <div class="version-detail" id="pre-chat-form-tab">
                            <h4>{{ $t('doc.AddandConfigure.PreChatFormTabs.title') }}</h4>
                            <p>{{ $t('doc.AddandConfigure.PreChatFormTabs.Clickonthe') }}<span class="click-inbox">{{ $t('doc.AddandConfigure.PreChatFormTabs.preChatFormTab') }}</span>{{ $t('doc.AddandConfigure.PreChatFormTabs.tab') }}</p>
                            <p>{{ $t('doc.AddandConfigure.PreChatFormTabs.youCan') }}  {{ $t('doc.AddandConfigure.PreChatFormTabs.collectInformation') }}  {{ $t('doc.AddandConfigure.PreChatFormTabs.beginningTheChat') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/prechatform-setting.png" alt="logo55">
                        </div>
                        <div class="table-details">
                            <table class="table table-bordered description">
                                <thead>
                                <tr>
                                    <th>{{ $t('doc.AddandConfigure.PreChatFormTable.fieldNameHeader') }}</th>
                                    <th>{{ $t('doc.AddandConfigure.PreChatFormTable.valueHeader') }}</th>
                                    <th>{{ $t('doc.AddandConfigure.PreChatFormTable.remarksHeader') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.enablePreChatForm.field') }}</td>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.enablePreChatForm.value') }}</td>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.enablePreChatForm.remarks') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.preChatMessage.field') }}</td>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.preChatMessage.value') }}</td>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.preChatMessage.remarks') }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.visitorNameEmail.field') }}</td>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.visitorNameEmail.value') }}</td>
                                    <td>{{ $t('doc.AddandConfigure.PreChatFormTable.visitorNameEmail.remarks') }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.AddandConfigure.versionDetail.Clickonthe') }}<span class="click-inbox">{{ $t('doc.AddandConfigure.versionDetail.updateButton') }}</span>{{ $t('doc.AddandConfigure.versionDetail.preChatFormEnabled') }}</p>
                        </div>
                        <div class="version-detail" id="Configuration-tab">
                            <h4>{{ $t('doc.AddandConfigure.configurationTab.title') }}</h4>
                            <p>{{ $t('doc.AddandConfigure.configurationTab.snippet') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/config-inbox.png" alt="logo55">
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.AddandConfigure.versionDetail.canclickOnThe') }}<span class="click-inbox">{{ $t('doc.AddandConfigure.versionDetail.copyButton') }}</span>{{ $t('doc.AddandConfigure.versionDetail.copyTheCode') }} <a
                                    href="#">{{ $t('doc.AddandConfigure.versionDetail.rootFileLink') }}</a>{{ $t('doc.AddandConfigure.versionDetail.website') }}</p>
                        </div>
                        <div class="version-detail" id="widget-builder-tab">
                            <h4>{{ $t('doc.AddandConfigure.widgetBuilderTab.title') }}</h4>
                            <p>{{ $t('doc.AddandConfigure.widgetBuilderTab.description') }}</p>
                        </div>
                        <div class="add-channels-img">
                            <img src="../../../../../assets/images/widget-builder-png.png" alt="logo55">
                        </div>
                        <div class="channels-title" id="next-step-find">
                            <h2>{{ $t('doc.AddandConfigure.channelsTitle.nextSteps.title') }}</h2>
                            <p>{{ $t('doc.AddandConfigure.channelsTitle.nextSteps.description') }}</p>
                            <ul class="next-step-configure">
                                <li><a href="/docs/product/channels/facebook"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.facebook') }}</a></li>
                                <li><a href="/docs/product/channels/instagram"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.instagram') }}</a></li>
                                <li><a href="/docs/product/channels/twitter"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.twitter') }}</a></li>
                                <li><a href="/docs/product/channels/whatsapp/whatsapp-cloud"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.whatsapp') }}</a></li>
                                <li><a href="/docs/product/channels/sms/twilio"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.sms') }}</a></li>
                                <li><a href="/docs/product/channels/email/create-channel"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.email') }}</a></li>
                                <li><a href="/docs/product/channels/api/create-channel"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.api') }}</a></li>
                                <li><a href="docs/product/channels/telegram"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.telegram') }}</a></li>
                                <li><a href="/docs/product/channels/line"><span>➡️</span>{{ $t('doc.AddandConfigure.channelsTitle.channelLinks.line') }}</a></li>
                            </ul>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">Edit this page</a>
                            </div> -->
                            <p>{{ $t('doc.AddandConfigure.lastUpdated.label') }} <span class="dark">5/29/2023</span> {{ $t('doc.AddandConfigure.lastUpdated.by') }} <span class="dark">{{ $t('doc.AddandConfigure.lastUpdated.authorName') }}</span></p>

                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.AddandConfigure.previousLink.label') }}</p>
                                    <router-link to="/docs/user-guide/setup-your-account/applications" class="introduction-account">
                                        <span>«</span> {{ $t('doc.AddandConfigure.previousLink.linkText') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.AddandConfigure.nextLink.label') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/sdk/setup">
                                        {{ $t('doc.AddandConfigure.nextLink.linkText') }} <span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <!-- <div class @click="scrollToTopcustom">hi</div> -->
                            <ul id="side_section_info">
                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Additional-inbox-settings-link' }">
                                            <a id="#Additional-inbox-settings-link" @click="scrollTo('#Additional-inbox-settings')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.additionalSettings') }}</a>
                                        </li>
                                    <ul>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Setting-tab-link' }">
                                            <a id="#Setting-tab-link" @click="scrollTo('#Setting-tab')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.settingsTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Collaborator-tab-link' }">
                                            <a id="#Collaborator-tab-link" @click="scrollTo('#Collaborator-tab')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.collaboratorsTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#bussiness-hours-tab-link' }">
                                            <a id="#bussiness-hours-tab-link" @click="scrollTo('#bussiness-hours-tab')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.businessHoursTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#pre-chat-form-tab-link' }">
                                            <a id="#pre-chat-form-tab-link" @click="scrollTo('#pre-chat-form-tab')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.preChatFormTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Configuration-tab-link' }">
                                            <a id="#Configuration-tab-link" @click="scrollTo('#Configuration-tab')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.configurationTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#widget-builder-tab-link' }">
                                            <a id="#widget-builder-tab-link" @click="scrollTo('#widget-builder-tab')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.widgetBuilderTab') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#next-step-find-link' }">
                                            <a id="#next-step-find-link" @click="scrollTo('#next-step-find')">{{ $t('doc.AddandConfigure.tableOfContents.sectionTitles.nextSteps') }}</a>
                                        </li>
                                    </ul>
                                <!-- </li> -->
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'AddConfigure',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
        //  this.scrollToTopcustom = this.scrollToTopcustom.bind(this);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {

        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>
<style>
.add-channels-img img {
    width: 100%;
    height: 100%;
}

.add-channels-img {
    padding: 0 36px;
    margin-bottom: 25px;
}

.Inbox-settings h2 {
    text-align: left;
    font-weight: 600;
    font-size: 32px;
    color: var(--main-containt-font-color);
}

.Inbox-settings p {
    text-align: left;
    padding-bottom: 20px;
    margin: 0;
    color: var(--main-containt-font-color);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
}

.Inbox-settings {
    padding: 0 36px;
}

.update-profile-check h4 {
    text-align: left;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
    color: var(--main-containt-font-color);
}

.version-detail p a {
    color: #1f93ff;
    text-decoration: none;
}

ul.next-step-configure li a {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    padding: 0;
    padding-left: 0;
}

ul.next-step-configure li a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #1f93ff;
}

ul.next-step-configure {
    margin: 0;
    padding-left: 0;
    gap: 10px;
}

ul.next-step-configure li {
    margin: 20px 0;
}

#side_section_info li a {
    cursor: pointer;
}</style>
