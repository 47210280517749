<template>
  <section class="channels-menu-detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8">
          <div class="Google-Tag-Manager-page">
            <div class="getting-details">
              <p>{{ $t("doc.channels") }}</p>
              <img
                src="../../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <p>{{ $t("doc.liveChat") }}</p>
              <img
                src="../../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <p>{{ $t("doc.setupInstruction.setupInstructionsTitle") }}</p>
              <img
                src="../../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <a href="#">{{
                $t("doc.setupInstruction.googleTagManager.googleTagManagerTitle")
              }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.setupInstruction.onThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img
                          src="../../../../../../assets/images/up_arrow_side.svg"
                          alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem ===
                                '#Access-the-tag-manager-console-link',
                            }"
                          >
                            <a @click="scrollTo('#Access-the-tag-manager-console')">{{
                              $t("doc.setupInstruction.googleTagManager.step1.title")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Create-new-tag-link',
                            }"
                          >
                            <a @click="scrollTo('#Create-new-tag')">{{
                              $t("doc.setupInstruction.googleTagManager.step2.title")
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details">
              <h1>
                {{ $t("doc.setupInstruction.googleTagManager.introduction.title") }}
              </h1>
              <p>
                {{ $t("doc.setupInstruction.googleTagManager.introduction.description") }}
              </p>
            </div>
            <div class="version-detail webflow" id="Access-the-tag-manager-console">
              <h4>{{ $t("doc.setupInstruction.googleTagManager.step1.title") }}</h4>
              <p>{{ $t("doc.setupInstruction.googleTagManager.step1.description") }}</p>
            </div>
            <div class="workspace-img">
              <img
                src="../../../../../../assets/images/workspace-changes-one.webp"
                alt="logo21"
              />
            </div>
            <div class="version-detail" id="Create-new-tag">
              <h4>{{ $t("doc.setupInstruction.googleTagManager.step2.title") }}</h4>
              <p>{{ $t("doc.setupInstruction.googleTagManager.step2.description") }}</p>
              <p>{{ $t("doc.setupInstruction.googleTagManager.step2.description2") }}</p>
            </div>
            <div class="workspace-img">
              <img
                src="../../../../../../assets/images/create-custom-html.webp"
                alt="logo21"
              />
            </div>
            <div class="version-detail" id="Configuration-tab">
              <p>{{ $t("doc.setupInstruction.googleTagManager.step3.description1") }}</p>
            </div>
            <div class="workspace-img">
              <img src="../../../../../../assets/images/18.png" alt="logo21" />
            </div>
            <div class="version-detail" id="Configuration-tab">
              <p>{{ $t("doc.setupInstruction.googleTagManager.step3.description2") }}</p>
            </div>
            <div class="workspace-img">
              <img
                src="../../../../../../assets/images/select-trigger-two.webp"
                alt="logo21"
              />
            </div>
            <div class="version-detail" id="Configuration-tab">
              <p>{{ $t("doc.setupInstruction.googleTagManager.step3.description3") }}</p>
            </div>
            <div class="workspace-img">
              <img
                src="../../../../../../assets/images/workspace-changes-one.webp"
                alt="logo21"
              />
            </div>
            <div class="version-detail" id="Configuration-tab">
              <p>{{ $t("doc.setupInstruction.googleTagManager.step3.description4") }}</p>
            </div>
            <div class="workspace-img">
              <img src="../../../../../../assets/images/version-name.webp" alt="logo21" />
            </div>
            <div class="update-profile-check">
              <p>{{ $t("doc.setupInstruction.googleTagManager.successMessage") }}</p>
            </div>
            <div class="edit-update-detail">
              <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">Edit this page</a>
                            </div> -->
              <p>
                {{ $t("doc.setupInstruction.googleTagManager.lastUpdatedOn") }}
                <span class="dark">5/28/2021 </span
                >{{ $t("doc.setupInstruction.googleTagManager.updatedBy") }}
                <span class="dark">{{
                  $t("doc.setupInstruction.googleTagManager.author")
                }}</span>
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p class="preview">
                    {{ $t("doc.setupInstruction.googleTagManager.navigation.previous") }}
                  </p>

                  <router-link
                    to="/docs/product/channels/live-chat/setup-instruction/docusaurus"
                    class="introduction-account"
                  >
                    <span>«</span>
                    {{
                      $t(
                        "doc.setupInstruction.googleTagManager.navigation.docusaurusTitle"
                      )
                    }}
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("doc.setupInstruction.googleTagManager.navigation.next") }}</p>
                  <router-link
                    to="/docs/product/channels/live-chat/setup-instruction/react-native-widget"
                  >
                    {{
                      $t("doc.setupInstruction.googleTagManager.navigation.reactNative")
                    }}
                    <span>»</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4">
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <ul id="side_section_info">
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#Access-the-tag-manager-console-link',
                  }"
                >
                  <a
                    id="#Access-the-tag-manager-console-link"
                    @click="scrollTo('#Access-the-tag-manager-console')"
                    >{{ $t("doc.setupInstruction.googleTagManager.step1.title") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#Create-new-tag-link',
                  }"
                >
                  <a id="#Create-new-tag-link" @click="scrollTo('#Create-new-tag')">{{
                    $t("doc.setupInstruction.googleTagManager.step2.title")
                  }}</a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "AddConfigure",
  data() {
    return {
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
    //  this.scrollToTopcustom = this.scrollToTopcustom.bind(this);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    isActiveSection(index) {
      return index === this.activeSectionIndex;
    },
  },
};
</script>
<style>
.workspace-img img {
  width: 100%;
  height: 100%;
}

.workspace-img {
  padding: 0 36px;
}
</style>
