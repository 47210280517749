<template>
    <section class="channels-menu-detail">
        <div class="container">
            <div class="row">
                    <div class="col-xl-9 col-lg-8">
                    </div>
                    <div class="col-xl-3 col-lg-4">

                    </div>
                </div>
      
        </div>
    </section>
</template>
<script>
export default ({})
</script>
<style>
.hmac-secret {
    padding: 0 36px;
    margin: 20px 0;
}
.hmac-secret img {
    width: 100%;
    height: 100%;
}
</style>