<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Features') }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#"> {{ $t("macros.Macros") }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.product.OnThisPage') }}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        class="{ 'active_menu_item': rightPanelMenuItem === '#how-to-create-a-macro ' }">
                                                        <a @click="scrollTo('#how-to-create-a-macro')" class="link_wrp">{{ $t('macros.macrosDesc') }}</a>
                                                        <ul>
                                                            <li
                                                                class="{ 'active_menu_item': rightPanelMenuItem === '#an-example-setup-of-a-macro ' }">
                                                                <a @click="scrollTo('#an-example-setup-of-a-macro')"
                                                                    class="link_wrp">{{ $t('macros.mecroexample') }}</a>
                                                            </li>

                                                        </ul>
                                                    </li>
                                                    <li
                                                        class="{ 'active_menu_item': rightPanelMenuItem === '#how-to-execute-a-macro ' }">
                                                        <a @click="scrollTo('#how-to-execute-a-macro')" class="link_wrp">{{ $t('macros.mecroexecute') }}</a>
                                                    </li>
                                                    
                                                    <li
                                                        class="{ 'active_menu_item': rightPanelMenuItem === '#how-to-edit-or-delete-a-macro ' }">
                                                        <a @click="scrollTo('#how-to-edit-or-delete-a-macro')"
                                                            class="link_wrp">{{ $t('macros.mecroedit') }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="Introduction-details text-align-left">
                                    <h1>{{ $t('macros.macrosuse') }}</h1>
                                    <p>{{ $t('macros.mecrodesc1') }}</p>
                                    <p>{{ $t('macros.mecrodesc2') }}</p>
                                    <p>{{ $t('macros.mecrodesc3') }}</p>
                                    <p>{{ $t('macros.mecrodesc4') }}</p>

                                </div>

                                <div class="channels-info break-word code-info">
                                    <div class="channels-title">

                                        <h2 class="anchorarticlenew" id="how-to-create-a-macro">{{ $t('macros.macrosDesc') }}<a class="hash-link" href="#how-to-create-a-macro"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p><strong>{{ $t('macros.Step1') }}</strong>{{ $t('macros.goto') }}</p>
                                        <p><img loading="lazy" alt="Macros in bcoor"
                                                src="../../../../assets/images/macros-in-chatwoot.png" width="2586"
                                                height="1050" class="max-img"></p>
                                        <p><strong>{{ $t('macros.Step2') }}</strong>{{ $t('macros.mecrodescst1') }}</p>
                                        <p><img loading="lazy" alt="Setting Up A Macro"
                                                src="../../../../assets/images/setting-up-a-macro.png" width="2084"
                                                height="1252" class="max-img"></p>
                                        <p>{{ $t('macros.mecrodescst2') }}</p>
                                        <p><img loading="lazy" alt="Macros actions in Chatwoot"
                                                src="../../../../assets/images/macros-actions-in-chatwoot.png" width="2292"
                                                height="2694" class="max-img"></p>
                                        <p>{{ $t('macros.mecrodescst3') }}</p>
                                        <h3 class="anchorarticlenew" id="an-example-setup-of-a-macro">
                                            {{ $t('macros.mecroexample') }}<a class="hash-link"
                                                href="#an-example-setup-of-a-macro"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                        <p> {{ $t('macros.mecroexamplest1') }}</p>
                                        <p><img loading="lazy" alt="Macros example Setup in Chatwoot"
                                                src="../../../../assets/images/macros-example-setup-in-chatwoot.png"
                                                width="1784" height="1042" class="max-img"></p>
                                        <p><strong>{{ $t('macros.mecroexamplest2') }}</strong></p>
                                        <p><strong>{{ $t('macros.Step3') }}</strong>{{ $t('macros.mecrovisibility1') }} </p>
                                        <p><img loading="lazy" alt="Macro visibility"
                                                src="../../../../assets/images/macro-visibility.png" width="844"
                                                height="658" class="max-img"></p>
                                        <p><strong>{{ $t('macros.Step4') }}</strong> {{ $t('macros.microsaveDesc') }}</p>
                                        <p> {{ $t('macros.microsaveDesc1') }}</p>
                                        <h2 class="anchorarticlenew" id="how-to-execute-a-macro">{{ $t('macros.mecroexecute') }}<a class="hash-link" href="#how-to-execute-a-macro"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p><strong>{{ $t('macros.Step1') }}</strong> {{ $t('macros.mecroexecuteDesc') }}</p>
                                        <p><img loading="lazy" alt="Create a macro - Step 1"
                                                src="../../../../assets/images/create-macro-step-1.png" width="1500"
                                                height="1166" class="max-img"></p>
                                        <p><strong>{{ $t('macros.Step2') }}</strong> {{ $t('macros.mecroexecuteDesc1') }}</p>
                                        <p><img loading="lazy" alt="Create a macro - Step 2"
                                                src="../../../../assets/images/create-macro-step-2.png" width="1038"
                                                height="1064" class="max-img"></p>
                                        <p><strong>{{ $t('macros.Step3') }}</strong> {{ $t('macros.mecroexecuteDesc2') }}</p>
                                        <p><img loading="lazy" alt="Create a macro - Step 3"
                                                src="../../../../assets/images/create-macro-step-3.png" width="1584"
                                                height="1204" class="max-img"></p>
                                        <h2 class="anchorarticlenew" id="how-to-edit-or-delete-a-macro">{{ $t('macros.mecroedit') }}<a class="hash-link" href="#how-to-edit-or-delete-a-macro"
                                                title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                        <p>{{ $t('macros.mecroeditDesc') }}</p>
                                        <p><img loading="lazy" alt="Edit or delete a Macro"
                                                src="../../../../assets/images/edit-or-delete-macro.png" width="1458"
                                                height="576" class="max-img"></p>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('macros.macrosuse') }}</h1>
                            <p>{{ $t('macros.mecrodesc1') }}</p>
                            <p >{{ $t('macros.mecrodesc2') }}</p>
                            <p>{{ $t('macros.mecrodesc3') }}</p>
                            <p>{{ $t('macros.mecrodesc4') }}</p>

                        </div>

                        <div class="channels-info break-word code-info">
                            <div class="channels-title">

                                <h2 class="anchorarticlenew" id="how-to-create-a-macronew">{{ $t('macros.macrosDesc') }}<a class="hash-link" href="#how-to-create-a-macro"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p><strong>{{ $t('macros.Step1') }}</strong> {{ $t('macros.goto') }}</p>
                                <p><img loading="lazy" alt="Macros in Chatwoot"
                                        src="../../../../assets/images/macros-in-chatwoot.png" width="2586" height="1050"
                                        class="max-img"></p>
                                <p><strong>{{ $t('macros.Step2') }}</strong> {{ $t('macros.mecrodescst1') }}</p>
                                <p><img loading="lazy" alt="Setting Up A Macro"
                                        src="../../../../assets/images/setting-up-a-macro.png" width="2084" height="1252"
                                        class="max-img"></p>
                                <p>{{ $t('macros.mecrodescst2') }}</p>
                                <p><img loading="lazy" alt="Macros actions in Chatwoot"
                                        src="../../../../assets/images/macros-actions-in-chatwoot.png" width="2292"
                                        height="2694" class="max-img"></p>
                                <p>{{ $t('macros.mecrodescst3') }}</p>
                                <h3 class="anchorarticlenew" id="an-example-setup-of-a-macronew">
                                    {{ $t('macros.mecroexample') }}<a class="hash-link" href="#an-example-setup-of-a-macro"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('macros.mecroexamplest1') }}</p>
                                <p><img loading="lazy" alt="Macros example Setup in Chatwoot"
                                        src="../../../../assets/images/macros-example-setup-in-chatwoot.png" width="1784"
                                        height="1042" class="max-img"></p>
                                <p><strong>{{ $t('macros.mecroexamplest2') }}</strong></p>
                                <p><strong>{{ $t('macros.Step3') }}</strong> {{ $t('macros.mecrovisibility1') }}</p>
                                <p><img loading="lazy" alt="Macro visibility"
                                        src="../../../../assets/images/macro-visibility.png" width="844" height="658"
                                        class="max-img"></p>
                                <p><strong>{{ $t('macros.Step4') }}</strong> {{ $t('macros.microsaveDesc') }}</p>
                                <p>{{ $t('macros.microsaveDesc1') }}</p>
                                <h2 class="anchorarticlenew" id="how-to-execute-a-macronew">{{ $t('macros.mecroexecute') }}<a class="hash-link" href="#how-to-execute-a-macro"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p><strong>{{ $t('macros.Step1') }}</strong> {{ $t('macros.mecroexecuteDesc') }}</p>
                                <p><img loading="lazy" alt="Create a macro - Step 1"
                                        src="../../../../assets/images/create-macro-step-1.png" width="1500" height="1166"
                                        class="max-img"></p>
                                <p><strong>{{ $t('macros.Step2') }}</strong> {{ $t('macros.mecroexecuteDesc1') }}</p>
                                <p><img loading="lazy" alt="Create a macro - Step 2"
                                        src="../../../../assets/images/create-macro-step-2.png" width="1038" height="1064"
                                        class="max-img"></p>
                                <p><strong>{{ $t('macros.Step3') }}</strong> {{ $t('macros.mecroexecuteDesc2') }}</p>
                                <p><img loading="lazy" alt="Create a macro - Step 3"
                                        src="../../../../assets/images/create-macro-step-3.png" width="1584" height="1204"
                                        class="max-img"></p>
                                <h2 class="anchorarticlenew" id="how-to-edit-or-delete-a-macronew">{{ $t('macros.mecroedit') }}<a class="hash-link" href="#how-to-edit-or-delete-a-macro"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('macros.mecroeditDesc') }}</p>
                                <p><img loading="lazy" alt="Edit or delete a Macro"
                                        src="../../../../assets/images/edit-or-delete-macro.png" width="1458" height="576"
                                        class="max-img"></p>

                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">Edit this page</a>
                            </div> -->
                            <p>{{ $t("doc.inboxes.last_updated_on") }} <span class="dark">4/3/2023</span>{{ $t("doc.inboxes.updated_by") }} <span class="dark"> Shivam Mishra</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t("Mobile_App.previous") }}</p>

                                    <router-link to="/docs/user-guide/features/automations" class="introduction-account">
                                        <span>«</span>  {{ $t('headersubmenu.Features.headerAutomations') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t("Mobile_App.next") }}</p>

                                    <router-link to="/docs/user-guide/features/pre-chat-form">
                                        {{ $t('doc.product.PreChatForm') }} <span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#how-to-create-a-macronew-link' }">
                                    <a :id="'#how-to-create-a-macro-link'" @click="scrollTo('#how-to-create-a-macronew')" class="link_wrp">{{ $t('macros.macrosDesc') }}</a>
                                    <ul>
                                        <li
                                            :class="{ 'active_menu_item': rightPanelMenuItem === '#an-example-setup-of-a-macronew-link' }">
                                            <a :id="'#an-example-setup-of-a-macronew-link'" @click="scrollTo('#an-example-setup-of-a-macronew')" class="link_wrp">{{ $t('macros.mecroexample') }}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#how-to-execute-a-macronew-link' }">
                                    <a :id="'#how-to-execute-a-macronew-link'" @click="scrollTo('#how-to-execute-a-macronew')" class="link_wrp">{{ $t('macros.mecroexecute') }}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#how-to-edit-or-delete-a-macronew-link' }">
                                    <a :id="'how-to-edit-or-delete-a-macronew-link'" @click="scrollTo('#how-to-edit-or-delete-a-macronew')" class="link_wrp">{{ $t('macros.mecroedit') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'dashboard-apps',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>


<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
