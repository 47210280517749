<template>
    <Header @language-selected="handleLanguageSelected"/>
    <section class="labels-page">
        <div class="container">
            <div class="integrations-heading">
                <h2>{{ $t('liveViewOfReports.status_conversation') }}</h2>
                <p>
                    {{ $t('liveViewOfReports.live_available') }}
                </p>
                <div class="start-button">
                    <a href="https://app.bcoor.com">{{ $t("automation.freeTrial") }}</a>
                </div>
            </div>
            <div class="labels-page-details">
                <p>
                    {{ $t('liveViewOfReports.customer_communication') }}
                </p>
            </div>
            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/liveViewOfReports/live-view-of-open-conversations.png" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('liveViewOfReports.conversationData') }}</h4>
                            <p>
                                {{ $t('liveViewOfReports.report_overView') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12 orderr-2">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('liveViewOfReports.agent_online') }}</h4>
                            <p>
                                {{ $t('liveViewOfReports.exact_number') }}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 orderr-1">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/liveViewOfReports/live-view-of-agents.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/liveViewOfReports/live-view.png" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('liveViewOfReports.countOfconversation') }}</h4>
                            <p>
                                {{ $t('liveViewOfReports.exactly_manynumber') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="conversations d-none">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="conversations-heading">
                            <h3>{{ $t('contactNotes.ready_dive') }}</h3>
                            <p>
                                {{ $t('automation.conversations') }}
                            </p>
                            <div class="signup-button">
                                <a href="https://app.bcoor.com">{{ $t('emailIntegration.sign_up_free') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="conversations-img">
                            <img src="../../../assets/images/automations/dashboard-small.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
        name: "sharedInbox",
        components: {
            Footer,
            Header,
        },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
    };
</script>