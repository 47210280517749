<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("home.header.Features") }}</p>
              <img
                src="../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />
              <a href="#">{{ $t("doc.product.CannedResponses") }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.product.OnThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img src="/img/up_arrow_side.7f2fb856.svg" alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Use-conversation-link',
                            }"
                          >
                            <a @click="scrollTo('#Use-conversation')" class="link_wrp">{{
                              $t("features.canned-responses.response_conversation")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#canned-response-link',
                            }"
                          >
                            <a @click="scrollTo('#canned-response')" class="link_wrp">{{
                              $t("features.canned-responses.add_new_canned_response")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Modify-canned-link',
                            }"
                          >
                            <a @click="scrollTo('#Modify-canned')" class="link_wrp">{{
                              $t("features.canned-responses.modify_canned_response")
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details">
              <div class="canned_list_wrp">
                <h1>{{ $t("features.canned-responses.common_message") }}</h1>
                <p>{{ $t("features.canned-responses.canned_response_reply") }}</p>

                <h3 id="Use-conversation">
                  {{ $t("features.canned-responses.response_conversation") }}
                </h3>
                <p>{{ $t("features.canned-responses.canned_response_available") }}</p>

                <p class="gif_wrp greeone">
                  <img
                    src="../../../../assets/images/_14/_14_4x.webp"
                    class="img-fuild"
                  />
                </p>
                <p class="gif_wrp gree">
                  <img
                    src="../../../../assets/images/_14/_14_3x.webp"
                    class="img-fuild"
                  />
                </p>
                <p class="gif_wrp greeimg">
                  <img
                    src="../../../../assets/images/_14/_14_2x.webp"
                    class="img-fuild"
                  />
                </p>
                <p class="gif_wrp greeres">
                  <img
                    src="../../../../assets/images/_14/_14_1x.webp"
                    class="img-fuild"
                  />
                </p>

                <h3 id="canned-response">
                  {{ $t("features.canned-responses.add_new_canned_response") }}
                </h3>
                <p>
                  {{ $t("features.canned-responses.admin_canned_response")
                  }}<strong> {{ $t("features.canned-responses.setting") }} </strong>
                  {{ $t("features.canned-responses.by_default_data") }}
                </p>

                <p class="gif_wrp">
                  <img src="../../../../assets/images/23.png" class="img-fuild" />
                </p>
                <p>
                  {{ $t("features.canned-responses.canned_available_top_right_data") }}
                </p>
                <p class="gif_wrp">
                  <img
                    src="../../../../assets/images/response-modal.png"
                    style="max-width: 500px"
                    class="img-fuild"
                  />
                </p>
                <p>{{ $t("features.canned-responses.field_shown") }}</p>

                <!-- <div class="table_wrp"> -->
                <div class="table-details" style="padding-left: 0">
                  <table class="table table-bordered description">
                    <thead>
                      <tr>
                        <th style="white-space: nowrap">
                          {{ $t("doc.labels.tableDetails.fieldName") }}
                        </th>
                        <th>
                          {{ $t("doc.AddandConfigure.widgetBuilderTab.description") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="white-space: nowrap">
                          {{ $t("features.canned-responses.shortcode") }}
                        </td>
                        <td>{{ $t("features.canned-responses.minimumLength") }}</td>
                      </tr>
                      <tr>
                        <td style="white-space: nowrap">
                          {{ $t("features.canned-responses.content") }}
                        </td>
                        <td>{{ $t("features.canned-responses.shortCodeData") }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- </div> -->

                <div class="canned_list_wrp">
                  <p>
                    {{ $t("features.canned-responses.Enterdetails") }}
                    <strong> {{ $t("features.canned-responses.submit") }} </strong>
                    {{ $t("features.canned-responses.request_btn") }}
                  </p>

                  <p>
                    <strong>{{ $t("doc.SDKSetup.sdkSettings.noteLabel") }}</strong
                    >{{ $t("features.canned-responses.canned_responses_agent") }}
                  </p>
                </div>

                <div class="canned_list_wrp">
                  <h3 id="Modify-canned">
                    {{ $t("features.canned-responses.modify_canned_response") }}
                  </h3>

                  <p>
                    {{ $t("features.canned-responses.to") }}
                    <strong>
                      {{ $t("features.canned-responses.canned_response") }}
                    </strong>
                    {{ $t("features.canned-responses.canned_response_list_form") }}
                    <strong> {{ $t("features.canned-responses.setting") }} </strong>
                    {{ $t("features.canned-responses.canned_response_available") }}
                    <strong> {{ $t("features.canned-responses.cancel") }} </strong>
                    {{ $t("features.canned-responses.discard_changes") }}
                  </p>

                  <p class="gif_wrp">
                    <img
                      src="../../../../assets/images/edit-canned.png"
                      style="max-width: 500px"
                      class="img-fuild"
                    />
                  </p>

                  <p class="gif_wrp">
                    <img
                      src="../../../../assets/images/edit-canned-response.webp"
                      style="max-width: 500px"
                      class="img-fuild"
                    />
                  </p>

                  <p>
                    {{ $t("features.canned-responses.to") }}
                    <strong>
                      {{ $t("features.canned-responses.delete_canned_response") }}
                    </strong>
                    {{ $t("features.canned-responses.deleteConfirmationModal")
                    }}<strong>{{ $t("features.canned-responses.yes_delete") }} </strong>
                    {{ $t("features.canned-responses.continue_deleteion") }}
                  </p>

                  <p class="gif_wrp">
                    <img
                      src="../../../../assets/images/delete-a-canned.webp"
                      style="max-width: 500px"
                      class="img-fuild"
                    />
                  </p>

                  <p>
                    <strong>{{ $t("doc.SDKSetup.sdkSettings.noteLabel") }}</strong
                    >{{ $t("features.canned-responses.cannedTemplate") }}
                    <a href="/docs/user-guide/features/template-variables">{{
                      $t("features.canned-responses.guide")
                    }}</a>
                    {{ $t("features.canned-responses.addingTemplate") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="update_edit_wrp">
              <!-- <div class="edit_wrp">
                                <a href="#">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>

                                    <p>
                                        {{ $t('doc.product.EditThisPage') }}
                                    </p>
                                </a>
                            </div> -->

              <div class="update_wrp">
                <p>
                  {{ $t("doc.product.LastUpdated") }}
                  <b>{{ $t("features.canned-responses.date") }}</b>
                  {{ $t("doc.product.By") }}
                  <b>{{ $t("features.canned-responses.author") }}</b>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p class="preview">{{ $t("doc.createChatWoodAccount.previous") }}</p>
                  <router-link
                    to="/docs/product/channels/supported-features"
                    class="introduction-account"
                  >
                    <span>«</span>{{ $t("features.canned-responses.supported_features") }}
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("doc.product.Next") }}</p>
                  <router-link to="/docs/user-guide/features/custom-attributes">
                    {{ $t("doc.SDKSetup.generateHMACLink.setCustomAttributes.title") }}
                    <span>»</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <ul id="side_section_info">
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#Use-conversation-link',
                  }"
                >
                  <a
                    id="#Use-conversation-link"
                    @click="scrollTo('#Use-conversation')"
                    class="link_wrp"
                    >{{ $t("features.canned-responses.response_conversation") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#canned-response-link',
                  }"
                >
                  <a
                    id="#canned-response-link"
                    @click="scrollTo('#canned-response')"
                    class="link_wrp"
                    >{{ $t("features.canned-responses.add_new_canned_response") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#Modify-canned-link',
                  }"
                >
                  <a
                    id="#Modify-canned-link"
                    @click="scrollTo('#Modify-canned')"
                    class="link_wrp"
                    >{{ $t("features.canned-responses.modify_canned_response") }}</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
  name: "ios",
  data() {
    return {
      isToggle: false,
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  components: {
    Header,
    Footer,
    SideBar,
    // MainContent
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

p.gif_wrp img {
  width: 100%;
  height: 100%;
}

.docmain-container {
  text-align: left;
  /* padding: 0 36px; */
}

.custome_list_wrp ul li {
  list-style-type: disc;
  text-align: left;
  /* padding: 20px 0; */
  color: var(--main-containt-font-color);
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  font-weight: 200;
}
</style>
