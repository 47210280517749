<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{$t('overview.channels')}}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{$t('concepts.overview')}}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{$t('doc.product.OnThisPage')}}</p>
                                                <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                        alt=""></span>

                                        
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#open-conversations-link' }">
                                                        <a @click="scrollTo('#open-conversations')" class="link_wrp">{{$t('overview.openConvos')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#agent-status-link' }">
                                                        <a @click="scrollTo('#agent-status')" class="link_wrp">{{$t('overview.agentStatus')}}</a>
                                                    </li>


                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#conversation-traffic-link' }">
                                                        <a @click="scrollTo('#conversation-traffic')"
                                                            class="link_wrp">{{$t('overview.conversationTraffic')}}</a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#conversations-by-agents-link' }">
                                                        <a @click="scrollTo('#conversations-by-agents')"
                                                            class="link_wrp">{{$t('overview.convoByAgents')}}</a></li>
s
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{$t('concepts.overview')}}</h1>
                            <p>{{$t('overview.overviewLive')}}
                            </p>

                        </div>

                        <div class="channels-info code-info">
                            <div class="channels-title">

                                <ul class="channels-menu-ul">
                                    <li>{{$t('overview.openConvos')}}</li>
                                    <li>{{$t('overview.agentStatus')}}</li>
                                    <li>{{$t('overview.convoByAgents')}}</li>
                                </ul>
                                <div align="center"><img src="../../../../../assets/images/live_agent_load.png"
                                        alt="Chatwoot Home Screen" class="max-img"></div>
                                <h3 class="anchorarticlenew" id="open-conversations">{{$t('overview.openConvos')}}<a class="hash-link" href="#open-conversations"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('overview.openoconvotypeMatrix')}}</p>
                                <ul class="channels-menu-ul">
                                    <li><strong>{{$t('overview.open')}}</strong> {{$t('overview.totalOpenConvo')}}</li>
                                    <li><strong>{{$t('overview.unattended')}}</strong>{{$t('overview.totalNoOf')}} <a
                                            href="/docs/user-guide/features/reports/concepts#unattended-conversations">{{$t('overview.unattconvos')}}</a> {{$t('overview.inAccounts')}}</li>
                                    <li><strong>{{$t('overview.unassigned')}}</strong> {{$t('overview.totalNoOf')}} <a
                                            href="/docs/user-guide/features/reports/concepts#unassigned-conversations">{{$t('overview.unassifnedconvos')}}</a> {{$t('overview.inAccounts')}}</li>
                                </ul>
                                <h3 class="anchorarticlenew" id="agent-status">{{$t('overview.agentStatus')}}<a class="hash-link"
                                        href="#agent-status" title="Direct link to heading">&ZeroWidthSpace;</a>
                                </h3>
                                <p>{{$t('overview.numagentstatus')}}</p>
                                <h3 class="anchorarticlenew" id="conversation-traffic">
                                    {{$t('overview.conversationTraffic')}}<a class="hash-link" href="#conversation-traffic"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('overview.convoTrafficPara')}}</p>
                                <h3 class="anchorarticlenew" id="conversations-by-agents">
                                    {{$t('overview.convoByAgents')}} <a class="hash-link" href="#conversations-by-agents"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{$t('overview.convoByagentPara')}}</p>

                            </div>
                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen"><svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg><a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{$t('doc.inboxes.edit_page')}}</a>
                            </div> -->
                            <p>{{$t('doc.inboxes.last_updated_on')}} <span class="dark">{{$t('overview.date')}} </span> {{$t('doc.inboxes.updated_by')}} <span class="dark">{{$t('overview.name')}}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{$t('concepts.previousPage')}}</p>


                                    <router-link to="/docs/user-guide/features/reports/concepts"
                                        class="introduction-account">
                                        <span>«</span> {{$t('concepts.mainconcepts')}}
                                    </router-link>


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{$t('concepts.nextPage')}}</p>

                                    <router-link to="/docs/user-guide/features/reports/conversation">
                                        {{$t('overview.conversations')}}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#open-conversations-link' }"><a
                                        id="#open-conversations-link" @click="scrollTo('#open-conversations')" class="link_wrp">{{$t('overview.openConvos')}}</a>
                                </li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#agent-status-link' }"><a
                                        id="#agent-status-link" @click="scrollTo('#agent-status')" class="link_wrp">{{$t('overview.agentStatus')}}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#conversation-traffic-link' }"><a
                                        id="#conversation-traffic-link" @click="scrollTo('#conversation-traffic')" class="link_wrp">{{$t('overview.conversationTraffic')}}</a>
                                </li>

                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#conversations-by-agents-link' }"><a
                                        id="#conversations-by-agents-link" @click="scrollTo('#conversations-by-agents')" class="link_wrp">{{$t('overview.convoByAgents')}}</a></li>



                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'overview',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
