<template>
    <Header @language-selected="handleLanguageSelected"/>
 <div class="blog-page">
        <div class="bg-white">
            <div class="bg-gradient-to-b from-white to-blue-50">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="blog-title-box">
                                <h1>{{ $t('custmerservice.custmerserviceCustomer') }}</h1>
                                <p>
                                  {{ $t('custmerservice.custmerservicegood') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-wrap d-flex">
                        <div class="left-blog">
                            <div class="left-blog-fixed">
                              <h3 class="uppercase">{{ $t('blog.blogbcoor') }}</h3>
                              <ul role="list" class="blogul">
                                <li>
                                  <a aria-current="page" href="file:///D:/chat/blog.html" class="">{{ $t('blog.blogs') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/product-update.html" class="">{{ $t('blog.blogCategories') }}</a>
                                </li>
                              </ul>
                            </div>
              
                            <div class="left-blog-fixed">
                              <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                              <ul role="list" class="blogul">
                                <li>
                                  <a aria-current="page" href="file:///D:/chat/custmer_service.html" class="">{{ $t('blog.blogCustomer') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor') }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        <div class="right-blog">
                            <div class="right-blog-list">
                                <div class="right-blog-list-inner">
                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2023/03/lessons-from-caretaker.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                      {{ $t('custmerservice.custmerservicecracked') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                      {{ $t('custmerservice.custmerserviceproviding') }}   
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first"> {{ $t('doc.createChatWoodAccount.hrichaShandily') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blogdate') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/12/optichannel-customer-service.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('custmerservice.custmerserviceengagement') }}   
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('custmerservice.custmerserviceoverwhelming') }}   
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blog21') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/12/customer-service-emails-guide.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('blog.blogdamn') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('custmerservice.custmerserviceResearch') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('custmerservice.custmerservicedecdec') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/11/making-good-FAQs.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                      {{ $t('custmerservice.custmerservicedecdec') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                       {{ $t('custmerservice.custmerservicethrough') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blognov') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/11/do-s-and-don-ts.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('blog.blogSatisfactory') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('blog.blogSatisgood') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blog07') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/12/how-to-communicate-with-customer.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('blog.blogService') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                       {{ $t('custmerservice.custmerservicedecdec') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blogOct') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/11/social-media-customer-service.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('blog.blogbeginner’s') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('custmerservice.custmerservicesocial') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blog10') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/10/customer-service-by-robots.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                          {{ $t('blog.blogcustomer') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                       {{ $t('custmerservice.custmerserviceAIdriven') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('custmerservice.custmerservice10') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/10/live-chat-support.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                          {{ $t('blog.blogcomplete') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                       {{ $t('blog.blogsecond-highest') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('blog.blog04') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/09/customer-service-vs-customer-support.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('custmerservice.custmerMind') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('custmerservice.custmersupport') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('custmerservice.custmersupport') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/09/omnichannel-customer-service.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('custmerservice.custmeromnichannel') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('custmerservice.custmeromnicontacting') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('custmerservice.custmertservice20') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/09/goals-in-customer-service-to-ensure-your-efforts-don-t-go-to-waste.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('custmerservice.custmertservicecritical') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('custmerservice.custmertserviceobjectivity') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('custmerservice.custmertservice2022') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/09/featured-image-2.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('custmerservice.custmertserviceevergreen') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('custmerservice.custmertserviceSatisfied') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('custmerservice.custmertservicesep06') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/08/customer-service-types.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                      {{ $t('custmerservice.custmertservicesecustomer') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('custmerservice.custmertserviceSetting') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second"> {{ $t('custmerservice.custmertserviceaug') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/08/Customer-success-KPIs.JPG" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('custmerservice.custmertservicecrucial') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('custmerservice.custmertserviceKPIs') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('custmerservice.custmertserviceAug5') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/07/customer-service-tips.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('custmerservice.custmertservice30') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('custmerservice.custmertserviceGood') }}  
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('custmerservice.custmertservicejul') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/07/customer-service-to-drive-business-results.jpeg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('custmerservice.custmertservicereasons') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                         {{ $t('custmerservice.custmertservicesuccess') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                                                        <p class="blog-user-second">{{ $t('custmerservice.custmertservice142022') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/06/better-cs-strategy-tips-1.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                         {{ $t('custmerservice.custmertserviceactionable') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('custmerservice.custmertservicestrategy') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                                        <p class="blog-user-second">{{ $t('custmerservice.custmertservicejun292022') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/11/employees-helping-customer-succeed-1.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                     {{ $t('custmerservice.custmertserviceComplete') }}   
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('custmerservice.custmertserviceexperience') }}  
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                                        <p class="blog-user-second">  {{ $t('custmerservice.custmertserviceapr') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>