<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{$t('home.header.Features')}}</p>
                            <img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow">
                            <a href="#">{{$t('pre_chat_form.PCF')}}</a>
                        </div>
                        <!-- <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                            <p>On this page</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>

                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_1"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                                            href="#channels-info">Channels</a></li>
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }"><a
                                                            href="#features-info">Features</a></li>
                                                    <li id="menu_item_3"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_3' }"><a
                                                            href="#integration-info">Integration</a></li>
                                                    <li id="menu_item_4"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_4' }"><a
                                                            href="#advanced-info">Advanced</a></li>
                                                    <li id="menu_item_5"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_5' }"><a
                                                            href="#mobile-app-info">Mobile app</a></li>
                                                    <li id="menu_item_6"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_6' }"><a
                                                            href="#other-info">Others</a></li>
                                                    <li id="menu_item_7"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_7' }"><a
                                                            href="#compatibility-info">Compatibility and Requirements</a>
                                                    </li>
                                                    <li id="menu_item_8"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_8' }"><a
                                                            href="#next-steps">Next Steps</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> -->

                        <div class="Introduction-details">
                            <h1>{{$t('pre_chat_form.collect_info_form')}}</h1>
                            <p>{{$t('pre_chat_form.PCFIPara')}}</p>

                            <p>{{$t('pre_chat_form.TypePCF')}}</p>

                            <p> {{$t('pre_chat_form.no1')}} <b>{{$t('pre_chat_form.stdfields')}}</b> {{$t('pre_chat_form.stdpara')}}</p>

                            <p> {{$t('pre_chat_form.no2')}} <b>{{$t('pre_chat_form.custome_field')}}</b> {{$t('pre_chat_form.fieldCreatedVia')}} <a href="#">{{$t('pre_chat_form.customeAttrbt')}}</a></p>

                            <p>{{$t('pre_chat_form.settingDesc')}}</p>

                            <ul>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('pre_chat_form.enable_disable_fields')}}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('pre_chat_form.COOFields')}}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('pre_chat_form.updatePlaceholder')}}</p>
                                </li>
                                <li style="list-style: disc;">
                                    <p class="pb-2">{{$t('pre_chat_form.EDValidation')}}</p>
                                </li>
                            </ul>
                            <div class="whatssimg">
                                <img src="../../../../assets/images/pre-chat-settings.webp">
                            </div>
                            <p>{{$t('pre_chat_form.tableDesc')}}</p>
                            <div class="table-details">
                                <table class="table table-bordered widthfull">
                                    <thead>
                                        <tr>
                                            <th>{{$t('pre_chat_form.field_name')}}</th>
                                            <th>{{$t('pre_chat_form.Desc')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{$t('pre_chat_form.Keyy')}}</td>
                                            <td>{{$t('pre_chat_form.FUniqueIdentify')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('pre_chat_form.type')}}</td>
                                            <td> {{$t('pre_chat_form.TOfield')}}</td>

                                        </tr>
                                        <tr>
                                            <td>{{$t('pre_chat_form.required')}}</td>
                                            <td>{{$t('pre_chat_form.RequiredOrNot')}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('pre_chat_form.label')}}</td>
                                            <td>{{$t('pre_chat_form.displayLabel')}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('pre_chat_form.placeHolder')}}</td>
                                            <td>{{$t('pre_chat_form.placeHolderVal')}}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <p>{{$t('pre_chat_form.pre_chat_enable_info')}}</p>
                            <div class="whatssimg" style="text-align: left;">
                                <img style="max-width: 340px;" src="../../../../assets/images/widget-pre-chat-form.webp">
                            </div>




                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{$t('doc.inboxes.edit_page')}}</a>
                            </div> -->
                            <p>{{$t('doc.inboxes.last_updated_on')}} <span class="dark">{{$t('pre_chat_form.date')}}</span> {{$t('doc.inboxes.updated_by')}} <span class="dark">{{$t('pre_chat_form.name')}}</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{$t('concepts.previousPage')}}</p>


                                    <router-link to="/docs/product/features/macros" class="introduction-account">
                                        <span>«</span> {{$t('pre_chat_form.macros')}}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{$t('concepts.nextPage')}}</p>



                                    <router-link to="/docs/user-guide/features/priority">
                                        {{$t('pre_chat_form.priority')}} <span>»</span>
                                    </router-link>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null
        };
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');

            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('href'));

                if (target?.offsetTop <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>


6500