<template>
<Header @language-selected="handleLanguageSelected"/>
    <!-- shared-inbox -->
 <section class="shared-inbox">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12 orderr-2">
                <div class="sharedinbox-heading chatpage-heading">
                    <h1>{{ $t('chatbots.chatbot_desc') }}</h1>
                    <p>{{ $t('chatbots.chatbotworkload') }}</p>
                    <a class="create-button" href="https://app.bcoor.com/app/auth/signup">{{ $t('home.header.Createafreeaccount') }}</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 orderr-1">
                <div class="sharedinbox-img chatpage chatbotsimgtwo">
                    <img src="../../../assets/images/chatbots/chat.gif">
                </div>
            </div>
        </div>
        
    </div>
 </section>
<!-- inbox -->

<!-- Private-sec -->
<section class="inbox Private-sec chatwoot-bg">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img chatbots-img">
                    <img src="../../../assets/images/chatbots/supported-platforms.png">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('chatbots.integrate') }}</h2>
                    <p>{{ $t('chatbots.Leverage') }}</p>
                </div>
            </div>
         
        </div>
    </div> 
</section>
<!-- Canned Responses -->
<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('chatbots.SeamlesslyDesc') }}</h2>
                    <p>{{ $t('chatbots.superiorData') }}</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/chatbots/agent-bot-handoff.png">
                </div>
            </div>     
        </div>
    </div> 
</section>

<section class="inbox Private-sec">
    <div class="container">
        <div class="row aline-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-img">
                    <img src="../../../assets/images/chatbots/message-types.png">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="index-details">
                    <h2>{{ $t('channels.rich_mesg') }}</h2>
                    <p>{{ $t('channels.supportexperiences') }}</p>
                </div>
            </div>     
        </div>
    </div> 
</section>
<Footer :selectLanguage="selectLanguage"/>
</template>

<script>
import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header
  },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
        handleRedirect() {
            window.location.href = 'https://app.bcoor.com'
        }
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
}
</script>