<template>
    <Header @language-selected="handleLanguageSelected"/>
       <div class="blog-page">
    <div class="bg-white">
      <div class="bg-gradient-to-b from-white to-blue-50">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="blog-title-box">
                <h1>{{ $t('blog.blogbcoor') }}</h1>
                <p>
                 {{ $t('blog.blogread') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main class="container-lg">
      <div class="row">
        <div class="col-md-12">
          <div class="blog-wrap d-flex">
            <div class="left-blog">
              <div class="left-blog">
                <h3 class="uppercase">{{ $t('blog.blogCategories') }}</h3>
                <ul role="list" class="blogul">
                  <li>
                    <a aria-current="page" href="file:///D:/chat/blog.html" class="router-link-active">{{ $t('blog.blogs') }}</a>
                  </li>
                  <li data-v-a9d1c59d="">
                    <a href="file:///D:/chat/product-update.html" class="router-link-active">{{ $t('blog.blogupdates') }}</a>
                  </li>
                </ul>
              </div>

              <div class="left-blog">
                <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                <ul role="list" class="blogul">
                  <li>
                    <a aria-current="page" href="file:///D:/chat/custmer_service.html" class="">{{ $t('blog.blogCustomer') }}</a>
                  </li>
                  <li data-v-a9d1c59d="">
                    <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering') }}</a>
                  </li>
                  <li data-v-a9d1c59d="">
                    <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource') }}</a>
                  </li>
                  <li data-v-a9d1c59d="">
                    <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                  </li>
                  <li data-v-a9d1c59d="">
                    <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor') }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="right-blog">
              <div class="right-blog-list">
                <div class="right-blog-list-inner">
                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2023/06/AI-and-Chatwoot.png" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogLeveraging') }}
                          </div>
                          <p class="listing-blog-paragraph">
                           {{ $t('blog.blogfeatures') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first"> {{ $t('blog.blogHricha') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogJun') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2023/06/dashboard-apps.webp" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.bloghow') }}
                          </div>
                          <p class="listing-blog-paragraph">
                            {{ $t('blog.bloghow') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('doc.createChatWoodAccount.hrichaShandily') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogJun') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2023/05/copywriting-guide.png" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogPrinciples') }}
                          </div>
                          <p class="listing-blog-paragraph">
                             {{ $t('blog.blogcontent') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('doc.createChatWoodAccount.hrichaShandily') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogJun') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/lessons-from-caretaker.png" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                            {{ $t('blog.blogcaretaker') }}
                          </div>
                          <p class="listing-blog-paragraph">
                          {{ $t('blog.blogservice') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('doc.createChatWoodAccount.hrichaShandily') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogdate') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/chatwoot-is-soc2-type2-compliant--1-.png" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                            {{ $t('blog.blogcompliant') }}
                          </div>
                          <p class="listing-blog-paragraph">
                           {{ $t('blog.blogcompliantSOC') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www-internal-blog.chatwoot.com/content/images/2021/08/sojan-official.jpg"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogSojan') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogmar') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2023/01/chatwoot-year-in-review.jpg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogImproving') }}
                          </div>
                          <p class="listing-blog-paragraph">
                             {{ $t('blog.blogaround') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">  {{ $t('blog.blogPranav') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogDec') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/12/optichannel-customer-service.jpeg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                             {{ $t('blog.blogOptichannel') }}
                          </div>
                          <p class="listing-blog-paragraph">
                               {{ $t('blog.blogoverwhelming') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blog2022') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/12/customer-service-emails-guide.jpg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                            {{ $t('blog.blogdamn') }}
                          </div>
                          <p class="listing-blog-paragraph">
                            {{ $t('blog.blogResearch') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blog21') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/11/making-good-FAQs.jpg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogclever') }}
                          </div>
                          <p class="listing-blog-paragraph">
                             {{ $t('blog.blogthrough') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blognov') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/11/do-s-and-don-ts.jpeg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                            {{ $t('blog.blogSatisfactory') }}
                          </div>
                          <p class="listing-blog-paragraph">
                            {{ $t('blog.blogSatisgood') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blog07') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/12/how-to-communicate-with-customer.jpg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogService') }}
                          </div>
                          <p class="listing-blog-paragraph">
                           {{ $t('blog.blogCommunication') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogOct') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/11/social-media-customer-service.jpg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogbeginner’s') }}
                          </div>
                          <p class="listing-blog-paragraph">
                          {{ $t('blog.blogbeginner’s') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blog17') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/10/customer-service-by-robots.jpeg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                           {{ $t('blog.blogcustomer') }} 
                          </div>
                          <p class="listing-blog-paragraph">
                            {{ $t('blog.blogcustomer') }} 
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blog10') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/10/live-chat-support.jpeg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                            {{ $t('blog.blogcomplete') }}
                          </div>
                          <p class="listing-blog-paragraph">
                            {{ $t('blog.blogsecond-highest') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blog04') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-blog-main">
                    <div class="listing-blog">
                      <div class="listing-blog-left">
                        <a href="#">
                          <img
                            src="https://www-internal-blog.chatwoot.com/content/images/2022/09/customer-service-vs-customer-support.jpeg" />
                        </a>
                      </div>
                      <div class="listing-blog-right">
                        <div class="listing-blog-wrap">
                          <div class="listing-blog-heading">
                            {{ $t('blog.blogMind') }}
                          </div>
                          <p class="listing-blog-paragraph">
                            {{ $t('blog.blogsupport') }}
                          </p>
                        </div>
                        <div class="d-flex align-items-center blog-user">
                          <div class="blog-user-image">
                            <img src="https://www.gravatar.com/avatar/7d14b03ca23f22df87a3e5209022d0fa?s=250&r=x&d=mp"
                              alt="Hricha Shandily" />
                          </div>
                          <div class="blog-user-text">
                            <p class="blog-user-first">{{ $t('blog.blogIshita') }}</p>
                            <p class="blog-user-second">{{ $t('blog.blogsep') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>