<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.channels') }}</p>
                                <img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow">
                            <a href="#"><p>{{ $t('doc.facebook.facebook') }}</p></a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.facebook.onThisPage') }} </p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <div class="tableOfContents-list">
                                            <ul id="side_section_info">
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#additional-inbox-settings-link' }">
                                                <a @click="scrollTo('#additional-inbox-settings')">{{ $t('doc.facebook.additionalInboxSettings') }}</a>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#settings-tab-link' }">
                                                <a @click="scrollTo('#settings-tab')">{{ $t('doc.facebook.settingsTab') }}</a>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#collaborators-tab-link' }">
                                                <a @click="scrollTo('#collaborators-tab')">{{ $t('doc.facebook.collaboratorsTab') }}</a>
                                            </li>
                                            <li :class="{ 'active_menu_item': rightPanelMenuItem === '#business-hours-tab-link' }">
                                                <a @click="scrollTo('#business-hours-tab')">{{ $t('doc.facebook.businessHoursTab') }}</a>
                                            </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('doc.facebook.setupTitle') }}</h1>
                            <p>{{ $t('doc.facebook.selfHostedNote') }}&nbsp;
                                <a href="#">{{ $t('doc.facebook.selfHostedGuide') }}</a>
                            </p>
                            <p>{{ $t('doc.facebook.cloudVersionNote') }}</p>


                        </div>

                        <div class="channels-info code-info">
                            <div class="channels-title">

                                <p><strong>{{ $t('doc.facebook.step1') }}</strong>{{ $t('doc.facebook.step1Content') }}</p>
                                <p><img src="../../../../assets/images/add-inbox.webp" class="max-img"></p>
                                <p><strong>{{ $t('doc.facebook.step2') }}</strong>{{ $t('doc.facebook.step2Content') }}</p>
                                <p><img src="../../../../assets/images/list_of_channels_new.webp" class="max-img"></p>
                                <p><strong>{{ $t('doc.facebook.step3') }}</strong>{{ $t('doc.facebook.step3Content') }}</p>
                                <p><img src="../../../../assets/images/fb-signin-in-chatwoot.png" class="max-img"></p>
                                <p><strong>{{ $t('doc.facebook.step4') }}</strong>{{ $t('doc.facebook.step4Content') }}</p>
                                <p><img alt="link_account" src="../../../../assets/images/authenticate-chatwoot.webp" class="max-img"></p>
                                <p><img alt="list_of_pages" src="../../../../assets/images/chatwoot-permissions.webp"
                                        class="max-img"></p>
                                <p><strong>{{ $t('doc.facebook.step5') }}</strong>{{ $t('doc.facebook.step5Content') }}</p>
                                <p><img src="../../../../assets/images/choose-page.png" class="max-img"></p>
                                <p><strong>{{ $t('doc.facebook.step5') }}</strong>{{ $t('doc.facebook.step5Content') }}</p>
                                <p><img alt="finish_inbox" src="../../../../assets/images/add-agents-to-fb.webp"
                                        class="max-img"></p>
                                <p>{{ $t('doc.facebook.successMessage') }}</p>
                                <p><img alt="inbox_settings" src="../../../../assets/images/fb-inbox-is-ready.webp"
                                        class="max-img"></p>
                                <h2 class="anchor anchorWithStickyNavbar_mojV" id="additional-inbox-settings">
                                    {{ $t('doc.facebook.additionalSettings') }}<a class="hash-link" href="#additional-inbox-settings"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h2>
                                <p>{{ $t('doc.facebook.additionalSettingsContent') }}</p>
                                <p><img alt="fb_settings" src="../../../../assets/images/fb-inbox-settings.png"
                                        class="max-img"></p>
                                <h3 class="anchor anchorWithStickyNavbar_mojV" id="settings-tab">{{ $t('doc.facebook.settingsTab') }}<a
                                        class="hash-link" href="#settings-tab"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p><strong>{{ $t('doc.facebook.enablegreeting') }}</strong> {{ $t('doc.facebook.enableGreetingContent') }} </p>
                                <p><strong>{{ $t('doc.facebook.enableCSAT') }}</strong> {{ $t('doc.facebook.enableCSATContent') }}</p>
                                <p><strong>{{ $t('doc.facebook.reauthorize') }}</strong> {{ $t('doc.facebook.reauthorizeContent') }}</p>
                                <h3 class="anchor anchorWithStickyNavbar_mojV" id="collaborators-tab">
                                    Collaborators tab<a class="hash-link" href="#collaborators-tab"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('doc.facebook.clickOnThe') }}&nbsp;<code>{{ $t('doc.facebook.collaborators') }}</code>&nbsp;{{ $t('doc.facebook.tab') }}</p>
                                <p>{{ $t('doc.facebook.collaboratorsTabContent1') }}</p>
                                <p>{{ $t('doc.facebook.collaboratorsTabContent2') }}</p>
                                <p><img alt="fb_settings" src="../../../../assets/images/collaborators-on-fb.png"
                                        class="max-img"></p>
                                <p>{{ $t('doc.facebook.clickOn') }}&nbsp;<code>{{ $t('doc.facebook.update') }}</code>&nbsp;{{ $t('doc.facebook.saveChanges') }}</p>
                                <h3 class="anchor anchorWithStickyNavbar_mojV" id="business-hours-tab">{{ $t('doc.facebook.businessHoursTab') }}<a class="hash-link" href="#business-hours-tab"
                                        title="Direct link to heading">&ZeroWidthSpace;</a></h3>
                                <p>{{ $t('doc.facebook.clickOnThe') }}&nbsp;<code>{{ $t('doc.facebook.businessHours') }}</code>&nbsp;{{ $t('doc.facebook.businessHoursTabContent') }}
                                </p>
                                <p><img alt="business hours setting on fb"
                                        src="../../../../assets/images/business-hrs-fb.png" class="max-img"></p>
                                <p>{{ $t('doc.facebook.additionalSettings') }}
                                    &nbsp;<code>{{ $t('doc.facebook.businessAvailability') }}</code>&nbsp;{{ $t('doc.facebook.businessAvailability1') }}</p>
                                <p>{{ $t('doc.facebook.businessAvailability2') }}</p>
                                <p>{{ $t('doc.facebook.businessAvailability3') }}</p>
                                <p><img alt="business hours setting on fb"
                                        src="../../../../assets/images/business-hours-advanced-settings-fb.png"
                                        class="max-img"></p>
                                <p>{{ $t('doc.facebook.clickOnThe') }}&nbsp;{{ $t('doc.facebook.BusinessHour') }}&nbsp;{{ $t('doc.facebook.button') }}</p>

                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">Edit this page</a>
                            </div> -->
                            <p>{{ $t('doc.facebook.lastUpdated') }} <span class="dark">10/20/2022 </span>{{ $t('doc.facebook.updatedBy') }} <span class="dark">{{ $t('doc.facebook.author') }}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.facebook.navigation.previous') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/setup-instruction/wordpress"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('doc.facebook.navigation.wordPress') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.facebook.navigation.next') }}</p>
                                    <router-link to="/docs/product/channels/instagram">
                                        {{ $t('doc.facebook.navigation.instagram') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                <section class="tableOfContents">
                <div class="tableOfContents-list">
                    <ul id="side_section_info">
                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#additional-inbox-settings-link' }">
                        <a id="#additional-inbox-settings-link" @click="scrollTo('#additional-inbox-settings')">{{ $t('doc.facebook.additionalInboxSettings') }}</a>
                    </li>
                        <ul>
                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#settings-tab-link' }">
                            <a id="#settings-tab-link" @click="scrollTo('#settings-tab')">{{ $t('doc.facebook.settingsTabLink') }}</a>
                        </li>
                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#collaborators-tab-link' }">
                            <a id="#collaborators-tab-link" @click="scrollTo('#collaborators-tab')">{{ $t('doc.facebook.collaboratorsTabLink') }}</a>
                        </li>
                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#business-hours-tab-link' }">
                            <a id="#business-hours-tab-link" @click="scrollTo('#business-hours-tab')">{{ $t('doc.facebook.businessHoursTabLink') }}</a>
                        </li>
                        </ul>
                    </ul>
                </div>
                </section>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'facebook',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
