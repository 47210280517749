<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="Add-Teams">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.teams.addTeams') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.teams.addTeams') }}</h1>
                            <p>{{ $t('doc.teams.teamsIntroduction') }}</p>
                            <p>{{ $t('doc.teams.startAddingTeams') }}</p>
                        </div>
                        <div class="add-teams-img">
                            <img src="../../../../assets/images/adding-new-team-.png" alt="logo26">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.teams.teamsImgDescription') }}</p>
                        </div>
                        <div class="add-teams-img">
                            <img src="../../../../assets/images/team-settings-.png" alt="logo26">
                        </div>
                        <div class="table-details">
                            <table class="table table-bordered description">
                                <thead>
                                    <tr>
                                        <th style="white-space: nowrap;">{{ $t('doc.teams.fieldName') }}</th>
                                        <th>{{ $t('doc.teams.description') }}</th>
                                        <th>{{ $t('doc.teams.remarks') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ $t('doc.teams.teamName') }}</td>
                                        <td>{{ $t('doc.teams.teamNameDescription') }}</td>
                                        <td>{{ $t('doc.teams.teamNameRemarks') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.teams.teamDescription') }}</td>
                                        <td>{{ $t('doc.teams.teamDescriptionDescription') }}</td>
                                        <td>{{ $t('doc.teams.teamDescriptionRemarks') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('doc.teams.checkBox') }}</td>
                                        <td>{{ $t('doc.teams.checkBoxDescription') }}
                                        </td>
                                        <td>{{ $t('doc.teams.checkBoxRemarks') }}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="update-profile-check">
                        <p>{{ $t('doc.teams.createTeam') }}</p>
                        <p>{{ $t('doc.teams.addAgentsStep') }}</p>
                        <p>{{ $t('doc.teams.agentsNotification') }}</p>
                        <p>{{ $t('doc.teams.selectCheckboxes') }}</p>
                        <p>{{ $t('doc.teams.clickAddAgents') }}</p>
                        </div>
                        <div class="add-teams-img">
                            <img src="../../../../assets/images/add-agents-to-team.png" alt="logo26">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.teams.createTeam') }}</p>
                            <p>{{ $t('doc.teams.addAgentsStep') }}</p>
                        </div>
                        <div class="add-teams-img">
                            <img src="../../../../assets/images/teams.png" alt="logo26">
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.inboxes.edit_page') }}</a>
                            </div> -->
                            <p>{{ $t('doc.inboxes.last_updated_on') }} <span class="dark">10/17/2022 </span>{{ $t('doc.inboxes.updated_by') }} <span class="dark">{{ $t('doc.createChatWoodAccount.hrichaShandily') }} 
                                </span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.inboxes.previous') }} </p>
                                    <router-link to="/docs/user-guide/setup-your-account/add-inbox-settings" class="introduction-account">
                                        <span>«</span> {{ $t('doc.teams.addInboxes') }} 
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                     <router-link to="/docs/user-guide/setup-your-account/add-label-settings">
                                        {{ $t('doc.teams.AddLabels') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default ({})
</script>
<style>
.add-teams-img img {
    width: 100%;
    height: 100%;
}

.add-teams-img {
    padding: 0px 36px;
}</style>