<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("doc.product.Features") }}</p>
              <img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow" /><a
                href="#"
                >{{ $t("features.conversation-workflow.conversationWorkflow") }}</a
              >
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.product.OnThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img src="/img/up_arrow_side.7f2fb856.svg" alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item: rightPanelMenuItem === '#campaigns-link',
                            }"
                          >
                            <a @click="scrollTo('#campaigns')" class="link_wrp">{{
                              $t("features.conversation-workflow.Campaigns")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#pre-chat-forms-link',
                            }"
                          >
                            <a @click="scrollTo('#pre-chat-forms')" class="link_wrp">{{
                              $t("features.conversation-workflow.PreChatForms")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#conversations-link',
                            }"
                          >
                            <a @click="scrollTo('#conversations')" class="link_wrp">{{
                              $t("features.conversation-workflow.conversations")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#assign-agent-link',
                            }"
                          >
                            <a @click="scrollTo('#assign-agent')" class="link_wrp">{{
                              $t("features.conversation-workflow.AssignAgent")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#assign-team-link',
                            }"
                          >
                            <a @click="scrollTo('#assign-team')" class="link_wrp">{{
                              $t("features.conversation-workflow.AssignTeam")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#assign-labels-link',
                            }"
                          >
                            <a @click="scrollTo('#assign-labels')" class="link_wrp">{{
                              $t("features.conversation-workflow.AssignLabels")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#delete-messag-link',
                            }"
                          >
                            <a @click="scrollTo('#delete-message')" class="link_wrp">{{
                              $t("features.conversation-workflow.DeleteMessage")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#sending-e-mail-link',
                            }"
                          >
                            <a @click="scrollTo('#sending-e-mails')" class="link_wrp">{{
                              $t("features.conversation-workflow.SendingE-mails")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#sending-emails-from-widge-link',
                            }"
                          >
                            <a
                              @click="scrollTo('#sending-emails-from-widget')"
                              class="link_wrp"
                              >{{
                                $t(
                                  "features.conversation-workflow.SendingEmailsfromWidget"
                                )
                              }}</a
                            >
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#transfering-agent-link',
                            }"
                          >
                            <a
                              @click="scrollTo('#transfering-agents')"
                              class="link_wrp"
                              >{{
                                $t("features.conversation-workflow.TransferingAgents")
                              }}</a
                            >
                          </li>
                          <li
                            :class="{
                              active_menu_item:
                                rightPanelMenuItem ===
                                '#changing-conversation-status-or-setting-reminder-link',
                            }"
                          >
                            <a
                              @click="
                                scrollTo(
                                  '#changing-conversation-status-or-setting-reminders'
                                )
                              "
                              class="link_wrp"
                              >{{
                                $t(
                                  "features.conversation-workflow.ChangingConversationStatusorsettingReminders"
                                )
                              }}</a
                            >
                            <ul>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem ===
                                    '#changing-conversation-status-or-setting-reminder-link',
                                }"
                              >
                                <a
                                  @click="
                                    scrollTo(
                                      '#changing-conversation-status-or-setting-reminders'
                                    )
                                  "
                                  class="link_wrp"
                                  >{{
                                    $t(
                                      "features.conversation-workflow.ConversationStatuses"
                                    )
                                  }}</a
                                >
                              </li>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#Workflo-link',
                                }"
                              >
                                <a @click="scrollTo('#Workflow')" class="link_wrp">{{
                                  $t("Advance.Agentbots.workflow")
                                }}</a>
                              </li>
                              <li
                                :class="{
                                  active_menu_item: rightPanelMenuItem === '#csa-link',
                                }"
                              >
                                <a @click="scrollTo('#csat')" class="link_wrp">{{
                                  $t("doc.product.CSAT")
                                }}</a>
                              </li>
                              <li
                                :class="{
                                  active_menu_item:
                                    rightPanelMenuItem === '#previous-conversation-link',
                                }"
                              >
                                <a
                                  @click="scrollTo('#previous-conversations')"
                                  class="link_wrp"
                                  >{{
                                    $t(
                                      "features.conversation-workflow.PreviousConversations"
                                    )
                                  }}</a
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="Introduction-details text-align-left">
                  <h1>{{ $t("features.conversation-workflow.conversationWorkflow") }}</h1>
                  <p>
                    {{ $t("features.conversation-workflow.conversationWorkflowDesc") }}
                  </p>
                  <p>
                    {{ $t("features.conversation-workflow.conversationWorkflowDesc1") }}
                  </p>
                  <p>{{ $t("features.conversation-workflow.exampleType") }}</p>
                </div>

                <div class="channels-info break-word">
                  <div class="channels-title">
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens.webp"
                        width="800"
                        alt="Chatwoot Widget Screen"
                        class="max-img"
                      />
                    </div>
                    <p>
                      {{ $t("features.conversation-workflow.automatedResponse")
                      }}<a
                        href="/docs/user-guide/setup-your-account/add-inbox-settings"
                        >{{
                          $t("doc.AddandConfigure.TableDetails.channelGreetingMessage")
                        }}</a
                      >
                      {{ $t("features.conversation-workflow.automatedResponseDesc") }}
                      <a href="/docs/user-guide/contacts">record</a>.
                    </p>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="campaigns">
                      {{ $t("features.conversation-workflow.Campaigns")
                      }}<a
                        class="hash-link"
                        href="#campaigns"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>
                      {{ $t("features.conversation-workflow.customerConversation")
                      }}<a
                        href="/docs/user-guide/setup-your-account/add-inbox-settings#campaigns"
                        >{{ $t("features.conversation-workflow.Campaignmessage") }}</a
                      >,
                      {{ $t("features.conversation-workflow.widgetScreen") }}
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/campaign4.png"
                        width="800"
                        alt="Campaign Message Display to End User"
                        class="max-img"
                      />
                    </div>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="pre-chat-forms">
                      {{ $t("features.conversation-workflow.PreChatForms")
                      }}<a
                        class="hash-link"
                        href="#pre-chat-forms"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>
                      {{ $t("features.conversation-workflow.weHaveEnbled") }}
                      <a
                        href="/docs/user-guide/setup-your-account/add-inbox-settings#pre-chat-form"
                        >{{ $t("features.conversation-workflow.PreChatForms") }}</a
                      >
                      {{ $t("features.conversation-workflow.reuestingCustomer") }}
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/prechatform.webp"
                        width="800"
                        alt="Chatwoot Widget Pre Chat Form "
                        class="max-img"
                      />
                    </div>
                    <p>
                      {{ $t("features.contacts.detailConfiguration")
                      }}<a href="/docs/user-guide/features/pre-chat-form">{{
                        $t("doc.conversationContinuity.guide")
                      }}</a>
                    </p>
                    <p>{{ $t("features.conversation-workflow.CaptureDashboard") }}</p>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="conversations">
                      {{ $t("features.contacts.Conversations")
                      }}<a
                        class="hash-link"
                        href="#conversations"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.BcoorAgentDashboard") }}</p>
                    <img
                      loading="lazy"
                      src="../../../../assets/images/main-app.webp"
                      alt="Chatwoot Dashboard Home Screen"
                      class="max-img"
                    />
                    <p>{{ $t("features.conversation-workflow.conversationCustomer") }}</p>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-agent">
                      {{ $t("features.conversation-workflow.AssignAgent")
                      }}<a
                        class="hash-link"
                        href="#assign-agent"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.autoAssignCreated") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/assign-agent.webp"
                        alt="Assign Agent to Conversation Drop Down"
                        class="max-img"
                      />
                    </div>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-team">
                      {{ $t("features.conversation-workflow.AssignTeam")
                      }}<a
                        class="hash-link"
                        href="#assign-team"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.dropBoxTeam") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/assign-team.webp"
                        alt="Assign Team to Conversation Drop Down "
                        class="max-img"
                      />
                    </div>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-labels">
                      {{ $t("features.conversation-workflow.AssignLabels")
                      }}<a
                        class="hash-link"
                        href="#assign-labels"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.LabelsConversations") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/add-remove-labels.webp"
                        alt="Assign Lable to Converesation Drop Down"
                        class="max-img"
                      />
                    </div>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-priority">
                      {{ $t("features.conversation-workflow.AssignPriority")
                      }}<a
                        class="hash-link"
                        href="#assign-priority"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>
                      {{ $t("features.conversation-workflow.dropBoxPriority") }}
                      <a href="/docs/user-guide/features/priority">here</a>
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/set-priority.webp"
                        alt="Actions done are recorded Chat screen"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.screenshotActions") }}</p>
                    <h3
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="reply-for-the-conversation"
                    >
                      {{ $t("features.conversation-workflow.Conversation")
                      }}<a
                        class="hash-link"
                        href="#reply-for-the-conversation"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.typingStartArea") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens.webp"
                        width="800"
                        alt="Chat Reply Window"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.buttonMessage") }}</p>
                    <p>{{ $t("features.conversation-workflow.variousOption") }}</p>
                    <img
                      loading="lazy"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAA5CAYAAACF8yP/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAH/SURBVGhD7ZqxbsIwFEX7af2IfgMLOx/AwA+wsbEzM2XNxsLMGokuZWilSlWpXnMdkhbXmGtHDi72ke5A89DLqZ+dSumDJEiWToUsnQpZOhWydCp4Sb9/fMnL61GeD5+yHzjoid64B1+cpQ9vR+PN3CK4Fx+cpGMSbuMjTktjnExNY4jLqKOWlsY+MjWMIbg3FtTS0rc4tNjg3lhQS0ubmsUUFtRmaRt6k9jCgtosbUNvEltYUJulbehNYgsLarO0Db2JezYyexrLo57JWrbGerewoHYQ6e1qWgsupDBc25cLJT8rDdccwoLa8NKQurqamIKpLHema1xYUBtYGjIXVljPbi2jHqPOgtqw0vUqj1aV+dqfVLKc+K82C2qDShfzZq82e9p0aP0cbm2d795mQe0A0pUUddTPdtW59K/PRblR0vxknIcFtUGlu5XDfp1vjDVqrOfNBNzFSquTW8liv46Nq4hpaMb+Tva0fjgpwdMebtOtbPcLar/rFhbUBpaug9G+9thSf6CQj7YLYUFteGlEiVvGu6cwwoLaYaRP6R5dpvHuGRbUDiodMiyozdI29CaxhQW1WdqG3iS2sKA2S9vQm8QWFtTS0km+wEvyVW2SL+VBcv9+0RKTuI8wcJYGGBHsjVscbuiJ3i4jreMl/d/J0qmQpVMhS6dCgtIi3xaysPOfOs63AAAAAElFTkSuQmCC"
                      width="50"
                      alt="Emoji Button"
                      class="max-img"
                    />
                    {{ $t("features.conversation-workflow.MessageButton") }}
                    <img
                      loading="lazy"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAuCAYAAACrrAD9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAEJSURBVFhH7ZixDoIwEIZ5NB6CZ3Bh9wEceAE2N3YegdWNxdmVBBcZNDExYs4clVi1JuQK8sfcJR8N9Ei/tNAmFxBwqJw0Ornz5UaHY0v75kr1j+ExeWx2eI+gObXOl+aAXewIXElzYgvCyTH9EkPK8TcIK8c/Cawco3JSVE6Kykn5Z7mKsmVMYWRY5JUjR46X3DZfWUIsuqJs95knxUuuSGNKNt/vfZlErkjHmcER5J7LqXJDUTlXxxBUztUxhH4T5tacEmsqmpKSCECu7kTMbPXPePbCtLRy5HjKMUZwivN1BLnpUDkpKidF5aTAykEXcqBLYLDFw5eyK3TBmi+wpf5HCxkqJwuiO51xnKdYqghgAAAAAElFTkSuQmCC"
                      width="50"
                      alt="Attachment Button"
                      class="max-img"
                    />
                    {{ $t("features.conversation-workflow.MessageButton") }}
                    <img
                      loading="lazy"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAArCAYAAAA3+KulAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAFKSURBVGhD7ZmxakIxFIb7aD5Edx/AydHJ2cnBB3BychQcHaV0dJC6SYcWSilO3eqpPzWQ3kT/KMETw/ngW+7lJvlIcDAPcoaP3best5/y/PIuT+u3m4o5MTfWcAnRoJ/9XjavX9GJNMRasKYUokElxTixphSCIGxxbMASTDl+QRDObWywEsTaGEGQxg9AqlgbIwiKDVSSDAvSlmFB2jIsSFuGBWnLyBo0H/el9dg5OJLJv3crGXbx/OBg4T2/XEa+oNnoGBMGTQbu+R0Fud3pzZrvFtKLRF4rI1vQ3y70ZbhsvFtOpY2g7lTm/vMrZWQO8j3uiAvybI9X0TFSZFjQKS2o8X2qDAs6pQU1vk+VkS3oVjIsSFuGBWnLsCBtGfUHVfdnfXXXKdVdeIGqriRBdZfGDmwxzq3GDwXmxNwpx8znbNA9YkFlI/ILHmhsh3BDxGIAAAAASUVORK5CYII="
                      width="50"
                      alt="Rich Text Editor Button"
                      class="max-img"
                    />
                    {{ $t("features.conversation-workflow.MessageButton") }}
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens11.png"
                        width="800"
                        alt="Chat Reply Window"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.editedCheckbox") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens13.png"
                        width="800"
                        alt="Rich Text Edite Message Screen"
                        class="max-img"
                      />
                    </div>
                    <h3
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="how-to-send-canned-responses"
                    >
                      {{ $t("features.conversation-workflow.cannedResponse") }}
                      <a
                        class="hash-link"
                        href="#how-to-send-canned-responses"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>
                      {{ $t("features.conversation-workflow.supposeInclude")
                      }}<a href="#">
                        {{ $t("Channels.Supportedfeatures.cannedResponses") }}
                      </a>
                      {{ $t("features.conversation-workflow.responseForCannedResponse") }}
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/31.png"
                        width="800"
                        alt="Canned Response Selection Window"
                        class="max-img"
                      />
                    </div>
                    <h2
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="private-chat-with-agents"
                    >
                      {{ $t("features.conversation-workflow.ProvateChatWithAgents") }}
                      <a
                        class="hash-link"
                        href="#private-chat-with-agents"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>
                      {{ $t("features.conversation-workflow.ProvateChatWithAgentsDEsc") }}
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/32.png"
                        width="800"
                        alt="Private Note Window "
                        class="max-img"
                      />
                    </div>
                    <p>
                      {{ $t("features.conversation-workflow.ProvateChatWithAgentsDEsc") }}
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/33.png"
                        width="800"
                        alt="Agent Selection Screen"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.LockedSign") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/34.png"
                        width="800"
                        alt="Message to Agent Window"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.whomPrivate") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/35.png"
                        width="800"
                        alt="Chatwoot Home Screen"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.repliedSecondAgent") }}</p>
                    <h2 class="anchor anchorWithStickyNavbar_mojV" id="delete-message">
                      {{ $t("features.conversation-workflow.DeleteMessage")
                      }}<a
                        class="hash-link"
                        href="#delete-message"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>{{ $t("features.conversation-workflow.ClickDelete") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/36.png"
                        width="800"
                        alt="Delete Option for Chat Screen"
                        class="max-img"
                      />
                    </div>
                    <h2 class="anchor anchorWithStickyNavbar_mojV" id="sending-e-mails">
                      {{ $t("features.conversation-workflow.SendingE-mails")
                      }}<a
                        class="hash-link"
                        href="#sending-e-mails"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>{{ $t("features.conversation-workflow.ProvidedUser") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens18b.webp"
                        width="800"
                        alt="Customer email ID display screen"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.transcriptScreen") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens18c.webp"
                        width="800"
                        alt="Customer email ID display screen"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.AssignedAddress") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens18d.webp"
                        width="800"
                        alt="Send Transcript options"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.SelectingEmail") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens18e.webp"
                        width="800"
                        alt="Send Transcript to Customer Option"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.successFullyInbox") }}</p>
                    <h2
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="sending-emails-from-widget"
                    >
                      {{ $t("features.conversation-workflow.SendingEmailsfromWidget")
                      }}<a
                        class="hash-link"
                        href="#sending-emails-from-widget"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>{{ $t("features.conversation-workflow.resolvedData") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/sendingemailsfromwidget.webp"
                        width="800"
                        alt="Chatwoot widget Send Transcript to Customer Option"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.displayedEmail") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/sendingemailsfromwidget1.webp"
                        width="800"
                        alt="Chatwoot widget email sent message screen"
                        class="max-img"
                      />
                    </div>
                    <h2
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="transfering-agents"
                    >
                      {{ $t("features.conversation-workflow.TransferingAgents")
                      }}<a
                        class="hash-link"
                        href="#transfering-agents"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>
                      {{ $t("features.conversation-workflow.TicketIssue") }}
                      <a href="/docs/user-guide/conversation-workflow#assign-agent">{{
                        $t("features.conversation-workflow.AssignAgentoption")
                      }}</a>
                      {{ $t("features.conversation-workflow.wholeConversation") }}
                    </p>
                    <h2
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="changing-conversation-status-or-setting-reminders"
                    >
                      {{
                        $t(
                          "features.conversation-workflow.ChangingConversationStatusorsettingReminders"
                        )
                      }}<a
                        class="hash-link"
                        href="#changing-conversation-status-or-setting-reminders"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <h3
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="conversation-statuses"
                    >
                      {{ $t("features.conversation-workflow.ConversationStatuses")
                      }}<a
                        class="hash-link"
                        href="#conversation-statuses"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.toggledFollowing") }}</p>
                    <p>
                      <strong>{{ $t("features.conversation-workflow.Pending") }}</strong>
                      {{ $t("features.conversation-workflow.pendingStatus") }}
                      <a href="#">
                        {{ $t("features.conversation-workflow.anAgentbot") }}
                      </a>
                      or
                      <a href="#">{{
                        $t("features.conversation-workflow.DialogflowIntegration")
                      }}</a>
                      {{ $t("features.conversation-workflow.enabled") }}
                    </p>
                    <p>
                      <strong>Open</strong
                      >{{ $t("features.conversation-workflow.ConversationBcoor") }}
                    </p>
                    <p>
                      <strong>Snoozed</strong>
                      {{ $t("features.conversation-workflow.conversationInformation") }}
                      <code>snoozed</code
                      >{{ $t("features.conversation-workflow.investigationStatus") }}
                      <code>Snoozed</code>
                      {{ $t("features.conversation-workflow.helpfulReminders") }}
                    </p>
                    <p>
                      {{ $t("features.conversation-workflow.supportedOption")
                      }}<code>Snoozed</code>:
                    </p>
                    <ul class="">
                      <li>{{ $t("features.conversation-workflow.snooze") }}</li>
                      <li>{{ $t("features.conversation-workflow.snooze") }}</li>
                      <li>{{ $t("features.conversation-workflow.snooze") }}</li>
                    </ul>
                    <p>
                      <strong>Resolved</strong>
                      {{ $t("features.conversation-workflow.conversationBeen") }}
                      <code>Resolved</code>
                      {{ $t("features.conversation-workflow.conversationBeen") }}
                    </p>
                    <h3 class="anchor anchorWithStickyNavbar_mojV" id="workflow">
                      Workflow<a
                        class="hash-link"
                        href="#workflow"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h3>
                    <p>{{ $t("features.conversation-workflow.appropriateAgent") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens19.png"
                        width="800"
                        alt="Chatwoot Conversation Screen"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.optionsGiven") }}</p>
                    <table>
                      <thead>
                        <tr>
                          <th>Options</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Mark as Pending</td>
                          <td>Change the issue to Pending status</td>
                        </tr>
                        <tr>
                          <td>Resolve</td>
                          <td>Change the issue to Close status</td>
                        </tr>
                        <tr>
                          <td>Reopen</td>
                          <td>Change the issue to Open status</td>
                        </tr>
                        <tr>
                          <td>Open</td>
                          <td>Change the pending issue to Open status</td>
                        </tr>
                        <tr>
                          <td>Snooze Until Next Reply</td>
                          <td>
                            The issue will be set aside for now and status will change to
                            open on next reply from customer
                          </td>
                        </tr>
                        <tr>
                          <td>Snooze Until Tomorrow</td>
                          <td>
                            The issue will be set aside for now and status will change to
                            open on next day
                          </td>
                        </tr>
                        <tr>
                          <td>Snooze Until Next Week</td>
                          <td>
                            The issue will be set aside for now and status will change to
                            open on next week
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>{{ $t("features.conversation-workflow.optionsGiven") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/howconversationhappens20.webp"
                        width="800"
                        alt="Conversation Status Section "
                        class="max-img"
                      />
                    </div>
                    <p>
                      <strong>Note:</strong>
                      {{ $t("features.conversation-workflow.conversationAgent") }}
                    </p>
                    <h2 class="anchor anchorWithStickyNavbar_mojV" id="csat">
                      CSAT<a class="hash-link" href="#csat" title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>
                      {{ $t("features.conversation-workflow.markedTicket")
                      }}<a href="#">CSAT option</a
                      >{{ $t("features.conversation-workflow.enabledSurvey") }}
                    </p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/csat1.png"
                        width="800"
                        alt="Chatwoot Widget CSAT Screen"
                        class="max-img"
                      />
                    </div>
                    <p>
                      {{ $t("features.conversation-workflow.responseCustomerCanBeview") }}
                      <a href="/docs/user-guide/features/reports/csat">CSAT section</a> in
                      Reports.
                    </p>
                    <h2
                      class="anchor anchorWithStickyNavbar_mojV"
                      id="previous-conversations"
                    >
                      {{ $t("features.conversation-workflow.PreviousConversations")
                      }}<a
                        class="hash-link"
                        href="#previous-conversations"
                        title="Direct link to heading"
                        >&ZeroWidthSpace;</a
                      >
                    </h2>
                    <p>{{ $t("features.conversation-workflow.conversationMarked") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/previousconversation1.png"
                        width="800"
                        alt="Chatwoot Conversation Window"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.previousBelow") }}</p>
                    <div align="center">
                      <img
                        loading="lazy"
                        src="../../../../assets/images/previousconversation2.png"
                        width="800"
                        alt="Chatwoot Conversation Window"
                        class="max-img"
                      />
                    </div>
                    <p>{{ $t("features.conversation-workflow.listedConversation") }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details text-align-left">
              <h1 id="Enable-CSAT">
                {{ $t("features.conversation-workflow.conversationWorkflow") }}
              </h1>
              <p>{{ $t("features.conversation-workflow.conversationWorkflowDesc") }}</p>
              <p>{{ $t("features.conversation-workflow.conversationWorkflowDesc1") }}</p>
              <p>{{ $t("features.conversation-workflow.exampleType") }}</p>
            </div>

            <div class="channels-info break-word">
              <div class="channels-title">
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/25.png"
                    width="800"
                    alt="Chatwoot Widget Screen"
                    class="max-img"
                  />
                </div>
                <p>
                  {{ $t("features.conversation-workflow.automatedResponse")
                  }}<a href="/docs/user-guide/setup-your-account/add-inbox-settings">{{
                    $t("doc.AddandConfigure.TableDetails.channelGreetingMessage")
                  }}</a>
                  {{ $t("features.conversation-workflow.automatedResponseDesc") }}
                  <a href="/docs/user-guide/contacts">record</a>.
                </p>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="campaigns1">
                  {{ $t("features.conversation-workflow.Campaigns")
                  }}<a class="hash-link" href="#campaigns" title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>
                  {{ $t("features.conversation-workflow.customerConversation")
                  }}<a
                    href="/docs/user-guide/setup-your-account/add-inbox-settings#campaigns"
                    >{{ $t("features.conversation-workflow.Campaignmessage") }}</a
                  >,
                  {{ $t("features.conversation-workflow.widgetScreen") }}
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/campaign4.png"
                    width="800"
                    alt="Campaign Message Display to End User"
                    class="max-img"
                  />
                </div>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="pre-chat-forms1">
                  {{ $t("features.conversation-workflow.PreChatForms")
                  }}<a
                    class="hash-link"
                    href="#pre-chat-forms"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>
                  {{ $t("features.conversation-workflow.weHaveEnbled") }}
                  <a
                    href="/docs/user-guide/setup-your-account/add-inbox-settings#pre-chat-form"
                    >{{ $t("features.conversation-workflow.PreChatForms") }}</a
                  >
                  {{ $t("features.conversation-workflow.reuestingCustomer") }}
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/26.png"
                    width="800"
                    alt="Chatwoot Widget Pre Chat Form "
                    class="max-img"
                  />
                </div>
                <p>
                  {{ $t("features.contacts.detailConfiguration")
                  }}<a href="/docs/user-guide/features/pre-chat-form">{{
                    $t("doc.conversationContinuity.guide")
                  }}</a>
                </p>
                <p>{{ $t("features.conversation-workflow.CaptureDashboard") }}</p>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="conversationsnew">
                  {{ $t("features.conversation-workflow.Conversations")
                  }}<a
                    class="hash-link"
                    href="#conversations"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.BcoorAgentDashboard") }}</p>
                <img
                  loading="lazy"
                  src="../../../../assets/images/27.webp"
                  alt="Chatwoot Dashboard Home Screen"
                  class="max-img"
                />
                <p>{{ $t("features.conversation-workflow.conversationCustomer") }}</p>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-agentnew">
                  {{ $t("features.conversation-workflow.AssignAgent")
                  }}<a
                    class="hash-link"
                    href="#assign-agent"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.autoAssignCreated") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/28.webp"
                    alt="Assign Agent to Conversation Drop Down"
                    class="max-img"
                  />
                </div>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-teamnew">
                  {{ $t("features.conversation-workflow.AssignTeam")
                  }}<a
                    class="hash-link"
                    href="#assign-team"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.dropBoxTeam") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/assign-team.webp"
                    alt="Assign Team to Conversation Drop Down "
                    class="max-img"
                  />
                </div>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-labelsnew">
                  {{ $t("features.conversation-workflow.AssignLabels")
                  }}<a
                    class="hash-link"
                    href="#assign-labels"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.LabelsConversations") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/add-remove-labels.webp"
                    alt="Assign Lable to Converesation Drop Down"
                    class="max-img"
                  />
                </div>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="assign-prioritynew">
                  {{ $t("features.conversation-workflow.AssignPriority")
                  }}<a
                    class="hash-link"
                    href="#assign-priority"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>
                  {{ $t("features.conversation-workflow.dropBoxPriority") }}
                  <a href="/docs/user-guide/features/priority">here</a>
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/set-priority.webp"
                    alt="Actions done are recorded Chat screen"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.screenshotActions") }}</p>
                <h3
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="reply-for-the-conversationnew"
                >
                  {{ $t("features.conversation-workflow.Conversation")
                  }}<a
                    class="hash-link"
                    href="#reply-for-the-conversation"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.typingStartArea") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/29.png"
                    width="800"
                    alt="Chat Reply Window"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.buttonMessage") }}</p>
                <p>{{ $t("features.conversation-workflow.variousOption") }}</p>
                <img
                  loading="lazy"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAA5CAYAAACF8yP/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAH/SURBVGhD7ZqxbsIwFEX7af2IfgMLOx/AwA+wsbEzM2XNxsLMGokuZWilSlWpXnMdkhbXmGtHDi72ke5A89DLqZ+dSumDJEiWToUsnQpZOhWydCp4Sb9/fMnL61GeD5+yHzjoid64B1+cpQ9vR+PN3CK4Fx+cpGMSbuMjTktjnExNY4jLqKOWlsY+MjWMIbg3FtTS0rc4tNjg3lhQS0ubmsUUFtRmaRt6k9jCgtosbUNvEltYUJulbehNYgsLarO0Db2JezYyexrLo57JWrbGerewoHYQ6e1qWgsupDBc25cLJT8rDdccwoLa8NKQurqamIKpLHema1xYUBtYGjIXVljPbi2jHqPOgtqw0vUqj1aV+dqfVLKc+K82C2qDShfzZq82e9p0aP0cbm2d795mQe0A0pUUddTPdtW59K/PRblR0vxknIcFtUGlu5XDfp1vjDVqrOfNBNzFSquTW8liv46Nq4hpaMb+Tva0fjgpwdMebtOtbPcLar/rFhbUBpaug9G+9thSf6CQj7YLYUFteGlEiVvGu6cwwoLaYaRP6R5dpvHuGRbUDiodMiyozdI29CaxhQW1WdqG3iS2sKA2S9vQm8QWFtTS0km+wEvyVW2SL+VBcv9+0RKTuI8wcJYGGBHsjVscbuiJ3i4jreMl/d/J0qmQpVMhS6dCgtIi3xaysPOfOs63AAAAAElFTkSuQmCC"
                  width="50"
                  alt="Emoji Button"
                  class="max-img"
                />
                {{ $t("features.conversation-workflow.MessageButton") }}
                <img
                  loading="lazy"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAuCAYAAACrrAD9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAEJSURBVFhH7ZixDoIwEIZ5NB6CZ3Bh9wEceAE2N3YegdWNxdmVBBcZNDExYs4clVi1JuQK8sfcJR8N9Ei/tNAmFxBwqJw0Ornz5UaHY0v75kr1j+ExeWx2eI+gObXOl+aAXewIXElzYgvCyTH9EkPK8TcIK8c/Cawco3JSVE6Kykn5Z7mKsmVMYWRY5JUjR46X3DZfWUIsuqJs95knxUuuSGNKNt/vfZlErkjHmcER5J7LqXJDUTlXxxBUztUxhH4T5tacEmsqmpKSCECu7kTMbPXPePbCtLRy5HjKMUZwivN1BLnpUDkpKidF5aTAykEXcqBLYLDFw5eyK3TBmi+wpf5HCxkqJwuiO51xnKdYqghgAAAAAElFTkSuQmCC"
                  width="50"
                  alt="Attachment Button"
                  class="max-img"
                />
                {{ $t("features.conversation-workflow.MessageButton") }}
                <img
                  loading="lazy"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAArCAYAAAA3+KulAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAFKSURBVGhD7ZmxakIxFIb7aD5Edx/AydHJ2cnBB3BychQcHaV0dJC6SYcWSilO3eqpPzWQ3kT/KMETw/ngW+7lJvlIcDAPcoaP3best5/y/PIuT+u3m4o5MTfWcAnRoJ/9XjavX9GJNMRasKYUokElxTixphSCIGxxbMASTDl+QRDObWywEsTaGEGQxg9AqlgbIwiKDVSSDAvSlmFB2jIsSFuGBWnLyBo0H/el9dg5OJLJv3crGXbx/OBg4T2/XEa+oNnoGBMGTQbu+R0Fud3pzZrvFtKLRF4rI1vQ3y70ZbhsvFtOpY2g7lTm/vMrZWQO8j3uiAvybI9X0TFSZFjQKS2o8X2qDAs6pQU1vk+VkS3oVjIsSFuGBWnLsCBtGfUHVfdnfXXXKdVdeIGqriRBdZfGDmwxzq3GDwXmxNwpx8znbNA9YkFlI/ILHmhsh3BDxGIAAAAASUVORK5CYII="
                  width="50"
                  alt="Rich Text Editor Button"
                  class="max-img"
                />
                {{ $t("features.conversation-workflow.MessageButton") }}
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/30.png"
                    width="800"
                    alt="Chat Reply Window"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.editedCheckbox") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/31.png"
                    width="800"
                    alt="Rich Text Edite Message Screen"
                    class="max-img"
                  />
                </div>
                <h3
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="how-to-send-canned-responsesnew"
                >
                  {{ $t("features.conversation-workflow.cannedResponse") }}
                  <a
                    class="hash-link"
                    href="#how-to-send-canned-responses"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>
                  {{ $t("features.conversation-workflow.supposeInclude")
                  }}<a href="#"
                    >{{ $t("Channels.Supportedfeatures.cannedResponses") }}
                  </a>
                  {{ $t("features.conversation-workflow.responseForCannedResponse") }}
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/31.png"
                    width="800"
                    alt="Canned Response Selection Window"
                    class="max-img"
                  />
                </div>
                <h2
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="private-chat-with-agentsnew"
                >
                  {{ $t("features.conversation-workflow.ProvateChatWithAgents") }}
                  <a
                    class="hash-link"
                    href="#private-chat-with-agents"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>
                  {{ $t("features.conversation-workflow.ProvateChatWithAgentsDEsc") }}
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/32.png"
                    width="800"
                    alt="Private Note Window "
                    class="max-img"
                  />
                </div>
                <p>
                  {{ $t("features.conversation-workflow.ProvateChatWithAgentsDEsc") }}
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/33.png"
                    width="800"
                    alt="Agent Selection Screen"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.LockedSign") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/34.png"
                    width="800"
                    alt="Message to Agent Window"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.whomPrivate") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/36.png"
                    width="800"
                    alt="Chatwoot Home Screen"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.repliedSecondAgent") }}</p>
                <h2 class="anchor anchorWithStickyNavbar_mojV" id="delete-messagenew">
                  {{ $t("features.conversation-workflow.DeleteMessage")
                  }}<a
                    class="hash-link"
                    href="#delete-message"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>{{ $t("features.conversation-workflow.ClickDelete") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/36.png"
                    width="800"
                    alt="Delete Option for Chat Screen"
                    class="max-img"
                  />
                </div>
                <h2 class="anchor anchorWithStickyNavbar_mojV" id="sending-e-mailsnew">
                  {{ $t("features.conversation-workflow.SendingE-mails")
                  }}<a
                    class="hash-link"
                    href="#sending-e-mails"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>{{ $t("features.conversation-workflow.ProvidedUser") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/36.png"
                    width="800"
                    alt="Customer email ID display screen"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.transcriptScreen") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/howconversationhappens18c.png"
                    width="800"
                    alt="Customer email ID display screen"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.AssignedAddress") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/howconversationhappens18d.png"
                    width="800"
                    alt="Send Transcript options"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.SelectingEmail") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/howconversationhappens18e.png"
                    width="800"
                    alt="Send Transcript to Customer Option"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.successFullyInbox") }}</p>
                <h2
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="sending-emails-from-widgetnew"
                >
                  {{ $t("features.conversation-workflow.SendingEmailsfromWidget")
                  }}<a
                    class="hash-link"
                    href="#sending-emails-from-widget"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>{{ $t("features.conversation-workflow.resolvedData") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/sendingemailsfromwidget.png"
                    width="800"
                    alt="Chatwoot widget Send Transcript to Customer Option"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.displayedEmail") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/sendingemailsfromwidget1.png"
                    width="800"
                    alt="Chatwoot widget email sent message screen"
                    class="max-img"
                  />
                </div>
                <h2 class="anchor anchorWithStickyNavbar_mojV" id="transfering-agentsnew">
                  {{ $t("features.conversation-workflow.TransferingAgents")
                  }}<a
                    class="hash-link"
                    href="#transfering-agents"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>
                  {{ $t("features.conversation-workflow.TicketIssue") }}
                  <a href="/docs/user-guide/conversation-workflow#assign-agent">{{
                    $t("features.conversation-workflow.AssignAgentoption")
                  }}</a>
                  {{ $t("features.conversation-workflow.wholeConversation") }}
                </p>
                <h2
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="changing-conversation-status-or-setting-remindersnew"
                >
                  {{
                    $t(
                      "features.conversation-workflow.ChangingConversationStatusorsettingReminders"
                    )
                  }}<a
                    class="hash-link"
                    href="#changing-conversation-status-or-setting-reminders"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <h3
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="conversation-statusesnew"
                >
                  {{ $t("features.conversation-workflow.ConversationStatuses")
                  }}<a
                    class="hash-link"
                    href="#conversation-statuses"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.toggledFollowing") }}</p>
                <p>
                  <strong>{{ $t("features.conversation-workflow.Pending") }}</strong>
                  {{ $t("features.conversation-workflow.pendingStatus") }}
                  <a href="#">
                    {{ $t("features.conversation-workflow.anAgentbot") }}
                  </a>
                  or
                  <a href="#">{{
                    $t("features.conversation-workflow.DialogflowIntegration")
                  }}</a>
                  {{ $t("features.conversation-workflow.enabled") }}
                </p>
                <p>
                  <strong>Open</strong
                  >{{ $t("features.conversation-workflow.ConversationBcoor") }}
                </p>
                <p>
                  <strong>Snoozed</strong>
                  {{ $t("features.conversation-workflow.conversationInformation") }}
                  <code>snoozed</code
                  >{{ $t("features.conversation-workflow.investigationStatus") }}
                  <code>Snoozed</code>
                  {{ $t("features.conversation-workflow.helpfulReminders") }}
                </p>
                <p>
                  {{ $t("features.conversation-workflow.supportedOption")
                  }}<code>Snoozed</code>:
                </p>
                <ul class="">
                  <li>{{ $t("features.conversation-workflow.snooze") }}</li>
                  <li>{{ $t("features.conversation-workflow.snooze") }}</li>
                  <li>{{ $t("features.conversation-workflow.snooze") }}</li>
                </ul>
                <p>
                  <strong>Resolved</strong>
                  {{ $t("features.conversation-workflow.conversationBeen") }}
                  <code>Resolved</code>
                  {{ $t("features.conversation-workflow.conversationBeen") }}
                </p>
                <h3 class="anchor anchorWithStickyNavbar_mojV" id="workflownew">
                  Workflow<a
                    class="hash-link"
                    href="#workflow"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h3>
                <p>{{ $t("features.conversation-workflow.appropriateAgent") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/howconversationhappens19.png"
                    width="800"
                    alt="Chatwoot Conversation Screen"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.optionsGiven") }}</p>
                <table>
                  <thead>
                    <tr>
                      <th>Options</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mark as Pending</td>
                      <td>Change the issue to Pending status</td>
                    </tr>
                    <tr>
                      <td>Resolve</td>
                      <td>Change the issue to Close status</td>
                    </tr>
                    <tr>
                      <td>Reopen</td>
                      <td>Change the issue to Open status</td>
                    </tr>
                    <tr>
                      <td>Open</td>
                      <td>Change the pending issue to Open status</td>
                    </tr>
                    <tr>
                      <td>Snooze Until Next Reply</td>
                      <td>
                        The issue will be set aside for now and status will change to open
                        on next reply from customer
                      </td>
                    </tr>
                    <tr>
                      <td>Snooze Until Tomorrow</td>
                      <td>
                        The issue will be set aside for now and status will change to open
                        on next day
                      </td>
                    </tr>
                    <tr>
                      <td>Snooze Until Next Week</td>
                      <td>
                        The issue will be set aside for now and status will change to open
                        on next week
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>{{ $t("features.conversation-workflow.optionsGiven") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/howconversationhappens20.png"
                    width="800"
                    alt="Conversation Status Section "
                    class="max-img"
                  />
                </div>
                <p>
                  <strong>Note:</strong>
                  {{ $t("features.conversation-workflow.conversationAgent") }}
                </p>
                <h2 class="anchor anchorWithStickyNavbar_mojV" id="csatnew">
                  CSAT<a class="hash-link" href="#csat" title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>
                  {{ $t("features.conversation-workflow.markedTicket")
                  }}<a href="#">CSAT option</a
                  >{{ $t("features.conversation-workflow.enabledSurvey") }}
                </p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/csat1.png"
                    width="800"
                    alt="Chatwoot Widget CSAT Screen"
                    class="max-img"
                  />
                </div>
                <p>
                  {{ $t("features.conversation-workflow.responseCustomerCanBeview") }}
                  <a href="/docs/user-guide/features/reports/csat">CSAT section</a> in
                  Reports.
                </p>
                <h2
                  class="anchor anchorWithStickyNavbar_mojV"
                  id="previous-conversationsnew"
                >
                  {{ $t("features.conversation-workflow.PreviousConversations")
                  }}<a
                    class="hash-link"
                    href="#previous-conversations"
                    title="Direct link to heading"
                    >&ZeroWidthSpace;</a
                  >
                </h2>
                <p>{{ $t("features.conversation-workflow.conversationMarked") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/previousconversation1.png"
                    width="800"
                    alt="Chatwoot Conversation Window"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.previousBelow") }}</p>
                <div align="center">
                  <img
                    loading="lazy"
                    src="../../../../assets/images/previousconversation2.png"
                    width="800"
                    alt="Chatwoot Conversation Window"
                    class="max-img"
                  />
                </div>
                <p>{{ $t("features.conversation-workflow.listedConversation") }}</p>
              </div>
            </div>

            <div class="edit-update-detail">
              <!-- <div class="edit-pen"><svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg><a href="#">{{ $t('doc.product.EditThisPage') }}</a></div> -->
              <p>
                {{ $t("doc.product.LastUpdated") }}<span class="dark">10/21/2022 </span>by
                <span class="dark">
                  {{ $t("doc.setupInstruction.googleTagManager.author") }}</span
                >
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p class="preview">{{ $t("Mobile_App.previous") }}</p>

                  <router-link
                    to="/docs/user-guide/features/reports/team"
                    class="introduction-account"
                  >
                    <span>«</span> {{ $t("sharedInbox.team") }}
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("doc.product.Next") }}</p>

                  <router-link to="/docs/product/features/advanced-conversation-filters">
                    {{ $t("features.conversation-workflow.advancedConversation") }}
                    <span>»</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4">
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <li
                :class="{ active_menu_item: rightPanelMenuItem === '#Enable-CSAT-link' }"
              >
                <a
                  id="#Enable-CSAT-link"
                  @click="scrollTo('#Enable-CSAT')"
                  class="link_wrp"
                  >{{ $t("doc.AddandConfigure.InboxSettings.stepEnableCSAT") }}</a
                >
              </li>
              <ul id="side_section_info">
                <li
                  :class="{ active_menu_item: rightPanelMenuItem === '#campaigns1-link' }"
                >
                  <a
                    id="#campaigns1-link"
                    @click="scrollTo('#campaigns1')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.Campaigns") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#pre-chat-forms1-link',
                  }"
                >
                  <a
                    id="#pre-chat-forms1-link"
                    @click="scrollTo('#pre-chat-forms1')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.PreChatForms") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#conversationsnew-link',
                  }"
                >
                  <a
                    id="#conversationsnew-link"
                    @click="scrollTo('#conversationsnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.conversations") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#assign-agentnew-link',
                  }"
                >
                  <a
                    id="#assign-agentnew-link"
                    @click="scrollTo('#assign-agentnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.AssignAgent") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#assign-teamnew-link',
                  }"
                >
                  <a
                    id="#assign-teamnew-link"
                    @click="scrollTo('#assign-teamnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.AssignTeam") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#assign-labelsnew-link',
                  }"
                >
                  <a
                    id="#assign-labelsnew-link"
                    @click="scrollTo('#assign-labelsnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.AssignLabels") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#assign-prioritynew-link',
                  }"
                >
                  <a
                    id="#assign-prioritynew-link"
                    @click="scrollTo('#assign-prioritynew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.AssignPriority") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#reply-for-the-conversationnew-link',
                  }"
                >
                  <a
                    id="#reply-for-the-conversationnew-link"
                    @click="scrollTo('#reply-for-the-conversationnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.Campaigns") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#how-to-send-canned-responsesnew-link',
                  }"
                >
                  <a
                    id="#how-to-send-canned-responsesnew-link"
                    @click="scrollTo('#how-to-send-canned-responsesnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.cannedResponse") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#private-chat-with-agentsnew-link',
                  }"
                >
                  <a
                    id="#private-chat-with-agentsnew-link"
                    @click="scrollTo('#private-chat-with-agentsnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.ProvateChatWithAgents") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#delete-messagenew-link',
                  }"
                >
                  <a
                    id="#delete-messagenew-link"
                    @click="scrollTo('#delete-messagenew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.DeleteMessage") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#sending-e-mailsnew-link',
                  }"
                >
                  <a
                    id="#sending-e-mailsnew-link"
                    @click="scrollTo('#sending-e-mailsnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.SendingE-mails") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#sending-emails-from-widgetnew-link',
                  }"
                >
                  <a
                    id="#sending-emails-from-widgetnew-link"
                    @click="scrollTo('#sending-emails-from-widgetnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.SendingEmailsfromWidget") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#transfering-agentsnew-link',
                  }"
                >
                  <a
                    id="#transfering-agentsnew-link"
                    @click="scrollTo('#transfering-agentsnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.TransferingAgents") }}</a
                  >
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem ===
                      '#changing-conversation-status-or-setting-remindersnew-link',
                  }"
                >
                  <a
                    id="#changing-conversation-status-or-setting-remindersnew-link"
                    @click="
                      scrollTo('#changing-conversation-status-or-setting-remindersnew')
                    "
                    class="link_wrp"
                    >{{
                      $t(
                        "features.conversation-workflow.ChangingConversationStatusorsettingReminders"
                      )
                    }}</a
                  >
                  <!-- <ul>
                                        <li
                                            :class="{ 'active_menu_item': rightPanelMenuItem === '#conversation-statusesnew-link' }">
                                            <a id="#conversation-statusesnew-link" @click="scrollTo('#conversation-statusesnew-reminders')"
                                                class="link_wrp">{{ $t('features.conversation-workflow.ConversationStatuses') }}</a>
                                        </li>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Workflownew-link' }">
                                            <a id="#Workflownew-link" @click="scrollTo('#Workflownew')" class="link_wrp">{{ $t('Advance.Agentbots.workflow') }}</a>
                                        </li>


                                    </ul> -->
                </li>
                <li :class="{ active_menu_item: rightPanelMenuItem === '#csatnew-link' }">
                  <a id="#csatnew-link" @click="scrollTo('#csatnew')" class="link_wrp">{{
                    $t("doc.product.CSAT")
                  }}</a>
                </li>
                <li
                  :class="{
                    active_menu_item:
                      rightPanelMenuItem === '#previous-conversationsnew-link',
                  }"
                >
                  <a
                    id="#previous-conversationsnew-link"
                    @click="scrollTo('#previous-conversationsnew')"
                    class="link_wrp"
                    >{{ $t("features.conversation-workflow.PreviousConversations") }}</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
  name: "conversation-workflow",
  data() {
    return {
      isToggle: false,
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  components: {
    Header,
    Footer,
    SideBar,
    MainContent,
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
