<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("doc.product.Features") }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('headersubmenu.Features.headerKeyboardShortcuts') }}</a>
                        </div>
                        <div class="Introduction-details">

                            <div class="canned_list_wrp">
                                <h1>{{ $t('headersubmenu.Features.headerKeyboardShortcuts') }}</h1>
                                <p>{{ $t('features.keyboard.headerKeyboardShortcutsDesc1') }}</p>
                                <p>{{ $t('features.keyboard.headerKeyboardShortcutsDesc2') }}
                                </p>

                                <p class="widget_wrp">
                                    <img src="../../../../assets/images//keyboard-shortcuts.gif" class="img-fuild">
                                </p>


                                <p>
                                    {{ $t('features.keyboard.headerKeyboardShortcutsDesc3') }}
                                </p>

                                <div class="table-details" style="padding-left: 0;">
                                    <table class="table table-bordered description">
                                        <thead>
                                            <tr>
                                                <th>{{ $t('features.keyboard.mac') }}</th>
                                                <th>{{ $t('features.keyboard.windows') }}</th>
                                                <th>{{ $t('features.keyboard.description') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ $t('features.keyboard.shortcut') }}</td>
                                                <td>{{ $t('features.keyboard.shortcut') }}</td>
                                                <td>{{ $t('features.keyboard.') }}{{ $t('features.openCobversationToggle.') }}.</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.123') }}</td>
                                                <td>{{ $t('features.keyboard.alt1') }}</td>
                                                <td>{{ $t('features.keyboard.nextConversation') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.up') }} <strong>{{ $t('features.keyboard.slash') }}</strong> {{ $t('features.keyboard.down') }}</td>
                                                <td>{{ $t('features.keyboard.up') }} <strong>{{ $t('features.keyboard.slash') }}</strong> {{ $t('features.keyboard.down') }}</td>
                                                <td>{{ $t('features.keyboard.navigate') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionE') }}</td>
                                                <td>{{ $t('features.keyboard.alt') }}</td>
                                                <td>{{ $t('features.keyboard.resolves') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionC') }}</td>
                                                <td>{{ $t('features.keyboard.altc') }}</td>
                                                <td>{{ $t('features.keyboard.toogle') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionv') }}</td>
                                                <td>{{ $t('features.keyboard.altV') }}</td>
                                                <td>{{ $t('features.keyboard.contacts') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionR') }}</td>
                                                <td>{{ $t('features.keyboard.altR') }}</td>
                                                <td>{{ $t('features.keyboard.reports') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionS') }}</td>
                                                <td>{{ $t('features.keyboard.alts') }}</td>
                                                <td>{{ $t('features.keyboard.settings') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optinA') }}</td>
                                                <td>{{ $t('features.keyboard.altA') }}</td>
                                                <td>{{ $t('features.keyboard.attachment') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionO') }}</td>
                                                <td>{{ $t('features.keyboard.altO') }}</td>
                                                <td>{{ $t('features.keyboard.open') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionN') }}</td>
                                                <td>{{ $t('features.keyboard.altN') }}</td>
                                                <td>{{ $t('features.keyboard.tab') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionB') }}</td>
                                                <td>{{ $t('features.keyboard.altB') }}</td>
                                                <td>{{ $t('features.keyboard.switch') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionP') }}</td>
                                                <td>{{ $t('features.keyboard.altP') }}</td>
                                                <td>{{ $t('features.keyboard.private') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionL') }}</td>
                                                <td>{{ $t('features.keyboard.altL') }}</td>
                                                <td>{{ $t('features.keyboard.reply') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionW') }}</td>
                                                <td>{{ $t('features.keyboard.altW') }}</td>
                                                <td>{{ $t('features.keyboard.rich') }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('features.keyboard.optionM') }}</td>
                                                <td>{{ $t('features.keyboard.altM') }}</td>
                                                <td>{{ $t('features.keyboard.close') }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="update_edit_wrp">
                            <!-- <div class="edit_wrp">
                                <a href="#">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>

                                    <p>
                                        {{ $t('doc.product.EditThisPage') }}
                                    </p>
                                </a>
                            </div> -->

                            <div class="update_wrp">
                                <p>
                                    {{ $t('doc.product.LastUpdated') }} <b> {{ $t('features.keyboard.date') }}</b> {{ $t('features.keyboard.by') }} <b>{{ $t('features.folder.author') }}</b>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>
                                    <router-link to="/docs/user-guide/features/folders" class="introduction-account">
                                        <span>«</span> {{ $t('doc.product.Folders') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>

                                    <router-link to="/docs/user-guide/features/segments">
                                        {{ $t('doc.product.Segments') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>