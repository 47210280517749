<template>
    <section class="channels-menu-detail">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="dark-mode-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.channels') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.liveChat') }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.darkMode.darkMode') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.darkMode.title') }}</h1>
                            <p>{{ $t('doc.darkMode.intro') }}</p>
                            <p>{{ $t('doc.darkMode.guide') }}</p>
                            <p>{{ $t('doc.darkMode.quickGlimpse') }}</p>
                        </div>
                        <div class="dark-mode-docs">
                            <img src="../../../../../assets/images/17.gif" alt="logo45">
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.darkMode.enableDarkMode') }}<span class="click-inbox">{{ $t('doc.darkMode.darkMode') }}</span>{{ $t('doc.darkMode.darkMode') }}{{ $t('doc.darkMode.parameter') }} <a href="/docs/product/channels/live-chat/sdk/setup">{{ $t('doc.darkMode.bcoorSettings') }}</a></p>
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('doc.darkMode.step1') }}<span class="click-inbox">{{ $t('doc.darkMode.light') }}</span>{{ $t('doc.darkMode.lightModeOption') }}</p>
                            <p>{{ $t('doc.darkMode.step2') }}<span class="click-inbox">{{ $t('doc.darkMode.auto') }}</span>{{ $t('doc.darkMode.autoModeOption') }}</p>
                                
                        </div>

                       
                        <div class="code-bar">
                            <div class="codeblockContainer">
                                <pre tabindex="0"
                                    class="prism-code language-js c odeBlock_jd64 thin-scrollbar"><code class="codeBlockLines_mRuA"><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token dom variable" style="color: rgb(191, 199, 213);">window</span><span class="token punctuation" style="color: rgb(199, 146, 234);">.</span><span class="token property-access">bcoorSettings</span><span class="token plain"> </span><span class="token operator" style="color: rgb(137, 221, 255);">=</span><span class="token plain"> </span><span class="token punctuation" style="color: rgb(199, 146, 234);">{</span><span class="token plain"></span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">  </span><span class="token comment" style="color: rgb(105, 112, 152); font-style: italic;">//... other Settings</span><span class="token plain"></span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">  </span><span class="token literal-property property">darkMode</span><span class="token operator" style="color: rgb(137, 221, 255);">:</span><span class="token plain"> </span><span class="token string" style="color: rgb(195, 232, 141);">"auto"</span><span class="token punctuation" style="color: rgb(199, 146, 234);">,</span><span class="token plain"></span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain"></span><span class="token punctuation" style="color: rgb(199, 146, 234);">}</span><span class="token punctuation" style="color: rgb(199, 146, 234);">;</span><br></span></code></pre>
                            </div>
                        </div>
                        <!-- <div class="version-detail noted">
                            <p>{{ $t('doc.darkMode.note') }}</p><span class="click-inbox"> {{ $t('doc.darkMode.dark') }}</span><p>{{ $t('doc.darkMode.supported') }}</p>
                        </div> -->
                        <div class="version-detail">
                                        <p>{{ $t('doc.darkMode.note') }} <span class="click-inbox">{{ $t('doc.darkMode.dark') }}</span>{{ $t('doc.darkMode.supported') }}   </p>
                                    </div>

                        
                        <div class="edit-update-detail">
                            <p class="lastupdate"><p class="notdetail">{{ $t('doc.darkMode.lastUpdatedOn') }}</p> <span class="dark">10/17/2022 </span>{{ $t('doc.darkMode.updatedBy') }} <span class="dark">{{ $t('doc.darkMode.HrichaShandily') }}</span></p>
                        </div> 
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.darkMode.navigation.previous') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/sdk/identity-validation"
                                        class="introduction-account">
                                        <span>«</span> <p class="preview">{{ $t('doc.darkMode.navigation.identityValidation') }}</p>
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview navigation">{{ $t('doc.darkMode.navigation.next') }}</p>
                                    <router-link to="/docs/product/channels/live-chat/conversation-continuity">
                                        <p class="preview">{{ $t('doc.darkMode.navigation.conversationContinuity') }}</p><span>»</span>
                                    </router-link>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                </div>
            </div>

        </div>
    </section>
</template>
<script>
export default ({})
</script>
<style>
.dark-mode-docs img {
    width: 100%;
    height: 100%;
}

.dark-mode-docs {
    padding: 0 36px;
    max-width: 500px;
    width: 100%;
}
</style>