<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('Mobile_App.mobile_app') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('Mobile_App.ios') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('Mobile_App.on_this_page') }}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }">
                                                        <a href="#features-info">{{ $t('Mobile_App.testing') }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="version-detail webflow">
                            <h4>{{ $t('Mobile_App.ios') }}</h4>
                            <p>{{ $t('Mobile_App.ios_intro') }}</p>
                            <div class="android_options">
                                <ul>
                                    <li>
                                        {{ $t('Mobile_App.production_ios_intro_1') }}
                                    </li>
                                    <li>
                                        {{ $t('Mobile_App.production_ios_intro_2') }}
                                    </li>
                                    <li>
                                        {{ $t('Mobile_App.production_ios_intro_3') }}
                                    </li>
                                    <!-- <li>
                                        Enter Installation URL: This is the page you go to when you want to access
                                        bcoor. It’s in the format <code>domain.com</code>. If you are using
                                        self-hosted bcoor installation, input your server URL. Otherwise, use
                                        <code>app.bcoor.com</code>
                                    </li> -->
                                    <li>
                                        {{ $t('Mobile_App.click_on') }}<code> {{ $t('Mobile_App.connect') }}</code> {{
                                            $t('Mobile_App.button_to_login') }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- <div class="version-detail webflow" id="Testing">
                            <h4>Testing</h4>

                            <p>To help with testing app updates before they're released, you can sign up in our beta
                                program. Open this <a href="https://testflight.apple.com/join/yQ4yoSx4" target="_blank"
                                    rel="noopener noreferrer">link</a> from your iOS device.</p>

                            <p>You can leave the Beta testing program at any time opening TestFlight app and click
                                <strong>Stop Testing</strong>.
                            </p>
                        </div> -->

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('Mobile_App.edit_this_page') }}</a>
                            </div> -->
                            <p>{{ $t('Mobile_App.last_update') }}<span class="dark">5/03/2023 </span>{{ $t('Mobile_App.by')
                            }} <span class="dark">Douglas Lara</span>
                            </p>
                        </div>

                        <div class="row and-ios">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>

                                    <router-link to="/docs/product/mobile-app/android" class="introduction-account">
                                        <span>«</span> {{ $t('Mobile_App.android') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Mobile_App.next') }}</p>
                                    <router-link to="/docs/product/others/faq">
                                        {{ $t('doc.product.FAQ') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- sidebarr section -->
                <!-- <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info"> -->
                <!-- <li id="menu_item_1" :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                        href="#Testing">Testing</a></li> -->

                <!-- </ul>
                        </div>
                    </section>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'ios',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
