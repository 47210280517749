<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="Add-Agentsetting">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.agents.addAgents') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.agents.addAgents') }}</h1>
                            <p>{{ $t('doc.agents.agentDescription') }}</p>
                            <p>{{ $t('doc.agents.addAgentsInstructions') }}</p>
                        </div>
                        <div class="add-agent-setting-img">
                            <img src="../../../../assets/images/click-to-invite-team-member-add-adgent.webp" alt="logo41">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.agents.clickHereToAddAgents') }}</p>
                            <p>{{ $t('doc.agents.orClickOnSettingsAgents') }}</p>
                            <p>{{ $t('doc.agents.administratorDescription') }}</p>
                        </div>
                        <div class="agent-setting">
                            <img src="../../../../assets/images/addagentsettings2.png" alt="logo61">
                        </div>
                        <div class="update-profile-check">

                            <p>Click on the <code>Add Agent</code> button on the Top Right Hand Side. </p>
                            <p>A pop up window will open in which you can enter the details of the Agent like name, role and email address. </p>
                        </div>
                        <div class="edit-agent-name">
                            <img src="../../../../assets/images/addagentsettings3.png" alt="logo">
                        </div>
                        <div class="table-details">
                        <table class="table table-bordered description">
                        <thead>
                            <tr>
                            <th style="white-space: nowrap;">{{ $t('doc.agents.fieldName') }}</th>
                            <th>{{ $t('doc.agents.value') }}</th>
                            <th>{{ $t('doc.agents.remarks') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ $t('doc.agents.agentName') }}</td>
                                <td>{{ $t('doc.agents.enterAgentName') }}</td>
                                <td>{{ $t('doc.agents.agentNameExample') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('doc.agents.role') }}</td>
                                <td>{{ $t('doc.agents.enterRole') }}</td>
                                <td>{{ $t('doc.agents.roleDescription') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('doc.agents.yourEmailAddress') }}</td>
                                <td>{{ $t('doc.agents.enterYourEmail') }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                        </table>
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.agents.clickAddAgent') }} <span class="click-inbox">{{ $t('doc.agents.addAgentButton') }}</span> {{ $t('doc.agents.agentAddedMessage') }}</p>
                            <p>{{ $t('doc.agents.editOptionDetails') }}</p>
                        </div>
                        <div class="edit-agent-name">
                            <img src="../../../../assets/images/addagentsettings4-name.png" alt="logo28">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.agents.deleteAgentInstructions') }}
                                {{ $t('doc.agents.delete') }} {{ $t('doc.agents.buttonDelete') }}
                            </p>
                        </div>
                        <div class="edit-agent-name">
                            <img src="../../../../assets/images/addagentsettings-confirm-deletion.webp" alt="logo28">
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                            <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD" aria-hidden="true">
                                <g>
                                <path d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                </path>
                                </g>
                            </svg>
                            <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }} <span class="dark"> 10/17/2022</span> {{ $t('doc.product.By') }} <span class="dark">{{ $t('doc.createChatWoodAccount.hrichaShandily') }}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6" >
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>
                                            <router-link to="/docs/user-guide/setup-your-account/configure-account-details" class="introduction-account">
                                                <span>«</span> {{ $t('doc.generalAccountDetails.configureProfile') }}
                                            </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                <router-link to="/docs/user-guide/setup-your-account/add-inbox-settings">
                                    {{ $t('doc.agents.AddInboxes') }}<span>»</span>
                                </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default ({})
</script>
<style>
.add-agent-setting-img img {
    width: 100%;
    height: 100%;
}

.edit-agent-name img {
    width: 100%;
    height: 100%;
}

.add-agent-setting-img {
    padding: 0 36px;
}

.agent-setting img {
    width: 100%;
    height: 100%;
}

.agent-setting {
    padding: 0 36px;
    margin-bottom: 20px;
}

.edit-agent-name {
    padding: 0 36px;
}</style>