<template>
    <div class="main-wrp" v-if="isDocProduct">
        <SideBar :isToggle="isToggle" :handleToggle="handleToggle" />
        <Header :handleToggle="handleToggle" />
        <div class="main-content">
            <router-view />
        </div>
    </div>
    
    <div class="main-wrp" v-else>
        <router-view />
    </div>
</template>

<script>
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    import SideBar from "@/components/SideBar.vue";

    export default {
        name: "App",
        data() {
            return {
                isToggle: false,
                isDocProduct: false,
            };
        },
        components: {
            Header,
            Footer,
            SideBar,
        },
        methods: {
            handleToggle() {
                this.isToggle = !this.isToggle;
            },
        },
        created() {
            this.$router.beforeEach((to, from, next) => {
                if (
                    to.name === "Introduction" ||
                    to.path.includes("/docs/product") ||
                    to.path.includes("/docs/")
                ) {
                    this.isDocProduct = true;
                } else {
                    this.isDocProduct = false;
                }
                next();
            });
        },
        mounted() {
            let browserLang = navigator.language.substring(0, 2);
            let defaultLang = 'zh';
            
            if (browserLang == defaultLang) {
                localStorage.setItem('language',browserLang);
            } else {
                localStorage.setItem('language',defaultLang);
            }
            
            // localStorage.setItem('language', defaultLang);
            let arraFull = 'en,zh'; // Note: Use a string, not an array
            let langArr = arraFull.split(",");

            if(localStorage.getItem('language')){
                this.$i18n.locale = localStorage.getItem('language');
                this.selectLanguage = localStorage.getItem('language');
            } else if((langArr.findIndex(x => x == browserLang))>-1){
                this.$i18n.locale = browserLang;
                this.selectLanguage = browserLang;
            } else {
                localStorage.setItem('language', defaultLang);
            }

            if(localStorage.getItem('language') == 'zh')
            {
                document.title = 'Bcoor.com 永远免费使用的在线客服系统 🌟全渠道对话接入｜自动实时翻译｜不限客服坐席｜安全坚如磐石';
                const metaDescriptionTag = document.querySelector('meta[name="description"]');
                if (metaDescriptionTag) {
                metaDescriptionTag.setAttribute('content', "使用 Bcoor 的在线客户服务系统——安全无忧、质量上乘、数据可靠，Bcoor超越美洽、LIve800 以及市面上所有客服系统。此外，还可通过我们极具吸引力的定价计划为您服务 💰💰 今天就让我们随你起航，永远辉煌。");  
                }
                const metaOGDescriptionTag = document.querySelector('meta[name="og:description"]');
                if (metaOGDescriptionTag) {
                metaOGDescriptionTag.setAttribute('content', "使用 Bcoor 的在线客户服务系统——安全无忧、质量上乘、数据可靠，Bcoor超越美洽、LIve800 以及市面上所有客服系统。此外，还可通过我们极具吸引力的定价计划为您服务 💰💰 今天就让我们随你起航，永远辉煌。");  
                }
            }else if(localStorage.getItem('language') == 'en')
            {
                document.title = "Bcoor.com Forever Free - 🌟Boundless Communication, Marketing Made Easy";
                const metaDescriptionTag = document.querySelector('meta[name="description"]');
                if (metaDescriptionTag) {
                metaDescriptionTag.setAttribute('content', "Transform your customer communication with Bcoor's online customer service system - 'Boundless Communication, Marketing Made Easy' 🌟. Our system offers unparalleled security, superior quality, and reliable data, surpassing competitors like Zendesk and LiveAgent. Plus, our attractive pricing plans make it easy for you to get started today - let us help you soar to new heights of success!");  
                }
                const metaOGDescriptionTag = document.querySelector('meta[name="og:description"]');
                if (metaOGDescriptionTag) {
                metaOGDescriptionTag.setAttribute('content', "Transform your customer communication with Bcoor's online customer service system - 'Boundless Communication, Marketing Made Easy' 🌟. Our system offers unparalleled security, superior quality, and reliable data, surpassing competitors like Zendesk and LiveAgent. Plus, our attractive pricing plans make it easy for you to get started today - let us help you soar to new heights of success!");  
                }
            }
        },
    };
    
// // Script to disable inspect(console) of browser. 
// document.addEventListener('contextmenu', (e) => e.preventDefault());

// function ctrlShiftKey(e, keyCode) {
//   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
// }

// document.onkeydown = (e) => {
//   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
//   if (
//     event.keyCode === 123 ||
//     ctrlShiftKey(e, 'I') ||
//     ctrlShiftKey(e, 'J') ||
//     ctrlShiftKey(e, 'C') ||
//     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
//   )
//     return false;
// };
</script>