<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="integration-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.integrations.integrations') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne"> 
                                           <p>{{ $t('doc.integrations.OnThisPage') }}</p>
                                                <span class="down_up_icon"><img
                                                        src="../../../../assets/images/up_arrow_side.svg" alt=""></span>
                                        
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                            <ul id="side_section_info">
                                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#slack-info-link' }">
                                                <a @click="scrollTo('#slack-info')">{{ $t('doc.integrations.slack') }}</a>
                                                </li>
                                                <li id="menu_item_2" class="">
                                                <a href="#Webhooks-info">{{ $t('doc.integrations.webhooks') }}</a>
                                                </li>
                                                <!-- <li :class="{ 'active_menu_item': rightPanelMenuItem === '#update-info-link' }">
                                                <a @click="scrollTo('#Dashboard-Apps')">{{ $t('doc.integrations.dashboardApps') }}</a>
                                                </li> -->
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="Introduction-details">
                            <h1>{{ $t('doc.integrations.header') }}</h1>
                            <p>{{ $t('doc.integrations.intro1') }} {{ $t('doc.integrations.intro2') }}</p>
                            <p>{{ $t('doc.integrations.setup') }}</p>
                        </div>
                        <div class="interoduction-img">
                            <img src="../../../../assets/images/integrations-on-chatwoot.png" alt="logo60">
                        </div>
                        <div class="cloud-version" id="slack-info">
                            <h4>{{ $t('doc.integrations.slack') }}</h4>
                            <p>{{ $t('doc.integrations.slackInfo') }}</p>
                        </div>
                        <div class="interoduction-img">
                            <img src="../../../../assets/images/slack-connect-button-2.png" alt="logo60">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.integrations.slackUrl') }}</p>
                        </div>

                        <div class="interoduction-img">
                            <img src="../../../../assets/images/slack1-workshop.png" alt="logo60">
                        </div>
                        <div class="cloud-version" id="Webhooks-info">
                            <h4>{{ $t('doc.integrations.webhooks') }}</h4>
                            <p>{{ $t('doc.integrations.webhooksInfo') }}</p>
                        </div>
                        <div class="interoduction-img">
                            <img src="../../../../assets/images/webhooks-integration.png" alt="logo60">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.integrations.addNewWebhook') }}</p>
                        </div>
                        <div class="interoduction-img">
                            <img src="../../../../assets/images/add-new-webhook.png" alt="logo60">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.integrations.webhookDetails') }}</p>
                        </div>
                        <div class="interoduction-img">
                            <img src="../../../../assets/images/webhooks3-.png" alt="logo60">
                        </div>
                        <!-- <div class="cloud-version" id="Dashboard-Apps">
                            <h4>{{ $t('doc.integrations.dashboardApps') }}</h4>
                            <p>{{ $t('doc.integrations.dashboardAppsLink') }} <a href="/docs/product/others/dashboard-apps">{{ $t('doc.integrations.dashboardAppsDoc') }}</a></p>
                        </div> -->
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.integrations.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.integrations.lastUpdatedOn') }} <span class="dark"> 10/17/2022</span> {{ $t('doc.integrations.updatedBy') }} <span class="dark">{{ $t('doc.integrations.HrichaShandily') }}</span></p>
                      
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>
                                    <router-link to="/docs/user-guide/setup-your-account/add-label-settings" class="introduction-account">
                                        <span>«</span> {{ $t('doc.integrations.AddLabels') }}
                                    </router-link>

                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                    <router-link to="/docs/user-guide/setup-your-account/applications">
                                        {{ $t('doc.integrations.Applications') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#slack-info-link' }"><a
                                        @click="scrollTo('#slack-info')" id="#slack-info-link">Slack</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#Webhooks-info-link' }"><a
                                        id="#Webhooks-info-link" @click="scrollTo('#Webhooks-info')">Webhooks</a></li>
                                <!-- <li :class="{ 'active_menu_item': rightPanelMenuItem === '#update-info-link' }"><a id="#update-info-link"
                                        @click="scrollTo('#Dashboard-Apps')">Dashboard Apps</a></li> -->
                            </ul>
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Integrations',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>
<style>
.interoduction-img img {
    width: 100%;
    height: 100%;
}

.interoduction-img {
    padding: 0px 36px;
}
</style>