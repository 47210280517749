<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t("home.header.Gmail") }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.tittle') }}</a>
                        </div>
                      
                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.channel') }}</h1>
                        </div>
                        <div class="channels-info break-word code-info">
                            <div class="channels-title channels-anchor">


                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step1') }}</strong> {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.dashboard') }}&nbsp;{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.settings') }}&nbsp;{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.up') }}&nbsp;{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.inboxes') }}&nbsp;{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.up') }}&nbsp;{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.add') }}&nbsp;{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.inbox') }}</p>
                                <p><img alt="inbox_create"
                                        src="../../../../../../assets/images/adding-inbox-in-chatwoot.webp" class="max-img">
                                </p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step2') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.icon') }}</p>
                                <p><img alt="list_of_channels"
                                        src="../../../../../../assets/images/list_of_channels_new.webp" class="max-img"></p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step3') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.microsoft') }}</p>
                                <p><img alt="microsoft_channel" src="../../../../../../assets/images/microsoft-channel.png"
                                        class="max-img"></p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step4') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.addEmail') }}</p>
                                <p><img alt="microsoft_emai" src="../../../../../../assets/images/microsoft-email.png"
                                        class="max-img"></p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step5') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.enter') }}</p>
                                <p><img alt="microsoft_authentication"
                                        src="../../../../../../assets/images/microsoft-authentication.png" class="max-img">
                                </p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step6') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.bccor') }}</p>
                                <p><img alt="microsoft_authorization"
                                        src="../../../../../../assets/images/microsoft-authorization.png" class="max-img">
                                </p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step7') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.addAgents') }}</p>
                                <p><img alt="add_agents" src="../../../../../../assets/images/add-agents-to-email-inbox.png"
                                        class="max-img"></p>
                                <p>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.hooray') }}</p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step5') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.configure') }}</p>
                                <p>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.notePlease') }}</p>
                                <p><img alt="configuration" src="../../../../../../assets/images/ms-oauth-email-config.gif"
                                        class="max-img"></p>
                                <p>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.fetch') }}</p>
                                <p><strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.step6') }}</strong>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.accessToken') }}&gt;
                                    {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.configuration') }}</p>
                                <p><img alt="ms_reauthorization"
                                        src="../../../../../../assets/images/ms-reauthorization.png" class="max-img"></p>


                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.lastUpdatedOn') }} <span class="dark"> {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.date') }} </span> {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.by') }} <span class="dark"> {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.tejaswiniChile') }}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.previous') }}</p>


                                    <router-link to="/docs/product/channels/email/gmail/add-forward-email"
                                        class="introduction-account">
                                        <span>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.side') }}</span> {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.imap') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.next') }}</p>

                                    <router-link to="/docs/product/channels/email/configure-imap">
                                        {{ $t('Advance.Channels.Email.Microsoft.CreateChannel.configureNew') }} <span>{{ $t('Advance.Channels.Email.Microsoft.CreateChannel.arrow') }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4"></div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'create-channel',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
