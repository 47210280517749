<template>
    <Header @language-selected="handleLanguageSelected"/>
       <div class="blog-page">
        <div class="bg-white">
            <div class="bg-gradient-to-b from-white to-blue-50">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="blog-title-box">
                                <h1>{{ $t('blog.blogOpensource') }}</h1>
                                <p>
                                    {{ $t('opensource.opensourWe') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-wrap d-flex">
                        <div class="left-blog">
                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogCategories') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/blog.html" class="">{{ $t('blog.blogs') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/product-update.html" class="">{{ $t('blog.blogupdates') }}</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="left-blog-fixed">
                                <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                                <ul role="list" class="blogul">
                                    <li>
                                        <a aria-current="page" href="file:///D:/chat/custmer_service.html"
                                            class="">{{ $t('blog.blogCustomer') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                                    </li>
                                    <li data-v-a9d1c59d="">
                                        <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right-blog">
                            <div class="right-blog-list">
                                <div class="right-blog-list-inner">
                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/07/data-privacy-image-3.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('blog.blogwithbcoor') }} 
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('opensource.opensourcedata') }} 
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2023/03/Sticker-2.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('doc.darkMode.HrichaShandily') }}</p>
                                                        <p class="blog-user-second">{{ $t('opensource.opensource06202') }} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/10/Group-2.jpg" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('opensource.opensourcedata') }}  
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                        {{ $t('opensource.opensourcefestwe') }}  
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2022/07/pranav.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first">{{ $t('blog.blogPranav') }}</p>
                                                        <p class="blog-user-second">{{ $t('opensource.opensourceoct') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>