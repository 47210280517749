<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="application-page">
                        <div class="getting-details">
                            <p>{{ $t("doc.product.Features") }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow" />
                            <a href="#">{{ $t("features.contacts.routing") }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            <p>{{ $t("doc.product.OnThisPage") }}</p>
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt="" /></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#manual-assignment-link',
                            }">
                                                        <a @click="scrollTo('#manual-assignment')">{{
                                                            $t("features.routing.manualAssignment")
                                                            }}</a>
                                                    </li>
                                                    <li :class="{
                              active_menu_item:
                                rightPanelMenuItem === '#Auto-assignment-link',
                            }">
                                                        <a @click="scrollTo('#Auto-assignment')">{{
                                                            $t("features.routing.autoAssignment")
                                                            }}</a>
                                                        <ul>
                                                            <li :class="{
                                  active_menu_item:
                                    rightPanelMenuItem ===
                                    '#enable-round-robin-assignment-link',
                                }">
                                                                <a @click="scrollTo('#enable-round-robin-assignment')">{{ $t('features.routing.enableRoundAssignment') }}</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('features.routing.routingConversation') }}</h1>
                            <p>{{ $t('features.routing.conversationAssignee') }}</p>
                        </div>
                        <div class="version-detail maual" id="manual-assignment">
                            <h4>{{ $t("features.routing.manualAssignment") }}</h4>
                            <p>{{ $t('features.routing.manuallyAssigned') }}</p>
                        </div>
                        <div class="version-detail maual">
                            <p class="adminstrators">{{ $t('features.routing.agentAcessInbox') }}</p>
                            <p>
                                <span class="access">{{ $t('features.routing.AdministratorsAccess') }}</span>
                            </p>
                        </div>
                        <div class="version-detail">
                            <p>{{ $t('features.routing.assignAgentAdmin') }}</p>
                        </div>

                        <div class="routing-assignt-img">
                            <img src="../../../../assets/images/assigned-agent.png" alt="logo21" />
                        </div>
                        <div class="version-detail maual" id="Auto-assignment">
                            <h4>{{ $t("features.routing.autoAssignment") }}</h4>
                            <p>{{ $t('features.routing.roundRobin') }}</p>
                            <p>
                                <span class="italic">{{ $t('doc.SDKSetup.sdkSettings.noteLabel') }}</span>
                                {{ $t('features.routing.roundRobinDefault') }}
                            </p>
                        </div>
                        <div class="version-detail maual">
                            <p class="adminstrators">{{ $t('features.routing.agentOnlineAccess') }}</p>
                            <p>
                                <span class="access">{{ $t('features.routing.offlineAgent') }}</span>
                            </p>
                        </div>
                        <div class="version-detail" id="enable-round-robin-assignment">
                            <p>{{ $t('features.routing.AssignmentInbox') }}</p>
                        </div>
                        <div class="version-detail maual">
                            <p class="adminstrators">{{ $t('features.routing.gotoSettingInbox') }}</p>
                            <p class="adminstrators">{{ $t('features.routing.settingInbox') }}</p>
                            <p class="adminstrators">{{ $t('features.routing.settingRoundrobin') }}</p>
                        </div>
                        <div class="routing-assignt-img">
                            <img src="../../../../assets/images/round-robin.png" alt="logo21" />
                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg><a href="#">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>
                                {{ $t('doc.product.LastUpdated') }} <span class="dark">
                                    {{ $t('features.routing.date') }}
                                </span> {{ $t('doc.product.By') }}
                                <span class="dark"> {{ $t('doc.setupInstruction.googleTagManager.author') }}</span>
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>

                                    <router-link to="/docs/user-guide/features/contacts" class="introduction-account">
                                        <span>«</span> {{ $t('doc.product.Contacts') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('doc.product.Next') }}</p>
                                    <router-link to="/docs/user-guide/features/folders">
                                        {{ $t('doc.product.Folders') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{
                    active_menu_item: rightPanelMenuItem === '#manual-assignment-link',
                  }">
                                    <a id="#manual-assignment-link" @click="scrollTo('#manual-assignment')">{{
                                                            $t("features.routing.manualAssignment")
                                                            }}</a>
                                </li>
                                <li :class="{
                    active_menu_item: rightPanelMenuItem === '#Auto-assignment-link',
                  }">
                                    <a id="#Auto-assignment-link" @click="scrollTo('#Auto-assignment')">{{
                                                            $t("features.routing.autoAssignment")
                                                            }}</a>
                                    <ul>
                                        <li :class="{
                        active_menu_item:
                          rightPanelMenuItem === '#enable-round-robin-assignment-link',
                      }">
                                            <a id="#enable-round-robin-assignment-link"
                                                @click="scrollTo('#enable-round-robin-assignment')">{{ $t('features.routing.enableRoundAssignment') }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "AddConfigure",
        data() {
            return {
                activeMenuItem: null,
                rightPanelMenuItem: null,
            };
        },
        mounted() {
            window.addEventListener("scroll", this.setActiveMenuItem);
            //  this.scrollToTopcustom = this.scrollToTopcustom.bind(this);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.setActiveMenuItem);
        },
        methods: {
            scrollTo(selector) {
                const element = document.querySelector(selector);
                if (element) {
                    const offset = 106;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = element.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                    this.rightPanelMenuItem = `${selector}-link`;
                }
            },
            setActiveMenuItem() {
                const scrollPosition = window.scrollY;
                const menuItems = document.querySelectorAll("#side_section_info li");
                this.activeMenuItem = null;
                for (let i = menuItems.length - 1; i >= 0; i--) {
                    const menuItem = menuItems[i];
                    // console.log(menuItem)
                    const target = document.querySelector(
                        menuItem
                            .querySelector("a")
                            .getAttribute("id")
                            ?.replace(/-link(?=[^-]*$)/, "")
                    );
                    if (target?.offsetTop - 200 <= scrollPosition) {
                        let tempEle;
                        if (menuItem) {
                            tempEle = menuItem?.getAttribute("id");
                            let targetID = target?.getAttribute("id");
                            this.rightPanelMenuItem = `#${targetID}-link`;
                            // menuItem?.classList.add("active_menu_item");
                        }
                        this.activeMenuItem = tempEle;
                        return;
                    }
                }
            },
            isActiveSection(index) {
                return index === this.activeSectionIndex;
            },
        },
    };
</script>
<style>
    p.adminstrators {
        /* padding: 0; */
        /* margin: 0; */
        margin: 0;
        padding-left: 20px;
    }

    .access {
        padding-left: 20px;
    }

    .routing-assignt-img img {
        width: 100%;
        height: 100%;
    }

    .routing-assignt-img {
        padding: 0 36px;
    }

    .version-detail.maual h4 {
        font-size: 32px;
    }

    .italic {
        font-style: italic;
        font-weight: 200;
    }
</style>