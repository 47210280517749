<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="application-page">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t('doc.createChatWoodAccount.SetupYourAccount') }}</p>
                            <img src="../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('doc.applications.Applications') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('doc.applications.header') }}</h1>
                            <p>{{ $t('doc.applications.intro1') }}</p>
                            <p>{{ $t('doc.applications.intro2') }}</p>
                        </div>
                        <div class="application-img">
                            <img src="../../../../assets/images/applications-in-chatwoot-ee.png" alt="logo56">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.applications.addHookBtn') }}</p>
                        </div>
                        <div class="application-img">
                            <img src="../../../../assets/images/application-dialogflow.png" alt="logo56">
                        </div>
                        <div class="update-profile-check">
                            <p>{{ $t('doc.applications.hookScreen') }}</p>
                        </div>
                        <div class="application-img">
                            <img src="../../../../assets/images/applications3.png" alt="logo56">
                        </div>
                        <div class="table-details">
                            <table class="table table-bordered description">
                              <thead>
                                <tr>
                                  <th>{{ $t('doc.applications.tableDetails.fieldName') }}</th>
                                  <th>{{ $t('doc.applications.tableDetails.value') }}</th>
                                  <th>{{ $t('doc.applications.tableDetails.remarks') }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ $t('doc.applications.tableDetails.dialogflowProjectId') }}</td>
                                  <td>{{ $t('doc.applications.tableDetails.dialogflowProjectIdDescription') }}</td>
                                  <td>{{ $t('doc.applications.tableDetails.dialogflowProjectIdRemarks') }}</td>
                                </tr>
                                <tr>
                                  <td>{{ $t('doc.applications.tableDetails.dialogflowProjectKeyFile') }}</td>
                                  <td>{{ $t('doc.applications.tableDetails.dialogflowProjectKeyFileDescription') }}</td>
                                  <td>{{ $t('doc.applications.tableDetails.dialogflowProjectKeyFileRemarks') }}</td>
                                </tr>
                                <tr>
                                  <td>{{ $t('doc.applications.tableDetails.selectInbox') }}</td>
                                  <td>{{ $t('doc.applications.tableDetails.selectInboxDescription') }}</td>
                                  <td>{{ $t('doc.applications.tableDetails.selectInboxRemarks') }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen"><svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                    class="iconEdit_dcUD" aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg><a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('doc.applications.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.applications.lastUpdatedOn') }} <span class="dark">10/17/2022 </span>{{ $t('doc.applications.updatedBy') }} <span class="dark"> {{ $t('doc.applications.HrichaShandily') }}</span></p>
                        </div>
                        <div class="row">
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p class="preview">{{ $t('doc.createChatWoodAccount.previous') }}</p>
                                       
                                      <router-link to="/docs/user-guide/integrations" class="introduction-account">
                                         <span>«</span> {{ $t('doc.applications.integrations') }}
                                    </router-link>


                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p>{{ $t('doc.product.Next') }}</p>
                                           
                                        <router-link to="/docs/product/channels/live-chat/create-website-channel">
                                            {{ $t('doc.applications.AddandConfigure') }} <span>»</span>
                                                    </router-link>
                                        </div>
                                    </div>
                                </div>

                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
    </div>
</section></template>

<script>
export default ({})
</script>
<style>.application-img img {
    width: 100%;
    height: 100%;
}

.application-img {
    padding: 0 36px;
}</style>