<template>
    <section class="configure-profile">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="emails-to-Chatwoot-inbox">
                        <div class="getting-details">
                            <p>{{ $t('doc.menu.Channels') }} </p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <p>{{ $t("home.header.Gmail") }}</p>
                            <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                            <a href="#">{{ $t('Advance.Channels.Email.ForwardEmail.tittle') }}</a>
                        </div>
                        <div class="Introduction-details">
                            <h1>{{ $t('Advance.Channels.Email.ForwardEmail.email') }}</h1>
                            <p> <span class="step">{{ $t('Advance.Channels.Email.ForwardEmail.step1') }}</span> {{ $t('Advance.Channels.Email.ForwardEmail.copyEmail') }}</p>
                        </div>
                        <div class="email-address">
                            <img src="../../../../../assets/images/email_address.webp" alt="">
                        </div>
                        <div class="version-detail" id="i-through-welcome">
                            <p><span class="step">{{ $t('Advance.Channels.Email.ForwardEmail.step2') }}</span> {{ $t('Advance.Channels.Email.ForwardEmail.emailAddress') }}</p>
                        </div>

                         <div class="version-detail" id="i-through-welcome">
                                <p class="gmail">{{ $t('Advance.Channels.Email.ForwardEmail.one') }}<a href="/docs/product/channels/email/gmail/add-forward-email"> {{ $t('Advance.Channels.Email.ForwardEmail.forwarding') }}</a> {{ $t('Advance.Channels.Email.ForwardEmail.gmailInbox') }}</p>
                            </div>
                         <div class="version-detail" id="i-through-welcome">
                              <p><span class="step">{{ $t('Advance.Channels.Email.ForwardEmail.step3') }}</span>{{ $t('Advance.Channels.Email.ForwardEmail.sendEmail') }} </p>
                            </div>
                              <div class="version-detail" id="i-through-welcome">
                                  <p><span class="step">{{ $t('Advance.Channels.Email.ForwardEmail.step4') }}</span>{{ $t('Advance.Channels.Email.ForwardEmail.bccorEmail') }}</p>
                                </div>
                                     <div class="edit-update-detail">
                                <!-- <div class="edit-pen">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                        aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>
                                    <a href="#">{{ $t('Advance.Channels.Email.ForwardEmail.editPage') }}</a>
                                </div> -->
                                <p>{{ $t('Advance.Channels.Email.ForwardEmail.lastUpdatedOn') }} <span class="dark"> {{ $t('Advance.Channels.Email.ForwardEmail.date') }} </span> {{ $t('Advance.Channels.Email.ForwardEmail.by') }} <span class="dark"> {{ $t('Advance.Channels.Email.ForwardEmail.hrichaShandily') }}
                                    </span></p>
                            </div>
                             <div class="row">
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p class="preview">{{ $t('Advance.Channels.Email.ForwardEmail.previous') }}</p>

                                     
                                         <router-link to="/docs/product/channels/email/configure-smtp" class="introduction-account">
                                                   <span>{{ $t('Advance.Channels.Email.ForwardEmail.side') }}</span>     {{ $t('Advance.Channels.Email.ForwardEmail.smtp') }}
                                                    </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p>{{ $t('Advance.Channels.Email.ForwardEmail.next') }}</p>
                               
                                        <router-link to="/docs/product/channels/email/configure-imap">
                                            {{ $t('Advance.Channels.Email.ForwardEmail.imap') }} <span>{{ $t('Advance.Channels.Email.ForwardEmail.arrow') }}</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">

                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default ({})
</script>
<style>
.email-address img {
    width: 100%;
    height: 100%;
}

.email-address {
    padding: 0 36px;
    margin-bottom: 20px;
}
p.gmail {
    padding-left: 10px;
}
</style>