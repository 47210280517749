<template>
    <!-- <div class="main-wrp">
    <SideBar :isToggle = 'isToggle' :handleToggle="handleToggle" />
    <Header  :handleToggle="handleToggle"/>
    <div class="main-content">
      <router-view />
    </div>
  </div> -->
    <MainContent/>
</template>

<script>
import MainContent from "@/components/MainContent.vue";

export default {
  name: 'Introduction',
  data() {
    return {
      // isToggle: false
    }
  },
  components: {
    // Header,
    // Footer,
    // SideBar,
    MainContent
  },
  // methods: {
  //   handleToggle() {
  //     this.isToggle = !this.isToggle
  //   }
  // },
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
  }
</style>
