import { createApp } from 'vue'
import App from './App.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "@/assets/css/style.css";
import "@/views/chatwoot/shared-inbox/shared-inbox.css";
import "@/views/chatwoot/channels/channels.css";
import "@/views/chatwoot/liveChat/liveChat.css";
import "@/views/chatwoot/mobileApps/mobileApps.css";
import "@/views/chatwoot/chatbots/chatbots.css";
import "@/views/chatwoot/integrations/integrations.css";
import "@/views/chatwoot/security/security.css";
import "@/views/chatwoot/termsOfService/termsOfService.css";
import "@/views/chatwoot/labels/labels.css";
import "@/views/chatwoot/Automations/Automations.css";
import "@/views/chatwoot/teams/teams.css";
import "@/views/chatwoot/contactNotes/contactNotes.css";
import "@/views/chatwoot/privateNotes/privateNotes.css";
import "@/views/chatwoot/contactSegments/contactSegments.css";
import "@/views/chatwoot/businessHours/businessHours.css";
import "@/views/chatwoot/liveViewOfReports/liveViewOfReports.css";
import "@/views/chatwoot/conversationReports/conversationReports.css";
import "@/views/chatwoot/agentReports/agentReports.css";
import "@/views/chatwoot/labelReports/labelReports.css";
import "@/views/chatwoot/csatReports/csatReports.css";
import "@/views/chatwoot/inboxReports/inboxReports.css";
import "@/views/chatwoot/teamReports/teamReports.css";
import "@/views/chatwoot/keyboardShortcuts/keyboardShortcuts.css";
import "@/views/chatwoot/commandBar/commandBar.css";
import "@/views/chatwoot/bulkActions/bulkActions.css";
import "@/views/chatwoot/cannedResponses/cannedResponses.css";
import "@/views/chatwoot/agentCapacity/agentCapacity.css";
import "@/views/chatwoot/whatsappBusiness/whatsappBusiness.css";
import "@/views/chatwoot/twitterIntegration/twitterIntegration.css";
import "@/views/chatwoot/facebookIntegration/facebookIntegration.css";
import "@/views/chatwoot/telegramIntegration/telegramIntegration.css";
import "@/views/chatwoot/instagramIntegration/instagramIntegration.css";
import "@/views/chatwoot/smsIntegration/smsIntegration.css";
import "@/views/chatwoot/slackIntegration/slackIntegration.css";
import "@/views/chatwoot/emailIntegration/emailIntegration.css";
import "@/views/chatwoot/helpCenter/helpCenter.css";
import "@/views/chatwoot/Blog/blog.css";

import router from './router'

// (function(d,t) {         
// 	var BASE_URL="http://65.20.70.120:3000";
// 	var g= d.createElement(t),s=d.getElementsByTagName(t)[0];         
// 	g.src=BASE_URL+"/packs/js/sdk.js";         
// 	g.defer = true;         
// 	g.async = true;         
// 	s.parentNode.insertBefore(g,s);         
// 	g.onload=function(){           
// 		window.chatwootSDK.run({             
// 			websiteToken: 'DQwRoEqJPe2BvpGnPsN2YGRE',             
// 			baseUrl: BASE_URL           
// 		})         
// 	}       
// })(document,"script");


import { createI18n } from "vue-i18n";

const messages = {
  en: require("./locales/en.json"),
  zh: require("./locales/zh.json"),
};

const i18n = createI18n({
  locale: "zh", // Set the default locale
  messages,
});


createApp(App).use(router).use(i18n).mount("#app");
