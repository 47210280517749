<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("doc.product.Features") }}</p>
              <img
                src="../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />

              <p>{{ $t("Advance.DashboardApps.integrations") }}</p>
              <img
                src="../../../../../assets/images/up-arrow.svg"
                alt="logo"
                class="right-arrow"
              />

              <a href="#">{{ $t("Dyte.Dyte") }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.product.OnThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img src="/img/up_arrow_side.7f2fb856.svg" alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#How-to-set-up-the-Dyte' }"
                          >
                            <a @click="scrollTo('#How-to-set-up-the-Dyte')">
                              {{ $t("Dyte.dyteDesc") }}</a
                            >
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#How-to-set-up-the-Dyte' }"
                          >
                            <a @click="scrollTo('#How-to-set-up-the-Dyte')">
                              {{ $t("Dyte.dyteDescVideo") }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details">
              <div class="canned_list_wrp">
                <h1>{{ $t("Dyte.enableVideo") }}</h1>

                <p>
                  {{ $t("Dyte.videoCalling") }}
                  <a href="#" target="_blank" rel="noopener noreferrer">{{
                    $t("headersubmenu.Features.headerChat")
                  }}</a>
                  {{ $t("Dyte.utilizeData") }}
                </p>

                <p>
                  {{ $t("Dyte.dyteAccount") }}
                  <a
                    href="https://dev.dyte.io/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    >create</a
                  >
                  {{ $t("Dyte.onefirst") }}
                </p>
              </div>

              <div class="canned_list_wrp" id="How-to-set-up-the-Dyte">
                <h2>
                  {{ $t("Dyte.setuptheBcoor") }}
                </h2>

                <p>
                  <strong>{{ $t("doc.AddandConfigure.step1") }}</strong>
                  {{ $t("Dyte.settingApplication") }}
                </p>

                <p class="email_wrp">
                  <img
                    src="../../../../../assets/images/dyte-integration.webp"
                    class="img-fuild"
                  />
                </p>
              </div>

              <div class="canned_list_wrp">
                <p>
                  <strong>{{ $t("doc.AddandConfigure.step2") }}</strong>
                  {{ $t("Dyte.settingApplication1") }}
                </p>

                <p class="email_wrp">
                  <img
                    src="../../../../../assets/images/connect-dyte.png"
                    class="img-fuild"
                  />
                </p>
              </div>

              <div class="canned_list_wrp">
                <p>
                  <strong>{{ $t("doc.AddandConfigure.step3") }}</strong>
                  {{ $t("Dyte.OrganizationID") }}
                </p>

                <p class="email_wrp">
                  <img
                    src="../../../../../assets/images/dyte-setup.webp"
                    class="img-fuild"
                  />
                </p>
              </div>
              <div class="canned_list_wrp">
                <p>
                  <strong>{{ $t("Dyte.dyteValue") }}</strong>
                </p>

                <p>{{ $t("Dyte.theseValue") }}</p>

                <p class="widget_wrp">
                  <img
                    src="../../../../../assets/images/dyte-developer.png"
                    class="img-fuild"
                  />
                </p>

                <p>{{ $t("Dyte.bcoorValue") }}</p>

                <p>{{ $t("Dyte.dyteData") }}</p>
              </div>

              <div class="canned_list_wrp" id="how-to-video-call">
                <h2>
                  {{ $t("Dyte.dyteDescVideo") }}
                </h2>

                <p>
                  {{ $t("Dyte.dyteDesc1") }}
                </p>

                <p>
                  <strong>{{ $t("doc.AddandConfigure.step1") }}</strong>
                  {{ $t("Dyte.settingApplication") }}
                </p>

                <p class="widget_wrp">
                  <img
                    src="../../../../../assets/images/video-calling.png"
                    class="img-fuild"
                  />
                </p>
              </div>

              <div class="canned_list_wrp">
                <p>
                  <strong>{{ $t("doc.AddandConfigure.step2") }}</strong>
                  {{ $t("Dyte.settingApplication1") }}
                </p>

                <p class="widget_wrp">
                  <img
                    src="../../../../../assets/images/click-to-join.png"
                    class="img-fuild"
                  />
                </p>
              </div>

              <div class="canned_list_wrp">
                <p>
                  <strong>{{ $t("doc.AddandConfigure.step3") }}</strong>
                  {{ $t("Dyte.OrganizationID") }}
                </p>

                <p class="widget_wrp" style="padding-left: 0">
                  <img
                    src="../../../../../assets/images/video-call-interface.png"
                    class="img-fuild"
                  />
                </p>

                <p>
                  {{ $t("Dyte.dyteDesc1") }}
                </p>

                <p class="widget_wrp" style="padding-left: 0">
                  <img
                    src="../../../../../assets/images/video-call-interface-for.png"
                    class="img-fuild"
                  />
                </p>
              </div>

              <div class="edit-update-detail">
                <!-- <div class="edit-pen">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40"
                                        class="iconEdit_dcUD" aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>
                                    <a href="#">{{ $t('doc.product.EditThisPage') }}</a>
                                </div> -->
                <p>
                  {{ $t("doc.product.LastUpdated") }}
                  <span class="dark">4/21/2023</span>by
                  <span class="dark">Pranav Raj S</span>
                </p>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="create-chat-woot-account">
                    <p class="preview">{{ $t("Mobile_App.previous") }}</p>

                    <router-link
                      to="/docs/product/features/integrations/dialogflow"
                      class="introduction-account"
                    >
                      <span>«</span> {{ $t("doc.product.DialogFlow") }}
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="create-chat-woot-account">
                    <p>{{ $t("doc.product.Next") }}</p>

                    <!-- <router-link to="/docs/product/features/integrations/google-translate">
                                            {{ $t('doc.product.GoogleTranslate') }} <span>»</span>
                                        </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <section class="tableOfContents">
              <div class="tableOfContents-list">
                <ul id="side_section_info">
                  <li
                    :class="{
                      active_menu_item:
                        rightPanelMenuItem === '#How-to-set-up-the-Dyte-link',
                    }"
                  >
                    <a
                      id="#How-to-set-up-the-Dyte-link"
                      @click="scrollTo('#How-to-set-up-the-Dyte')"
                    >
                      {{ $t("Dyte.dyteDesc") }}</a
                    >
                  </li>

                  <li
                    :class="{
                      active_menu_item: rightPanelMenuItem === '#how-to-video-call-link',
                    }"
                  >
                    <a
                      id="#how-to-video-call-link"
                      @click="scrollTo('#how-to-video-call')"
                    >
                      {{ $t("Dyte.dyteDescVideo") }}</a
                    >
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
  name: "dashboard-apps",
  data() {
    return {
      isToggle: false,
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  components: {
    Header,
    Footer,
    SideBar,
    MainContent,
  },
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveMenuItem);
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );
        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
