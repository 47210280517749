<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.APIChannel') }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('Channels.ApiChannel.createChannel.tittle') }}</a>
                        </div>
                     
                        <div class="Introduction-details text-align-left">
                            <h1>{{ $t('Channels.ApiChannel.createChannel.channelInbox') }}</h1>
                            <p>{{ $t('Channels.ApiChannel.createChannel.channelConsists') }}</p>
                        </div>

                        <div class="channels-info code-info">
                            <div class="channels-title">
                                <ol class="channels-menu-ol">
                                    <li>{{ $t('Channels.ApiChannel.createChannel.channelApiCreate') }}</li>
                                    <li>{{ $t('Channels.ApiChannel.createChannel.messageApis') }}</li>
                                    <li>{{ $t('Channels.ApiChannel.createChannel.webhooksMessage') }}</li>
                                </ol>
                                <p>{{ $t('Channels.ApiChannel.createChannel.document') }}</p>
                                <p><strong>{{ $t('Channels.ApiChannel.createChannel.step1') }} </strong>{{ $t('Channels.ApiChannel.createChannel.settings') }} &gt; {{ $t('Channels.ApiChannel.createChannel.inboxesAdd') }}</p>
                                <p><strong>{{ $t('Channels.ApiChannel.createChannel.step2') }} </strong>{{ $t('Channels.ApiChannel.createChannel.select') }} <strong>{{ $t('Channels.ApiChannel.createChannel.api') }}</strong> {{ $t('Channels.ApiChannel.createChannel.listOfChannels') }}</p>
                                <p><img alt="select-api-inbox" src="../../../../../assets/images/select-api-inbox.png"
                                        class="max-img"></p>
                                <p><strong>{{ $t('Channels.ApiChannel.createChannel.step3') }} </strong>{{ $t('Channels.ApiChannel.createChannel.nameChannel') }}
                                </p>
                                <p><img alt="configure-screen" src="../../../../../assets/images/configure-screen.png"
                                        class="max-img"></p>
                                <p><strong>{{ $t('Channels.ApiChannel.createChannel.step4') }} </strong>{{ $t('Channels.ApiChannel.createChannel.agentsAdd') }}</p>
                                <p><img alt="add-agents" src="../../../../../assets/images/add-agents.png" class="max-img">
                                </p>
                                <p><strong>{{ $t('Channels.ApiChannel.createChannel.step5') }} </strong>{{ $t('Channels.ApiChannel.createChannel.setupInbox') }}</p>
                                <p><img alt="take-me-there" src="../../../../../assets/images/take-me-there.png"
                                        class="max-img"></p>
                                <p><img alt="inbox-welcome-screen"
                                        src="../../../../../assets/images/inbox-welcome-screen.png" class="max-img"></p>
                                <p>{{ $t('Channels.ApiChannel.createChannel.channelSetup') }} <a href="#">{{ $t('Channels.ApiChannel.createChannel.here') }}</a></p>

                            </div>
                        </div>

                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('Channels.ApiChannel.createChannel.editPage') }}</a>
                            </div> -->
                            <p>{{ $t('Channels.ApiChannel.createChannel.lastUpdatedOn') }} <span class="dark"> {{ $t('Channels.ApiChannel.createChannel.date') }} </span> {{ $t('Channels.ApiChannel.createChannel.by') }} <span class="dark"> {{ $t('Channels.ApiChannel.createChannel.pranavRaj') }}</span></p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Channels.ApiChannel.createChannel.previous') }}</p>

                                    <router-link to="/docs/product/channels/api/create-channel"
                                        class="introduction-account">
                                        <span>«</span> {{ $t('Channels.ApiChannel.createChannel.createChannelName') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Channels.ApiChannel.createChannel.next') }}</p>

                                    <router-link to="/docs/product/channels/api/receive-messages">
                                        {{ $t('Channels.ApiChannel.createChannel.receiveMessages') }} <span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">

                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'createchannel',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    methods: {
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
