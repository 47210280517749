<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                      <div class="getting-details">
                                    <p>{{$t('doc.product.Channels')}}</p>
                                    <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                                    <p>{{$t('wassuptwilo.WLC')}}</p>
                                    <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                                    <a href="#">{{$t('twiloChannel.360Dialog')}}</a>
                                </div>
                                
                        <div class="Introduction-details">
                            <h1>{{$t('360dialog.setupdia')}}</h1>
                            <p><b>{{$t('twiloChannel.step1')}}</b> {{$t('twiloChannel.setupPath')}}</p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/adding-inbox-in-chatwoot.webp">
                              </div>
                              <p><b>{{$t('twiloChannel.step2')}}</b> {{$t('wassuptwilo.clickWassup')}}</p>
                              <div class="whatssimg">
                                  <img src="../../../../../assets/images/channel-select.webp">
                                </div>
                                <p><b>{{$t('twiloChannel.step3')}}</b> {{$t('360dialog.choose360')}}</p>
                              <div class="whatssimg">
                                  <img src="../../../../../assets/images/select-360dialog.webp">
                                </div>
                            <p> {{$t('360dialog.restofStes')}} <a href="#"> {{$t('360dialog.thisdoc')}}</a></p>
                        </div>
                  
                
                 
                          <div class="edit-update-detail">
                              <!-- <div class="edit-pen">
                                  <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                      aria-hidden="true">
                                      <g>
                                          <path
                                              d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                          </path>
                                      </g>
                                  </svg>
                                  <a href="#">{{$t('doc.inboxes.edit_page')}}</a>
                              </div> -->
                              <p>{{$t('doc.inboxes.last_updated_on')}} <span class="dark">{{$t('twiloChannel.date')}} </span> {{$t('doc.inboxes.updated_by')}} <span class="dark"> {{$t('twiloChannel.name')}}</span></p>
                          </div>
                
                
                        
                        <div class="row">
                            <div class="col-lg-6">
                              <div class="create-chat-woot-account text-left">
                                <p>{{$t('concepts.previousPage')}}</p>
                                <a href="#">{{$t('360dialog.twitter')}}<span>»</span></a>
                            </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{$t('concepts.nextPage')}}</p>
                                    <a href="#">{{$t('360dialog.twilio')}}<span>»</span></a>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">
                   
            </div>
        </div>
    </div>
</section></template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');

            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('href'));

                if (target?.offsetTop <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>


6500