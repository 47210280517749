<template>
  <header class="header Introduction-header" id="navbar">
    <nav class="navbar navbar-expand-lg" :class="{ onScroll: !view.topOfPage }">
      <div class="container-fluid">
        <div class="togglebutton">
          <button class="toggle" @click="handleToggle" aria-label="toggle" type="button">
            <svg width="30" height="30" viewBox="0 0 30 30" aria-hidden="true">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
                d="M4 7h22M4 15h22M4 23h22"
              ></path>
            </svg>
          </button>
        </div>
        <div class="moon-effiect dark-light-mood" @click="theameModeHandler">
            <svg
              v-if="!isDarkMode"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="lightToggleIcon_v35p"
            >
              <path
                fill="currentColor"
                d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"
              ></path>
            </svg>
            <svg
              v-else
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="darkToggleIcon_nQuB"
            >
              <path
                fill="#fff"
                d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"
              ></path>
            </svg>
          </div>

        <router-link class="navbar-brand" to="/">
          <img
            src="../assets/images/bcoor_logo.svg"
            class="logo"
            alt="logo11"
            width="200"
            height="100"
          />

          <img
            src="../assets/images/darkmood-bcoor.png"
            class="logo-white"
            alt="logo1"
            width="200"
            height="100"
          />
        </router-link>
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav menulist">
            <li>
              <div class="tracation-menu">
                <a href="#" @click="openLang()">
                  <span v-if="selectLanguage == 'en'"> English </span>
                  <span v-else> 简体中文 </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="icon text-opacity-70 h-4 w-4 transition duration-150 ease-in-out group-hover:text-opacity-80"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    data-v-ca945699=""
                  >
                    <path
                      fill="currentColor"
                      d="M4.293 8.293a1 1 0 0 1 1.414 0L12 14.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414Z"
                    ></path>
                  </svg>
                </a>
                <ul class="tracation-dropdown" v-if="langBoxData">
                  <li
                    :style="$i18n.locale == 'en' ? activeClassObject : languageClass"
                    @click="selectLanguageFunct('en')"
                  >
                    <a href="#">English</a>
                    <div v-if="selectLanguage == 'en'" v-html="svgObject.content"></div>
                  </li>

                  <li
                    :style="$i18n.locale == 'zh' ? activeClassObject : languageClass"
                    @click="selectLanguageFunct('zh')"
                  >
                    <a href="#">简体中文</a>
                    <div v-if="selectLanguage == 'zh'" v-html="svgObject.content"></div>
                  </li>
                </ul>
              </div>
            </li>
            <router-link class="navbar-brand logo-main" to="/">
          <img
            src="../assets/images/bcoor_logo.svg"
            class="logo"
            alt="logo11"
            width="200"
            height="100"
          />

          <img
            src="../assets/images/darkmood-bcoor.png"
            class="logo-white"
            alt="logo1"
            width="200"
            height="100"
          />
        </router-link>
            <li class="nav-item nav-user-guide">
              <a class="nav-link active" aria-current="page" href="#">{{
                $t("home.sidebarMenu.UserGuide")
              }}</a>
            </li>
          </ul>
          <div class="moon-effiect" @click="theameModeHandler">
            <svg
              v-if="!isDarkMode"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="lightToggleIcon_v35p"
            >
              <path
                fill="currentColor"
                d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"
              ></path>
            </svg>
            <svg
              v-else
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="darkToggleIcon_nQuB"
            >
              <path
                fill="#fff"
                d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "Header",
  props: {
    handleToggle: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      langBoxData: false,
      isToggle: false,
      isDarkMode: false,
      view: {
        topOfPage: true,
      },
      selectLanguage: localStorage.getItem("language") ?? "en",
      supportedLanguages: {
        en: "English",
        zh: "简体中文",
      },
    };
  },
  computed: {
    activeClassObject() {
      return {
        "background-color": "#ECF6FE",
      };
    },
    languageClass() {
      return {
        "background-color": "",
      };
    },
    svgObject() {
      return {
        content: `
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 800 601" fill="none" class="open">
                        <path
                            d="M742.798 0L256.305 486.428L57.1495 287.251L0 344.503L256.305 600.799L800 57.1638L742.798 0Z"
                            fill="#589DF4" />
                    </svg>`,
      };
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (localStorage.getItem("theme") == "" || localStorage.getItem("theme") == null) {
      this.isDarkMode = false;
      localStorage.setItem("isDarkMode", this.isDarkMode);
      localStorage.setItem("theme", "light");
      document.documentElement.setAttribute("data-theme", "light");
    }
    this.isDarkMode = localStorage.getItem("isDarkMode") == "true" ? true : false;
    document.documentElement.setAttribute("data-theme", localStorage.getItem("theme"));
  },
  methods: {
    selectLanguageFunct(event) {
      if (this.selectLanguage != event) {
        this.$i18n.locale = event;
        this.selectLanguage = event;
        this.langBoxData = !this.langBoxData;
        localStorage.setItem("language", this.selectLanguage);

       if(event == 'zh')
       {
        document.title = 'Bcoor.com 永远免费使用的在线客服系统 🌟全渠道对话接入｜自动实时翻译｜不限客服坐席｜安全坚如磐石';
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
          metaDescriptionTag.setAttribute('content', "使用 Bcoor 的在线客户服务系统——安全无忧、质量上乘、数据可靠，Bcoor超越美洽、LIve800 以及市面上所有客服系统。此外，还可通过我们极具吸引力的定价计划为您服务 💰💰 今天就让我们随你起航，永远辉煌。");  
        }
        const metaOGDescriptionTag = document.querySelector('meta[name="og:description"]');
        if (metaOGDescriptionTag) {
          metaOGDescriptionTag.setAttribute('content', "使用 Bcoor 的在线客户服务系统——安全无忧、质量上乘、数据可靠，Bcoor超越美洽、LIve800 以及市面上所有客服系统。此外，还可通过我们极具吸引力的定价计划为您服务 💰💰 今天就让我们随你起航，永远辉煌。");  
        }
       }else if(event == 'en')
       {
        document.title = "Bcoor.com Forever Free - 🌟Boundless Communication, Marketing Made Easy";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
          metaDescriptionTag.setAttribute('content', "Transform your customer communication with Bcoor's online customer service system - 'Boundless Communication, Marketing Made Easy' 🌟. Our system offers unparalleled security, superior quality, and reliable data, surpassing competitors like Zendesk and LiveAgent. Plus, our attractive pricing plans make it easy for you to get started today - let us help you soar to new heights of success!");  
        }
        const metaOGDescriptionTag = document.querySelector('meta[name="og:description"]');
        if (metaOGDescriptionTag) {
          metaOGDescriptionTag.setAttribute('content', "Transform your customer communication with Bcoor's online customer service system - 'Boundless Communication, Marketing Made Easy' 🌟. Our system offers unparalleled security, superior quality, and reliable data, surpassing competitors like Zendesk and LiveAgent. Plus, our attractive pricing plans make it easy for you to get started today - let us help you soar to new heights of success!");  
        }
       }




      }
    },
    openLang() {
      this.langBoxData = !this.langBoxData;
    },
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    theameModeHandler() {
      this.isDarkMode = !this.isDarkMode;
      if (this.isDarkMode) {
        document.documentElement.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
        localStorage.setItem("isDarkMode", this.isDarkMode);
      } else {
        localStorage.setItem("theme", "light");
        localStorage.setItem("isDarkMode", this.isDarkMode);
        document.documentElement.setAttribute("data-theme", "light");
      }
    },
    // handleToggle() {
    //   document.body.classList.add('custom-class');
    // },
  },
};
</script>

<style>
body nav {
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

body nav.onScroll {
  box-shadow: 0 0 10px #aaa;
  background-color: #fff;
}

body nav.onScroll ul li {
  color: #10b761;
}

body nav ul {
  margin-left: 3em;
  width: 25%;
  display: flex;
  justify-content: space-between;
}

body nav ul li {
  cursor: pointer;
  font-weight: 600;
  list-style-type: none;
  color: white;
}
</style>
