<template>
    <Header @language-selected="handleLanguageSelected"/>
    <section class="labels-page">
        <div class="container">
            <div class="integrations-heading privateNotes-heading">
                <h2>{{$t('commandBar.commandBar_title')}}</h2>
                <p>{{ $t('commandBar.commandBar_desc') }}</p>
                <div class="start-button">
                    <a href="https://app.bcoor.com">{{ $t('emailIntegration.sign_up_free') }}</a>
                </div>
            </div>
            <div class="labels-page-details">
                <p>{{ $t('commandBar.commandBar_desc_data') }}</p>
            </div>
            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/commandBar/quick-access.png" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('commandBar.quick_access') }}</h4>
                            <p>{{ $t('commandBar.quick_access_data') }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12 orderr-2">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('commandBar.swift_navigation') }}</h4>
                            <p>{{ $t('commandBar.swift_navigation_desc') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 orderr-1">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/commandBar/swift-navigation.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/commandBar/conversation-actions.png" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('commandBar.conversation_action') }}</h4>
                            <p>{{ $t('commandBar.commandBar_description') }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="conversations  d-none">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="conversations-heading">
                            <h3>{{ $t('commandBar.effcient_customer') }}</h3>
                            <p>{{ $t('automation.conversations') }}</p>
                            <div class="signup-button">
                                <a href="https://app.bcoor.com">{{ $t('emailIntegration.sign_up_free') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="conversations-img">
                            <img src="../../../assets/images/automations/dashboard-small.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
        name: "sharedInbox",
        components: {
            Footer,
            Header,
        },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
    };
</script>