<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.GettingStarted') }}</p>
                            <a href="#">{{ $t('doc.product.Introduction') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            {{ $t('doc.product.Introduction') }}
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li id="menu_item_1"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                                            href="#channels-info">{{ $t('doc.product.Channels') }}</a></li>
                                                    <li id="menu_item_2"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_2' }"><a
                                                            href="#features-info">{{ $t('doc.product.Features') }}</a></li>
                                                    <li id="menu_item_3"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_3' }"><a
                                                            href="#integration-info">{{ $t('doc.product.Integration') }}</a></li>
                                                    <li id="menu_item_4"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_4' }"><a
                                                            href="#advanced-info">{{ $t('doc.product.Advanced') }}</a></li>
                                                    <li id="menu_item_5"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_5' }"><a
                                                            href="#mobile-app-info">{{ $t('doc.product.MobileApp') }}</a></li>
                                                    <li id="menu_item_6"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_6' }"><a
                                                            href="#other-info">{{ $t('doc.product.Others') }}</a></li>
                                                    <li id="menu_item_7"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_7' }"><a
                                                            href="#compatibility-info">{{ $t('doc.product.CompatibilityAndRequirements') }}</a>
                                                    </li>
                                                    <li id="menu_item_8"
                                                        :class="{ 'active_menu_item': activeMenuItem === 'menu_item_8' }"><a
                                                            href="#next-steps">{{ $t('doc.product.NextSteps') }}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">
                            <h1>{{ $t('dialogflow.integratebcoorwithDialogflow') }}</h1>
                            <p>{{ $t('dialogflow.dialogflowDescription') }}</p>
                        
                            <h2 style="text-align: left;">{{ $t('dialogflow.configuringdialogflowIntegrationinbcoor') }}</h2>

                            <p class="http-request">{{ $t('dialogflow.notedialogflow') }} <a href="#">{{ $t('dialogflow.createflow') }}</a> {{ $t('dialogflow.first') }}.</p>

                                <p class="pb-2 pt-4">{{ $t('dialogflow.integrationSetting') }}</p>
                                <p class="pb-2">{{ $t('dialogflow.project_desc') }}</p>

                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/add-to-chatwoot.png">
                                </div>
                                <p class="pb-2">{{ $t('dialogflow.integration_complete') }}</p>
                                <p class="pb-2">{{ $t('Advance.features.Integrations.Dialogflow.test') }}</p>

                                <h2 style="text-align: left;">{{ $t('Advance.features.Integrations.Dialogflow.creating') }}</h2>

                                <p class="pb-2">{{ $t('Advance.features.Integrations.Dialogflow.createDialog') }}</p>
                                
                                <h3 style="text-align: left;">{{ $t('Advance.features.Integrations.Dialogflow.newAgent') }}</h3>
                                <p class="pb-2">Go to <a href="#">{{ $t('Advance.features.Integrations.Dialogflow.console') }}</a>  We will be using Dialogflow Essentials for this article. Click on "Create new agent". It would show options as shown below.</p>

                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/create-new-agent.png">
                                </div>
                               
                                <h3 style="text-align: left;"> {{ $t('Advance.features.Integrations.Dialogflow.intents') }} </h3>
                                <p class="pb-2">You will need to create intents based on how you want your bot to respond. There will be 2 default intents in the project called "Default Fallback Intent" and "Default Welcome Intent", as shown below</p>
                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/default-intents.png">
                                </div>

                               <p class="pb-2">Now a basic bot configuration is complete, let us create a service account and connect it with bcoor.</p>
                               
                               <p class="http-request">You can also create additional intents for your specific use cases.
                                bcoor also supports advanced intents that enables agent handoff, interactive messages etc.
                                refer: <a href="#"> Advanced Intents</a></p>
                                <h3 style="text-align: left;margin-top: 20px;">Create a service account</h3>
                                <p class="pb-2">To connect this bot with bcoor, you need to create a service account on your Google Cloud console. Navigate to the project console in Google cloud by clicking on the <b>Project ID</b>  in the project settings below.</p>
                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/project-settings.png">
                                </div>
                                <p>Navigate to IAM & Admin -> Service Accounts. You will see a view like the one below. Click on "Create Service Account".</p>

                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/service-account.png">
                                </div>
                                <p>Provide a Service Account name and description as shown below.</p>

                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/service-account-details.png">
                                </div>

                                <p>To provide access, select Dialogflow API Client from the dropdown.</p>

                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/service-access.png">
                                </div>
                                <p>Continue and click on "Done". Now, you would be able to see the service listed in the dashboard. The next step is to create a key so that it can be shared with bcoor. Click on the service account and click on the "Keys" tab. Then, click on "Add Key". You will be able to see a screen like the one below.</p>

                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/add-keys.png">
                                </div>
                                
                                <p>Click on "JSON" and click on "Create". It would generate a key for your service account, download the key and save it for use later.</p>
                                <h2 style="text-align:left;">Advanced Intents</h2>
                                <h3 style="text-align: left;">Creating a handoff intent</h3>
                                <p class="pb-2">Once the user requests to talk to the agent, Dialogflow needs to inform bcoor that an agent can now take over the conversation.</p>
                                <p class="pb-2">Create an intent named "Handoff Intent" with training phrases like "Talk to an agent" or "Speak with an agent", etc. To handle the handoff intent, we will create a "Custom Payload" response as shown below.</p>
                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/handoff-intent.png">
                                </div>
                                <p class="pb-2">Upon triggering an intent with the above payload, bcoor will toggle the status of the conversation to open and hands it off to an agent.</p>
                                
                                <h3 style="text-align: left;">Interactive Messages</h3>
                                <p class="http-request">Note: Interactive messages are supported only in website channel at the moment</p>
                                <p class="pb-2">bcoor dialogflow integration also supports <a href="#">interactive messages.</a>  The following types of interactive messages are supported:</p>
                                <ul>
                                    <li><p class="pb-2">1.<a href="#">Options</a>: follow up supported</p></li>
                                    <li><p class="pb-2">1.<a href="#">Cards</a></p></li>
                                    <li><p class="pb-2">1.<a href="#">Articles</a></p></li>
                                </ul>
                                <p class="pb-2" style="font-size: 14px; font-weight: 600;">Creating an interactive message Intent</p>
                                <p class="pb-2">You can create other interactive messages by changing the payload as mentioned in interactive messages documentation.</p>
                                <p class="pb-2">Create an intent with required training phrases and a "Custom Payload" response as shown below for an options message.</p>

                                <p class="http-request ">When user interactes with the input messages. The value they selected is sent back to dialogflow, So that you configure a follow up intent if required.
                                <b>Example:</b>  Configure an intent with training phrase "I like biryani" for the cases where the contact select the option "biryani".</p>
                                <h2 style="text-align:left; margin-top: 20px;">FAQ</h2>   

                                <h3 style="text-align: left;">How can an agent transfer the conversation back to Dialogflow bot?</h3>
                                <p class="pb-2">When the Dialogflow bot is connected to an inbox, conversations are created with <span class="click-inbox">pending</span>  status instead of <span class="click-inbox">open</span> . This lets the intial triaging to happen via the bot before the conversation is passed on to an agent. When handoff happens, the conversation status is changed into open and the bot stops responding to it.</p>
                                <p class="pb-2">Sometimes the agents would want to push back a conversation which was handed off, back again into the bot queue. They can do this by changing the conversation status back to pending again so that the bot will start responding to that conversation again.</p>

                        </div>
                        
                          <div class="edit-update-detail">
                              <div class="edit-pen">
                                  <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                      aria-hidden="true">
                                      <g>
                                          <path
                                              d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                          </path>
                                      </g>
                                  </svg>
                                  <a href="#">Edit this page</a>
                              </div>
                              <p>Last updated on <span class="dark">4/28/2023 </span>by <span class="dark"> Shivam
                                      Mishra</span></p>
                          </div>
                
                
                        
                        <div class="row">
                            <div class="col-lg-6">
                              <div class="create-chat-woot-account text-left">
                                <p>Previous</p>
                                <a href="#">Twitter<span>»</span></a>
                            </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>Next</p>
                                    <a href="#">Twilio<span>»</span></a>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li id="menu_item_1" :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                        href="#channels-info">Setting a priority</a></li>
                                <li id="menu_item_1" :class="{ 'active_menu_item': activeMenuItem === 'menu_item_1' }"><a
                                href="#channels-info">FAQs</a></li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section></template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');

            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('href'));

                if (target?.offsetTop <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>
