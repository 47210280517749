<template>
  <section class="docmain-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-8">
          <div class="docmain-container">
            <div class="getting-details">
              <p>{{ $t("doc.createChatWoodAccount.SetupYourAccount") }}</p>
              <img src="../assets/images/up-arrow.svg" alt="logo" class="right-arrow" />
              <a href="#">{{ $t("doc.product.Introduction") }}</a>
            </div>
            <div class="according-menu">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>{{ $t("doc.product.OnThisPage") }}</p>
                      <span class="down_up_icon"
                        ><img src="../assets/images/up_arrow_side.svg" alt=""
                      /></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="tableOfContents-list">
                        <ul id="side_section_info">
                          <li
                            :class="{
                              active_menu_item: rightPanelMenuItem === '#channels-info',
                            }"
                          >
                            <a @click="scrollTo('#channels-info')">{{
                              $t("doc.menu.Channels")
                            }}</a>
                          </li>
                          <li
                            :class="{
                              active_menu_item: rightPanelMenuItem === '#features-info',
                            }"
                          >
                            <a @click="scrollTo('#features-info')">{{
                              $t("doc.menu.Features")
                            }}</a>
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#integration-info' }"
                          >
                            <a @click="scrollTo('#integration-info')">{{
                              $t("doc.menu.Integration")
                            }}</a>
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#advanced-info' }"
                          >
                            <a @click="scrollTo('#advanced-info')">{{
                              $t("doc.menu.Advanced")
                            }}</a>
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#mobile-app-info' }"
                          >
                            <a @click="scrollTo('#mobile-app-info')">{{
                              $t("doc.menu.MobileApp")
                            }}</a>
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#other-info' }"
                          >
                            <a @click="scrollTo('#other-info')">{{
                              $t("doc.menu.Others")
                            }}</a>
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#compatibility-info' }"
                          >
                            <a @click="scrollTo('#compatibility-info')"
                              >{{ $t("doc.menu.CompatibilityAndRequirements") }}
                            </a>
                          </li>
                          <li
                            class="{ 'active_menu_item': rightPanelMenuItem === '#next-steps' }"
                          >
                            <a @click="scrollTo('#next-steps')">{{
                              $t("doc.menu.NextSteps")
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Introduction-details">
              <h1>{{ $t("doc.product.Introduction") }}</h1>
              <p>
                {{ $t("doc.product.BccorIsAn") }}
                <a
                  href="https://opensource.com/resources/what-open-source"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ $t("doc.product.OpenSource") }}</a
                >
                {{ $t("doc.product.paragraph1") }}
                {{ $t("doc.product.paragraph2") }}
              </p>
              <p>
                {{ $t("doc.product.paragraph3") }} {{ $t("doc.product.paragraph4") }}
                {{ $t("doc.product.paragraph5") }}
              </p>
              <p>{{ $t("doc.product.paragraph6") }}</p>
              <p>{{ $t("doc.product.paragraph7") }}</p>
            </div>
            <div class="channels-info" id="channels-info">
              <div class="channels-title">
                <h2>{{ $t("doc.product.Channels") }}</h2>
                <ul class="channels-menu">
                  <!-- <li><a href="#">{{ $t('doc.product.WebsiteLiveChat') }}</a></li> -->
                  <li>
                    <a href="/docs/product/channels/facebook">{{
                      $t("doc.product.Facebook")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/instagram">{{
                      $t("doc.product.Instagram")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/twitter">{{
                      $t("doc.product.Twitter")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/whatsapp/whatsapp-cloud">{{
                      $t("doc.product.WhatsappViaWhatsappCloud")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/whatsapp/twilio">{{
                      $t("doc.product.WhatsappViaTwilio")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/whatsapp/360dialog">{{
                      $t("doc.product.WhatsappVia360Dialog")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/sms/twilio">{{
                      $t("doc.product.SMSviaTwilio")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/sms/bandwidth">{{
                      $t("doc.product.SMSviaBandwidth")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/email/create-channel">{{
                      $t("doc.product.EmailViaCreateChannel")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/email/configure-imap">{{
                      $t("doc.product.EmailViaConfigureIMAP")
                    }}</a>
                  </li>
                  <!-- <li><a href="/docs/product/channels/email/configure-smtp">{{ $t('doc.product.EmailViaConfigureSmtp') }}</a></li> -->
                  <li>
                    <a href="/docs/product/channels/email/forward-email">{{
                      $t("doc.product.EmailViaForwardEmail")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/telegram">{{
                      $t("doc.product.Telegram")
                    }}</a>
                  </li>
                  <li>
                    <a href="/docs/product/channels/line">{{ $t("doc.product.Line") }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <section id="features-info">
              <div class="channels-info">
                <div class="channels-title">
                  <h2>{{ $t("doc.product.Features") }}</h2>
                  <ul class="channels-menu">
                    <li>
                      <a href="/docs/user-guide/features/canned-responses">{{
                        $t("doc.product.CannedResponses")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/custom-attributes">{{
                        $t("doc.product.CustomAttributes")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/csat">{{
                        $t("doc.product.CSAT")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/contacts">{{
                        $t("doc.product.Contacts")
                      }}</a>
                    </li>
                    <li>
                      <a
                        href="/docs/product/features/inbox/routing-conversations-round-robin"
                        >{{ $t("doc.product.ConversationRouting") }}</a
                      >
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/folders">{{
                        $t("doc.product.Folders")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/keyboard-shortcuts">{{
                        $t("doc.product.KeyboardShortcuts")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/multilingual-support">{{
                        $t("doc.product.MultilingualSupport")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/commandbar">{{
                        $t("doc.product.Commandbar")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/segments">{{
                        $t("doc.product.Segments")
                      }}</a>
                    </li>
                    <!-- <li><a href="/docs/user-guide/features/pre-chat-form">{{
                                            $t('doc.product.PreChatForm') }}</a></li> -->
                    <li>
                      <a href="/docs/user-guide/features/template-variables">{{
                        $t("doc.product.TemplateVariables")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/priority">{{
                        $t("doc.product.ConversationPriority")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/pre-chat-form">{{
                        $t("doc.product.preChatForm")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/user-guide/features/automations">{{
                        $t("doc.product.automations")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/features/macros">{{
                        $t("doc.product.macros")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <section id="integration-info">
              <div class="channels-info">
                <div class="channels-title">
                  <h2>{{ $t("doc.product.Integration") }}</h2>
                  <ul class="channels-menu">
                    <li>
                      <a href="/docs/product/features/integrations/dialogflow">{{
                        $t("doc.product.DialogFlow")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/features/integrations/dyte">{{
                        $t("doc.product.Dyte")
                      }}</a>
                    </li>
                    <!-- <li>
                      <a href="/docs/product/features/integrations/google-translate">{{
                        $t("doc.product.GoogleTranslate")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/features/integrations/slack">{{
                        $t("doc.product.Slack")
                      }}</a>
                    </li> -->
                  </ul>
                </div>
              </div>
            </section>

            <section id="advanced-info">
              <div class="channels-info">
                <div class="channels-title">
                  <h2>{{ $t("doc.product.Advanced") }}</h2>
                  <ul class="channels-menu">
                    <li>
                      <a href="/docs/product/advanced/agent-bots">{{
                        $t("doc.product.AgentBots")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/advanced/interactive-messages">{{
                        $t("doc.product.InteractiveMessages")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/advanced/dashboard-apps">{{
                        $t("doc.product.HelpCenter")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/advanced/webhooks">{{
                        $t("doc.product.ConfigureWebhooks")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/advanced/webhook-events">{{
                        $t("doc.product.supportedWebhooks")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/advanced/websocket-events">{{
                        $t("doc.product.WebsocketEvents")
                      }}</a>
                    </li>
                    <!-- <li>
                      <a href="/docs/product/advanced/cookies">{{
                        $t("doc.product.Cookies")
                      }}</a>
                    </li> -->
                  </ul>
                </div>
              </div>
            </section>

            <section id="mobile-app-info">
              <div class="channels-info">
                <div class="channels-title">
                  <h2>{{ $t("doc.product.MobileApp") }}</h2>
                  <ul class="channels-menu">
                    <li>
                      <a href="/docs/product/mobile-app/android">{{
                        $t("doc.product.Android")
                      }}</a>
                    </li>
                    <li>
                      <a href="/docs/product/mobile-app/ios">{{
                        $t("doc.product.iOS")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <section id="compatibility-info">
              <div class="channels-title">
                <h2>{{ $t("doc.product.CompatibilityAndRequirements") }}</h2>
                <p>
                  {{ $t("doc.product.WebDashboardAndLiveChatWidgetWorks") }}
                  {{ $t("doc.product.SupportBrowserVersions") }}
                </p>
              </div>
            </section>

            <div class="table-details padding-leftone">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("doc.product.Browser") }}</th>
                    <th>{{ $t("doc.product.MinimumVersion") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t("doc.product.MozillaFirefox") }}</td>
                    <td>{{ $t("doc.product.fiftytwo") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.GoogleChrome") }}</td>
                    <td>{{ $t("doc.product.fiftyseven") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.Chromium") }}</td>
                    <td>{{ $t("doc.product.fiftyseven") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.AppleSafari") }}</td>
                    <td>{{ $t("doc.product.Fourteen") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.MicrosoftEdge") }}</td>
                    <td>{{ $t("doc.product.Sixteen") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="moblie-os">{{ $t("doc.product.supportedMobileOSVersions") }}</p>
            <div class="table-details padding-leftone">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("doc.product.Browser") }}</th>
                    <th>{{ $t("doc.product.MinimumVersion") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t("doc.product.MozillaFirefox") }}</td>
                    <td>{{ $t("doc.product.fiftytwo") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.GoogleChrome") }}</td>
                    <td>{{ $t("doc.product.fiftyseven") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.Chromium") }}</td>
                    <td>{{ $t("doc.product.fiftyseven") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.AppleSafari") }}</td>
                    <td>{{ $t("doc.product.Fourteen") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("doc.product.MicrosoftEdge") }}</td>
                    <td>{{ $t("doc.product.Sixteen") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <section id="next-steps">
              <div class="channels-title">
                <h2>{{ $t("doc.product.NextSteps") }}</h2>
                <p>{{ $t("doc.product.Werecommendthat") }}</p>
                <ul class="next-details">
                  <li>
                    <a href="#"
                      >{{ $t("doc.product.SidebarNavigation") }}
                      <span class="right">↩</span></a
                    >
                  </li>
                </ul>
                <!-- <span class="let-us-doc">{{ $t("doc.product.UserFeedback") }}</span> -->
              </div>
            </section>
            <div class="edit-update-detail">
              <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="https://github.com/bcoor/docs/edit/main/docs/product.md">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
              <p>
                {{ $t("doc.product.LastUpdated") }}
                <span class="dark">{{ $t("doc.product.Date") }} </span>
                {{ $t("doc.product.By") }}
                <span class="dark"> {{ $t("doc.product.Author") }}</span>
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <!-- <div class="create-chat-woot-account">
                                        <p class="preview">Previous</p>
                                         <router-link to="/docs/user-guide/setup-your-account/create-an-account" class="introduction-account">
                                  <span>«</span>Introduction
                                        </router-link>
                                    
                                    </div> -->
              </div>
              <div class="col-lg-6">
                <div class="create-chat-woot-account">
                  <p>{{ $t("doc.product.Next") }}</p>
                  <router-link to="/docs/user-guide/setup-your-account/create-an-account">
                    {{ $t("doc.product.CreateAccount") }}<span>»</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4">
          <section class="tableOfContents">
            <div class="tableOfContents-list">
              <ul id="side_section_info">
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#channels-info-link',
                  }"
                >
                  <a id="#channels-info-link" @click="scrollTo('#channels-info')">
                    {{ $t("doc.menu.Channels") }}</a
                  >
                </li>

                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#features-info-link',
                  }"
                >
                  <a id="#features-info-link" @click="scrollTo('#features-info')">{{
                    $t("doc.menu.Features")
                  }}</a>
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#integration-info-link',
                  }"
                >
                  <a id="#integration-info-link" @click="scrollTo('#integration-info')">{{
                    $t("doc.menu.Integration")
                  }}</a>
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#advanced-info-link',
                  }"
                >
                  <a id="#advanced-info-link" @click="scrollTo('#advanced-info')">{{
                    $t("doc.menu.Advanced")
                  }}</a>
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#mobile-app-info-link',
                  }"
                >
                  <a id="#mobile-app-info-link" @click="scrollTo('#mobile-app-info')">{{
                    $t("doc.menu.MobileApp")
                  }}</a>
                </li>
                <li
                  :class="{ active_menu_item: rightPanelMenuItem === '#other-info-link' }"
                >
                  <a id="#other-info-link" @click="scrollTo('#other-info')">{{
                    $t("doc.menu.Others")
                  }}</a>
                </li>
                <li
                  :class="{
                    active_menu_item: rightPanelMenuItem === '#compatibility-info-link',
                  }"
                >
                  <a
                    @click="scrollTo('#compatibility-info')"
                    id="#compatibility-info-link"
                    >{{ $t("doc.menu.CompatibilityAndRequirements") }}</a
                  >
                </li>
                <li
                  :class="{ active_menu_item: rightPanelMenuItem === '#next-steps-link' }"
                >
                  <a @click="scrollTo('#next-steps')" id="#next-steps-link">{{
                    $t("doc.menu.NextSteps")
                  }}</a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainContent",
  data() {
    return {
      activeMenuItem: null,
      rightPanelMenuItem: null,
    };
  },
  components: {},
  mounted() {
    window.addEventListener("scroll", this.setActiveMenuItem);
  },
  // beforeDestroy() {
  //     window.removeEventListener('scroll', this.setActiveMenuItem);
  // },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        this.rightPanelMenuItem = `${selector}-link`;
      } else {
        console.error(`Element with selector '${selector}' not found.`);
      }
    },
    setActiveMenuItem() {
      const scrollPosition = window.scrollY;
      const menuItems = document.querySelectorAll("#side_section_info li");
      this.activeMenuItem = null;
      for (let i = menuItems.length - 1; i >= 0; i--) {
        const menuItem = menuItems[i];
        // console.log(menuItem)
        const target = document.querySelector(
          menuItem
            .querySelector("a")
            .getAttribute("id")
            ?.replace(/-link(?=[^-]*$)/, "")
        );

        if (target?.offsetTop - 200 <= scrollPosition) {
          let tempEle;
          if (menuItem) {
            tempEle = menuItem?.getAttribute("id");
            let targetID = target?.getAttribute("id");
            this.rightPanelMenuItem = `#${targetID}-link`;
            // menuItem?.classList.add("active_menu_item");
          }
          this.activeMenuItem = tempEle;
          return;
        }
      }
    },
    isActiveSection(index) {
      return index === this.activeSectionIndex;
    },
  },
};
</script>
