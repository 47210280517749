<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('doc.product.Advanced') }}</p><img src="/img/up-arrow.7f2fb856.svg" alt="logo" class="right-arrow"><a
                                href="#">{{ $t('doc.product.AgentBots') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne"><button class="accordion-button"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <p>{{ $t('doc.product.OnThisPage') }}</p> 
                                            <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg"
                                                    alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#channels-info-link' }">
                                                        <a @click="scrollTo('#channels-info')">
                                                          {{ $t('Advance.Agentbots.workflow') }}</a>
                                                    </li>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#use-cases-link' }">
                                                        <a @click="scrollTo('#use-cases')">
                                                          {{ $t('Advance.Agentbots.useCases') }}
                                                        </a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#implementation-example-link' }">
                                                        <a @click="scrollTo('#implementation-example')">
                                                          {{ $t('Advance.Agentbots.implementationExamples') }}
                                                        </a>
                                                    </li>
                                                    <li
                                                        :class="{ 'active_menu_item': rightPanelMenuItem === '#agent-bots-link' }">
                                                        <a @click="scrollTo('#agent-bots')">
                                                          {{ $t('Advance.Agentbots.addingAgentBots') }}
                                                        </a>
                                                        <ul>
                                                            <li
                                                                :class="{ 'active_menu_item': rightPanelMenuItem === '#creating-agent-bots-via-api-link' }">
                                                                <a @click="scrollTo('#creating-agent-bots-via-api')">
                                                                  {{ $t('Advance.Agentbots.createAgentBotsVieAPI') }}</a>
                                                            </li>
                                                            <li
                                                                :class="{ 'active_menu_item': rightPanelMenuItem === '#creating-agent-bots-via-api-link' }">
                                                                <a
                                                                    @click="scrollTo('#creating-agent-bots-via-rails-console')">
                                                                  {{ $t('Advance.Agentbots.createAgentBotsVieRails') }} </a>
                                                            </li>
                                                        </ul>
                                                    <li :class="{ 'active_menu_item': rightPanelMenuItem === '#faq-link' }"><a
                                                            @click="scrollTo('#faq')">
                                                  {{ $t('faq') }}</a>
                                                        <ul>
                                                            <li
                                                                :class="{ 'active_menu_item': rightPanelMenuItem === '#human-agent-handoff--link' }">
                                                                <a @click="scrollTo('#human-agent-handoff-')">
                                                                  {{ $t('Advance.Agentbots.humanAgent') }}
                                                                    ?</a>
                                                            </li>
                                                        </ul>


                                                    </li>


                                                    </li>




                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="Introduction-details theme-doc-markdown markdown text-align-left">
                            <h1>{{ $t('doc.product.AgentBots') }}</h1>
                            <p><img loading="lazy" alt="agent-bot-architecture"
                                    src="../../../../assets/images/agent-bot-architecture.2a56b52e.png" width="2687" height="1428"
                                    class="max-img"></p>


                            <p>{{ $t('Advance.Agentbots.agentBotsIntro1') }} </p>
                            <p>{{ $t('Advance.Agentbots.agentBotsIntro2') }}<a href="https://www.bcoor.com/developers/api/" target="_blank"
                                    rel="noopener noreferrer">APIs</a></p>
                        </div>
                        <div class="channels-info code-info" id="channels-info">
                            <div class="channels-title">
                                <h2>{{ $t('Advance.Agentbots.workflow') }}</h2>
                                <ul class="channels-menu channels-menu-ul text-align-left">
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro1') }} <code>widget_triggered</code>,
                                        <code>message_created</code>, <code>message_updated</code> {{ $t('Advance.Agentbots.workFlowIntro2') }}
                                    </li>
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro3') }}</li>
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro4') }}</li>
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro5') }}</li>
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro6') }}</li>
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro7') }}</li>
                                    <li>{{ $t('Advance.Agentbots.workFlowIntro8') }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="channels-info text-align-left" id="channels-info">
                            <div class="channels-title">
                                <h2 id="use-cases">{{ $t('Advance.Agentbots.useCases') }}</h2>
                                <ul class="channels-menu channels-menu-ul">
                                    <li>{{ $t('Advance.Agentbots.userCasesIntro1') }}</li>
                                    <li>{{ $t('Advance.Agentbots.userCasesIntro2') }}</li>
                                    <li>{{ $t('Advance.Agentbots.userCasesIntro3') }}</li>
                                    <li>{{ $t('Advance.Agentbots.userCasesIntro4') }}</li>
                                </ul>
                            </div>
                        </div>


                        <div class="channels-info text-align-left" id="channels-info">
                            <div class="channels-title">
                                <h2 id="implementation-examples">{{ $t('Advance.Agentbots.implementationExamples') }}</h2>
                                <ol class="channels-menu-ol">
                                    <li><a href="https://github.com/bcoor/dialogflow-agent-bot-demo" target="_blank"
                                            rel="noopener noreferrer">https://github.com/bcoor/dialogflow-agent-bot-demo</a>
                                        : {{ $t('Advance.Agentbots.implementationExamplesIntro1') }}</li>
                                    <li><a href="https://github.com/bcoor/rasa-agent-bot-demo" target="_blank"
                                            rel="noopener noreferrer">https://github.com/bcoor/rasa-agent-bot-demo</a>
                                        : {{ $t('Advance.Agentbots.implementationExamplesIntro2') }}</li>
                                    <li><a href="https://github.com/douglara/bcoor-botpress-bridge" target="_blank"
                                            rel="noopener noreferrer">https://github.com/douglara/bcoor-botpress-bridge</a>
                                        : {{ $t('Advance.Agentbots.implementationExamplesIntro3') }}</li>
                                </ol>
                                <blockquote class="blockquote_custom" style="height: auto;">
                                    <p style="padding-bottom:0;">{{ $t('Advance.Agentbots.implementationExamplesIntro4') }} <a
                                            href="/docs/product/advanced/interactive-messages">{{ $t('Advance.Agentbots.botMessageTypes') }}</a>
                                      {{ $t('Advance.Agentbots.onBccor') }}</p>
                                </blockquote>
                            </div>
                        </div>


                        <div class="channels-info text-align-left" id="adding-agent-bots-to-bccor">
                            <div class="channels-title" id="agent-bots">
                                <h2 class="anchor ">{{ $t('Advance.Agentbots.addingAgentBots') }}</h2>
                                <p>{{ $t('Advance.Agentbots.addingAgentBotsIntro1') }}</p>
                                <ol class="channels-menu-ol">
                                    <li><strong>{{ $t('Advance.Agentbots.globalBots') }}</strong> : {{ $t('Advance.Agentbots.addingAgentBotsIntro2') }}</li>
                                    <li><strong>{{ $t('Advance.Agentbots.accountBots') }}</strong> : {{ $t('Advance.Agentbots.addingAgentBotsIntro3') }} </li>
                                </ol>
                                <p>{{ $t('Advance.Agentbots.addingAgentBotsIntro4') }}</p>
                                <h2 class="anchor " id="creating-agent-bots-via-api">
                                  {{ $t('Advance.Agentbots.createAgentBotsVieAPI') }}</h2>
                                <ol class="channels-menu-ol">
                                    <li><strong> {{ $t('Advance.Agentbots.globalBots') }}</strong> {{ $t('Advance.Agentbots.createAgentBotsVieApiIntro1') }} <a
                                            href="https://www.bcoor.com/developers/api/#tag/AgentBots" target="_blank"
                                            rel="noopener noreferrer">{{ $t('Advance.Agentbots.createAgentBotsVieAPI') }}</a>. </li>
                                    <li><strong> {{ $t('Advance.Agentbots.agentBotApis') }}</strong> {{ $t('Advance.Agentbots.createAgentBotsVieApiIntro2') }} <a
                                            href="https://www.bcoor.com/developers/api/#tag/Account-AgentBots"
                                            target="_blank" rel="noopener noreferrer">{{ $t('Advance.Agentbots.accountBotApis') }}</a>.</li>
                                </ol>
                                <h2 class="anchor " id="creating-agent-bots-via-rails-console">
                                  {{ $t('Advance.Agentbots.createAgentBotsVieRails') }}</h2>
                                <p>{{ $t('Advance.Agentbots.createAgentBotsVieRailsIntro1') }}</p>
                                <div class="codeBlockContainer">
                                    <div class="codeBlockContent"
                                        style="color: rgb(191, 199, 213); background-color: rgb(41, 45, 62);">
                                        <pre tabindex="0"
                                            class="prism-code language-text codeBlock_jd64 thin-scrollbar"><code class="codeBlockLines_mRuA"><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">{{ $t('Advance.Agentbots.bundleExec') }}</span><br></span></code></pre>
                                        <button type="button" aria-label="Copy code to clipboard" title="Copy"
                                            class="copyButton clean-btn"><span class="copyButtonIcons"
                                                aria-hidden="true"><svg class="copyButtonIcon" viewBox="0 0 24 24">
                                                    <path
                                                        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z">
                                                    </path>
                                                </svg><svg class="copyButtonSuccessIcon" viewBox="0 0 24 24">
                                                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z">
                                                    </path>
                                                </svg></span></button>
                                    </div>
                                </div>
                                <p>{{ $t('Advance.Agentbots.createAgentBotsVieRailsIntro2') }}</p>
                                <div class="codeBlockContainer theme-code-block">
                                    <div class="codeBlockContent"
                                        style="color: rgb(191, 199, 213); background-color: rgb(41, 45, 62);">
                                        <pre tabindex="0"
                                            class="prism-code language-text codeBlock_jd64 thin-scrollbar"><code class="codeBlockLines_mRuA"><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain"># {{ $t('Advance.Agentbots.specifyUrl') }}</span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain"># {{ $t('Advance.Agentbots.passAccountId') }} bot</span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">{{ $t('Advance.Agentbots.botAgentUrl') }}</span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">{{ $t('Advance.Agentbots.botAccessToken') }}</span><br></span></code></pre>
                                        <button type="button" aria-label="Copy code to clipboard" title="Copy"
                                            class="copyButton clean-btn"><span class="copyButtonIcons"
                                                aria-hidden="true"><svg class="copyButtonIcon" viewBox="0 0 24 24">
                                                    <path
                                                        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z">
                                                    </path>
                                                </svg><svg class="copyButtonSuccessIcon" viewBox="0 0 24 24">
                                                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z">
                                                    </path>
                                                </svg></span></button>
                                    </div>
                                </div>
                                <p>Add an avatar to your bot (optional)</p>
                                <div class="codeBlockContainer theme-code-block">
                                    <div class="codeBlockContent"
                                        style="color: rgb(191, 199, 213); background-color: rgb(41, 45, 62);">
                                        <pre tabindex="0"
                                            class="prism-code language-text codeBlock_jd64 thin-scrollbar"><code class="codeBlockLines_mRuA"><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">{{ $t('Advance.Agentbots.avatarFileDown') }}</span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">{{ $t('Advance.Agentbots.avatarFileAttach') }}</span><br></span></code></pre>
                                        <button type="button" aria-label="Copy code to clipboard" title="Copy"
                                            class="copyButton clean-btn"><span class="copyButtonIcons"
                                                aria-hidden="true"><svg class="copyButtonIcon" viewBox="0 0 24 24">
                                                    <path
                                                        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z">
                                                    </path>
                                                </svg><svg class="copyButtonSuccessIcon" viewBox="0 0 24 24">
                                                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z">
                                                    </path>
                                                </svg></span></button>
                                    </div>
                                </div>
                                <p>{{ $t('Advance.Agentbots.connectAgentBot') }}</p>
                                <div class="codeBlockContainer theme-code-block">
                                    <div class="codeBlockContent"
                                        style="color: rgb(191, 199, 213); background-color: rgb(41, 45, 62);">
                                        <pre tabindex="0"
                                            class="prism-code language-text codeBlock_jd64 thin-scrollbar"><code class="codeBlockLines_mRuA"><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain"># {{ $t('Advance.Agentbots.connectAgentBotLink1') }}</span><br></span><span class="token-line" style="color: rgb(191, 199, 213);"><span class="token plain">{{ $t('Advance.Agentbots.connectAgentBotLink2') }}</span><br></span></code></pre>
                                        <button type="button" aria-label="Copy code to clipboard" title="Copy"
                                            class="copyButton clean-btn"><span class="copyButtonIcons"
                                                aria-hidden="true"><svg class="copyButtonIcon" viewBox="0 0 24 24">
                                                    <path
                                                        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z">
                                                    </path>
                                                </svg><svg class="copyButtonSuccessIcon" viewBox="0 0 24 24">
                                                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z">
                                                    </path>
                                                </svg></span></button>
                                    </div>
                                </div>
                                <h2 class="channels-title-custom" id="faq">{{ $t('doc.product.FAQ') }}</h2>
                                <h2 class="channels-title-custom" id="human-agent-handoff-">{{ $t('Advance.Agentbots.humanAgent') }}</h2>
                                <p class="code-info">{{ $t('Advance.Agentbots.faqIntro2') }}
                                    <code>pending</code> status instead of <code>open</code>{{ $t('Advance.Agentbots.faqIntro3') }}<code>open</code>
                                </p>
                                <p class="code-info">{{ $t('Advance.Agentbots.faqIntro4') }}<code>pending</code> {{ $t('Advance.Agentbots.faqIntro5') }}</p>
                            </div>
                        </div>
                        <div class="edit-update-detail">
                            <!-- <div class="edit-pen">
                                <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                    aria-hidden="true">
                                    <g>
                                        <path
                                            d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                        </path>
                                    </g>
                                </svg>
                                <a href="#">{{ $t('doc.product.EditThisPage') }}</a>
                            </div> -->
                            <p>{{ $t('doc.product.LastUpdated') }} <span class="dark">5/03/2023 </span>{{ $t('Mobile_App.by') }} <span class="dark">Douglas Lara</span>
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p class="preview">{{ $t('Mobile_App.previous') }}</p>
                                    <router-link to="/docs/product/features/integrations/slack" class="introduction-account">
                                        <span>«</span> {{ $t('doc.product.Slack') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="create-chat-woot-account">
                                    <p>{{ $t('Mobile_App.next') }}</p>
                                    <router-link to="/docs/product/advanced/interactive-messages">
                                        I{{ $t('Advance.Agentbots.interactiveMessages') }}<span>»</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info">
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#channels-info-link' }"><a
                                        id="#channels-info-link" @click="scrollTo('#channels-info')">
                                  {{ $t('Advance.Agentbots.workflow') }}</a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#use-cases-link' }"><a
                                        id="#use-cases-link" @click="scrollTo('#use-cases')">
                                  {{ $t('Advance.Agentbots.useCases') }}
                                    </a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#implementation-examples-link' }"><a
                                        id="#implementation-examples-link" @click="scrollTo('#implementation-examples')">
                                  {{ $t('Advance.Agentbots.implementationExamples') }}
                                    </a></li>
                                <li :class="{ 'active_menu_item': rightPanelMenuItem === '#agent-bots-link' }"><a
                                        id="#agent-bots-link" @click="scrollTo('#agent-bots')">
                                  {{ $t('Advance.Agentbots.addingAgentBots') }}
                                    </a>
                                    <ul>
                                        <li
                                            :class="{ 'active_menu_item': rightPanelMenuItem === '#creating-agent-bots-via-api-link' }">
                                            <a id="#creating-agent-bots-via-api-link" @click="scrollTo('#creating-agent-bots-via-api')">
                                              {{ $t('Advance.Agentbots.createAgentBotsVieAPI') }}</a>
                                        </li>
                                        <li
                                            :class="{ 'active_menu_item': rightPanelMenuItem === '#creating-agent-bots-via-rails-console-link' }">
                                            <a id="#creating-agent-bots-via-rails-console-link" @click="scrollTo('#creating-agent-bots-via-rails-console')">
                                              {{ $t('Advance.Agentbots.createAgentBotsVieRails') }}</a>
                                        </li>
                                    </ul>
                                    </li>
                                    <ul>
                                        <li :class="{ 'active_menu_item': rightPanelMenuItem === '#human-agent-handoff--link' }">
                                            <a id="#human-agent-handoff--link" @click="scrollTo('#human-agent-handoff-')">
                                               {{ $t('Advance.Agentbots.humanAgent') }}
                                                ?</a>
                                        </li>
                                    </ul>
                                <!-- </li> -->
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
import MainContent from "@/components/MainContent.vue";

export default {
    name: 'agent-bots',
    data() {
        return {
            isToggle: false,
            activeMenuItem: null,
            rightPanelMenuItem: null
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        MainContent
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
        scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
                this.rightPanelMenuItem = `${selector}-link`
            }
        },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');
            this.activeMenuItem = null
            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                // console.log(menuItem)
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('id')?.replace(/-link(?=[^-]*$)/, ""));
                if ((target?.offsetTop - 200) <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                        let targetID = target?.getAttribute('id')
                        this.rightPanelMenuItem = `#${targetID}-link`
                        // menuItem?.classList.add("active_menu_item");
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}</style>
