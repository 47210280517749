<template>
  <Header @language-selected="handleLanguageSelected"/>
  <div class="blog-page">
    <div class="bg-white">
      <div class="bg-gradient-to-b from-white to-blue-50">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="pricing_heading pricing_plans">
                <h1>{{ $t("pricing.pricingplans") }}</h1>
                <p>
                  {{ $t("pricing.pricingbcoor") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main class="container">
      <div class="row">
        <div class="col-12">
          <div class="Business_wrp">
            <div class="pricing_table_wrp">
              <table>
                <thead>
                  <tr>
                    <th class="pre-plans">
                      {{ $t("pricing.pricingPlans") }}
                    </th>
                    <th class="plan">
                      {{ $t("pricing.pricingfree") }}
                    </th>
                    <th class="plan">
                      {{ $t("pricing.pricingpro") }}
                    </th>
                    <th class="plan">
                      {{ $t("pricing.pricingMax") }}
                    </th>
                    <th class="plan">
                      {{ $t("pricing.pricingproMax") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="pricing_wrp">
                    <th>
                      {{ $t("pricing.pricingPricing") }}
                    </th>
                    <td>
                      <div class="pricing_details">
                        <div class="month_info">
                          <p>
                            {{ $t("pricing.pricingdollar") }}
                            <span>
                              {{ $t("pricing.pricingagent") }}
                            </span>
                          </p>
                        </div>
                        <p class="agent_wrp">
                          {{ $t("pricing.pricingagentupto") }}
                        </p>
                        <a href="https://app.bcoor.com/app/auth/signup" class="d-flex justify-sm-content-center w-100 text-decoration-none">
                          <button class="create-button account-button accountbtn">
                            {{ $t("home.header.Createafreeaccount") }}
                          </button>
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class="pricing_details">
                        <div class="month_info">
                          <p>
                            {{ $t("pricing.pricing188") }}
                            <span>
                              {{ $t("pricing.pricing20") }}
                            </span>
                          </p>
                        </div>
                        <p class="agent_wrp">
                          {{ $t("pricing.pricingBilled") }}
                        </p>
                        <a href="https://app.bcoor.com" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none">
                          <button type="button" aria-label="butto name">
                            {{ $t("pricing.pricingBuy") }}
                          </button>
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class="pricing_details">
                        <div class="month_info">
                          <p>
                            {{ $t("pricing.pricing888") }}
                            <span>
                              {{ $t("pricing.pricing60") }}
                            </span>
                          </p>
                        </div>
                        <p class="agent_wrp">
                          {{ $t("pricing.pricingBilled") }}
                        </p>
                        <a href="https://app.bcoor.com" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none">
                          <button type="button" aria-label="butto name">
                            {{ $t("pricing.pricingBuy") }}
                          </button>
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class="pricing_details">
                        <div class="month_info">
                          <p>
                            ??
                            <span>
                              <!-- /agent/month -->
                            </span>
                          </p>
                        </div>
                        <p class="agent_wrp">
                          {{ $t("pricing.pricingbilledAnnually") }}
                        </p>
                        <a href="https://t.me/bcoorsupport" target="#blank" class="d-flex justify-sm-content-center w-100">
                          <button type="button" aria-label="butto name">
                            {{ $t("pricing.pricingBuy") }}
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th class="pricing-channel">
                      {{ $t("doc.channels") }}
                    </th>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("headersubmenu.Features.headerChat") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingEmail") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.facebook.facebook") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.Instagram") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.Twitter") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingWhatsApp") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.Telegram") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.Line") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingAPI") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {{ $t("pricing.pricingCollaboration") }}
                    </th>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingprivate") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingMentions") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingLabels") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("headersubmenu.Features.headerKeyboardShortcuts") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.CannedResponses") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingcommandbar") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingauto") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingmulti") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingCustom") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingFilters") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("headersubmenu.Features.headerBusinessHours") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingAutoresponder") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingTeams") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingAutomation") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingAgentCapacity") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {{ $t("pricing.pricingManage") }}
                    </th>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingSegments") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingSegmentsContact") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingCampaigns") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.CustomAttributes") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingPreChatForm") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {{ $t("doc.integrations.integrations") }}
                    </th>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.integrations.slack") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.product.DialogFlow") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("doc.integrations.dashboardApps") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {{ $t("home.sidebarMenu.Reports") }}
                    </th>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingLiveView") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingConversation") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingInbox") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("features.commandBar.labelReport") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("features.commandBar.teamReport") }}
                    </th>
                    <td></td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("headersubmenu.Features.headerCSATReports") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingDownloadable") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {{ $t("doc.product.Others") }}
                    </th>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingSAML") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingLive") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300 mini"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <!-- <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingLive") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr> -->

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingDedicated") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingconversations") }}
                    </th>
                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingMessage") }}
                      </p>
                    </td>

                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>

                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>

                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingdata") }}
                    </th>
                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>

                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>

                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>

                    <td>
                      <p class="days">
                        {{ $t("pricing.pricingUnlimited") }}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingRemove") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat">
                      {{ $t("pricing.pricingPhone") }}
                    </th>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                        ></path>
                      </svg>
                    </td>

                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                        style=""
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 12"
                        data-v-ca945699=""
                      >
                        <path
                          fill="currentColor"
                          d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                        ></path>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <th class="sub_live_chat"></th>
                    <td class="table_btn_wrp">
                      <a class="d-flex justify-sm-content-center w-100 text-decoration-none">
                        <button
                          class="create-button account-button accountbtn" style="margin : 0"
                          @click="handleRedirect"
                        >
                          {{ $t("home.header.Createafreeaccount") }}
                        </button>
                      </a>
                    </td>
                    <td class="table_btn_wrp">
                      <a href="https://t.me/bcoorsupport" target="#blank">
                        <button type="button" aria-label="butto name">
                          {{ $t("pricing.pricingBuy") }}
                        </button>
                      </a>
                    </td>
                    <td class="table_btn_wrp">
                      <a href="https://t.me/bcoorsupport" target="#blank">
                        <button type="button" aria-label="butto name">
                          {{ $t("pricing.pricingBuy") }}
                        </button>
                      </a>
                    </td>
                    <td class="table_btn_wrp">
                      <a href="https://t.me/bcoorsupport" target="#blank">
                        <button type="button" aria-label="butto name">
                          {{ $t("pricing.pricingBuy") }}
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="Business_card_box">
            <div class="card_box start_up">
              <div class="card_data_wrp">
                <h5>
                  {{ $t("pricing.pricingfree") }}
                </h5>
                <div>
                  <div class="pricing_details">
                    <div class="month_info">
                      <p>
                        {{ $t("pricing.pricingdollar") }}
                        <span>
                          {{ $t("pricing.pricingagent") }}
                        </span>
                      </p>
                    </div>
                    <p class="agent_wrp">
                      {{ $t("pricing.pricingagentupto") }}
                    </p>
                    <a class="d-flex justify-sm-content-center w-100 text-decoration-none">
                      <button
                        class="px-4 w-100"
                        @click="handleRedirect"
                      >
                        {{ $t("home.header.Createafreeaccount") }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.menu.Channels") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerChat") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("Advance.DashboardApps.email") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Facebook") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Instagram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Twitter") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerSMS") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Telegram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Line") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.APIChannel") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingCollaboration") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingprivate") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingMentions") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLabels") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerKeyboardShortcuts") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CannedResponses") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headercommandBar") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingauto") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingmulti") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCustom") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingFilters") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerBusinessHours") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAutoresponder") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingTeams") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingAutomation") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAgentCapacity") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingManage") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSegments") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingSegmentsContact") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCampaigns") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CustomAttributes") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingPreChatForm") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.createChatWoodAccount.integrations") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerSlack") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.DialogFlow") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.integrations.dashboardApps") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2"></th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLiveView") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingConversation") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.agentReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.inboxReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLabels") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingTeams") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerCSATReports") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingDownloadable") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.product.Others") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSAML") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLiveView") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPriority") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingDedicated") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingconversations") }}
                      </th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingMessage") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingdata") }}</th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingRemove") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPhone") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="table_btn_wrp">
                        <div class="d-flex justify-content-center"  >
                          <a class="d-flex justify-sm-content-center w-100 px-sm-4 text-decoration-none">
                            <button
                            class="w-100"
                            @click="handleRedirect"
                            >
                            {{ $t("home.header.Createafreeaccount") }}
                          </button>
                        </a>
                      </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card_box start_up">
              <div class="card_data_wrp">
                <h5>
                  {{ $t("pricing.pricingpro") }}
                </h5>
                <div>
                  <div class="pricing_details">
                    <div class="month_info">
                      <p>
                        {{ $t("pricing.pricing188") }}
                        <span>
                          {{ $t("pricing.pricing20") }}
                        </span>
                      </p>
                    </div>
                    <p class="agent_wrp">
                      {{ $t("pricing.pricingBilled") }}
                    </p>
                    <a href="https://t.me/bcoorsupport" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none">
                      <button type="button" aria-label="butto name" class="w-100">
                        {{ $t("pricing.pricingBuy") }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.menu.Channels") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerChat") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Email") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.instagram.navigation.facebook") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Instagram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Twitter") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerChat") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Telegram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Line") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.APIChannel") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingCollaboration") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingprivate") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingMentions") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLabels") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerKeyboardShortcuts") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CannedResponses") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingcommandbar") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingauto") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingmulti") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCustom") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingFilters") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerBusinessHours") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAutoresponder") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingTeams") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingAutomation") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAgentCapacity") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingManage") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSegments") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingSegmentsContact") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCampaigns") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CustomAttributes") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingPreChatForm") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("headersubmenu.Features.headerIntegrations") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerSlack") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.DialogFlow") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("Advance.DashboardApps.dashborad") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("home.sidebarMenu.Reports") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLiveView") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingConversation") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.agentReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.inboxReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.labelReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.teamReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerCSATReports") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingDownloadable") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("home.sidebarMenu.Reports") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLiveView") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingConversation") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.agentReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.inboxReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.labelReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.teamReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerCSATReports") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingDownloadable") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.product.Others") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSAML") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLive") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPriority") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingDedicated") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingconversations") }}
                      </th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingdata") }}</th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingRemove") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPhone") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <td class="table_btn_wrp" colspan="2">
                        <a href="https://t.me/bcoorsupport" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none px-sm-4">
                          <button type="button" aria-label="butto name">
                            {{ $t("pricing.pricingBuy") }}
                          </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card_box start_up">
              <div class="card_data_wrp">
                <h5>
                  {{ $t("pricing.pricingMax") }}
                </h5>
                <div>
                  <div class="pricing_details">
                    <div class="month_info">
                      <p>
                        {{ $t("pricing.pricing888") }}
                        <span>
                          {{ $t("pricing.pricing60") }}
                        </span>
                      </p>
                    </div>
                    <p class="agent_wrp">
                      {{ $t("pricing.pricingBilled") }}
                    </p>
                    <a href="https://t.me/bcoorsupport" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none">
                      <button type="button" class="w-100" aria-label="butto name">
                        {{ $t("pricing.pricingBuy") }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.menu.Channels") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerChat") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingEmail") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Facebook") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Instagram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Twitter") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerSMS") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Telegram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Line") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.APIChannel") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingCollaboration") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingprivate") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingMentions") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLabels") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerKeyboardShortcuts") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CannedResponses") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headercommandBar") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingauto") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingmulti") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCustom") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingFilters") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerBusinessHours") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAutoresponder") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingTeams") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingAutomation") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAgentCapacity") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingManage") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSegments") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingSegmentsContact") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCampaigns") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingPreChatForm") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingPreChatForm") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("Advance.DashboardApps.integrations") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.integrations.slack") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.DialogFlow") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("Advance.DashboardApps.dashborad") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("home.sidebarMenu.Reports") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLiveView") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingConversation") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.agentReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingInbox") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.labelReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerTeamReports") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerCSATReports") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingDownloadable") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.product.Others") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSAML") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLive") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPriority") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingDedicated") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingconversations") }}
                      </th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingdata") }}</th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingRemove") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPhone") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="dash_wrp h-6 w-6 p-1 rounded-full bg-gray-50 text-gray-300"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 20 20"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M2 9.75A.75.75 0 0 1 2.75 9h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 9.75Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <td class="table_btn_wrp" colspan="2">
                        <a href="https://t.me/bcoorsupport" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none px-sm-4">
                          <button type="button" aria-label="butto name">
                            {{ $t("pricing.pricingBuy") }}
                          </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card_box start_up">
              <div class="card_data_wrp">
                <h5>
                  {{ $t("pricing.pricingproMax") }}
                </h5>
                <div>
                  <div class="pricing_details">
                    <div class="month_info">
                      <p>
                        ??
                        <span>
                          <!-- /agent/month -->
                        </span>
                      </p>
                    </div>
                    <p class="agent_wrp">
                      {{ $t("pricing.pricingBilled") }}
                    </p>
                    <a href="https://t.me/bcoorsupport" target="#blank" class="d-flex justify-sm-content-center w-100 text-decoration-none">
                      <button type="button" class="w-100" aria-label="butto name">
                        {{ $t("pricing.pricingBuy") }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.menu.Channels") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerChat") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingEmail") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Facebook") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Instagram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Twitter") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerSMS") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Telegram") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.Line") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.APIChannel") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingCollaboration") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingprivate") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingMentions") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLabels") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerKeyboardShortcuts") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CannedResponses") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingcommandbar") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingauto") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingmulti") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCustom") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingFilters") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerBusinessHours") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAutoresponder") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingTeams") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingAutomation") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingAgentCapacity") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("pricing.pricingManage") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSegments") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingSegmentsContact") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingCampaigns") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.product.CustomAttributes") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingPreChatForm") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.integrations.integrations") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerSlack") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("doc.product.DialogFlow") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("doc.integrations.dashboardApps") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("home.sidebarMenu.Reports") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLiveView") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingConversation") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerAgentReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.inboxReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.labelReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("features.commandBar.teamReport") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("headersubmenu.Features.headerCSATReports") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingDownloadable") }}
                      </th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_table_box">
                <table>
                  <thead>
                    <th class="card_header_wrp" colspan="2">
                      {{ $t("doc.product.Others") }}
                    </th>
                  </thead>

                  <tbody>
                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingSAML") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingLive") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPriority") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingDedicated") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">
                        {{ $t("pricing.pricingconversations") }}
                      </th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingdata") }}</th>
                      <td>
                        <p>
                          {{ $t("pricing.pricingUnlimited") }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingRemove") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3
                                                .org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <th class="sub_live_chat">{{ $t("pricing.pricingPhone") }}</th>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="icon h-6 w-6 p-1 rounded-full bg-green-100 text-green-500"
                          style=""
                          width="1em"
                          height="1em"
                          viewBox="0 0 12 12"
                          data-v-ca945699=""
                        >
                          <path
                            fill="currentColor"
                            d="M9.765 3.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L2.22 6.53a.75.75 0 0 1 1.06-1.06l1.705 1.704l3.72-3.939a.75.75 0 0 1 1.06-.03Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>

                    <tr>
                      <td class="table_btn_wrp" colspan="2">
                        <a href="https://t.me/bcoorsupport" target="#blank"  class="d-flex justify-sm-content-center w-100 text-decoration-none px-sm-4">
                          <button type="button" aria-label="butto name">
                            {{ $t("pricing.pricingBuy") }}
                          </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
import Footer from "@/views/chatwoot/Footer.vue";
import Header from "@/views/chatwoot/Header.vue";

export default {
  name: "sharedInbox",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      selectLanguage: 'en',
    };
  },
  methods: {
    handleLanguageSelected (language) {
      this.selectLanguage = language;
    },handleRedirect() {
      window.location.href = 'https://app.bcoor.com/app/auth/signup'
    }
  },
  created() {
    const localStorageItem = localStorage.getItem('language');

    if (localStorageItem) {

      this.selectLanguage = localStorageItem;
    }
  }
};
</script>
