<template>
    <Header @language-selected="handleLanguageSelected"/>
         <div class="blog-page">
        <div class="bg-white">
            <div class="bg-gradient-to-b from-white to-blue-50">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="blog-title-box">
                                <h1>{{ $t('engineering.Engineering') }}</h1>
                                <p>
                                   {{ $t('engineering.engineeringalways') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-wrap d-flex">
                        <div class="left-blog">
                            <div class="left-blog-fixed">
                              <h3 class="uppercase">{{ $t('blog.blogCategories') }}</h3>
                              <ul role="list" class="blogul">
                                <li>
                                  <a aria-current="page" href="file:///D:/chat/blog.html" class="">{{ $t('blog.blogs') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/product-update.html" class="">{{ $t('blog.blogupdates') }}</a>
                                </li>
                              </ul>
                            </div>
              
                            <div class="left-blog-fixed">
                              <h3 class="uppercase">{{ $t('blog.blogTAGS') }}</h3>
                              <ul role="list" class="blogul">
                                <li>
                                  <a aria-current="page" href="file:///D:/chat/custmer_service.html" class="">{{ $t('blog.blogCustomer') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/engineering.html" class="">{{ $t('blog.blogEngineering') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/open-source.html" class="">{{ $t('blog.blogOpensource') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/woot-journal.html" class="">{{ $t('blog.blogWoot') }}</a>
                                </li>
                                <li data-v-a9d1c59d="">
                                  <a href="file:///D:/chat/more-chatwoot.html" class="">{{ $t('blog.blogwithbcoor') }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        <div class="right-blog">
                            <div class="right-blog-list">
                                <div class="right-blog-list-inner">
                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2022/07/Chatwoot-engineering.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('engineering.engineeringHow') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                             {{ $t('engineering.engineeringfacing') }} </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2022/07/tejaswini.jpeg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first"> {{ $t('engineering.engineeringTejaswini') }} </p>
                                                        <p class="blog-user-second">{{ $t('engineering.engineeringjul') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/deeplinking-banner.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                       {{ $t('engineering.engineeringDeep') }}  
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('engineering.engineeringLearnings') }}  
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/Screenshot-2021-08-15-at-2.39.18-PM.png" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first"> {{ $t('engineering.engineeringLearnings') }}  </p>
                                                        <p class="blog-user-second">{{ $t('engineering.engineeringjul14') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listing-blog-main">
                                        <div class="listing-blog">
                                            <div class="listing-blog-left">
                                                <a href="#">
                                                    <img
                                                        src="https://www-internal-blog.chatwoot.com/content/images/2021/08/local_tunnel_ts.png" />
                                                </a>
                                            </div>
                                            <div class="listing-blog-right">
                                                <div class="listing-blog-wrap">
                                                    <div class="listing-blog-heading">
                                                        {{ $t('engineering.engineeringaccess') }}
                                                    </div>
                                                    <p class="listing-blog-paragraph">
                                                          {{ $t('engineering.engineeringdevelopment') }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center blog-user">
                                                    <div class="blog-user-image">
                                                        <img
                                                            src="https://www-internal-blog.chatwoot.com/content/images/2021/08/zQK4swoV_400x400.jpg" />
                                                    </div>
                                                    <div class="blog-user-text">
                                                        <p class="blog-user-first"> {{ $t('engineering.engineeringsony') }}</p>
                                                        <p class="blog-user-second">{{ $t('engineering.engineeringmay') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Footer :selectLanguage="selectLanguage"/>
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
        import Header from "@/views/chatwoot/Header.vue";
    
        export default {
      name: "sharedInbox",
      components: {
        Footer,
        Header
      },
          data () {
            return {
              selectLanguage: 'en',
            };
          },
          methods: {
            handleLanguageSelected (language) {
              this.selectLanguage = language;
            },
          },
          created() {
            const localStorageItem = localStorage.getItem('language');

            if (localStorageItem) {

              this.selectLanguage = localStorageItem;
            }
          }
    }
    </script>