<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                        <div class="getting-details">
                            <p>{{ $t('Mobile_App.mobile_app') }}</p>
                            <!-- <img src="../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow"> -->
                            <a href="#">{{ $t('Mobile_App.android') }}</a>
                        </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                          <p>{{ $t('Mobile_App.on_this_page') }}</p>  
       <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                     <li class="{ 'active_menu_item': rightPanelMenuItem === '#Production' }">
                                            <a @click="scrollTo('#Production')">
                                              {{ $t('Mobile_App.production') }}</a>
                                        </li>
                                         <!-- <li class="{ 'active_menu_item': rightPanelMenuItem === '#Testing' }">
                                                <a @click="scrollTo('#Testing')">
                                                  Testin</a>
                                            </li> -->
                               
                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="version-detail webflow">
                            <h4 >{{ $t('Mobile_App.android') }}</h4>
                            <p>{{ $t('Mobile_App.android_intro') }}</p>
                            <div class="android_options" id="Production">
                                <h4>
                                  {{ $t('Mobile_App.production') }}
                                </h4>
                                <ul>
                                    <li>
                                      {{ $t('Mobile_App.production_android_intro_1') }}
                                    </li>
                                    <li>
                                      {{ $t('Mobile_App.production_android_intro_2') }}
                                    </li>
                                    <li>
                                      {{ $t('Mobile_App.production_android_intro_3') }}
                                    </li>
                                    <!-- <li>
                                        Enter Installation URL: This is the page you go to when you want to access
                                        bcoor. It’s in the format
                                        
                                                    <span class="click-inbox">domain.com</span>         
                                                        . If you are using self-hosted bcoor
                                        installation,
                                        input
                                        your
                                        server URL. Otherwise, use
                                        <code>
                                                            app.bcoor.com
                                                        </code>
                                    </li> -->
                                    <li>
                                      {{ $t('Mobile_App.click_on') }}<span class="click-inbox"> {{ $t('Mobile_App.connect') }}</span>  {{ $t('Mobile_App.button_to_login') }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- <div class="version-detail webflow" id="Testing">
                            <h4>Testing</h4>

                            <p>To help with testing app updates before they're released, you can sign up in our beta
                                program. Open this <a href="https://play.google.com/apps/testing/com.chatwoot.app"
                                    target="_blank" rel="noopener noreferrer">link</a>
                                from your Android device.</p>

                            <p>You can leave the Beta testing program at any time by clicking this <a
                                    href="https://play.google.com/apps/testing/com.chatwoot.app" target="_blank"
                                    rel="noopener noreferrer">link</a> while logged in with your Google Play email
                                address used to opt-in for the Beta program, then click <span class="step">Leave the
                                    program.</span></p>
                        </div> -->
                             <div class="edit-update-detail">
                                <!-- <div class="edit-pen">
                                    <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                        aria-hidden="true">
                                        <g>
                                            <path
                                                d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                            </path>
                                        </g>
                                    </svg>
                                    <a href="https://github.com/chatwoot/docs/edit/main/docs/product.md">{{ $t('Mobile_App.edit_this_page') }}</a>
                                </div> -->
                                <p>{{ $t('Mobile_App.last_update') }} <span class="dark">5/03/2023 </span>{{ $t('Mobile_App.by') }} <span class="dark">Douglas Lara</span>
                                </p>
                            </div>

                            <div class="row and-ios">
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p class="preview">{{ $t('Mobile_App.previous') }}</p>
                                        <router-link to="/docs/product/advanced/websocket-events" class="introduction-account">
                                            <span>«</span> {{ $t('Mobile_App.websocket_events') }} 
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="create-chat-woot-account">
                                        <p>{{ $t('Mobile_App.next') }}</p>
                                        <router-link to="/docs/product/mobile-app/ios">
                                          {{ $t('Mobile_App.android') }}<span>»</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                    </div>

                </div>

                <!-- side barr section -->
                <!-- <div class="col-xl-3 col-lg-4">
                    <section class="tableOfContents">
                        <div class="tableOfContents-list">
                            <ul id="side_section_info"> -->
                                 <!-- <li class="{ 'active_menu_item': rightPanelMenuItem === '#Production' }">
                                        <a @click="scrollTo('#Production')">
                                          {{ $t('Mobile_App.production') }}</a>
                                    </li> -->
                                     <!-- <li class="{ 'active_menu_item': rightPanelMenuItem === '#Testing' }">
                                            <a @click="scrollTo('#Testing')">
                                              Testing</a>
                                        </li> -->
                               
                            <!-- </ul>
                        </div>
                    </section>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SideBar from "@/components/SideBar.vue";
// import MainContent from "@/components/MainContent.vue";

export default {
    name: 'Introduction',
    data() {
        return {
            isToggle: false
        }
    },
    components: {
        Header,
        Footer,
        SideBar,
        // MainContent
    },
    methods: {
         scrollTo(selector) {
            const element = document.querySelector(selector);
            if (element) {
                const offset = 106;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        },
        handleToggle() {
            this.isToggle = !this.isToggle
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
.android_options {
    text-align: left;
}
.android_options ul {
    margin: 0;
    padding: 0;
    padding-left: 32px;
    /* padding-bottom: 23px; */
}
.android_options ul li {
    list-style-type: disc;
    padding-bottom: 20px;
    font-size: 18px;
    /* padding-left: 22px; */
}
.android_options h4 {
    text-align: left;
    padding: 20px 0;
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    color: var(--main-containt-font-color);
}   
</style>
