<template>
    <Header @language-selected="handleLanguageSelected" />
    <section class="labels-page">
        <div class="container">
            <div class="integrations-heading">
                <h2>{{ $t('labels.easy_organize')}}</h2>
                <p>
                    {{ $t('labels.better_organized')}}
                </p>
                <div class="start-button">
                    <a href="https://app.bcoor.com">{{$t("automation.freeTrial") }}</a>
                </div>
            </div>
            <div class="labels-page-details">
                <p>
                    {{ $t('labels.labels_desc') }}
                </p>
            </div>
            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/Labels/adding-label-in-chatwoot.png" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('labels.label_customization') }}</h4>
                            <p>
                                {{ $t('labels.label_customization_bcoor') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12 orderr-2">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('labels.instantlyDesc') }}</h4>
                            <p>
                                {{ $t('labels.instantlyDesc1') }}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 orderr-1">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/Labels/labelling-conversations-from-sidebar.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels-page-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img">
                            <img src="../../../assets/images/Labels/labels-report-in-chatwoot-dashboard.png" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="labels-page-img-details">
                            <h4>{{ $t('labels.overview_desc') }}</h4>
                            <p>
                                {{ $t('labels.overview_desc1') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="conversations d-none">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="conversations-heading">
                            <h3>{{ $t('labels.access_organized') }}</h3>
                            <p>
                                {{ $t('labels.access_organized1') }}
                            </p>
                            <div class="signup-button">
                                <a href="https://app.bcoor.com">{{ $t('emailIntegration.sign_up_free') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="conversations-img">
                            <img src="../../../assets/images/Labels/dashboard-small.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer :selectLanguage="selectLanguage" />
</template>

<script>
    import Footer from "@/views/chatwoot/Footer.vue";
    import Header from "@/views/chatwoot/Header.vue";

    export default {
        name: "sharedInbox",
        components: {
            Footer,
            Header,
        },
      data () {
        return {
          selectLanguage: 'en',
        };
      },
      methods: {
        handleLanguageSelected (language) {
          this.selectLanguage = language;
        },
      },
      created() {
        const localStorageItem = localStorage.getItem('language');

        if (localStorageItem) {

          this.selectLanguage = localStorageItem;
        }
      }
    };
</script>