<template>
    <section class="docmain-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <div class="docmain-container">
                           <div class="getting-details">
                                    <p>{{$t('doc.product.Channels')}}</p>
                                    <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                                    <p>{{$t('doc.inboxes.sms_channel')}}</p>
                                    <img src="../../../../../assets/images/up-arrow.svg" alt="logo" class="right-arrow">
                                    <a href="#">{{$t('twiloChannel.twilio')}}</a>
                                </div>
                        <div class="according-menu">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             <p>{{$t('doc.product.OnThisPage')}}</p>  
       <span class="down_up_icon"><img src="/img/up_arrow_side.7f2fb856.svg" alt=""></span>
                                            
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="tableOfContents-list">
                                                <ul id="side_section_info">
                                                    <li class="{ 'active_menu_item': rightPanelMenuItem === '#Twilio-studio' }"><a @click="scrollTo('#Twilio-studio')"
                                            >{{$t('twiloChannel.congifChannel')}}</a></li>
                                                  
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="Introduction-details">
                            <h1>{{$t('twiloChannel.setUpTwilo')}}</h1>
                            <p><b>{{$t('twiloChannel.step1')}}</b> {{$t('twiloChannel.setupPath')}}</p>
                            <div class="whatssimg">
                                <img src="../../../../../assets/images/adding-inbox-in-chatwootAA.webp">
                              </div>
                              <p><b>{{$t('twiloChannel.step2')}}</b> {{$t('twiloChannel.clickSMS')}}</p>
                              <div class="whatssimg">
                                  <img src="../../../../../assets/images/select_sms.webp">
                                </div>
                                <p><b>{{$t('twiloChannel.step3')}}</b> {{$t('twiloChannel.twiloAPI')}}</p>
                              <div class="whatssimg">
                                  <img src="../../../../../assets/images/sms-inbox-configuration-screen-chatwoot.webp">
                                </div>
                                <p>{{$t('twiloChannel.inputForChannel')}}</p>
                                <div class="table-details pl-0" style="padding-left:0px;">
                                    <table class="table table-bordered widthfull">
                                        <thead>
                                            <tr>
                                                <th>{{$t('twiloChannel.input')}}</th>
                                                <th>{{$t('twiloChannel.DESC')}}</th>
                                                <th>{{$t('twiloChannel.WhereTF')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{$t('twiloChannel.inputCN')}}</td>
                                                <td>{{$t('twiloChannel.description')}}</td>
                                                <td>{{$t('twiloChannel.whereCanIFindIt1')}}</td>
                                            </tr>
                                            <tr>
                                                <td>{{$t('twiloChannel.inputPN')}}</td>
                                                <td>{{$t('twiloChannel.descriptionPN')}}</td>
                                                <td>{{$t('twiloChannel.whereCanIFindItPN')}}</td>
                                            </tr>
                                            <tr>
                                                <td>{{$t('twiloChannel.inputSID')}}</td>
                                                <td>{{$t('twiloChannel.descriptionSID')}}</td>
                                                <td>{{$t('twiloChannel.whereCanIFindItSID')}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{$t('twiloChannel.inputAT')}}</td>
                                                <td>{{$t('twiloChannel.descriptionAT')}}</td>
                                                <td>{{$t('twiloChannel.whereCanIFindItAT')}}</td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                    <p class="text-left">{{$t('twiloChannel.click')}}<span class="click-inbox">{{$t('twiloChannel.createChannel')}}</span>{{$t('twiloChannel.afterFI')}}</p>
                                </div>
                                <p><b>{{$t('twiloChannel.step4')}}</b> {{$t('twiloChannel.addAgentToInbox')}}</p>
                              <div class="whatssimg">
                                  <img src="../../../../../assets/images/add-agents-to-sms-channel.webp">
                                </div>
                                <p>{{$t('twiloChannel.HoorayMSG')}}</p>
                                <div class="whatssimg">
                                    <img src="../../../../../assets/images/whatsapp-inbox-is.webp">
                                  </div>
                                  <p>{{$t('twiloChannel.receiveMSG')}}</p>
                                </div>
                        
                       
                                <div class="Introduction-details" id="Twilio-studio">
                                    <h1>{{$t('twiloChannel.Configbacoor')}}</h1>
                                    <p>{{$t('twiloChannel.webhookURL')}}</p>   
                                    <p>{{$t('twiloChannel.suchCase')}} <a href="#"> {{$t('twiloChannel.foloSteps')}}</a></p> 
                                </div>
                                
                
                 
                          <div class="edit-update-detail">
                              <!-- <div class="edit-pen">
                                  <svg fill="currentColor" height="20" width="20" viewBox="0 0 40 40" class="iconEdit_dcUD"
                                      aria-hidden="true">
                                      <g>
                                          <path
                                              d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z">
                                          </path>
                                      </g>
                                  </svg>
                                  <a href="#">{{$t('doc.inboxes.edit_page')}}</a>
                              </div> -->
                              <p>{{$t('doc.inboxes.last_updated_on')}} <span class="dark">{{$t('twiloChannel.date')}} </span> {{$t('doc.inboxes.updated_by')}} <span class="dark"> {{$t('twiloChannel.name')}}</span></p>
                          </div>
                
                
                      
                            <div class="row">
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p class="preview">{{$t('concepts.previousPage')}}</p>
                                        
                                            
                                                         <router-link to="/docs/product/channels/whatsapp/360dialog" class="introduction-account">
                                                          <span>«</span> {{$t('twiloChannel.360Dialog')}}  <span>»</span>
                                                            </router-link>
                                                    
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="create-chat-woot-account">
                                            <p>{{$t('concepts.nextPage')}}</p>
                                       
                                             
                                                         <router-link to="/docs/product/channels/sms/bandwidth">
                                                            {{$t('twiloChannel.bandWidth')}}  <span>»</span>
                                                    </router-link>
                                        </div>
                                    </div>
                                </div>

                    </div>

                </div>
                
        </div>
    </div>
</section></template>

<script>
export default {
    name: 'MainContent',
    data() {
        return {
            activeMenuItem: null,
            rightPanelMenuItem: null
        };
    },
    mounted() {
        window.addEventListener('scroll', this.setActiveMenuItem);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveMenuItem);
    },
    methods: {
          scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const offset = 106;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
        setActiveMenuItem() {
            const scrollPosition = window.scrollY;
            const menuItems = document.querySelectorAll('#side_section_info li');

            for (let i = menuItems.length - 1; i >= 0; i--) {
                const menuItem = menuItems[i];
                const target = document.querySelector(menuItem.querySelector('a').getAttribute('href'));

                if (target?.offsetTop <= scrollPosition) {
                    let tempEle
                    if (menuItem) {
                        tempEle = menuItem?.getAttribute('id')
                    }
                    this.activeMenuItem = tempEle
                    return;
                }
            }
        },
        isActiveSection(index) {
            return index === this.activeSectionIndex;
        }
    },
}
</script>


6500